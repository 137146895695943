// Customizable Area Start
import React from "react";
import { Box, Button, Container, Grid, Typography, styled, withStyles } from "@material-ui/core";
import { Theme, ThemeProvider, createTheme } from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "typeface-lato";
import FooterWeb from "./Footer.web";
import HeaderWeb from "./Header.web";
import DOMPurify from "dompurify";
import LandingPageController, { Category, HeaderSlide, ICatalogueData, IWeeklyDeals, Props, SingleBanner, configJSON } from "./LandingPageController";
import { LandingPageBg, LandingPageBg2, backIcon, earPods, nextIcon } from "./assets";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { Loader2 } from "../../../components/src/Loader.web";

const CustomCarousel = styled(Carousel)({
  position: "relative",
  "& button": {
    top: "calc(100% - 65px) !important"
  },
  "& > div:nth-child(3)": {
    left: "unset !important",
    right: 50
  }
});
const CustomSlider = styled(Slider)({
  "& .slick-slide > div > div": {
    borderRadius: 10
  },
  "& .slick-slide > div": {
    display: "flex",
    margin: "0 5px",
    gap: 10
  },
  "& .slick-slide": {
    height: "unset"
  },
  "& .slick-prev": {
    top: -25,
    left: "unset",
    right: 50,
    color: "#000",
    backgroundColor: "#fff",
    padding: "5px",
    fontSize: "18px",
    borderRadius: '50%',
  },
  "& .slick-next": {
    top: -25,
    left: "unset",
    right: 0,
    color: "#000",
    backgroundColor: "#fff",
    padding: "5px",
    fontSize: "18px",
    borderRadius: '50%',
    "& >path": {
    }
  },
  "& .slick-prev::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)"
  },
  "& .slick-next::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)"
  }
});
const CustomSlider3 = styled(Slider)({
  "& .slick-slide > div > div": {
    borderRadius: 10
  },
  "& .slick-slide > div": {
    display: "flex",
    margin: "0 8px",
    gap: 10
  },
  "& .slick-slide": {
    height: "unset"
  },
  "& .slick-prev": {
    top: "42%",
    color: "#000",
    backgroundColor: "#fff",
    padding: "4px",
    fontSize: "12px",
    borderRadius: '50%',
  },
  "& .slick-next": {
    top: "42%",
    color: "#000",
    backgroundColor: "#fff",
    padding: "4px",
    fontSize: "12px",
    borderRadius: '50%',
    "& >path": {
    }
  },
  "& .slick-prev::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)"
  },
  "& .slick-next::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)"
  }
});
const CustomSlider2 = styled(Slider)({
  "& .slick-slide": {
    height: "unset"
  },
  "& .slick-prev": {
    fontSize: 16,
    color: "#000"
  },
  "& .slick-next": {
    fontSize: 16,
    color: "#000"
  },
  "& .slick-prev::before": {
    fontSize: 18,
    opacity: 1,
    color: "#000"
  },
  "& .slick-next::before": {
    fontSize: 18,
    opacity: 1,
    color: "#000"
  }
});

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500
    },
    body1: {
      fontSize: 16,
      color: "white",
      fontFamily: "Lato, Arial, san-serif"
    },
    body2: {
      fontSize: 14,
      color: "white",
      fontFamily: "Lato, Arial, san-serif",
      paddingBottom: "10px"
    },
    subtitle1: {
      margin: "20px 0px",
      fontFamily: "Lato, Arial, san-serif"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        fontFamily: "Lato, Arial, san-serif"
      }
    }
  }
});
// Customizable Area End

class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderCarousel() {

    return (
      <Box>
        <Carousel
          autoPlay={false}
          navButtonsAlwaysVisible={this.state.headerSlideShow?.length ? true : false}
          activeIndicatorIconButtonProps={{
            style: webStyle.activeBtnProps
          }}
          indicatorIconButtonProps={{
            style: webStyle.iconBtnProps
          }}
          indicatorContainerProps={{
            style: webStyle.containerProps
          }}
          navButtonsProps={{
            style: {
              ...webStyle.navBtnPropsOne,
              top: '50%',
              transform: 'translateY(-30%)',
            },
          }}
          

        >
          {this.state.headerSlideShow?.map((item: HeaderSlide, index: number) => {
            const image = item.attributes.banner_image;
            return (
              <Box onClick={() => this.handleBannerLinks(item)} data-test-id="headerSlideShow" key={item.attributes.id} style={webStyle.headerSlideBox} className={this.props?.classes?.carousal_image_container}>
                <img data-test-id="logo" src={image} className={this.props?.classes?.carousal_image} style={webStyle.headerImg} />
              </Box>
            );
          })}
        </Carousel>
      </Box>
    );
  }

  renderCategory() {
    const settings = {
      centerMode: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.categorySlideShow.length,
      slidesToScroll: 1,
      boxShadow: "0px 1px 3px #EBEBEB",
      prevArrow: <ArrowBackIos style={webStyle.arrowBack} />,
      nextArrow: <ArrowForwardIos style={webStyle.arrowBack} />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: this.state.categorySlideShow.length >1 ?this.state.categorySlideShow.length-1:1,
            slidesToScroll: 1,
            infinite: true,
            
           
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow:  this.state.categorySlideShow.length >2 ?this.state.categorySlideShow.length-2:1,
            slidesToScroll: 1,
            infinite: true,
            
          }
        },
        {
          breakpoint: 786,
          settings: {
            slidesToShow:  this.state.categorySlideShow.length >3 ? this.state.categorySlideShow.length-4:1,
            slidesToScroll: 1,
            infinite: true,
            
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: this.state.categorySlideShow.length > 1 ? 3:1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 460,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
    };

    return (
      <Box>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} className={this.props?.classes?.categoryPage}>
          <Typography variant={"h2"} className="shopByText">
            {configJSON.shopByCategory}
          </Typography>
          <Typography variant={"h4"} style={webStyle.allCategoryText}>
            {configJSON.allCategoryText}
          </Typography>
        </Box>
        { this.state.categoryLoading ? 
          (<Box sx={webStyle.loaderWrapper} id="loaderBox">
            <Loader2 loading={this.state.categoryLoading} />
          </Box>)
        :
         <Box style={webStyle.categorySliderBox}>
          <CustomSlider2 {...settings}>
            {this.state.categorySlideShow?.map((item: Category, index: number) => {
              const image = item.attributes.category_image;
              return (
                <Box data-test-id="categorySlideShow" onClick={() => this.handleCategorySlideShow(item.id,item.attributes.name)} className={this.props?.classes?.textStyle}>
                <Box key={index + "categorySlideShow"} style={webStyle.categoryBox}>
                  <Box
                    style={webStyle.categoryCardOne}
                  >
                    <img src={image || earPods} alt={item?.attributes?.name} style={webStyle.name} />
                    <Typography style={webStyle.categoryText}>{item?.attributes?.name}</Typography>
                  </Box>
                </Box>
                </Box>
              );

            })}
          </CustomSlider2>
        </Box>}
      </Box>
    );
  }

  renderCategoriesCard() {
    return ( 
        <Box className={this.props?.classes?.itemBoxWrraper} style={webStyle.singleBannerBox}>
        {this.state.singleBannerData?.map((item, index) => (
            <Box data-test-id="singleBannerData" onClick={() => this.handleBannerLinks(item)} className={this.props?.classes?.bannerContentWrapper} style={{ borderRadius: "10px", background: `#f3cc69 url(${item.attributes.banner_image}) no-repeat`, backgroundPosition: "center", backgroundSize: "100% 100%",cursor:'pointer' }} key={index}>
              <Box style={webStyle.bannerTitle}></Box>
              <Box style={webStyle.bannerDes}></Box>
            </Box>
        ))}
        </Box>
    );
  }

  renderTrending() {

    const settings = {
      className: "center",
      centerMode: false,
      infinite: true,
      dots:false,
      centerPadding: "60px",
      slidesToShow: 5,
      speed: 500,
      rows: 1,
      slidesPerRow: 1,
      padding: "10px 0px",
      boxShadow: "0px 1px 3px #EBEBEB",
      prevArrow: <img src={backIcon}/>,
      nextArrow: <img src={nextIcon}/>,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
           
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
           
          }
        },
        {
          breakpoint: 786,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
           
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
    };

    const {classes} = this.props;
    return (
      <Box>
        <div>
          <h2 style={webStyle.trending}>{configJSON.trendingProductsLabel}</h2>
        {this.state.trendingProductsLoading ? 
          (<Box sx={webStyle.loaderWrapper} id="loaderBox">
            <Loader2 loading={this.state.trendingProductsLoading} />
        </Box>)
        : 
          <div style={webStyle.trendingWrapper}>
            <div className={this.props?.classes?.itemSliderWrraper}>
              <div style={{ flex: 1 }} className={classes?.trandingTemlateImg}>
                <img src={this.state.salesBannerOne} alt="broken" style={{...webStyle.trendingTemplate,width:"100%"}} />
              </div>
              <div className={classes?.trendingTemplate}>
                <CustomSlider {...settings}>
                  {this.state.productListOne?.map((item, index) => {
                    const { product_title, product_image } = item?.data?.attributes
                    return(
                      <div key={index}>
                      <div data-test-id="listOne" onClick={() => this.handleTrendingProducts(item.data.id)} style={webStyle.trendingProductsSlider}>
                        <img src={product_image} alt={product_title} style={webStyle.dualSliderImg} />
                      </div>
                      <p style={webStyle.dualSliderTxt} className={classes?.trendingBack2}>{product_title}</p>
                    </div>
                    )
                  })}
                </CustomSlider>
              </div>
            </div>
            <div  className={this.props?.classes?.itemSliderWrraper} >
              <div style={{ flex: 1}} className={classes?.trandingTemlateImg}>
                <img alt="broken" style={{...webStyle.trendingTemplate,width:"100%"}} src={this.state.salesBannerTwo} />
              </div>
              <div className={classes?.trendingTemplate}>
                <CustomSlider {...settings}>
                  {this.state.productListTwo?.map((item2, index2) => {
                    const { product_title, product_image } = item2?.data?.attributes
                    return(
                    <div key={index2}>
                      <div data-test-id="listTwo" onClick={() => this.handleTrendingProducts(item2.data.id)} style={webStyle.trendingProductsSlider}>
                        <img alt={product_title} src={product_image} style={webStyle.dualSliderImg} />
                      </div>
                      <p style={webStyle.dualSliderTxt} className={classes?.dualSliderTxtBack}> {product_title}</p>
                    </div>
                    )
                    })}
                </CustomSlider>
              </div>
            </div>
          </div>
}


        </div>
      </Box>
    );
  }

  renderCarousel2() {
    const renderBannerCarousel = (bannerData: SingleBanner[]) => {
      return (
        <CustomCarousel
          autoPlay={false}
          navButtonsAlwaysVisible={ bannerData?.length ? true : false }
          activeIndicatorIconButtonProps={{
            style: webStyle.activeBtnProps
          }}
          indicatorIconButtonProps={{
            style: webStyle.iconBtnPropsOne
          }}
          indicatorContainerProps={{
            style: webStyle.containerPropsTwo
          }}
          navButtonsProps={{
            style: webStyle.navBtnProps
          }}
        >
          {bannerData.map((item: SingleBanner, index: number) => (
            <div
            data-test-id="middleSlideShow"
            onClick={() => this.handleBannerLinks(item)}
              key={index}
              style={{
                ...webStyle.bannerCarousel,
                background: `#f3cc69 url(${item.attributes.banner_image}) no-repeat`,
                backgroundSize: "100% 100%",
                backgroundPosition: "center right",
                cursor:'pointer'
              }}
            >
            </div>
          ))}
        </CustomCarousel>
      );
    };
    return (
      <Box style={{ ...webStyle.padding, paddingInline: 0 }}>
          <div style={{ height: "90px", backgroundImage: `url("${this.state.middleBannerData[0]?.attributes?.banner_image}")`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", display: "flex", justifyContent: "space-between", alignItems: "center", paddingInline: "50px" }}>
            <Typography style={webStyle.middleBannerText}>{this.state.middleBannerData[0]?.attributes.title}</Typography>
            <img></img>
            <Button data-test-id="middleBannerDataOne" style={webStyle.middleBannerBtn} onClick={() => this.handleBannerLinks(this.state.middleBannerData[0])} >{this.state.middleBannerData[0]?.attributes.button_text}</Button>
          </div>
        <Box sx={{ marginTop: "20px", padding: "20px",...webStyle.imageBg2 }}>
          <Grid container spacing={2} data-test-id="leftSlideShow">
            <Grid item xs={12} sm={6}>
              {renderBannerCarousel(this.state.leftBannerData)}
            </Grid>
            <Grid item xs={12} sm={6} data-test-id="rightSlideShow">
              {renderBannerCarousel(this.state.rightBannerData)}
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }

  renderWeeklyHomie() {

    const settings = {
      marginTop: "20px",
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 1,
      rows: 1,
      slidesPerRow: 1,
      prevArrow: <ArrowBackIos style={webStyle.arrowBack} />,
      nextArrow: <ArrowForwardIos style={webStyle.arrowBack} />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 6,
          }
        },
        {
          breakpoint: 961,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 787,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 601,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
          }
        }
      ],
    };
    const productDeals = this.state.dealsList.attributes.weekly_deals
    const favHeader = this.state.favBookList.attributes.title.split(' | ');
    const {classes} = this.props;
    return (
      <>
        <Box style={{ ...webStyle.hero, background: "#faf9f6", padding: "20px 0", margin: "0"}}>
          <div style={{ height: "90px", backgroundImage: `url("${this.state.middleBannerData[1]?.attributes.banner_image}")`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", display: "flex", justifyContent: "space-between", alignItems: "center", paddingInline: "50px", margin: "-20px 0px" }}>
            <Typography style={webStyle.middleBannerText}>{this.state.middleBannerData[1]?.attributes.title}</Typography>
            <Button data-test-id="middleBannerDataTwo" onClick={() => this.handleBannerLinks(this.state.middleBannerData[1])}  style={{...webStyle.middleBannerBtn,backgroundColor:'#000',color:'#fff'}}>{this.state.middleBannerData[1]?.attributes.button_text}</Button>
          </div>
          <Box style={{margin:'0 20px'}}>
            {this.state.isDealPresent &&
            <>
            <Box className={classes?.dealTopBox}>
              <Typography  data-test-id="deals" className={this.props?.classes?.weeklyText}>Weekly Homieee Deal</Typography>
              <Box style={webStyle.rightBox}>
                <Box className={classes?.textBox}>
                  <Typography style={webStyle.textColor}>Deal ends in</Typography>
                </Box>
                <Box style={webStyle.CounterTimeSection}>
                  <span style={webStyle.CounterTime}>{this.state.days}</span>
                  <span style={webStyle.dealtext}>Day</span>
                </Box>
                <Box style={webStyle.colonColor}>{":"}</Box>
                <Box style={webStyle.CounterTimeSection}>
                  <span style={webStyle.CounterTime}>{this.state.hours}</span>
                  <span style={webStyle.dealtext}>Hrs</span>
                </Box>
                <Box style={webStyle.colonColor}>{":"}</Box>
                <Box style={webStyle.CounterTimeSection}>
                  <span style={webStyle.CounterTime}>{this.state.minutes}</span>
                  <span style={webStyle.dealtext}>Min</span>
                </Box>
                <Box style={webStyle.colonColor}>{":"}</Box>
                <Box style={webStyle.CounterTimeSection}>
                  <span style={webStyle.CounterTime}>{this.state.seconds}</span>
                  <span style={webStyle.dealtext}>Sec</span>
                </Box>
                <Box className={classes?.rightText}>
                  <Typography style={webStyle.lightText}>Remains untill the end of the offer</Typography>
                </Box>
              </Box>
            </Box>
            <Grid container spacing={2} >
              {productDeals.map((item: IWeeklyDeals, index: number) => (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                 <Box  style={webStyle.dealBox}>
                  <img data-test-id="dealsImg" onClick={() => this.redirectToWeeklyDeal(item.deal.id)} src={item.bg_image} alt="image" style={webStyle.dealImg} />
                  <Box style={webStyle.dealCard}>
                    <Typography style={{ color: this.setColor(index), ...webStyle.dealDiscount}}>{this.roundOffPercent(item.discount_percent)}%</Typography>
                    <Typography style={webStyle.prodName}>{item.caption}</Typography>
                  </Box>
                 </Box>
                </Grid>
              ))}
            </Grid>
            </>
            }
            <Box style={webStyle.bookSec}>
              <Box style={webStyle.bottom}>
                <span style={webStyle.discountText}>{favHeader[0]}</span>
                <span style={webStyle.favText}> | {favHeader[1]}</span>
              </Box>
              <Box style={webStyle.bookSlider}>
                <CustomSlider3 {...settings}>
                  {this.state.favBookList.attributes.catalogues.map((item: ICatalogueData, index:number) => {
                    const { product_title, product_image } = item.data.attributes
                    const { id } = item.data
                    return(  
                    <Box key={index} style={webStyle.productCard}>
                      <Box
                      data-test-id="books"
                        style={webStyle.imageBox}
                        onClick={() => this.handleTrendingProducts(id)}
                      >
                        <img src={product_image} alt={product_title} style={webStyle.bookImg} />
                      </Box>
                      <Typography style={webStyle.categoryTextBottom}>{product_title}</Typography>
                    </Box>
                  )})}
                </CustomSlider3>
              </Box>
            </Box>
          </Box>
        </Box>

      </>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <ThemeProvider theme={theme}>
          <Box sx={webStyle.mainWrapper}>
            {/* Categories Bar */}
            <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
            <Container style={{ maxWidth: "1700px", padding: "0", margin: "0 auto", backgroundColor:'#faf9f6' }}>
              <Box sx={webStyle.heroWrraper}>
                {/* Hero */}
                {this.renderCarousel()}
                {this.renderCategory()}
                {this.renderCategoriesCard()}
                {this.renderTrending()}
              </Box>
              <Box style={webStyle.imageBg}>
                {this.renderCarousel2()}
                {this.renderWeeklyHomie()}
                </Box>
                <Grid container className={this.props?.classes?.footerContainer} style={{ ...webStyle.footBannerWrapper, boxSizing: "content-box", backgroundImage: `url("${this.state.footerBannerData[0]?.attributes.banner_image}")` }}>
                  <Grid className="footerTitle" item xs={12} sm={5} md={5} lg={5} >
                    {this.state.footerBannerData[0]?.attributes.title}
                  </Grid>
                  <Grid className="footerDescription"  item xs={12} sm={4} md={4} lg={4} >
                      <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.footerBannerData[0]?.attributes.description)}}>
                      </div>
                  </Grid>
                  <Grid className="footerbutton" item xs={12} sm={3} md={3} lg={3} >
                    <Button  data-test-id="footerBannerData" onClick={() => this.handleBannerLinks(this.state.footerBannerData[0])}  className="footerBannerBtn">{this.state.footerBannerData[0]?.attributes.button_text}</Button>
                  </Grid>
                </Grid>
            </Container>
            {/* Footer */}
            <FooterWeb navigation={this.props.navigation} id={this.props.id} categories={[]} token={this.state.token} />
          </Box>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  trendingTemplate: {
    width: "200px",
    margin:'auto',
    height: "350px",
    display: "inline-block",
    borderRadius: "8px"
  },
  test: {
    background: "grey",
    height: "50px",
    width: "100%"
  },
  test2: {
    width: "100%"
  },
  dualSliderImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover" as const
  },
  trendingProductsSlider: {
    height: "290px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    cursor: 'pointer',
    boxShadow: "0px 1px 3px #EBEBEB"
  },
  dualSliderTxt: {
    border: "1px solid white",
    borderRadius: "5px",
    margin: "7px 0 0",
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    fontFamily: `"Lato", sans-serif` as const,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap" as const,
    fontSize:"14px",
    webkitLineClamp: 1,
    padding: '16px 20px'
  },
  trendingSliderbck: {
    background: "#E7E9F5"
  },
  mainWrapper: {
    background: "#fff",
    width: "100%",
    overflowX: "hidden"
  },
  navigationBar: {
    width: "100%",
    minHeight: "70px",
    background: "linear-gradient(139deg, rgba(249,199,33,1) 0%, rgba(250,57,146,1) 64%, rgba(162,105,238,1) 100%)"
  },
  categoriesBar: {
    width: "100%",
    height: "60px",
    background: "rgba(23,34,54,255)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px"
  },
  heroWrraper: {
    background:"#faf9f6",
    margin: "0 20px",
    padding: "20px 0px",
    height: "auto",
    '@media screen and (max-width: 460px)':{
      padding: '20px'
    }
  },
  imageBg: {
    background:`url(${LandingPageBg}) center/cover no-repeat;`,
  },
  imageBg2: {
    background:`#faf9f6`,
  },
  hero: {
    margin: "auto",
    padding: "20px",
    height: "auto",
    background: "#F9D5D5"
  },
  footer: {
    width: "100%",
    minHeight: "500px",
    background: "rgba(23,34,54,255)",
    padding: "20px 0px"
  },
  footerWrapper: {
    width: "100%",
    height: "auto",
    background: "white",
    flexDirection: "row",
    display: "flex"
  },
  fontTheme1: {
    fontSize: 14,
    color: "white",
    fontFamily: `"Lato", sans-serif` as const,
    paddingBottom: "5px"
  },
  categoryPage: {
    marginBottom: "30px",
    width: "375px"
  },
  categoryPage2: {
    marginBottom: "30px",
    width: "400px"
  },
  categoryImage: {
    boxSizing: 'border-box',
    width: "100%",
    padding: "20px"
  },
  categoryText: {
    background: "#fff",
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    display: "block",
    fontWeight:600,
    fontSize:14,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    color: "#33333a",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap" as const,
    padding: "0 10px 20px 10px",
    textDecoration: 'none' as const
  },
  categoryImageBottom: {
    width: "150px",
    height: "auto",
    borderRadius: "8px"
  },
  categoryCard: {
    background: "#fff",
    borderRadius: 10,
    margin: "30px",
    width: "250px",
    textAlign: "center"
  },
  categoryTextBottom: {
    textAlign: "center" as const,
    display: "block",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    color: "#33333a",
    fontFamily: `"Lato-Medium", sans-serif`,
    fontSize: "14px",
    fontWeight: 600,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap" as const,
    padding: "10px",
  },
  CounterTime: {
    background: "#ee1052",
    borderRadius: "4px",
    display: "flex",
    alignItems: "cneter",
    padding: "8px 14px",
    color: "#fff"
  },
  CounterTimeSection: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "4px",
    alignItems: "center",
    paddingTop: "18px"
  },
  bannerTitle: {
    fontSize: "36px",
    fontFamily: `"Lato", sans-serif` as const,
    textTransform: "capitalize" as const
  },
  bannerDes: {
    marginTop: "10px",
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif` as const,
    color: "#444444",
    textTransform: "capitalize" as const
  },
  loaderWrapper: {
    position: 'relative',
    width: '50px',
    left: '50%',
    marginBottom: "100px"
  },
  trending: {
    color: "#33333a",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: "26px",
    fontWeight: 600
  },
  footerContainer: {
    paddingTop: "60px",
    justifyContent: "center"
  },
  categoriesContainer: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: "30px",
    color: "white"
  },
  signUpBtnWrapper: {
    marginTop: "11px",
    background: "rgba(253, 97, 12, 1)",
    textTransform: "none" as const,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: `"Lato", sans-serif` as const,
    borderRadius: "5px",
    width: "fit-content"
  },
  signUpBox: {
    padding: "5px 35px",
    fontWeight: "bold",
    fontSize: 14,
    color: "white",
        fontFamily: `"Lato", sans-serif` as const,
  },
  socialIcons: {
    width: "30px",
    height: "30px",
    borderRadius: "50px"
  },
  originalImg: {
    width: "40px",
    height: "40px",
    borderRadius: "50px"
  },
  btnWrapper: {
    width: "185px",
    height: "2px",
    marginTop: "30px",
    background: "white"
  },
  contactUsWrapper: {
    marginTop: "20px",
    background: "rgba(253, 97, 12, 1)",
    textTransform: "none" as const,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: `"Lato", sans-serif` as const,
    borderRadius: "5px",
    width: "fit-content"
  },
  contactUsBox: {
    padding: "5px 45px",
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: `"Lato", sans-serif`,
    color: "white",
  },
  copyRightText: {
    width: "100%",
    display: "flex",
    fontFamily: `"Lato", sans-serif`,
    justifyContent: "center",
    fontSize: 14,
    color: "#eeedee"
  },
  navMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: `"Lato", sans-serif`,
    paddingRight: "30px"
  },
  headerSlideShow: {
    height: "730px",
    maxHeight: "730px",
    position: "relative" as const,
    backgroundPosition: "center right",
    borderRadius: "1rem"
  },
  slideShowBox: {
    position: "absolute" as const,
    top: "50%",
    transform: "translate(0, -50%)",
    fontFamily: `"Lato", sans-serif`,
    width: "700px",
    left: "7%"
  },
  headerTitle: {
    fontSize: "2.5rem",
    margin: "30px 0px",
    fontFamily: `"Lato", sans-serif`,
    lineHeight: "47px"
  },
  headerDesc: {
    fontSize: "18px",
    marginBottom: "10px",
    textTransform: "capitalize" as const,
    fontFamily: `"Lato", sans-serif`,
  },
  headerBtn: {
    background: "#fff",
    padding: "20px",
    boxShadow: "rgba(2, 2, 3, 0.12) 2px 1px 3px",
    borderRadius: "8px",
    marginTop: "30px",
    fontWeight: 700 as const,
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif`,
    textTransform: "capitalize" as const
  },
  middleBannerBtn: {
    color: "#2a4356",
    background: "#fedb23",
    fontWeight: 700 as const,
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    textTransform: "capitalize" as const,
    padding: "8px 24px",
    border: "none",
    borderRadius: "8px"
  },
  bannerBtnText: {
    background: "#fff",
    padding: "20px",
    boxShadow: "rgba(2, 2, 3, 0.12) 2px 1px 3px",
    borderRadius: "8px",
    marginTop: "30px",
    fontWeight: 700 as const,
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif`,
    textTransform: "capitalize" as const
  },
  buttonText: {
    color: "white",
    background: "black",
    fontWeight: 700 as const,
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif`,
    textTransform: "capitalize" as const
  },
  padding: {
    margin: "auto",
    padding: "0 15px",
    height: "auto",
    background: "#faf9f6"
  },
  middleBannerText: {
    fontWeight: 700 as const,
    fontSize: "18px",
    fontFamily: `"Lato", sans-serif`,
    color: "#fff"
  },
  footerBannerHeading: {
    fontWeight: 400 as const,
    fontSize: "32px",
    fontFamily: `"Lato", sans-serif` as const,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "50px",
    "@media screen and (max-width: 460px)": {
      fontSize: "24px",
      justifyContent: "center",
      paddingRight: "0px"
    }
  },
  footerBannerDes: {
    fontWeight: 700 as const,
    fontSize: "18px",
    fontFamily: `"Lato", sans-serif`,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 20px",
    "@media and (max-width: 460px)": {
      fontSize: "14px"
    }
  },
  footerBannerBtn: {
    fontWeight: 700 as const,
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    color: "#747474",
    padding: "8px 24px",
    textTransform: "none" as const,
    border: "none",
    backgroundColor: "#fff",
    "@media and (max-width: 460px)": {
      fontSize: "12px"
    }
  },
  footBannerWrapper: {
    padding: "24px 0",
    fontFamily: `"Lato", sans-serif`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  footerButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "64px"
  },
  singleBannerFlex: {
    flex: 3,
    display: 'flex'
  },
  navBtnProps: {
    backgroundColor: "#fff",
    width: "40px",
    height: "40px",
    color: "#000",
    "&.MuiSvgIcon-root": {
      fill: "gray !important"
    }
  },
  navBtnPropsOne: {
    color: "#000",
    backgroundColor: "transparent",
    top: "0 !important",
    "& .MuiSvgIcon-root": {
      fill: "gray !important"
    }
  },
  containerProps: {
    zIndex: 1,
    position: "relative" as const,
    bottom: 40
  },
  iconBtnProps: {
    color: "gray",
    width: "10px",
    height: "10px",
    marginRight: "5px"
  },
  activeBtnProps: {
    color: "#cc5500",
    fill: "white"
  },
  headerImg: { 
    cursor: "pointer",
    borderRadius: "1rem", 
    display: 'block', 
    objectFit: 'cover' as const, 
    height: '100%', 
    width: '100%' 
  },
  headerSlideBox: { 
    flex: 3, 
    justifyContent: 'center' 
  },
  arrowBack: { 
    color: 'black', 
    fontSize: '18px' 
  },
  shopByText: { 
    fontSize: "26px", 
    fontWeight: 600, 
    fontFamily: `"Lato", sans-serif` 
  },
  allCategoryText: { 
    fontSize: "18px", 
    fontFamily: `"Lato", sans-serif`,
    marginTop:'4px' ,
    color:"#565656" 
  },
  categorySliderBox: { 
    height: "200px", 
    margin: "0px 45px" 
  },
  categoryBox: { 
    padding: '0 10px',
    boxSizing: 'border-box' as const,
    cursor:'pointer' 
  },
  categoryCardOne: {
    borderRadius: '10px',
    backgroundColor: "#fff",
    width: "100%",
    boxShadow: "0px 1px 3px #EBEBEB"
  },
  name: { 
    boxSizing: 'border-box' as const, 
    height: "125px", 
    width: '100%' ,
    objectFit:'contain' as 'contain'
  },
  singleBannerBox: { 
    display: "flex", 
    padding: "20px 0px"
  },
  trendingWrapper: { 
    display: "flex", 
    flexDirection: "column" as const, 
    gap: "45px" 
  },
  iconBtnPropsOne: {
    border: "1px solid white",
    color: "gray",
    width: "10px",
    height: "10px",
    marginRight: "5px"
  },
  containerPropsTwo: {
    zIndex: 1,
    position: "relative" as const,
    bottom: 40,
    display: "none"
  },
  bannerCarousel: {
    maxHeight: "539px",
    height: "500px",
    borderRadius:7,
    position: "relative" as const
  },
  rightBox: { 
    display: "flex", 
    gap: "10px", 
    alignItems: "center" 
  },
  textColor: {
    color: '#000'
  },
  dealtext: { 
    color: "#474747", 
    fontFamily: `"Lato", sans-serif`, 
    fontSize: "12px", 
    fontWeight: 900 
  },
  lightText: { 
    color: "#666666", 
    fontFamily: `"Lato", sans-serif`, 
    fontSize: "12px", 
    fontWeight: 300 
  },
  dealBox: { 
    height: "410px", 
    border: "2.5px solid white", 
    borderRadius: "10px" 
  },
  dealImg: {
    width: "100%", 
    height: "100%", 
    borderRadius: "10px",
    cursor: 'pointer' 
  },
  dealCard: { 
    position: "relative" as const, 
    bottom: "120px", 
    boxShadow: "0px 2px 4px rgba(0,0,0,0.03)", 
    background: "white", 
    width: "66.66%", 
    height: "100px", 
    borderTopRightRadius: "5px", 
    borderBottomRightRadius: "5px" 
  },
  dealDiscount: {
    fontSize: "30px", 
    fontWeight: 600, 
    paddingLeft: "15px", 
    paddingTop: "15px" ,
    textDecoration: 'none' as const
  },
  prodName : { 
    color: "rgb(123, 120, 120)", 
    fontWeight: 600, 
    paddingLeft: "15px" ,
    textDecoration: 'none' as const
  },
  bookSec: { 
    height: "auto", 
    borderRadius: "10px", 
    background: "linear-gradient( to right,#F8ECDA,#FCE4CA)", 
    margin: "30px 0 10px", 
    padding: "30px" 
  },
  bottom: { 
    marginBottom: "25px" 
  },
  discountText: { 
    fontFamily: `"Lato",sans-serif`, 
    color: "black", 
    fontSize: "1.3rem", 
    fontWeight: 600 
  },
  favText: { 
    fontFamily: `"Lato",sans-serif`, 
    color: "#FF6008", 
    fontSize: "1.3rem", 
    fontWeight: 600 
  },
  bookSlider: { 
    height: "auto", 
    margin: "0px 30px", 
    display: "flex", 
    justifyContent: "center", 
    flexDirection: "column" as const 
  },
  productCard: { 
    padding: '0 0px', 
    boxSizing: 'border-box' as const
  },
  imageBox: {
    borderRadius: '5px',
    backgroundColor: "#fff",
    width: "100%",
    cursor:'pointer'
  },
  bookImg: {
    boxSizing: 'border-box' as const, 
    height: "154px", 
    width: '100%'
  },
  colonColor: {
    color: '#fff',
    margin:'0 -5px'
  }
};

export const styles = (theme: Theme) => ({
  carousal_image: {
    position: 'absolute' as const,
    width: '100%' as const,
    height: 'auto' as const,
    top: 0,
    left: 0
  },
  carousal_image_container: {
    position: 'relative' as const,
    width: '100%' as const,
    '&::before': {
      content: '""' as const,
      paddingTop: '33.33%' as const,
      display: 'block' as const,
    } as const,
  },
  trandingTemlateImg: {
    width: "200px",
    height: "350px",
    margin:'auto',
    display: "inline-block",
    borderRadius: "8px",
    [theme.breakpoints.down('xs')]:{
      width: '100%'
    }
  },
  trendingTemplate: {
    flex: 5, 
    width: 'calc(100% - 220px)', 
    marginLeft:'20px',
    height: "350px",
    display: "inline-block",
    borderRadius: "8px",
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 'auto'
    }
  },
  itemSliderWrraper: {
    [theme.breakpoints.up("sm")]: {
      flexDirection:'row'as const,
      gap:'20px',
  },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection:'column'as const,
      gap:'50px',
  },
  },
  weeklyText: {
    color: "#33333a", 
    fontFamily: `"Lato", sans-serif`, 
    fontWeight: 600 ,
    [theme.breakpoints.up("sm")]: {
      fontSize:'28px'as const,
      textAlign: 'center'
    },
    [theme.breakpoints.down("xs")]: {
      fontSize:'18px'as const,
  },
  },
  itemBoxWrraper: {
    flexDirection:'row'as const,
    [theme.breakpoints.up("sm")]: {
      flexDirection:'row'as const,
      gap:'20px',
  },
    [theme.breakpoints.down("xs")]: {
      flexDirection:'column'as const,
      gap:'50px',
  },
  },
  bannerContentWrapper: {
    padding: "100px 25px",
    width:'100%,',
    flex:3,
    [theme.breakpoints.down("xs")]: {
      padding: "40px 25px",
     flex:1,
  },
  },
  footerContainer: {
    '& .footerTitle':{
      fontWeight: 400 as const,
      fontSize: "28px",
      fontFamily: `"Lato", sans-serif` as const,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingRight: "50px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
        justifyContent: "center",
        paddingRight: "0px",
        padding: "0px 0 10px 20px" 
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
        justifyContent: "center",
        paddingRight: "0px",
        padding: "0px 0 10px" 
      },
    },
    '& .footerDescription':{
      fontWeight: 700 as const,
      fontSize: "14px",
      fontFamily: `"Lato", sans-serif`,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px"
      },
    },
    '& .footerbutton':{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: "64px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: '24px',
        paddingRight:'20px'
      },
      [theme.breakpoints.down("xs")]: {
        padding: "10px 0 10px", 
        justifyContent: "center" 
      },
      '& .footerBannerBtn':{
        fontWeight: 700 as const,
        fontSize: "14px",
        fontFamily: `"Lato", sans-serif`,
        color: "#747474",
        padding: "8px 24px",
        textTransform: "none" as const,
        border: "none",
        backgroundColor: "#fff",
        [theme.breakpoints.down("sm")]: {
          padding: "8px 18px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px"
        },
      },
    },
  },
  textStyle: {
    textDecoration:'none' as const,
    '&:hover' : {
      textDecoration:'none' as const,
    }
  },
  dealTopBox: { 
    display: "flex", 
    justifyContent: "space-between", 
    height: "80px", 
    alignItems: "center", 
    padding: "20px 20px 13px 8px",
    '@media (max-width:820px)': {
      margin:'20px 0',
    },
    [theme.breakpoints.down('xs')]: {
      height: "120px",
      padding: "20px 0px",
      margin: '20px 0px',
      flexDirection: "column" as const
    }
  },
  categoryPage: {
    marginBottom: "30px",
    width: "375px",
    [theme.breakpoints.down('xs')]: {
      width: "auto"
    },
    "& .shopByText": { 
      fontSize: "26px", 
      fontWeight: 600, 
      fontFamily: `"Lato", sans-serif`,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20
      }
    },
  },
  dualSliderTxtBack:{
    background: "#E1EFFD"
  },
  trendingBack2: {
    background: "#E6E8F4"
  },
  textBox: { 
    height: "100%", 
    padding: "8px 14px", 
    color: "#404040", 
    fontFamily: `"Lato", sans-serif`, 
    fontSize: "20px", 
    fontWeight: 400,
    '@media (max-width:600px)': {
      padding:'8px 0',
      display:'flex',
      alignItems:'center',
      justifyContent: 'center'
    }
  },
  rightText: { 
    height: "100%", 
    padding: "8px 14px 8px 0px", 
    marginRight: "-25px",
    '@media (max-width:600px)': {
      display:'flex',
      alignItems:'center',
      justifyContent: 'center'
    } 
  },
  footerButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "64px",
    '@media (max-width:810px)':{
      paddingLeft: '24px'
    }
  }
});

export { LandingPageWeb };
export default withStyles(styles)(LandingPageWeb)
// Customizable Area End
