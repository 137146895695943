import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { BoxNoScroll } from "../CustomizedElementStyle";
import DOMPurify from 'dompurify';
// Customizable Area End

import PrivacyPolicyController, { Props } from "./PrivacyPolicyController";

export default class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box sx={{ fontSize: "18px", padding: "20px 0px 15px 0px", fontFamily: `"Lato", sans-serif`, fontWeight: 400 }}>Privacy Policy</Box>
        <BoxNoScroll style={{ overflow: "auto", paddingBottom: 20 }}>
          <Box style={{ minHeight: 753, borderRadius: "8px", flexDirection: "column", padding: "30px", overflowY: "auto", overflowX: "hidden", position: "relative", display: "flex", background: "white" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
              <Box sx={{ fontSize: "27px", fontWeight: 600, fontFamily: `"Lato", sans-serif` }}>Privacy Policy</Box>
            </Box>
            <Box sx={{ width: "100%", marginTop: 10}}>
              {this.state.privacyContent.map((item, index) => {
                return (
                  <>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}}>
                    </div>
                    <Box style={{ width: "100%", height: 20 }}></Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
