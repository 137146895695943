import React from "react";

// Customizable Area Start
import { Box, Chip, Grid, IconButton, Menu, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, withStyles } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as Yup from "yup";
import { FormControlTwo, MenuItemTwo, CustomDeleteButton } from "../../dashboard/src/CustomizedElementStyle";
// Customizable Area End

import { Formik } from "formik";
import MyDealsController, { Props } from "./MyDealsController.web";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { Pagination } from "@material-ui/lab";
import { CustomOrangeButton } from "../../../components/src/CommonButton";

export default class MyDeals extends MyDealsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Starts
    userSchema = () => {
        return Yup.object().shape({
            dealsValue: Yup.number().required("Please enter deals price.").min(0, 'Invalid price').typeError('Invalid price')
                .max(10000000, 'Invalid price'),
        });
    };

    myDealsComponent() {
        return (
            <>
                <Box sx={{ width: "100%", display: "flex", alignContent: "center", alignItems: "flex-end", justifyContent: "flex-start" }}>
                    <Grid item lg={6} sm={6} xs={6}>
                        <FormControlTwo fullWidth={true} >
                            <Box sx={{ color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontWeight: 600, fontSize: 12, marginBottom: 10 }}>{"Select Deal"}</Box>
                            <Select
                                data-test-id="selectSKUDropdown"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                placeholder="Select SKU"
                                value={this.state.dealsDdValues}
                                defaultValue=""
                                onChange={(event: any) => this.handleChangeMenuItem(event?.target?.value)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        style: {
                                            background: "white",
                                            border: "1px solid #b1b3b5",
                                            boxShadow: "none",
                                            borderRadius: 5,
                                            marginTop: "5px",
                                            padding: 0,
                                            maxHeight: "400px", // Set the maximum height limit for the menu
                                            overflowY: "auto",
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: "200px"
                                        }
                                    }
                                }}
                            >
                            {this.state.dealsList.length > 0 ? this.state.dealsList.map((item: any) => (
                            <MenuItemTwo value={item?.attributes?.id}>
                                <Box sx={{ whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: "100%"}}>
                                    {item?.attributes?.deal_name}
                                </Box>
                            </MenuItemTwo>
                            ))
                            : (<Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  maxWidth: "100%", paddingLeft: '10px', fontFamily: `"Lato", sans-serif`, fontSize: 15 }}> No deals found...  </Box> )}
                            </Select>
                        </FormControlTwo>

                    </Grid>
                    <Grid item lg={6} sm={6} xs={6}>
                       {this.state.startDate.attributes?.start_date && this.state.startDate.attributes?.end_date &&
                        <Typography style={{ fontSize: 15, marginBottom: "10px", marginLeft: "20px", fontFamily: `"Lato", sans-serif` ,color:'#242323'}} variant="body1"><span style={{ color: "rgb(102, 102, 102)", fontFamily: `"Lato-Heavy",sans-serif`, fontSize: 15, marginRight: 7 }}>Date :</span> {`From ${moment(this.state?.startDate?.attributes?.start_date).format("D MMM, YYYY")} To ${moment(this.state?.startDate?.attributes?.end_date).format("D MMM, YYYY")}`}</Typography>
                       }
                    </Grid>
                </Box>
                <Paper style={{ width: "100%" }}>
                    <TableContainer>
                        <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={webStyle.tabelHeadeing}>SELLER SKU</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>PRODUCT TITLE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>SKU MIN PRICE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>CURRENT OFFER PRICE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>DEAL PRICE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>ACTION</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing} align="center">STATUS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.dealsWithSKUList?.length > 0 ? (
                                    this.state.dealsWithSKUList.map((item: any, index: number) => {
                                        const {sku, is_variant, catalogue_offer} = item.attributes.catalogue_full_details?.data?.attributes
                                        const variantProduct = item.attributes.catalogue_full_details?.data?.attributes?.variant_product_group
                                        return (
                                            <TableRow data-test-id="myDealsList" key={item.id}>
                                                {/* @ts-ignore */}
                                                <TableCell style={webStyle.tabelCell}>{is_variant ? variantProduct.product_sku : sku}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>{item.attributes.product_title}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>{item.attributes.seller_price || 0}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>{catalogue_offer && catalogue_offer.sale_price || 0}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>{item.attributes.deal_price}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>
                                                    <div>
                                                        <IconButton
                                                            aria-label="more"
                                                            aria-controls="long-menu"
                                                            onClick={(event: any) => { this.handleClickAction(event, item) }}
                                                            id="addMoreIconRef"
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>

                                                        <Menu
                                                            id="long-menu"
                                                            data-test-id="editRemoveRef"
                                                            anchorEl={this.state.anchorEl}
                                                            keepMounted
                                                            open={Boolean(this.state.anchorEl)}
                                                            onClose={this.handleCloseAction}
                                                            PaperProps={{
                                                                style: {
                                                                    maxHeight: 400,
                                                                    width: '12ch',
                                                                    marginTop:'50px',
                                                                    marginLeft: '-35px'
                                                                },
                                                            }}
                                                        >
                                                                <MenuItemTwo data-test-id="editRef" style={{ display: 'block', padding: "7px",textAlign:'center' }}  onClick={() => this.editDealHandler()} >
                                                                    Edit
                                                                </MenuItemTwo>
                                                            <MenuItemTwo data-test-id="removeRef" style={{ display: 'block', padding: "7px",textAlign:'center' }}  onClick={() => this.removeDealHandler()}>
                                                                Remove
                                                            </MenuItemTwo>

                                                        </Menu>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center"> <CustomChip  label={item?.attributes?.status && item.attributes.status.charAt(0).toUpperCase() + item.attributes.status.slice(1)} style={this.handleStatusColor(item?.attributes?.status)} /></TableCell>
                                            </TableRow>
                                        );
                                    })) :
                                    <TableRow>
                                        <TableCell style={webStyle.tabelCell} colSpan={7} >
                                            <Typography variant="h6" style={{ textAlign: 'center', fontFamily: `"Lato", sans-serif`, padding: '10px' }}>
                                                No data available
                                            </Typography>
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {this.state.dealsWithSKUList?.length > 0 && (
                    <Pagination
                      style={webStyle.paginateClass}
                      data-test-id="pagination"
                      page={this.state.page}
                      count={this.state.totalCount}
                      onChange={(event: React.ChangeEvent<unknown>, value: number) => {     
                        this.handleChangeMenuItem(this.state.dealsDdValues,value);
                      }}
                      shape="rounded"
                      siblingCount={0}
                      boundaryCount={1}
                    />
                  )}
                </Paper>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" ,paddingBottom:'25px'}}>
                    <CustomOrangeButton
                        id="nextRef"
                        onClick={() => this.handelGoToNextpage()}
                        style={{
                            textTransform: "none",
                            fontFamily: `"Lato", sans-serif`,
                            fontWeight: 700,
                            fontSize: 16,
                            background: "#FF6008",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "8px",
                            marginTop: "70px"
                        }}
                    >
                        Next
                    </CustomOrangeButton>
                </Box>
                <Modal open={this.state.successModal}>
                    <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
                        <Box sx={{ width: "299px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
                            <Box sx={{ marginBottom: "21px", textAlign: "center", fontFamily: `"Lato", sans-serif` }}>{"Price updated Successfully"}</Box>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <CustomDeleteButton id="modalClose" style={{ backgroundColor: 'green' }} onClick={() => this.handleCloseSuccessModal()}>
                                    OK
                                </CustomDeleteButton>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </>
        );
    }

    editDealsViewHandler() {
        return (
            <>
                <Formik
                    data-test-id="formik"
                    initialValues={{
                        dealsValue: this.state.dealsValue,
                    }}
                    validationSchema={this.userSchema}
                    onSubmit={(values) => {
                        this.onEditTask();
                    }}
                >
                    {({ errors, setFieldValue, handleSubmit }) => (
                        <form data-test-id="formDealsRef" onSubmit={handleSubmit} noValidate autoComplete="off" style={{
                            height: "100%", display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            margin: 0
                        }}>
                            <Grid item lg={6} sm={12} xs={12}>
                                <FormControlTwo fullWidth={true}>
                                    <Box sx={{ color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontWeight: 600, fontSize: 12, marginBottom: 10 }}>{"Add Deal Price"}</Box>
                                    <TextField
                                        data-test-id="selectSKURef"
                                        id="demo-simple-select-outlined"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{

                                            inputComponent: 'input',
                                        }}
                                        label=""
                                        variant="outlined"
                                        placeholder="Add deals"
                                        value={this.state.dealsValue}
                                        onChange={(event) => {
                                            this.onValueChange(event.target.value)
                                            setFieldValue("dealsValue", event.target.value);
                                        }}
                                    />
                                    {errors["dealsValue"] && <Typography
                                        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
                                    >
                                        {errors["dealsValue"]}
                                    </Typography>}
                                </FormControlTwo>
                            </Grid>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <CustomOrangeButton
                                    data-test-id="selectedDealsListRef"
                                    type="submit"
                                    style={{
                                        textTransform: "none",
                                        fontFamily: `"Lato", sans-serif`,
                                        fontWeight: 700,
                                        fontSize: 16,
                                        background: "#FF6008",
                                        color: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "8px"
                                    }}
                                >
                                    Submit
                                </CustomOrangeButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            </>
        );
    }


    // Customizable Area End

    // Customizable Area Start
    render() {
        return (<>
            {this.state.myDealactiveStep === "mydealsStep" && this.myDealsComponent()}
            {this.state.myDealactiveStep === "selectSKU" && this.editDealsViewHandler()}

        </>)

        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    tabelCell: {
        color: "rgb(102, 102, 102)",
        fontFamily: `"Lato", sans-serif`,
    },
    productDetailsColumn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 56,
        width: "100%",
        fontSize: 14,
        fontWeight: 700,
        fontFamily: `"Lato", sans-serif`,
        color: "#000",
        background: "linear-gradient(180deg, #F8F8F8 0%, #EEE 100%)"
    },
    tabelHeadeing: {
        fontWeight: 600,
        fontSize: "15px",
        backgroundColor: "#f0f0f0",
        fontFamily: `"Lato", sans-serif`,
        color: "#727272",
        whiteSpace:'nowrap' as const,
    },
    productDetailsColumnSelected: {
        height: 56,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#ffa06a",
        color: "black",
        fontSize: 14,
        fontWeight: 700,
        fontFamily: `"Lato", sans-serif`
    },
    paginateClass: {
        display: "flex",
        margin: "25px",
        justifyContent: "center"
      },

};

export const CustomChip = withStyles(() => ({
    root: {
      "& .MuiChip-label": {
       padding:0
      },
  
    },
  }))(Chip);
// Customizable Area End
