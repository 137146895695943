import React from "react";

// Customizable Area Start
import FeaturesController, { Props } from "./FeaturesController";
import Features from "./Features.web";
import Whyus from "./Whyus.web";
import Pricing from "./Pricing.web";
import SellerAccountRegistration from "./SellerAccountRegistration.web";
import { Switch, Route, withRouter } from "react-router-dom";
import SellerLandingNavbar from "./SellerLandingNavbar.web";
// Customizable Area End

class FeaturesHeader extends FeaturesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <SellerLandingNavbar navigation={this.props.navigation} id={this.props.navigation} />
        <Switch>
          <Route
            path={"/"}
            component={() => (
            <SellerAccountRegistration navigation={this.props.navigation} id={this.props.id} />
            )}/>
          <Route
            path="/seller/register"
            component={ () => 
              <SellerAccountRegistration navigation={this.props.navigation} id={"register"} classes={this.props.classes}/>
            }/>
          <Route
            path="/seller/features"
            component={() => 
              <Features navigation={this.props.navigation} id={"features"} />
            }
            exact
          />
          <Route
            path="/seller/whyus"
            component={() => (
              <Whyus navigation={this.props.navigation} id={this.props.id} />
            )}
            exact
          />
          <Route
            path="/seller/pricing"
            component={() => (
              <Pricing navigation={this.props.navigation} id={this.props.id} />
              )}
            exact
          />
        </Switch>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export {FeaturesHeader}
export default withRouter(FeaturesHeader as any) as any;
// Customizable Area End
