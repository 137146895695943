// Customizable Area Start
import React from "react";
import TermsOfServiceController, { Props, configJson } from "./TermsOfServiceController.web";
import { Box, styled } from "@material-ui/core";
import { BoxNoScroll, OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
import { byezzyLogo } from "../../email-account-registration/src/assets";
import { configJSON } from "../../contactus/src/CommonNavController";
import * as DOMPurify from 'dompurify';
// Customizable Area End

class TermsOfService extends TermsOfServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
          <BoxNoScroll style={customStyles.boxNoScroll}>
            <Box style={customStyles.box}>
            <Box style={customStyles.boxContainer}>
              <img src={byezzyLogo} alt="logo" style={customStyles.logoDimension}/>
              <CustomOrangeButton data-test-id="backBtn" onClick={this.handleBackButton}>
            {configJSON.backToHomeText}
          </CustomOrangeButton>
            </Box>
              <Box style={customStyles.heading}>{configJson.termsOfServiceText}</Box>
              <div dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(this.state.termsOfService.content)}}  data-test-id="termsContent">
              </div>
              <Box style={customStyles.spacer}></Box>
            </Box>
          </BoxNoScroll>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customStyles = {
  boxNoScroll: {
    paddingBottom: 20,
    overflow: "auto",
  },
  box: {
    overflowX: "hidden" as const,
    position: "relative" as const,
    display: "flex",
    background: "white",
    minHeight: "auto",
    borderRadius: "8px",
    flexDirection: "column" as const,
    padding: "30px",
    overflowY: "auto" as const,
  },
  heading: {
    fontWeight: 600,
    fontFamily: `"Lato", sans-serif`,
    fontSize: "27px",
    marginTop:'35px',
    marginBottom: '25px',
  },
  content: {
    fontSize: 16,
    color: "#33333A",
    width: "100%",
    marginTop: 10,
    fontFamily: `"Lato", sans-serif`,
  },
  spacer: {
    height: 20,
    width: "100%",
  },
  logoDimension: {
    height: '85px',
    width: '102px',
  },
  boxContainer : {
    height: '100px',
    margin:'-30px -5px',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
};

const CustomOrangeButton = styled(OrangeButton)({
  '@media (max-width:400px)': {
    padding:'12px 20px'
  }
})

export default TermsOfService;
// Customizable Area End
