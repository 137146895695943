import React from "react";
// Customizable Area Start
import {
  Box,
  InputAdornment,
  ClickAwayListener,
  Paper,
  MenuItem,
  styled,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { HomeSearchBox } from "../../landingpage/src/CustomElementStyling";
import { SearchIconSVG } from "../../dashboard/src/CustomizedElementStyle";
export const configJSON = require("./config");
type SuggestionItem = {
  attributes: {
    category_id: string;
    product_title: string;
  }
}
import { Loader2 } from "../../../components/src/Loader.web";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import SearchController, { Props } from "./SearchController";

export default class Search extends SearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {suggestions, open, searchQuery} = this.state;
    return (
      <>
      <Box sx={webStyle.container}>
        <HomeSearchBox
          data-test-id="searchBar"
          label=""
          id="outlined-start-adornment"
          autoComplete="off"
          fullWidth
          placeholder="What are you looking for?"
          value={this.state.searchQuery}
          onChange={this.handleInputChange}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && suggestions.length > 0 && searchQuery !== "") {
              this.handleSuggestionClick(searchQuery, suggestions[0].attributes.category_id)
            }
            if(!this.state.isLoading && e.key === 'Enter' && suggestions.length === 0 && searchQuery !== "") {
              this.handleSearch(searchQuery)
            }
          }
          }

          style={webStyle.homeSearch}
          InputProps={{
            startAdornment: <InputAdornment position="start" style={{ marginRight: 20 }}></InputAdornment>,
            endAdornment: (
              <InputAdornment position="end" style={{ paddingRight: 20 }}>
                {open && suggestions.length !== 0 ? (
                  <IconButton onClick={() => this.handleSuggestionClick(searchQuery, suggestions[0].attributes.category_id)}>
                    {this.state.isLoading && <CircularProgress data-test-id="loader" size={24} style={{ margin: '10px' }}/>}
                    <SearchIconSVG />
                  </IconButton>
                ) : <IconButton>
                  {this.state.isLoading && <CircularProgress size={24} style={{ margin: '10px' }}/>}
                  <SearchIconSVG />
                </IconButton>}
              </InputAdornment>
            )
          }}
        /> 
        {this.state.open && (
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper style={webStyle.paperStyle}>
              {this.state.suggestions.map((suggestion: SuggestionItem, index: number) => (
                <CustomMenuItem
                  key={index}
                  data-testId="suggestion-option"
                  onClick={() => this.handleSuggestionClick(suggestion.attributes.product_title, suggestion.attributes.category_id)}
                >
                  {suggestion.attributes.product_title}
                </CustomMenuItem>
              ))}
            </Paper>
          </ClickAwayListener>
        )}
        {(this.state.resultCount > 0 && !this.state.productNotFound && this.state.searchQuery !== "") && (
          <Box sx={webStyle.searchCount}>
            {this.state.resultCount} results for <b style={{color: "#ff6008"}}>"{this.state.searchQuery.trim()}"</b>
          </Box>
        )} 
         {(this.state.resultCount === 0 && this.state.productNotFound && this.state.searchQuery && this.state.searchQuery !== "") && (
          <Box sx={webStyle.searchCount} id="noResults">
            No results for <b style={{color: "#ff6008"}}>"{this.state.searchQuery.trim()}"</b>
          </Box>
        )} 
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  homeSearch: { 
    fontFamily: `"Lato-Medium", san-serif`, 
    position: "relative" as const
  },
  paperStyle: { 
    position: "absolute" as const, 
    width: "100%", 
    top: "40px", 
    zIndex: 999, 
    color: "#000" 
  },
  container: {
    position: "relative",
    width: "100%"
  },
  searchCount: {
    fontFamily: `"Lato", sans-serif`, 
    fontSize: 12,
    paddingTop: '8px',
    paddingLeft: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap'
  }
}

const CustomMenuItem = styled(MenuItem)({
  color: "#000",
  backgroundColor: "#fff",
  '&:hover': {
    color: "#fff",
    backgroundColor: "#FF6008",
  }
})
// Customizable Area End
