//@ts-nocheck
import React from "react";

// Customizable Area Start
import { Box, Radio, Typography, ClickAwayListener, InputAdornment, withStyles, Theme, styled, Button } from "@material-ui/core";
import { BoxNoScroll, CustomMenuItemBox, SearchIconSVG } from "../CustomizedElementStyle";
import {CustomizeInputSearchBox} from "../../../../components/src/CommonButton";
// Customizable Area End

import SellerCategorizeController, { configJSON, Props } from "./SellerCategorizeController";

export default class SellerCategorize extends SellerCategorizeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      <>
        <Box sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 400, fontFamily: `"Lato", sans-serif`, color: "#000" }}>{this.props.id === "seller-home-bulk-upload-product" ? "Product Catalog" : "Partner Catalog"}</Box>
        <BoxNoScroll style={{ paddingBottom: 20, overflow: "auto" }}>
          <Box style={{ height: "auto", borderRadius: "8px", display: "flex", flexDirection: "column", padding: "30px", overflowY: "auto", overflowX: "hidden", background: "white" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 0 }}>
              <Box className={classes.header}>Let's start with categorizing your product </Box>
            </Box>
            <Box style={{ height: 1, background: "#EEEEEE", marginTop: "20px" }}></Box>
            <Box sx={{ width: "100%", height: 30 }}></Box>
            <ClickAwayListener data-test-id="clickAwayRef" onClickAway={this.handleClickAwaySearch}>
              <Box className={classes.section}>
                <Box>
                  <CustomizeInputSearchBox
                    data-test-id="selectCategory"
                    label={configJSON.selectCategory}
                    id="outlined-start-adornment"
                    autoComplete="off"
                    value={this.state.searchCategoryValue}
                    onChange={this.setSearchValue}
                    fullWidth
                    onFocus={this.handleInputFocus}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={{ marginRight: 15 }}></InputAdornment>,
                      endAdornment: (
                        <InputAdornment position="end" style={{ paddingRight: 20 }}>
                          <SearchIconSVG />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                {this.state.categoryError && (
                  <Typography data-test-id="categoryErr" component={"div"} style={{ color: "red", fontSize: "11px", marginTop: "6px" }}>
                    {this.state.categoryError}
                  </Typography>
                )}

                {this.state.searchCategoryValue ? (
                  <Box sx={{ marginBottom: 20, display: "flex", flexDirection: "row", marginTop: 20 }}>
                    <Box style={{ color: "#666666", fontWeight: 1000, fontSize: 12, fontFamily: `"Lato", sans-serif`, marginRight: 5, overflow: 'hidden', textOverflow: 'ellipsis', width: '400px'}}>{`found ${this.state.searchCategoryResults.length} results for ${this.state.searchCategoryValue}`}</Box>
                    <Box style={{ fontFamily: `"Lato", sans-serif`, fontWeight: 500, color: "#666666", fontSize: 12 }}>Please use a more specific keyword to narrow down the results</Box>
                  </Box>
                ) : (
                  ""
                )}

                {this.state.openSearchCategoryResult && (
                  <Box
                    style={{
                      position: "relative",
                      marginTop: "12px",
                      maxHeight: "400px",
                      overflowY: "auto"
                    }}
                  >
                    {this.state.searchCategoryResults?.map((item, index) => {
                      return (
                        <CustomMenuItemBox data-test-id="result" className={classes.card} style={{ background: item.id === (this.state.microCategoryId || this.state.categoryId) ? "#ff7020b0" : "#fff" }} key={`${item}-${index}`} onClick={() => this.handleChangeCategory(item)}>
                          <Radio data-test-id="radio" name="product" className={classes.radio} checked={item.id === (this.state.microCategoryId || this.state.categoryId)} onChange={() => this.handleChangeCategory(item)} />
                          <Box>
                            <Typography className={classes.textOne}>{item.attributes.name}</Typography>
                            <Typography style={{color: item.id === (this.state.microCategoryId || this.state.categoryId) ? "#fff": "#697A8D"}} className={classes.textTwo}>{item.attributes.relation}</Typography>
                          </Box>
                        </CustomMenuItemBox>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </ClickAwayListener>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <StyledOrangeButton
                onClick={this.handleOnSubmit}
                data-test-id="nextRef"
                type="submit"
              >
                Next
              </StyledOrangeButton>
            </Box>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  errorMsg: {
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px"
  },
  header: {
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px"
    }
  },
  radio: {
    color: "#666666",
    marginRight: "12px",
    paddingLeft: "0",
    "&.Mui-checked": {
      color: "#666666"
    }
  },
  card: {
    display: "flex",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    background: "#fff",
    marginBottom: "12px",
    borderRadius: "5px"
  },
  textOne: {
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif`,
    color: "#33333a"
  },
  textTwo: {
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`
  },
  section: {
    marginTop: "50px", 
    width: "70%",
    '@media (max-width: 600px)' : {
      width:'100%'
    }
  }
});

const StyledOrangeButton = styled(Button)({
  textTransform: "none",
  fontFamily: "'Lato', sans-serif",
  fontWeight: 700,
  fontSize: 16,
  background: "#FF6008",
  color: "white",
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  padding: "8px 30px",
  "&:hover": {
    backgroundColor: "#ff7f50"
  },
  right: 30,
  bottom: 16,
  marginTop: 150,
  '@media (max-width: 600px)': {
    right: 0
  }
})
const styledCategories = withStyles(styles)(SellerCategorize);
export { styledCategories };
// Customizable Area End
