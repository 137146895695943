import React from "react";
import {
    Typography,
    // Customizable Area Start
    Paper,
    Grid,
    Box,
    Divider,
    withStyles,
    Theme,
    Container,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from 'react-router-dom';
import {styledAddressList as UserAddressList} from "./UserAddressList.web";
import ReturnAndWarrentyClaims from "../../catalogue/src/ReturnAndWarrentyClaims.web";
import UserWishlist from "./UserWishlist.web"
import HeaderWeb from "../../landingpage/src/Header.web";
import UserOrderHistory from "./UserOrderHistory2.web";
import FooterWeb from "../../landingpage/src/Footer.web";
import UserProfile from "./UserProfile.web";

// Customizable Area End

import UserMenuController, { Props, } from "./MenuBarController";

class UserMenu extends UserMenuController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }



    // Customizable Area Start
    menuList() {

        const { classes } = this.props;
        const menuData = [{ label: "My Profile", value: "Profile" }, { label: "Order History", value: "Order-history" }, { label: "Wishlist", value: "wishlist" }, { label: "Addresses", value: "Address" }]
        return menuData.map((item, index) => (<>
            <Link to={`/my-account/${item.value}`} rel="noopener" style={{ textDecoration: "none", width: "inherit" }}>
                <Typography data-testid="menuList" key={item.value} ref={this.inputRef} className={this.state.selectedMenu === item.value ? classes.active : classes.menuList} onClick={this.menuSelector.bind(this,item.value)}>{item.label}</Typography>
            </Link>
            {index !== menuData.length - 1 && <Divider style={{ width: "100%", color: '#efeff4', height: '1px' }} />}
        </>)
        )
    }


    // Customizable Area End

    render() {
        const { firstName, lastName, email, } = this.state
        const { classes } = this.props;
        return (
            <Box style={{ fontFamily: `"Lato", sans-serif !important` }}>
                <HeaderWeb navigation={this.props.navigation} id={this.props.id} cartId={this.state.cartId} address={this.state.address}/>
                <Container style={{ maxWidth: "1700px", padding: "0", margin: "0 auto" }}>
                    <Box sx={webStyle.mainWrapper}>
                        <Grid container spacing={3} >
                            <Grid item className={classes.leftSide} >
                                <Paper className={classes.papperClasses}>
                                    <Box style={webStyle.leftTop} >
                                        <Typography className={classes.personName}>{firstName} {lastName}</Typography>
                                        <Typography className={classes.personEmail}>{email}</Typography>
                                        <Divider style={{ width: "100%", color: '#efeff4', height: '2px', marginBlock: '10px' }} />
                                    </Box>
                                    <Box className={'menuListWrraper'}>
                                        {this.menuList()}
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item className={classes.rightSide}>
                                <Paper className={classes.papperClassesOne}>
                                    {this.state.selectedMenu === "Profile" && <UserProfile userinfo={this.userProfiles} navigation={this.props.navigation} id={"asd-dfg"}></UserProfile>}
                                    {this.state.selectedMenu === "Address" && this.state.buyerId && <UserAddressList data-test-id="addressList" userId={this.state.buyerId} navigation={this.props.navigation} id={"user-address-list"} match={this.props.match} defaultAddress={this.handleDefaultAddress}></UserAddressList>}
                                    {this.state.selectedMenu === "Order-history" && <UserOrderHistory navigation={this.props.navigation} id={"fgdfg"} userId={"fghf"} ></UserOrderHistory>}
                                    {this.state.selectedMenu === "wishlist" && this.state.buyerId && <UserWishlist data-test-id="userWishlist" navigation={this.props.navigation} id={"vcbnfgh"} userId={this.state.buyerId} cartId={this.handleCartId}></UserWishlist>}
                                    {this.state.selectedMenu === "return-and-warrenty-claims" && <ReturnAndWarrentyClaims navigation={this.props.navigation} id={"return-&-warrenty-claims"}></ReturnAndWarrentyClaims>}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <FooterWeb token={""} navigation={this.props.navigation} id={""} categories={[]} />
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    mainWrapper: {
        background: '#eae9e9',
        padding: "20px",
        fontFamily: " Lato, Arial, san-serif",
    },
    leftTop: { 
        display: 'flex', 
        flexDirection: 'column' as const, 
        alignItems: 'flex-start', 
        gap: '0px' 
    }
}

const styles = (theme: Theme) => ({
    personName: {
        color: "#33333a",
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Lato, sans-serif",
        lineHeight: '24px',
        whiteSpace: 'normal' as const,
        wordBreak:"break-word" as const,
        [theme.breakpoints.down("md")]: {
            fontSize: "16px",
            lineHeight: '18px'
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
            lineHeight: '18px'
        }
    },
    personEmail: {
        color: "#666666",
        fontWeight: 400,
        fontSize: "12px",
        fontFamily: "Lato, sans-serif",
        lineHeight: "38px",
        whiteSpace: "nowrap" as const,
        overflow: "hidden" as const,
        textOverflow: "ellipsis" as const,
        margin:'-2px 0',
        [theme.breakpoints.down("md")]: {
            fontSize: "12px",
            lineHeight: '26px',
        }
    },
    active: {
        color: "#ff6008",
        lineHeight: "21.6px",
        marginBlock: "14px" as const,
        fontWeight: 700,
        textDecoration: "underline",
        cursor: "pointer",
        fontSize: '18px',
        fontFamily: "Lato, sans-serif",
        // maxWidth: '170px',
        [theme.breakpoints.down("md")]: {
            fontSize: "16px",
            lineHeight: '18px'
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
            lineHeight: '18px'
        }
    },
    menuList: {
        color: "#232529",
        lineHeight: "21.6px",
        marginBlock: "14px",
        fontWeight: 400,
        cursor: "pointer",
        fontFamily: "Lato, sans-serif",
        textDecoration: "underline" as const,
        textDecorationColor: "#fff" as const,
        fontSize: '18px',
        // maxWidth: '170px',
        [theme.breakpoints.down("md")]: {
            fontSize: "16px",
            lineHeight: '18px'
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
            lineHeight: '18px'
        }
    },
    leftSide: {
        width: '22%',
        height: '690px',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            height: 'auto',
        }
    },
    rightSide: {
        width: '78%',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            marginTop: '20px'
        }
    },
    papperClasses: {
        background: '#fff',
        padding: "20px",
        height: "100%",
        boxSizing: 'border-box' as const,
        borderRadius: "15px",
        '& .menuListWrraper': {
            marginTop: '50px' as const,
            [theme.breakpoints.down("xs")]: {
                marginTop: '10px' as const,
                height:'max-content'
            }
        },
        [theme.breakpoints.down("sm")]: {
            padding: "20px",
        }
    },
    papperClassesOne: {
        background: '#fff',
        padding: "30px",
        height: "100%",
        boxSizing: 'border-box' as const,
        borderRadius: "15px",
        marginBottom: '20px',
        [theme.breakpoints.down("sm")]: {
            padding: "20px",
            marginBottom: '20px'
        }
    }
})

export { UserMenu }
export default withStyles(styles)(UserMenu)
// Customizable Area End