import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("../src/config.js")
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes : {
    tabs : string,
    ordersWrapper: string,
    tableHead: string,
    checkBox: string,
    tabsLabel: string,
    layout: string,
    tableContainer: string,
    pendingOrdersWrapper: string,
    ordersInfo: string,
    optionsWrapper: string,
    options: string,
    tableHeaderText: string,
    tableRowHeaderText: string,
    customerOption: string,
    pendingOrderStatus: string,
    viewOption: string,
    day: string,
    prodImage: string,
    buyer: string,
    buyerName: string,
    detailsWrapper: string,
    detailsTableHead: string,
    completedOrderStatus: string,
    cancelledOrderStatus: string,
    tableCellContent: string,
    dateWrapper: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  value: number,
  ordersList : any[],
  selectedOrders : any[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FulFilledByEzzyOrderManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      authToken: "",
      value:0,
      ordersList : [],
      selectedOrders : []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  ordersList = [
    {
      id: 1,
      attributes: {
        date: '23/05/23 01:26PM GST'
      }
    },
    {
      id: 2,
      attributes: {
        date: '23/05/23 01:26PM GST'
      }
    }
  ]


  handleChange = (event:any,value:any) => {
    this.setState({value})
  }

  handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected = this.state.ordersList.map((n: any) => n.id);
      this.setState({ selectedOrders: newSelected });

    } else {
      this.setState({ selectedOrders: [] });
    }
  };

  handleClickOrdersRow = (num: any) => {
    const selectedIndex = this.state.selectedOrders.indexOf(num);

    let newSelected: any[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedOrders, num);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedOrders.slice(1));
    } else if (selectedIndex === this.state.selectedOrders.length - 1) {
      newSelected = newSelected.concat(
        this.state.selectedOrders.slice(0, -1)
      );
    }
    this.setState({
        selectedOrders: newSelected,
    });
  };

  isSelectedOrders = (num: number) => {
    if(this.state.selectedOrders.indexOf(num) !== -1) return true;
    else return false
  }
  
  getStatus = (value: number) => {
    if(value === 0){
      return "PAYMENT DONE"
    }
    else if(value === 1){
      return "COMPLETED"
    }
    else return "CANCELLED"
  }

  getStatusClass = (value:number) => {
    if(value === 0){
      return this.props.classes.pendingOrderStatus
    }
    else if(value === 1){
      return this.props.classes.completedOrderStatus
    }
    else return this.props.classes.cancelledOrderStatus
  }

  getActionResults = (value: number) => {
    if(value === 1){
      return "Refund"
    }
    else return "View"
  }

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  async componentDidMount(): Promise<void> {
    this.handleGetToken();
  }
  // Customizable Area End
}
