// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { GetCookies } from "../../../framework/src/WebUtilities";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface CategoryData {
  id: string;
  attributes: {
    category: string;
  };
}

export interface CategoryList {
  id: string;
  attributes: {
    name: string;
  };
}

export interface SocialMediaData {
  social_media_url: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  categories: CategoryData[];
  token: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  socialMediaInfo: SocialMediaData[];
  categories: CategoryList[];
  authToken: String;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FooterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSocialMediaInfoApiCallId: string = "";
  categoryAPICallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      socialMediaInfo: [],
      categories: [],
      authToken: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getSocialMediaInfoApiCallId) {
        this.setState({ socialMediaInfo: responseJson });
      }
      if (apiRequestCallId === this.categoryAPICallId) {
        this.setState({ categories: responseJson?.data });
      }
      // apiCategorySlideshow
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.getSocialMediaInfo();
    this.getCategories();
    this.handleGetToken();
  }

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  getCategories = async () => {
    const buyer_token = GetCookies("ba") || "";

    const headers = {
      "Content-Type": configJSON.contentType,
      token: buyer_token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.categoryAPICallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/categories");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  chunkArray = (array: CategoryList[], size: number) => {
    return Array.from({ length: Math.ceil(array?.length / size) }, (item, index) => array?.slice(index * size, index * size + size));
  };

  handleGoToStaticPage = (selectedPage: string) => {
    let setPath: string;
    if (selectedPage === "Terms & Conditions" || selectedPage === "Shipping & Returns") {
      setPath = selectedPage.split(" & ").join("-");
    } else {
      setPath = selectedPage.split(" ").join("-");
    }
    const navigate: Message = new Message(getName(MessageEnum.NavigationStaticPageMessage));
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
    this.props.navigation.navigate("StaticPage", { path: setPath.toLowerCase() });
    const message: Message = new Message(getName(MessageEnum.SendMessage));
    message.addData(getName(MessageEnum.SelectedStaticPage), selectedPage);
    this.send(message);
  };

  handleGoToHomeContactUsPage = () => {
    const navigate: Message = new Message(getName(MessageEnum.NavigationHomeContactUsMessage));
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  };

  handleGoToSignUpPage = () => {
    this.props.navigation.navigate && this.props.navigation.navigate("FeaturesHeader");
  };

  getSocialMediaInfo = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.props.token
    };
    const mediaMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSocialMediaInfoApiCallId = mediaMessage.messageId;
    mediaMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getSocialMediaApiEndPoint);
    mediaMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    mediaMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(mediaMessage.id, mediaMessage);
  };

  // Customizable Area End
}
