import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { Message } from "../../../framework/src/Message";

export const configJSON = require('./config.js');
export const configJSON2 = require('../../dashboard/src/config.js');

export interface IBrandData {
  id: string,
  attributes: {
      id: number,
      brand_name: string
  }
}

export interface ISearchProductBrandData {
  id: string,
  attributes: {
    brand_id: number;
    brand_name: string;
    product_image: string;
    product_title: string;
    is_variant: boolean,
    variant_product_group: {
      product_besku?: string
    } | null;
    besku: number;
    category: {
      id: string,
      name: string
    };
    details: string;
    product_content :{
      product_attributes: {
        long_description: string,
        size_and_capacity: {
          prod_model_number: string;
        }
      }
    },
    brand: {
      id: string,
      brand_name: string
    }
  }
}

export interface IBrandProductListResult {
  data: ISearchProductBrandData[],
  message: string,
  total_count: number
}

export interface IBrandListRes {
  data: IBrandData[]
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    tableHeading: string,
    title: string,
    viewBtn: string,
    brandRadio: string,
    brandImage: string,
    footerContainer: string,
    footerLabel: string,
    menuItem: string,
    theader: string,
    tableWrap: string,
    searchOption: string,
    resultsTextContainer: string,
    description: string,
    searchResText: string,
    searchWrap: string,
    rightPadding: string,
    rightMargin: string,
    noScroll: string,
    wrapper: string,
    main: string,
    section: string,
    desc: string,
    headingBox: string,
    topHeader: string,
    line: string,
    productSearch: string,
    noProdText: string,
    prodDescription: string,
    error: string,
    prodResultsText: string,
    productBtn: string,
    productTitle: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  productList: ISearchProductBrandData[];
  selectProductScreen: boolean;
  selectSKU: boolean;
  skuData: string;
  skuValue: string;
  activeStep: string;
  selectedproductId: string;
  searchKeyword: string,
  token: string,
  selectedBrandId: string,
  pageNumber: number,
  brandValue: string,
  brandList: IBrandData[],
  totalProdCount: number,
  brandErr: string,
  isViewMore: boolean,
  descData: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerAddBrandProductController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputBrandRef: React.RefObject<InputProps & { click: Function }>;
  apiCreateBrandCallId: string = "";
  getBrandProdResultsApiCallId: string = "";
  getBrandListApiCallId: string = "";
  tableRef: React.RefObject<HTMLTableSectionElement>
  // Customizable Area End

  // Customizable Area Startp
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      activeStep: "listProduct",
      selectProductScreen: true,
      selectSKU: false,
      skuData: "",
      selectedproductId: "1",
      skuValue: "",
      token: '',
      productList: [],
      searchKeyword: '',
      selectedBrandId: '',
      pageNumber: 1,
      brandValue: '',
      brandList: [],
      totalProdCount: 0,
      brandErr: "",
      isViewMore: false,
      descData: ''
    };

    this.inputBrandRef = createRef();
    this.tableRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.getBrandProdResultsApiCallId) {
        this.handleBrandProdListRes(responseJson)
      }
      if (apiRequestCallId === this.getBrandListApiCallId) {
        this.handleBrandListRes(responseJson);
      }
    }
  }

  handleBrandProdListRes = (responseJson: IBrandProductListResult) => {
    if(responseJson.message === configJSON.noProductsFoundText) {
      this.setState({productList: [],totalProdCount: 0 })
    }
    else if(responseJson.data.length > 0) {
      this.tableRef.current?.scrollIntoView({behavior: 'smooth', block:'start'})
      this.setState({
        totalProdCount: responseJson.total_count,
        productList: responseJson?.data
      })
    }
    
  }

  handleBrandListRes = async(responseJson: IBrandListRes) => {
    if (responseJson.data.length > 0) {
      this.setState({ brandList: responseJson.data});
      await removeStorageData("selectedBrand")
    } 
    else {
      this.setState({ brandList: [] });
    }
  };

  async componentDidMount(): Promise<void> {
    const selectedBrand = await getStorageData("selectedBrand")
    const token = await getStorageData("authToken")
    this.setState({token: token})
    this.getBrandList()
    if(selectedBrand) {
      this.setState({selectedBrandId: selectedBrand, brandValue: selectedBrand})
      this.getBrandProductList()
    }
  }

  getBrandProductList = () => {
    const headers = { 
      "Content-Type": configJSON.productApiContentType, 
      token: this.state.token 
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getBrandProdResultsApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.brandProductListEndPoint + `${this.state.searchKeyword}` + `&brand_id=${this.state.selectedBrandId}&per_page=10&page=${this.state.pageNumber}`
    );
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), 
      JSON.stringify(headers)
    );
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  SearchBrandProdDebounce = (call: (...args: string[]) => void, delay: number | string | unknown) => {
    let timer: string | number;
    return function(...args: string[]) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  };

  searchProducts = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchKeyword : event.target.value})
    if(this.state.selectedBrandId) {
      this.setState({brandErr: ''})
      this.handleSearchOnChange(event.target.value)
    }
    else this.setState({brandErr: "Please select brand"})
  }

  handleSearchOnChange =  this.SearchBrandProdDebounce(this.getBrandProductList, 1000)

  handleChangeBrandName = (brandValue: string) => {
    this.setState({ brandValue: brandValue, selectedBrandId: brandValue}, () => this.getBrandProductList())
  }

  getBrandList = async() => {
    const headers = {
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getBrandListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.barndListApiEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), 
      JSON.stringify(headers)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBrandProdCount = () => {
    const count: number = this.state.totalProdCount / 10;
    const roundedReturnCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedReturnCount
  }

  handleAddNewProductBtn = () => {
    this.props.navigation.navigate(configJSON.sellerHomeText, {path:configJSON2.singleProductSelCategoryPath})
  }

  handleViewMoreBtn = (desciption: string) => {
    this.setState({isViewMore: !this.state.isViewMore, descData: desciption})
  }

  handleCloseDes = () => {
    this.setState({isViewMore: false, descData:''})
  }

  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
