// Customizable Area Start
import React from "react";
import PrivacyNoticeController, { Props, configJSON2 } from "./PrivacyNoticeController.web";
import { Box, styled } from "@material-ui/core";
import { BoxNoScroll, OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
import { byezzyLogo } from "../../email-account-registration/src/assets";
import { configJSON } from "../../contactus/src/CommonNavController";
import * as DOMPurify from 'dompurify';
// Customizable Area End

class PrivacyNotice extends PrivacyNoticeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
          <BoxNoScroll style={webStyle.boxNoScroll}>
            <Box style={webStyle.box}>
            <Box style={webStyle.boxContainer}>
              <img src={byezzyLogo} alt="logo" style={webStyle.logoDimension}/>
              <StyledOrangeBtn data-test-id="back_home" onClick={this.handleBackBtn}>
            {configJSON.backToHomeText}
          </StyledOrangeBtn>
            </Box>
              <Box style={webStyle.heading}>{configJSON2.privacyNoticeText}</Box>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.privacyNotice.content)}} data-test-id="privacyContent">
              </div>
              <Box style={webStyle.spacer}></Box>
            </Box>
          </BoxNoScroll>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  boxNoScroll: {
    overflow: "auto",
    paddingBottom: 20,
  },
  box: {
    minHeight: "auto",
    borderRadius: "8px",
    flexDirection: "column" as const,
    padding: "30px",
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
    position: "relative" as const,
    display: "flex",
    background: "white",
  },
  heading: {
    fontSize: "27px",
    marginTop:'35px',
    marginBottom: '25px',
    fontWeight: 600,
    fontFamily: `"Lato", sans-serif`,
  },
  content: {
    width: "100%",
    marginTop: 10,
    fontFamily: `"Lato", sans-serif`,
    fontSize: 16,
    color: "#33333A",
  },
  spacer: {
    width: "100%",
    height: 20,
  },
  logoDimension: {
    width: '102px',
    height: '85px'
  },
  boxContainer : {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100px',
    margin:'-30px -5px'
  }
};

const StyledOrangeBtn = styled(OrangeButton)({
  '@media (max-width:400px)': {
    padding:'12px 20px'
  }
})

export default PrivacyNotice;
// Customizable Area End
