// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { GetCookies } from "framework/src/WebUtilities";
import { IBrandList } from "./BuyerSearchProduct/ProductCatalogueController.web";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("../src/config.js")

export interface ISubCategoryList {
  id: string,
  attributes: {
    name: string
  }
}

interface ISubCategoryRes {
  data: ISubCategoryList[]
}

export interface IDealProductList {
  id: string,
  attributes: {
    product_image: string,
    product_title: string | null,
    product_content: {
      product_attributes: {
        mrp?: string | number | null,
        retail_price: string | number | null
      }
    } | null;
    deal_catalogues: {
      deal_price: string,
      status: string
    }[] ;
    catalogue_offer: {
      sale_price: string,
      status: boolean,
      sale_schedule_to: string
    } | null
    product_rating: {
       average_rating: number, 
       total_reviews: number
    } | null;
    id: number,
    sub_category: ISubCategoryList
  }
}

export interface IDealProductListRes {
  data: IDealProductList[],
  max_range: number,
  min_range: number,
  total_count: number
}

export interface IBrandData {
  data: IBrandList[]
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  match?: {
    params : {
      id: string | number
    }
  };
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  subCategoryList: ISubCategoryList[],
  subCategoryIdList: string[],
  perPage: string,
  dealProductList: IDealProductList[] | null,
  brandData: IBrandList[],
  dealId: string,
  brandIds: number[],
  selectedBrandIds: number[],
  maxRange: number | null,
  minRange: number | null,
  prodPiceRange: number[],
  priceData: {
    max_range: number,
    min_range: number
  },
  ratings: boolean[],
  selectedRatings: number[],
  selectedSubCategory: string[];
  sortByDeal: string;
  isBrandPage: boolean;
  colorFilter: string[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class WeeklyDealsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDealProdListApiCallId: string = "";
  getBrandListApiCallId: string = "";
  getSubCategoryListApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      subCategoryList: [],
      subCategoryIdList: [],
      perPage: "50",
      dealProductList: null ,
      brandData: [],
      dealId: '',
      brandIds: [],
      selectedBrandIds: [],
      maxRange: null,
      minRange: null,
      prodPiceRange: [],
      priceData: {
        max_range: 0,
        min_range: 0
      },
      ratings: new Array(5).fill(false),
      selectedRatings: [],
      selectedSubCategory: [],
      sortByDeal: "recommended",
      isBrandPage: false,
      colorFilter: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.getDealProdListApiCallId) {
        const productList = responseJson.data as IDealProductList[]
        this.setState({
          dealProductList: productList,
          priceData: responseJson,
          prodPiceRange:[Number(responseJson.min_range),Number(responseJson.max_range)],
        })
        if(!this.state.isBrandPage) {
          this.getBrandsFromDealProducts()
          this.getSubCategoriesFromProducts()
        }
      }
      if(apiRequestCallId === this.getBrandListApiCallId) {
        this.handleBrandResponse(responseJson)
      }
      if(apiRequestCallId === this.getSubCategoryListApiCallId) {
        this.handleSubCategoryResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  handleBrandResponse = (responseJson:IBrandData) => {
    if(responseJson) {
      const brandIds = responseJson.data.map((item: IBrandList) => item.attributes.id)
      this.setState({brandData: responseJson.data, brandIds: brandIds})
    }
  }

  handleSubCategoryResponse = (responseJson:ISubCategoryRes) => {
    if(responseJson) {
      const subCategoryIdList = responseJson.data.map((item: ISubCategoryList) => item.id)
      this.setState({subCategoryList: responseJson.data, subCategoryIdList: subCategoryIdList})
    }
  }
  
  componentDidUpdate(prevProps: Props, prevState: S) {
    const dealId = window.location.pathname.split("/")[2]
    if(prevState.dealId !== dealId) {
    const isBrandPage = window.location.pathname.includes('brand');
    this.setState({prodPiceRange:[], dealId, isBrandPage, sortByDeal: 'recommended'},() => {
      this.getDealProductList(dealId);
    })
    }
    else return
  }

  async componentDidMount() {
    const sortByDeal = await getStorageData("sortFilter");
    if(sortByDeal) {
      this.setState({sortByDeal})
    }
    const dealId = window.location.pathname.split("/")[2]
    const isBrandPage = window.location.pathname.includes('brand');
    this.setState({prodPiceRange: [],dealId: dealId, isBrandPage})
    this.getDealProductList(dealId)
  }

  async componentWillUnmount() {
    await removeStorageData('sortFilter');
   }

  getDealProductList = async (dealId: string) => {
    const token = GetCookies("ba");
    const brand = window.location.pathname.includes('brand');
    const keyName = brand ? 'brand_id' : 'deal_id';
    const body = {
      [keyName]: dealId,
      "sub_category_ids":this.state.selectedSubCategory,    
      "mini_category_ids": [],  
      "micro_category_ids": [],
      "brand_ids": this.state.selectedBrandIds,  
      "sort_by": this.state.sortByDeal,  
      "min_price": this.state.minRange,
      "max_price": this.state.maxRange,
      "per_page": this.state.perPage,  
      "page_no": 1,  
      "filter_by_rating": this.state.selectedRatings,
      "color_filter": this.state.colorFilter.length > 0 ? this.state.colorFilter : null
  }
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealProdListApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandAndDealProductApiEndPoint
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(message.id, message);
  }

  getBrandsFromDealProducts = async () => {
    const token = GetCookies("ba")
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandListApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/brands/list_brands_from_catalogues?deal_id=${this.state.dealId}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  getSubCategoriesFromProducts = async() => {
    const token = GetCookies("ba")
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategoryListApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/list_sub_categories_from_catalogues?deal_id=${this.state.dealId}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  navigateToHome = () => {
    this.props.navigation.navigate('Home')
  }

  handleProductsPerPage = (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    const value = event.target.value;
    this.setState({
      perPage: value as string,
    },() => {
      this.getDealProductList(this.state.dealId)
    });
  };

  handleSubCategoryChange = (subCategoryId:string, event:React.ChangeEvent<HTMLInputElement>) => {

    const currentSelected = this.state.selectedSubCategory;
    const isChecked = event.target?.checked;
    
    const updatedSelected = isChecked
        ? [...currentSelected, subCategoryId]  
        : currentSelected.filter((subCatId: string) => subCatId !== subCategoryId);  
    
    this.setState({ selectedSubCategory: updatedSelected }, () => {
        this.getDealProductList(this.state.dealId);
    });
  }

  handleBrandSelect = (brandId: number) => {
    this.setState({ selectedBrandIds: [brandId] }, () => {
      this.getDealProductList(this.state.dealId);
  });
  }
  handleBrandChange = (brandId: number,event: React.ChangeEvent<HTMLInputElement>) =>{
    const currentBrandIds = this.state.selectedBrandIds;
    const isChecked = event.target?.checked;

    const updatedBrandIds = isChecked
        ? [...currentBrandIds, brandId] 
        : currentBrandIds.filter((brand: number) => brand !== brandId);  

    this.setState({ selectedBrandIds: updatedBrandIds }, () => {
        this.getDealProductList(this.state.dealId);
    });
  };

  handlePriceChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
    this.setState({ prodPiceRange: value as number[] }, () => this.setState({minRange: this.state.prodPiceRange[0], maxRange: this.state.prodPiceRange[1]}));
    this.getDealProductList(this.state.dealId)
  };

  handlePriceChangeCommitted = (
    event: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    const value = newValue as number[]; 
    const minPrice = Math.floor(value[0]);
    const maxPrice = Math.ceil(value[1]);
    this.setState({maxRange: maxPrice, minRange: minPrice}, () => this.getDealProductList(this.state.dealId))
    
  };

  handleChangeRating = (indexValue:number) => (event:React.ChangeEvent<HTMLInputElement>) => {
    const checked  = event.target?.checked;
    this.setState((prevState) => {
        const ratings = [...prevState.ratings];
        ratings[indexValue] = checked; 
        return { ratings };
    });
    const tempArray: number[] = event.target?.checked
    ? [...this.state.selectedRatings, 5 - indexValue]
    : this.state.selectedRatings.filter(rating => rating !== 5 - indexValue);
    this.setState({selectedRatings: tempArray},() => {
      this.getDealProductList(this.state.dealId)
    })
};

handleSortByDealOption = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
  const value = event.target.value as string;
  this.setState({sortByDeal: value},() => {
    setStorageData("sortFilter", value)
    this.getDealProductList(this.state.dealId)
  })
}

handleColorFilter = (color:string) => {
  this.setState((prevState) => {
  const colorFilter = prevState.colorFilter.includes(color)
    ? prevState.colorFilter.filter((clrStr) => clrStr !== color)
    : [...prevState.colorFilter, color];

  return { colorFilter };
  }, () => this.getDealProductList(this.state.dealId));
}

  // Customizable Area End
}
