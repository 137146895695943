import { Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const CustomOrangeButton = withStyles(() => ({
    root: {
      textTransform: "none",
      fontFamily: "'Lato', sans-serif",
      fontWeight: 700,
      fontSize: 16,
      background: "#FF6008",
      color: "white",
      display: "flex",
      alignItems: "center",
      borderRadius: "8px",
      padding: "8px 30px",
      "&:hover": {
        backgroundColor: "#ff7f50"
      }
    }
}))(Button);

export const CustomizeInputSearchBox = withStyles(() => ({
    root: {
      "& .MuiInputBase-input": {
        fontSize: 14,
        fontFamily: '"Lato", sans-serif',
        fontWeight: 500
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: "#f44336"
      },
      "& .MuiInputBase-root.Mui-error": {
        border: "1px solid #f44336"
      },
      "& .MuiInputBase-root": {
        border: "1px solid #c8c8c8"
      },
      "& .MuiInput-underline:before, & .MuiInput-underline:after": {
        borderBottom: "none" // Remove the underline
      },
      "& .MuiInputLabel-root": {
        top: -24,
        left: 0,
        color: "rgb(154,154,154)",
        fontWeight: 600,
        fontSize: 14,
        fontFamily: `"Lato", sans-serif`
      },
      "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
        borderBottom: "none" // Remove the underline on hover
      },
      "& .MuiInput-formControl": {
        margin: 0,
        padding: "6px 0px 7px",
        borderRadius: "5px"
      },
      "& .MuiInputBase-input::placeholder": {
        color: "#585858",
        fontFamily: "Lato, sans-serif",
        fontSize: "12px",
        fontWeight: 400
      }
    }
}))(TextField);

export const AddNewButton = withStyles(() => ({
    root: {
      padding: "8px 20px",
      background: "#10BC9E",
      borderRadius: 4,
      fontFamily: `"Lato", sans-serif`,
      color: "white",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "none",
      "&:hover": {
        background: "#10BC9E"
      }
    }
  }))(Button);

  export const BlackButton = withStyles(() => ({
    root: {
      textTransform: "none",
      fontFamily: "'Montserrat Variable', sans-serif",
      fontWeight: 700,
      fontSize: 16,
      background: "#444",
      color: "white",
      display: "flex",
      alignItems: "center",
      borderRadius: "8px",
      padding: "8px 30px",
      "&:hover": {
        background: "#444"
      }
    }
  }))(Button);  