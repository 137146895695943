// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ClassNameMap } from "@material-ui/styles";
import { Message } from "../../../framework/src/Message";
import { GetCookies } from "../../../framework/src/WebUtilities";
import Cookies from "js-cookie";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");

export interface ITopBanner {
  data: {
    attributes: {
      title: string,
      button_link: string,
      button_text: string
    }
  }
}

export interface ICategoryList {
  id: number | string,
  attributes: {
    name: string,
    header_image?: string,
    category_image?: string
    category: {
      name: string,
      header_image: string,
      category_image: string
    }
  }
}

export interface IAddToCartRes {
  message: string
}

interface ICategoryRes {
  data: ICategoryList[]
}

interface ICartDetailsRes {
  data: {
    attributes: {
      total_items: number
    }
  }
}

interface ISuccessData {
  data: {
    id: string,
    attributes: {
      id: number,
      full_name: string
      current_order: string | null
    }
  }
}

export interface IAddressData {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    address_1: string;
    address_2: string;
    city: string;
    phone_number: string;
    state: string;
    is_default: boolean
  };
}

export interface IAddressRes {
  data: IAddressData[]
}

export interface ITopBrandData {
    id: string;
   type: string;
  attributes: {
      id: string;
      sequence_no: string;
      brand_id: string;
      created_at: string;
      updated_at: string;
      brand: {
          id: string;
          created_at: string;
          updated_at: string;
          brand_name: string;
          brand_name_arabic: string;
          brand_website: string;
          brand_year: string;
          approve: string;
          restricted: string;
          gated: string;
          account_id: null
      }
  }
}
export interface ITopBrandRes {
 data: ITopBrandData[]
}

export interface ITopMostPopularData  {
  id: string;
  type: string;
  attributes: {
      id: string;
      sequence_no: string;
      category_id: string;
      created_at: string;
      updated_at: string;
      category: {
          id: string;
          name: string;
          created_at: string;
          updated_at: string;
          admin_user_id: string;
          rank: null
      }
  }
}
export interface ITopMostPopularRes {
  data: ITopMostPopularData[]
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: ClassNameMap<"bannerTitle" | "fireIcon" | "topBanner" | "address" | "buyerLastName" | "welcomeText" | "signInBox" | "categoryNavText" | "navRight" | "colorOne" | "colorStyle" | "verticalMargin" | "logoStyle" | "badge" | "cartWrapper" | "categoriesbar" | "shopBtn" | "navTextAllCategory" | "header_image_container" | "header_image" | "categoriesContainer" | "staticItems" | "dropdownWrraper" | "staticDropdownWrraper" | "categoriesItem" | "staticCategoriesContainer" | "staticDropdown" | "dropdown" | "navMenu" | "navText" | "navTextList" | "dropDown" | "navigationBar" | "location" | "searchBox" | "headerPartText" | "navDropdownWrraper" | "navDropdownWrraperItem">;
  match?: {
    params: {
      type: string
    }
  };
  cartId?: string,
  itemCount?: number | null,
  setOrderId?: (orderId:string) => void ,
  address?: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  buyer_lastname: string;
  openProfileMenu: boolean;
  catagoryList: ICategoryList[];
  anchorEl: null | HTMLElement;
  selectedCategory: string;
  staticCategories: ICategoryList[];
  isHovered: number | string;
  count: number | null,
  token: string ,
  orderId: string | null,
  address: string;
  topBrand: ITopBrandData[];
  mostPopular: ITopMostPopularData[];
  headerCategories: any[],
  topBanner: ITopBanner
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HeaderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCheckBuyerSessionCallId = "";
  apiGetCatagoryListCallId = "";
  getCartDetailsApiCallId = "";
  addToCartApiCallId: string = "";
  getDefaultAddressApiCallId: string = "";
  getTopPopularBrandApiCallId: string = "";
  getMostPopularApiCallId: string = "";
  getHeaderCategoriesApiCallId: string = "";
  getTopBannerApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      buyer_lastname: "",
      openProfileMenu: false,
      catagoryList: [],
      isHovered: -1,
      anchorEl: null,
      selectedCategory: '',
      staticCategories: [],
      count: null,
      token: '',
      orderId: '',
      address: '',
      topBrand: [],
      mostPopular: [],
      headerCategories: [],
      topBanner: {
        data: {
          attributes: {
            title: '',
            button_link: '',
            button_text: ''
          }
        }
      }
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      this.handleSuccessResponse(responseJson, apiRequestCallId);
      // apiCategorySlideshow
    }
  }
  // Customizable Area Start
  handleSuccessResponse = async(responseJson: unknown, apiRequestCallId: string) => {
    const successData = responseJson;
    switch (apiRequestCallId) {
      case this.apiCheckBuyerSessionCallId:
        const buyerRes = responseJson as ISuccessData;
        if ("data" in buyerRes) {
          this.setState({ buyer_lastname: buyerRes.data.attributes.full_name });
          this.handleBuyerSessionSuccessRes(buyerRes)
          this.getDefaultAddress()
          await setStorageData("buyerLastName", buyerRes.data.attributes.full_name)
        }
        break;

      case this.apiGetCatagoryListCallId:
        const catRes = responseJson as ICategoryRes
        if ("data" in catRes) {
          this.setState({ catagoryList: catRes.data });
          const staticCategory = ['IT hardware', 'Electronics', 'Baby Products', 'Sports', 'Mobile & Accessories', 'Tv, Audio & Cameras'];
          const filteredArray1 = catRes.data && catRes.data.filter((item: ICategoryList) => staticCategory.some(catObj => catObj.toLowerCase() === item.attributes.name.toLowerCase()));
          const sortedResultArray = staticCategory.map((name: string) => filteredArray1.find((catObj: {attributes: {name: string}}) => catObj.attributes.name.toLowerCase() === name.toLowerCase())).filter(Boolean);
          this.setState({ staticCategories: sortedResultArray as ICategoryList[] });
        }
        break;
      case this.getCartDetailsApiCallId: 
        const cartDetailRes = responseJson as ICartDetailsRes 
        if(cartDetailRes.data) {
          this.handleCartDetailsRes(cartDetailRes)
        }
        break;

      case this.addToCartApiCallId : 
        const addToCartRes = responseJson as IAddToCartRes
        this.handleAddToCartRes(addToCartRes)
       break; 

      case this.getDefaultAddressApiCallId : 
        const addressList = responseJson as IAddressRes
        this.handleAddressList(addressList)
        break;
      case this.getTopPopularBrandApiCallId:
        const topBrandList = responseJson as ITopBrandRes
        this.handleTopBrandList(topBrandList)
        break;
      case this.getMostPopularApiCallId:
        const topMostPopularList = responseJson as ITopMostPopularRes;
        this.handleTopPopularList(topMostPopularList)
        break;
      case this.getHeaderCategoriesApiCallId : 
        this.setState({headerCategories: (responseJson as ICategoryRes).data})
        break;
      case this.getTopBannerApiCallId : 
        this.handleTopBanner(responseJson as ITopBanner)
        break;  
      default: break; 
    }
  };

  handleTopBanner = (responseJson: ITopBanner) => {
    if(responseJson.data) {
      this.setState({topBanner: responseJson})
    }
  }

  handleAddressList = (responseJson: IAddressRes) => {
    if(responseJson.data) {
      const addressRes = responseJson.data.filter((item: IAddressData) => item.attributes.is_default)
      if(addressRes) {
        this.setState({address: addressRes[0]?.attributes.address_1})
      }
    }
  }

  handleTopBrandList = (responseJson: ITopBrandRes) => {
    if(responseJson.data) {
      this.setState({topBrand: responseJson.data});
    }
  }

  handleTopPopularList = (responseJson: ITopMostPopularRes) => {
    if(responseJson.data) {
      this.setState({mostPopular: responseJson.data});
    }
  }

  handleAddToCartRes = (responseJson: IAddToCartRes) => {
    if(responseJson.message === "Order items added successfully") {
      removeStorageData("cart_items")
      this.handleCheckLoginSession(this.state.token)
   }   
  }

  handleCartDetailsRes = (responseJson: ICartDetailsRes) => {
    const token = GetCookies("ba")
    if(token) {
      const total = responseJson.data.attributes.total_items;
      if(total === 0) {
        this.setState({count: null})
      }
      else this.setState({count: total});
      if(this.props.itemCount === 0) {
        this.setState({ count: null})
      }
    }
  }

  handleBuyerSessionSuccessRes = async (successData:ISuccessData) => {
    const cartItems = await getStorageData("cart_items", true)
    if(successData.data.attributes && successData.data.attributes.current_order !== null) {
      this.setState({orderId: successData.data.attributes.current_order})
      
      this.handleLoggedUserResponse(successData.data.attributes.current_order)
      if(cartItems?.length > 0) {
        const productList = cartItems.map((orderItem:{id: string, quantity: string}) => {
          const { id, quantity } = orderItem ;
          return { catalogue_id: id , quantity: quantity };
        });
        this.addToCartApiCall(productList)
      }
      this.props.setOrderId?.(successData?.data?.attributes?.current_order)
    }
    else {
      this.props.setOrderId?.('')
      if(cartItems.length > 0) {
      const productList = cartItems.map((orderItem:{id: string, quantity: string}) => {
        const { id, quantity } = orderItem ;
        return { catalogue_id: id , quantity: quantity };
      });
      this.addToCartApiCall(productList)
      }
    }
  }

  handleLoggedUserResponse = async (orderId: string) => {
    const token = GetCookies("ba")
    if(token) {
      this.getCartDetails(orderId)
    }

    else return 
  }

  handleLogo = async() => {
    await removeStorageData('searchQuery');
    await removeStorageData('searchResultCount');
    this.props.navigation.navigate("Home");
  };

  handleClickAway = () => {
    this.setState({ openProfileMenu: false });
  };

  handleOpenProfileMenu = () => {
    this.setState({ openProfileMenu: !this.state.openProfileMenu });
  };

  handleCheckLoginSession = (buyer_token: string | boolean) => {
    if (buyer_token) {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      const headers = { "Content-Type": configJSON.contentType };
      this.apiCheckBuyerSessionCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.checkBuyerSession + buyer_token);
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      return null
    }
  };

  handleCatagoryAPI = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = { "Content-Type": configJSON.contentType };
    this.apiGetCatagoryListCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCatagoryListApiEndPoint);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSignOut = async () => {
    Cookies.remove("ba");
    Cookies.remove("accountId")
    Cookies.remove('setAddress')
    Cookies.remove('currentOrder')
    await removeStorageData("buyerLastName")
    this.setState({ buyer_lastname: "" , address: '', token:''});
    const message: Message = new Message(getName(MessageEnum.NavigationHomeScreenMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleOpenLoginModal = () => {
    const message: Message = new Message(getName(MessageEnum.SendMessage));
    message.addData(getName(MessageEnum.OpenBuyerEmailLoginModal), true);
    this.send(message);
  };

  handleShoppingCart = () => {
    this.props.navigation.navigate("ShoppingCart");
  };

  handleMouseEnter = (index: number | string) => {
    this.setState({ isHovered: index });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: -1 });
  };
  async componentDidMount(): Promise<void> {
    const isCartEmpty = await getStorageData("cart_items")
    const buyer_lastname = await getStorageData("buyerLastName") || ""
    if(isCartEmpty === null) {
      await setStorageData("cart_items", "[]")
    }
    const buyer_token: string = GetCookies("ba") || "";
    this.setCount(buyer_token)
    this.setState({token: buyer_token, buyer_lastname: buyer_lastname})
    this.handleCheckLoginSession(buyer_token);
    this.handleCatagoryAPI();
    this.getTopPopularBrand();
    this.getHeaderCategories();
    this.getMostPopularCategory();
    this.getTopBanner()
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const token = GetCookies("ba")
    if (prevProps.match !== this.props.match || prevState.catagoryList !== this.state.catagoryList) {
      this.setState({ isHovered: -1 });
    }
    if(this.props.cartId) {
      if(this.props.cartId && prevProps.cartId !== this.props.cartId) {
        return this.getCartDetails(this.props.cartId)
      }
    }
    if(this.props.itemCount !== prevProps.itemCount) {
      if(this.props.itemCount === 0) {
        this.setState({count : null})
      }
      else this.setState({count : this.props.itemCount as number})
    }

    if(this.props.address !== prevProps.address) {
      this.setState({address: this.props.address as string })
    }

  }

  getColor(text: string) {
    if (this.props.match?.params?.type === text) {
      return { color: "#f34f26", border: "none" };
    }
    else {
      return {};
    }
  }

  getColorMouse = (item: ICategoryList) =>{
    if (this.props.match?.params?.type == item.attributes?.category.name || this.state.isHovered === item.id) {
      return this.props.classes.colorStyle
    } else {
      return this.props.classes.colorOne
    }
  }

  getCartDetails = (orderNum: string) => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: GetCookies("ba"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartDetailsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${orderNum}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(message.id, message);
  }

  setCount = async(token: string) => {
    if(!token) {
      const cartData = await getStorageData("cart_items", true)
      const quantityArr = cartData.map((orderItem:{quantity: string}) => orderItem.quantity);
      const totalQuantity = quantityArr.reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0);
      this.setState({count: totalQuantity > 0 ? totalQuantity : null})
    }
  }

  addToCartApiCall = async (productList: {catalogue_id: string , quantity: string}[] ) => {
    const token =  GetCookies("ba")
    const headers = {
      "Content-Type": configJSON.contentType,
      token: token
    };
    const body = {
      "order_items": productList
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addToCartApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_shopping_cart/order_items/guest_user_order`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCategory = (categoryId: string | number, type:string) => {
    this.props.navigation.navigate("BuyerExploreProducts", {id: categoryId, type: type})
    removeStorageData("searchQuery")
    removeStorageData('subCatagoryIds')
    removeStorageData("brandIds")
  }

  handleBrandPageView = (productId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BrandProducts");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    this.send(message)
  }
  handleMyAccount = () => {
    this.props.navigation.navigate("Profile", {path: "Profile"})
  }

  handleWishlist = () => {
    this.props.navigation.navigate("Profile", {path: "wishlist"})
  }

  getDefaultAddress = async() => {
    const accountId = GetCookies("accountId")
    const headers = {
      "Content-Type": configJSON.contentType,
      token: GetCookies("ba"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDefaultAddressApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/${accountId}/user_delivery_addresses`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(message.id, message);
  }

  getCategoryImage = (item: ICategoryList) => {
    return item.attributes.category.header_image ? item.attributes.category.header_image : item.attributes.category.category_image
  }

  getTopPopularBrand = async() => {
    const headers = {
      "Content-Type": configJSON.contentType,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopPopularBrandApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topBrandApiEndPoint
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(message.id, message);
  }

  getMostPopularCategory = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMostPopularApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topPopularCategoryApiENdPoint
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(message.id, message);
  }

  getHeaderCategories = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHeaderCategoriesApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.headerCategoriesApiENdPoint
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(message.id, message);
  }

  getTopBanner = () => {
    const headers = {
      "Content-Type": configJSON.contentType,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopBannerApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBannerApiEndpoint
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(message.id, message);
  }

  isConditionalRending= (condition: boolean, value1: React.ReactNode, value2: React.ReactNode) =>  {
    return condition ? value1 : value2
  }

  handleShopBtn = () => {

  }
  // Customizable Area End
}
