// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("../../src/config.js");

interface IProductRelation {
  attributes: 
      {
          category: {
              name:string
          },
          sub_category: {
              name: string
          }, 
          mini_category: {
              name: string
          }, 
          micro_category: {
              name: string
          }
      }
}

interface IMenuTabResponse {
  data: {
    id: string,
    attributes: {
      title: string,
      position: string,
      store_name?: string | null
    }
  }[],
  errors: string[]
}

interface ICatalogData {
  data: {
    id: string
  }
}

interface IMenuResponse {
  data: {
    id: string,
    attributes: {
      title: string,
      position: string,
      catalogues: ICatalogData[]
    }
  }
}

export interface IProductData {
  id: string, 
  attributes: {
    product_title: string, 
    product_content: {
      product_attributes: {
        product_title: string
      }
    },
    category:{
      id: string,
      name: string
    },
    sub_category:{
      id: string,
      name: string
    },
    mini_category:{
      id: string,
      name: string
    },
    micro_category:{
      id: string,
      name: string
    },
  }
}

interface ITabProductData {
  data: IProductData[];
}

interface ITabList {
  id: string,
  title: string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tabName: string | null,
  token: string,
  tabList: ITabList[],
  activeMenuTab: number | null,
  tabPosition: number | string | null,
  searchProductValue: string;
  openTabProdResult: boolean;
  searchProdRes: IProductData[],
  productId: string,
  prodList: IProductData[];
  selectProdId: string[];
  tabNameErr: string,
  positionErr: string,
  tabId: string | null,
  menuError: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StoreMenuTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createTabApiCallId: string = "";
  getMenuTabApiCallId: string = "";
  getProdtResultsApiCallId: string = "";
  showMenuTabApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      tabName: "",
      token: "",
      tabList: [],
      activeMenuTab: null,
      tabPosition: "",
      searchProductValue: "",
      openTabProdResult: false,
      searchProdRes: [],
      productId: '',
      prodList: [],
      selectProdId: [],
      positionErr: '',
      tabNameErr: '',
      tabId: '',
      menuError: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if(apiRequestCallId === this.getProdtResultsApiCallId) {
        this.handleTabProductResults(responseJson)
      }
      if (apiRequestCallId === this.createTabApiCallId) {
        this.handleTabResponse(responseJson)
      }
      if (apiRequestCallId === this.getMenuTabApiCallId) {
        this.getMenuTabList(responseJson)
      }
      if (apiRequestCallId === this.showMenuTabApiCallId) {
        this.showMenuTabResponse(responseJson)
      }
    }
  }

  showMenuTabResponse = (responseJson: IMenuResponse) => {
    if(responseJson.data) {
      this.setState({
        tabName: responseJson.data.attributes.title,
        tabPosition: responseJson.data.attributes.position
      })
      if(responseJson.data.attributes.catalogues.length > 0) {
        this.productSearchUpdate('')
        this.setState({
          selectProdId : responseJson.data.attributes.catalogues.map((item:ICatalogData) => item.data.id),
          openTabProdResult: true
        })
      }
    }
  }

  getMenuTabList = (responseJson: IMenuTabResponse) => {
    if(responseJson) {
      const storeNames = responseJson.data.filter((item: {id: string, attributes: { title: string, store_name?: string | null }}) => 
        item.attributes?.store_name === null
      ).map((item: {id: string, attributes: { title: string }}) => ({
        id: item.id,
        title: item.attributes?.title
      }));
    this.setState({tabList: storeNames })
    }
  }

  handleTabResponse = (responseJson: IMenuTabResponse) => {
    if(responseJson.data) {
      this.setState({tabPosition: '', tabName: '', selectProdId: [], searchProductValue: '', menuError:'', searchProdRes: [], activeMenuTab:null, tabId:''})
      this.getMenuTabs()
    }
    else if(responseJson.errors[0]) {
      this.setState({menuError:responseJson. errors[0]})
    }
  }

  handleTabName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    this.setState({tabName: event.target.value})
    const nameRegex = /^(?!\s)(?!.*\s$)(?!^[0-9\W_]*$)[a-zA-Z0-9\W]{3,25}$/;
    if(!nameRegex.test(value)) {
      this.setState({tabNameErr: configJSON.countryErrorMsg})
    }
    else this.setState({tabNameErr: ''})
  }

  addMenu = () => {
    if(this.state.tabName && this.state.tabPosition && !this.state.tabNameErr && !this.state.positionErr) {
      this.createTab()
    }
  }

  handleMenuTab = (index: number, id:string) => {
    
    if(this.state.activeMenuTab === index && this.state.tabId === id) {
      this.setState({activeMenuTab: null, tabId: null, tabPosition: '', tabName: '', searchProdRes: [], searchProductValue: '', menuError: '', tabNameErr: '', positionErr:'' })
    }
    else {
      this.setState({tabId: id})
      this.setState({ activeMenuTab: index, tabPosition: '', tabName: '', searchProdRes: [], searchProductValue: '', menuError: '', tabNameErr: '', positionErr:'' })
      this.showMenuTabData(id)
    }  
  }

  handleTabPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    const position = event.target.value
    this.setState({tabPosition : event.target.value})
    const positionRegex = /^[2-7]$/
    if(!positionRegex.test(position)){
      this.setState({positionErr: configJSON.tabPositionErrMsg})
    }
    else this.setState({positionErr: ''})
  }

  handleNextBtn = () => {
    if(this.state.tabList.length >= 1) {
      const message: Message = new Message(getName(MessageEnum.SendMessage));
      message.addData(getName(MessageEnum.ChangeActiveTabStore), { activeTab: "banner", activeScreen: "banner" });
      this.send(message);
    }
    else if(this.state.selectProdId) {
      if(this.state.tabId) {
        this.createTab()
      }
      else {
        if(this.state.selectProdId.length === 0) {
          this.setState({menuError: configJSON.fillAllFieldsText})
        }
        else this.setState({menuError: configJSON.createTabErrMsg})
      }
    }
  }

  handleTabProductResults = (responseJson: ITabProductData) => {
    if (responseJson.data) {
        this.setState({ searchProdRes: responseJson.data});
     
    } else this.setState({ searchProdRes: []});
  };

  handleClick = () => {
    this.setState({ openTabProdResult: true });
  };

  async componentDidMount(): Promise<void> {
    this.getMenuTabs()
  }

  getMenuTabs = async() => {
    const storeId = await getStorageData("store_id")
    const headers = { token: await getStorageData("authToken")  };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getMenuTabApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + "/store_menus");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  createTab = async() => {
    const storeId = await getStorageData("store_id")
    const headers = { token: await getStorageData("authToken")  };
    const endPoint = this.state.tabId ? `/store_menus/${this.state.tabId}` : "/store_menus";
    const method = this.state.tabId ? configJSON.patchMethod : configJSON.postMethod
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createTabApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    const formData = new FormData();
    this.state.selectProdId && this.state.selectProdId.forEach((value: string) => formData.append("catalogue_ids[]",value))
    formData.append("title",this.state.tabName as string);
    formData.append("position",String(this.state.tabPosition));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  handleSearchProductValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      this.setState({ searchProductValue: event.target.value, openTabProdResult: true});
      this.productSearchUpdate(event.target.value);
    } else this.setState({ searchProductValue: "", openTabProdResult: false, searchProdRes: [] });
  };


  getProductResults = async () => {
    const brandId = await getStorageData("brandId")
    const headers = { "Content-Type": configJSON.contentType, token: await getStorageData("authToken") };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProdtResultsApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.searchProductEndpoint + `${this.state.searchProductValue}` + `&brand_id=${brandId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  SearchProductListDebounce = (call: (...args: string[]) => void, delay: number | string | unknown) => {
    let timerOne: number | string;
    return function(...args: string[]) {
      clearTimeout(timerOne);
      timerOne = setTimeout(() => {
        call(...args);
      }, delay);
    };
  };

  getProductRelation = (item: IProductRelation) => {
    return `${item.attributes.category.name} >> ${item.attributes.sub_category?.name} >> ${item.attributes.mini_category?.name} >> ${item.attributes.micro_category?.name}`
  }


  productSearchUpdate = this.SearchProductListDebounce(this.getProductResults, 1000);  

  handleSelectTabProduct = (event: React.ChangeEvent<HTMLInputElement>, data: IProductData) => {
    if (event.target?.checked) {
      this.setState({
        prodList: [...this.state.prodList, data],
        selectProdId: [...this.state.selectProdId, data.id]
      });
    } else {
      let productData = [...this.state.prodList];
      let findIndexData = productData.findIndex((product:{id: string}) => product.id === data.id);
      productData.splice(findIndexData, 1);
      let copySelectProductBrandId = [...this.state.selectProdId];
      let findIndexBrand = copySelectProductBrandId.findIndex(id => id === data.id);
      copySelectProductBrandId.splice(findIndexBrand, 1);
      this.setState({
        prodList: [...productData],
        selectProdId: [...copySelectProductBrandId]
      });
    }
  };

  showMenuTabData = async(id:string) => {
    const storeId = await getStorageData("store_id")
    const headers = { token: await getStorageData("authToken") };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.showMenuTabApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + "/store_menus/" + id);
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(message.id, message);
  }

  // Customizable Area End
}