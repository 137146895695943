import React from "react";

// Customizable Area Start
import { BoxNoScroll } from "../CustomizedElementStyle";
import { Box } from "@material-ui/core";
import * as DOMPurify from 'dompurify';
// Customizable Area End

import SellerTaxPolicyController, { Props } from "./SellerTaxPolicyController";

export default class SellerTaxPolicy extends SellerTaxPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box sx={{ fontSize: "18px", padding: "20px 0px 15px 0px", fontWeight: 400, fontFamily: `"Lato", sans-serif` }}>Tax Policy</Box>

        <BoxNoScroll style={{ paddingBottom: 20, overflow: "auto" }}>
          <Box style={{ flexDirection: "column", minHeight: 753, borderRadius: "8px", padding: "30px", overflowY: "auto", overflowX: "hidden", position: "relative", display: "flex", background: "white" }}>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
              <Box sx={{ fontSize: "27px", fontFamily: `"Lato", sans-serif`, fontWeight: 600 }}>Tax Policy</Box>
            </Box>
            <Box sx={{ marginTop: 10, width: "100%" }}>
              {this.state.contentData.map((item, index) => {
                return (
                  <>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}}>
                    </div>
                    <Box style={{ width: "100%", height: 20 }}></Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
