// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { GetCookies } from "framework/src/WebUtilities";
export const configJSON = require("../../src/config");
import * as Yup from "yup";
import { toast } from "react-toastify";
import { getStorageData } from "framework/src/Utilities";

export interface IPriceDetails {
  total_fees: string, 
  total_tax: string,
  final_price: string,
  discount: string
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match: any;
  classes: {
    path: string,
    background: string,
    span: string,
    cartBox: string,
    cartBoxDes: string,
    cartText: string,
    divider: string,
    bottomText: string,
    billingAddress: string,
    continue: string,
    address: string,
    priceContainer: string,
    detail: string,
    text: string,
    horizontal: string,
    paymentBtn: string,
    bottomContainer: string,
    orderSummary: string,
    marginOne: string,
    marginTwo: string,
    marginThree: string,
    flexOne: string,
    align: string,
    flexTwo: string,
    orderContainer: string,
    section: string,
    priceSection: string,
    error: string,
    detailOne: string,
    detailData: string,
    left: string,
    container: string,
    navigation: string,
    linkText: string,
    fieldMargin: string,
    errorOne: string,
    paymentImage: string,
    finalData : string,
    paymentError: string,
    position: string,
    topMargin: string,
    topMarginTwo: string,
    phoneNumber: string,
    descContainer: string,
    addressWidth: string,
    totalStyle: string,
    selectedState: string
  }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: string,
  lastName: string,
  addressOne: string,
  addressTwo: string,
  phoneNumber: string,
  selectedState: string,
  city: string,
  zipCode: string,
  isCheckBox: boolean,
  addressId: string,
  accountId: string,
  addressDetails: any,
  orderPriceDetails: IPriceDetails,
  isClicked: boolean,
  setErrorMsg: boolean,
  mapCenter: {
    lat: number,
    lng: number
  },
  mapCoOrdinates: {
      lat: number,
      lng: number
  },
  google: typeof google,
  addressErrMsg: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CreateAddressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAddressApiCallId: string = "";
  addAddressApiCallId: string = "";
  getProductListApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.ReceiveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseData)
    ];
    this.state = {
        firstName: '',
        lastName: '',
        addressOne: '',
        addressTwo: '',
        phoneNumber: '',
        selectedState: '',
        city: '',
        zipCode: '',
        isCheckBox: false,
        addressId: '',
        accountId: '',
        addressDetails: [],
        orderPriceDetails: {
          total_fees: '', 
          total_tax: '',
          final_price: '',
          discount:''
        },
        isClicked: false,
        setErrorMsg: false,
        mapCenter: {
          lat: 0,
          lng: 0
        },
        mapCoOrdinates: {
            lat: 0,
            lng: 0
        },
        google: window.google,
        addressErrMsg: ''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.errors) {
        this.handleErrorResponse(responseJson, apiRequestCallId)
      }
      else this.handleSuccessResponse(responseJson, apiRequestCallId);
    }
    
  }

  async componentDidMount() {
    const { match } = this.props;
    const addressId = match.params?.id;
    this.setState({addressId: addressId})
    if(addressId && addressId !== '00') {
      this.getShippingAddressInfo()
    }
    this.getProductList()
  }

  Schema = Yup.object().shape({
    firstName: Yup.string()
    .trim()
    .max(25, 'Max 25 characters allowed')
    .matches(/^[a-zA-Z\s]+$/, "Only Alphabets allowed")
    .required('This field is required'),
    lastName: Yup.string()
    .trim()
    .max(25, 'Max 25 characters allowed')
    .matches(/^[a-zA-Z\s]+$/, "Only Alphabets allowed")
    .required('This field is required'),
    addressOne: Yup.string()
    .trim()
    .required('This field is required'),
    addressTwo: Yup.string()
    .trim()
    .matches(/^(?=.*[a-zA-Z])\S+$/, "Address 2 should be alphanumeric")
    .max(50, 'Max 50 characters allowed')
    .required('This field is required'),
    phoneNumber:  Yup.string()
    .trim()
    .matches(/^971-\d{2}-\d{7}$/, "Phone number must be in the format 971-xx-xxxxxxx, where 'xx' represents digits.")
    .required("This field is required"),
    selectedState: Yup.string()
    .trim()
    .required('This field is required'),
    city: Yup.string()
    .trim()
    .max(15, 'Max 15 characters allowed')
    .matches(/^[a-zA-Z\s]+$/, "Only Alphabets allowed")
    .required('This field is required'),
    zipCode:  Yup.string()
    .trim()
    .max(5, "Max 5 digits allowed")
    .matches(/^\d+$/, "Zip code not valid")
    .required("This field is required")
  })

  setNumber = (number: string) => {
    const mobNumber = number;

    if (mobNumber && mobNumber.indexOf('-') === -1) {
        const countryCode = mobNumber.slice(0, 3);
        const regionCode = mobNumber.slice(3, 5);
        const phoneNumber = mobNumber.slice(5);

        const formattedNo = `${countryCode}-${regionCode}-${phoneNumber}`;
        return formattedNo
    } 
    else return mobNumber
  }

  handleStateChange = (event: React.ChangeEvent<{ value: unknown }>, setFieldValue:any) => {
    this.setState({selectedState: event.target.value as string})
    setFieldValue('selectedState', event.target.value)
  }

  handlePhoneNumberChange = (setFieldValue:any, event:React.ChangeEvent<HTMLInputElement>) => {
    const inputNumber = event.target.value;
    const formattedNumber = this.handlePhoneNumber(inputNumber);
    const cursorPosition:any = event.target.selectionStart;
    const inputElement = event.target
    if (inputNumber === "") {
        setFieldValue('phoneNumber', "");
    } else {
      
        setFieldValue('phoneNumber', formattedNumber.length <= 14 ? formattedNumber : formattedNumber.slice(0, 14));
        setTimeout(() => {
          const addedChars = formattedNumber.length - inputNumber.length;
          const newCursorPosition = cursorPosition + addedChars;
      
          if (inputElement.setSelectionRange) {
              inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
          }
      }, 0);
    }
}

handlePhoneNumber = (value:string) => {
    const numericValue = value.replace(/\D/g, "");
    const truncatedValue = numericValue.slice(0, 12);
    let formattedValue = truncatedValue.slice(0, 3) + (truncatedValue.length > 3 ? "-" + truncatedValue.slice(3, 5) : "") + (truncatedValue.length > 5 ? "-" + truncatedValue.slice(5) : "");
    return formattedValue;
}

  onSubmit = (values: any) => {
    if(values) {
      if(this.state.addressOne) {
        this.addShippingAddress(values)
        this.setState({isClicked : true})
      }
      else this.setState({addressErrMsg : "This field is required"})
    }
  };

  handleErrorResponse = (responseJson: any, apiRequestCallId:any) => {
    if (apiRequestCallId === this.addAddressApiCallId) {
      if(responseJson.errors) {
        toast.error(`${responseJson.errors[0]}`)
      }
    }
  }

  handleSuccessResponse = (responseJson: any, apiRequestCallId:any) => {
    if (apiRequestCallId === this.getAddressApiCallId){
      this.handlePlacesAutoCompleteSelect(responseJson.data.attributes?.address_1)
      this.setState({addressDetails: responseJson.data, isCheckBox: responseJson.data.attributes.is_default})
    }

    if (apiRequestCallId === this.addAddressApiCallId) {
      this.props.navigation.navigate('ShippingAddress')
    }

    if(apiRequestCallId === this.getProductListApiCallId) {
      this.setState({orderPriceDetails: responseJson.data.attributes})
    }

  }

  getShippingAddressInfo = async() => {
    const accountId = await getStorageData("accountId")
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const addressMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddressApiCallId = addressMessage.messageId;
    addressMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${accountId}/user_delivery_addresses/${this.state.addressId}`
    );
    addressMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addressMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(addressMessage.id, addressMessage);
  }

  addShippingAddress = async(values:any) => {
    const token = GetCookies("ba");
    const accountId = await getStorageData("accountId")
    const addressId = this.props.match.params?.id
    const headers = {
      'Content-Type': configJSON.productApiContentType,
      token: token,
    };

    const { firstName, lastName, addressOne, addressTwo, phoneNumber, selectedState, city, zipCode} = values
    const method = addressId !== '00' ? configJSON.apiMethodTypePatch : configJSON.apiMethodTypePost
    const endPoint = addressId !== '00' ? `/account_block/accounts/${accountId}/user_delivery_addresses/${addressId}` : `/account_block/accounts/${accountId}/user_delivery_addresses`

    const httpBody = {
      "data": {
          "attributes": {
              "first_name": firstName,
              "last_name": lastName,
              "phone_number": phoneNumber,
              "address_1": this.state.addressOne,
              "address_2": addressTwo,
              "city": city,
              "state": selectedState,
              "zip_code": zipCode,
              "is_default": this.state.isCheckBox   
              
          }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAddressApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getProductList = () => {
    const id = GetCookies("currentOrder");
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const orderMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductListApiCallId = orderMessage.messageId;
    orderMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${id}`
    );
    orderMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    orderMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(orderMessage.id, orderMessage);
  };

  handlePaymentNowBtn = () => {
    const isAddressSet = GetCookies('setAddress')
    if (isAddressSet !== '') {
      this.setState({setErrorMsg : false})
      this.props.navigation.navigate('OrderOverView')
    }
    else this.setState({setErrorMsg : true})
  }

  handlePlacesAutoCompleteChange = (address: string) => {
    if(address) {
      this.setState({ addressOne: address , addressErrMsg: ''});
    }
    else this.setState({ addressOne: '', addressErrMsg : "This field is required" });
  };

  handlePlacesAutoCompleteSelect = (address:string) => {
    this.setState({ addressOne: address });
    const geocoder = new google.maps.Geocoder()
    geocoder
    .geocode({ address: address})
    .then((response: any) => {
        if (response.results[0]) {
            const location = response.results[0].geometry.location;
            const latitude = location.lat();
            const longitude = location.lng();
            this.setState({ 
            mapCenter: { lat: latitude, lng: longitude },
            mapCoOrdinates: { lat: latitude, lng: longitude }
            });
        }
    })
  .catch((error: string) => {
    this.setState({addressErrMsg: error})
  })
  };

  // Customizable Area End
}
