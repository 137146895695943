// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { GetCookies } from "framework/src/WebUtilities";
import { IOrderData } from "./CheckOutOrderController.web";
export const configJSON = require("../../src/config");

export interface IPriceInfo {
  total_fees: string;
  total_tax: string;
  final_price: string;
}

export interface IAddress {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    address_1: string;
    address_2: string;
    city: string;
    phone_number: string;
    state: string;
  };
}


// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    main: string;
    route: string;
    product: string;
    headerText: string;
    cartWrapper: string;
    headerDesc: string;
    productContainer: string;
    headerLabel: string;
    detailsWrapper: string;
    leftSide: string;
    prodName: string;
    prodColor: string;
    brand: string;
    quantitySec: string;
    quantity: string;
    priceContainer: string;
    priceText: string;
    verticalLine: string;
    addressDesc: string;
    imgSec: string;
    mainWrapper: string,
    linkSec: string,
    home: string,
    cartScroll: string,
    productGrid: string,
    orderWrap: string,
    itemImage: string,
    place: string,
    addressSec: string,
    closeErrBox: string,
    closeIcon: string,
    paymentFailErr: string,
    modalContainer: string,
    innerModalBox: string,
    payErrorImage: string,
    payErrorBox: string
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderData: IOrderData[];
  addId: string;
  setAddress: IAddress;
  priceInfo: IPriceInfo;
  paymentUrl: string,
  isPayBtn: boolean,
  isPaymentFailed: boolean
  paymentFailureError: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderItemsApiCallId: string = "";
  showOrderStatusApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseData),
    ];
    this.state = {
      orderData: [],
      addId: "",
      setAddress: {
        id: "",
        attributes: {
          first_name: "",
          last_name: "",
          address_1: "",
          address_2: "",
          city: "",
          phone_number: "",
          state: "",
        },
      },
      priceInfo: {
        total_fees: "",
        total_tax: "",
        final_price: "",
      },
      paymentUrl: "",
      isPayBtn: false,
      isPaymentFailed: false,
      paymentFailureError: ''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.handleSuccessResponse(responseJson, apiRequestCallId);
      }
    }
  }

  handleSuccessResponse = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getOrderItemsApiCallId) {
      this.setState({
        setAddress: responseJson.data.attributes.shipping_address.data,
        orderData: responseJson.data.attributes.order_items.data,
        priceInfo: responseJson.data.attributes,
      });
    }
    if (apiRequestCallId === this.showOrderStatusApiCallId) {
      const statusCodes = [3,-2]
      if(!statusCodes.includes(responseJson.order.status.code)) {
        if(responseJson.order.transaction) {
          this.setState({isPaymentFailed:true, paymentFailureError: "Oops! Your order couldn’t be placed"})
        }
      }
    }
  };

  async componentDidMount() {
    const orderId = GetCookies("currentOrder");
    this.getOrderItems(orderId);
    this.showOrderStatus()
  }

  showOrderStatus = () => {
    const id = GetCookies("currentOrder");
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const showOrderMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showOrderStatusApiCallId = showOrderMessage.messageId;
    showOrderMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/telr_payments/show_or_check?order_id=${id}`
    );
    showOrderMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    showOrderMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(showOrderMessage.id, showOrderMessage);
  }

  getOrderItems = (id: string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderItemsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${id}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  };

  handleClosePopup = () => {
    this.setState({isPaymentFailed: false})
  }
  // Customizable Area End
}
