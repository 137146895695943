Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPutMethod = "PUT";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";
exports.httpPatchMethod = "PATCH";

exports.ReviewsApiContentType = "application/json";
exports.getReviewsAPiEndPoint = "reviews/reviews";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.titleTitle = "Title";
exports.descriptionTitle = "Description";
exports.accountIdTitle = "Account Id";
exports.anonymousTitle = "Anonymous";

exports.description = "Description";
exports.account_id = 1;
exports.anonymous = false;
exports.namePlaceHolder = "Name";
exports.titlePlaceHolder = "Title";
exports.numberPlaceHolder = "Phone number";
exports.descriptionPlaceHolder = "Description";

exports.labelTitleText = "Reviews";
exports.labelBodyText = "Reviews Body";

exports.btnExampleTitle = "CLICK ME";
exports.thanksForAddingReviewText = "Thanks!! Your rating has been recorded";
exports.reviewSubmittedText = "Review Submitted";
exports.yourReviewSubmitText = "Your review has been submitted";
exports.getReviewsApiEndpoint = "bx_block_catalogue/reviews/buyer_review_listing?order_item_id=";
// Customizable Area End