import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { circleImage, objectsImage, byezzyLogo } from "./assets";
import { toast } from "react-toastify";
import { createRef } from "react";
export const circleImg = circleImage;
export const logoImg = byezzyLogo;
export const objectsImg = objectsImage;
import { getNavigationMessage } from "../../../components/src/CommonFunctions";
import { FormikProps } from "formik";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { IPrivacyNotice } from "../../termsconditions/src/PrivacyNoticeController.web";

interface FormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  emailAddress: string;
  phoneNumber: string | number;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
}
interface SignUpDataErrors {
  firstName: boolean;
  lastName: boolean;
  companyName: boolean;
  emailAddress: boolean;
  phoneNumber: boolean;
  password: boolean;
  confirmPassword: boolean;
  errorMessage: string[];
}
interface ErrorResponse extends IBlock {
  errors: {
    [key: string]: string;
  }[];
}
interface SuccessResponse {
  seller: {
    data: {
      id: string;
      type: string;
      attributes: {
        firstName: string;
        lastName: string;
        companyOrStoreName: string;
        email: string;
        phoneNumber: string;
        countryCode: string;
        type: string;
        activated: string;
        createdAt: string;
        updatedAt: string;
        deviceId: string;
        uniqueAuthId: string;
      };
    };
  };
  message: string;
}

interface SignUpRes {
  seller: SuccessResponse;
  token: string;
}

interface MessageResponse {
  message: string;
  errors: string[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: Record<string,string>
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  companyName: string;
  emailAddress: string;
  phoneNumber: number | string;
  password: string;
  confirmPassword: string;
  termsAndCondition: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  disableSignInButton: boolean;
  signUpDataErrors: {
    firstName: boolean;
    lastName: boolean;
    companyName: boolean;
    emailAddress: boolean;
    phoneNumber: boolean;
    password: boolean;
    confirmPassword: boolean;
    errorMessage: string[];
  };
  phoneNumErr: string;
  authToken: String;
  welcomeContent: IPrivacyNotice
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCreateSellerAccountCallId: string = "";
  getSellerUserDetailsApiCallId: string = "";
  apiLandingPageContentCallId: string = "";
  formikRef: React.RefObject<FormikProps<FormValues>>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      companyName: "",
      emailAddress: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      termsAndCondition: false,
      showPassword: false,
      showConfirmPassword: false,
      signUpDataErrors: {
        firstName: false,
        lastName: false,
        companyName: false,
        emailAddress: false,
        phoneNumber: false,
        password: false,
        confirmPassword: false,
        errorMessage: []
      },
      disableSignInButton: false,
      phoneNumErr: "",
      authToken: "",
      welcomeContent: {
        id: '',
        title: '',
        content: ''
      }
      // Customizable Area End
    };

    // Customizable Area Start
    this.formikRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.apiCreateSellerAccountCallId) {
        this.handleApiResponseAction(responseJson);
      }
      if(apiRequestCallId === this.getSellerUserDetailsApiCallId) {
        this.handleUserDetailsRes(responseJson)
      }
      if (apiRequestCallId === this.apiLandingPageContentCallId) {
        this.handleWelcomeToByEzzyRes(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleWelcomeToByEzzyRes = (responseJson: IPrivacyNotice[]) => {
    if(responseJson) {
      const welcomeContent = responseJson.filter((item:IPrivacyNotice) => {
        if(item.title === "Welcome to ByEzzy!") {
            return item
        }
      })
        if(welcomeContent.length) {
          this.setState({welcomeContent : welcomeContent[0]})
        }
    }
  }

  handleUserDetailsRes = async(responseJson:any) => {
    if(responseJson.data) {
      const accountActivated = responseJson.data.attributes.activated
      await setStorageData("isAccountActivated",accountActivated )
      await setStorageData("isAccountCreated",true )
      await setStorageData("isDocumentVerified",responseJson.data.attributes.document_status )
      this.handleGetToken();
    }
  }

  isStringNullOrBlank(value: string) {
    return value === null || value.length === 0;
  }

  handleApiResponseAction = (responseJson: unknown) => {
    const errorResponse = responseJson as ErrorResponse;
    const successResponse = responseJson as SuccessResponse;
    const message = responseJson as MessageResponse;
    try {
      if (message.message === configJSON.sellerAccCreateButByerAccExists && message.errors[0] === configJSON.emailAlreadyTaken) {
        toast.success(configJSON.sellerAccCreateButByerAccExists, {
          progress: undefined,
          autoClose: 3000, // milliseconds
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          pauseOnHover: true
        });
        setTimeout(() => {
          const messageVal: Message = new Message(getName(MessageEnum.ResponseOtpInputConfirmationMessage));
          messageVal.addData(getName(MessageEnum.NavigationPropsMessage), {
            message: responseJson as SignUpRes
          });
          this.send(messageVal);
        }, 2000);
      } else if (successResponse.seller.data) {
        
        setTimeout(() => {
          this.formikRef.current?.resetForm();
        }, 1000);
        const message: Message = new Message(getName(MessageEnum.ResponseOtpInputConfirmationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), {
          message: responseJson as SignUpRes
        });
        this.send(message);
      } else if (errorResponse.errors.length > 0 && message.message !== configJSON.sellerAccCreateButByerAccExists) {
        let errorMsg = "";
        const errorKeyReference = Object.keys(errorResponse.errors[0]);
        if (errorResponse.errors[0][(errorKeyReference as unknown) as string] === "Email invalid") {
          errorMsg = errorResponse.errors[0][(errorKeyReference as unknown) as string];
        } else {
          errorMsg = (errorResponse.errors[0] as unknown) as string;
        }
        toast.error(errorMsg ? errorMsg : "Failed to create account. Please try again later!", {
          draggable: true,
          progress: undefined,
          autoClose: 3000, // milliseconds
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: false,
          position: "top-right"
        });
        this.setState({
          signUpDataErrors: {
            ...this.state.signUpDataErrors,
            errorMessage: [...this.state.signUpDataErrors.errorMessage, errorMsg]
          }
        });
      }
      this.setState({ disableSignInButton: false });
    } catch (error) {
      errorResponse.errors.forEach(value => {
        toast.error(value, {
          draggable: true,
          progress: undefined,
          autoClose: 3000, // milliseconds
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: false,
          position: "top-right"
        });
      });

      this.setState({
        signUpDataErrors: {
          ...this.state.signUpDataErrors,
          errorMessage: [...this.state.signUpDataErrors.errorMessage, "Something went wrong! Please try again later!"]
        }
      });
      this.setState({ disableSignInButton: false });
      this.isStringNullOrBlank("");
    }
  };
  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => event.preventDefault();
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
  handleClickShowConfirmPassword = () => this.setState({ showConfirmPassword: !this.state.showConfirmPassword });

  createSellerAccount = async (values: FormValues) => {
    const isAccountActivated = await getStorageData("isAccountActivated")
    if (isAccountActivated && this.state.authToken !== "null" && this.state.authToken) {
      toast.success(configJSON.sellerAccCreateButByerAccExists, {
        progress: undefined,
        autoClose: 3000, // milliseconds
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: true
      })
      setTimeout(() => {
        this.props.navigation.navigate("SellerHomeDashboard", { path: "products" });
      }, 2000)
    } else {
      this.setState({ disableSignInButton: true });
      const headers = {
        "Content-Type": configJSON.contentType
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apiCreateSellerAccountCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSignUpEndpoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            user_type: "seller",
            attributes: {
              first_name: values.firstName,
              last_name: values.lastName,
              company_or_store_name: values.companyName,
              email: values.emailAddress,
              full_phone_number: values.phoneNumber,
              password: values.password,
              confirm_password: values.confirmPassword
            }
          }
        })
      );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleOpenSellerLoginEmail = async () => {
    const isAccountActivated = await getStorageData('isAccountActivated')
    if (isAccountActivated && this.state.authToken && this.state.authToken !== "null") {
      this.props.navigation.navigate("SellerHomeDashboard", { path: "products" });
    } else {
      const message: Message = new Message(getName(MessageEnum.NavigationSellerLoginEmailMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), {
        openEmailLoginModal: true
      });
      this.send(message);
      this.send(message);
    }
  };

  async componentDidMount(): Promise<void> {
    const signUpPage = document.getElementById("signUpPage")
    if(signUpPage) { signUpPage.scrollIntoView({ block: "start"})}

    window.scrollTo(0, 0);
    this.getSellerLandingPageContent()
    this.getSellerUserDetails()
  }

  getSellerUserDetails = async() => {
    const token = await getStorageData("authToken");
    const headers = { "Content-Type": configJSON.productApiContentType };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSellerUserDetailsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `account_block/accounts/logged_user?token=${token}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(message.id, message);
  }

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    const verification = await getStorageData("isDocumentVerified")
    const isAccountActivated = await getStorageData("isAccountActivated")
    this.setState({ authToken });
    if(authToken && authToken !== "null"){
      if(isAccountActivated && verification === "Your document has been verified"){
        this.props.navigation.navigate("SellerHomeDashboard", { path: "products" });
      }
      if(isAccountActivated && verification !== "Your document has been verified") {
        this.props.navigation.navigate("SellerLanding")
      }
    }
  };

  handleContactNumber = (value: string) => {
    const numValue = value.replace(/\D/g, "");
    const truncatedValue = numValue.slice(0, 12);
    let formattedValue = truncatedValue.slice(0, 3) + (truncatedValue.length > 3 ? "-" + truncatedValue.slice(3, 5) : "") + (truncatedValue.length > 5 ? "-" + truncatedValue.slice(5) : "");
    return formattedValue;
  };

  handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string) => void) => {
    const number = event.target.value;
    const formattedNumber = this.handleContactNumber(number);
    const cursorPosition = event.target.selectionStart as number;
    const inputElement = event.target;
    if (number === "") {
      setFieldValue("phoneNumber", "");
    } else {
      setFieldValue("phoneNumber", formattedNumber.length <= 14 ? formattedNumber : formattedNumber.slice(0, 14));
      setTimeout(() => {
        const addedChars = formattedNumber.length - number.length;
        const newCursorPosition = cursorPosition + addedChars;

        if (inputElement.setSelectionRange) {
          inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
        }
      }, 0);
    }
  };

  handleFooterTitle = (title: string) => {
    setStorageData('footerTitle',title)
    this.props.navigation.navigate("SellWithByEzzy", {type: title.toLocaleLowerCase()})
  }

  handleGoToPrivacy = () => {
    this.send(getNavigationMessage('PrivacyNotice', this.props));
  }
  
  handleGoToTerms = () => {
    this.send(getNavigationMessage('TermsOfService', this.props));
  }

  getSellerLandingPageContent = async() => {
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiLandingPageContentCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.staticApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
