import React from "react";

// Customizable Area Start
import {
  Box,
  Card,
  CardMedia,
  Container,
  Typography, withStyles
} from "@material-ui/core";
import ProductListController, { Props, calculateDiscountPercentage } from "./ProductListController.web";
import { rapidLogo, starIcon } from "../assets";
import { getCurrentDate } from "../../../../components/src/CommonFunctions";
// Customizable Area End


class ProductList extends ProductListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  displayDealPrice = (deal_price:string, retail:number ,isDealExists: boolean) => {
    const {classes} = this.props;
    return(
      isDealExists && 
      <>
      <Typography className={classes.discountedPrice}>
        AED <span className={classes.offer}>{deal_price}</span>
      </Typography>
      {calculateDiscountPercentage(Number(retail), Number(deal_price)) > 0 ? <Typography className={classes.ogPrice}>
        <span className={classes.mrp}>
          {retail}
        </span>{" "}
        <b className={classes.discount} data-test-id="discountRef"> &nbsp; {calculateDiscountPercentage(Number(retail),Number(deal_price))}% OFF</b>
      </Typography>: <Typography className={classes.ogPriceTwo}>&nbsp;</Typography>}
      </>
    )
  }

  displayOfferPrice = (sale_price:string, retail:number ,isOfferExists: boolean) => {
    const {classes} = this.props;
    return(
      isOfferExists && 
      <>
      <Typography className={classes.discountedPrice}>
        AED <span className={classes.offer}>{sale_price}</span>
      </Typography>
      {calculateDiscountPercentage(Number(retail), Number(sale_price)) > 0 ? <Typography className={classes.ogPrice}>
        <span className={classes.mrp}>
          {retail}
        </span>{" "}
        <b className={classes.discount} data-test-id="discountRef"> &nbsp; {calculateDiscountPercentage(Number(retail),Number(sale_price))}% OFF</b>
      </Typography>: <Typography className={classes.ogPriceTwo}>&nbsp;</Typography>}
      </>
    )
  }

  displayRetailPrice (dealActive : boolean, offerActive:boolean, mrp: number | null, retail: number) {
    const {classes} = this.props;
    return (
      <>
      {!dealActive && !offerActive && !mrp &&
      <>
      <Typography className={classes.discountedPrice}>
        AED <span className={classes.offer}>{retail}</span>
      </Typography>
      <Typography className={classes.ogPriceTwo}>&nbsp;</Typography>
      </>
    }
      </>
    )
  }

  displayPrice = () => {
    const {classes, item} = this.props;
    const productData = item.attributes.product_content && item.attributes.product_content.product_attributes;
    const retailPrice = productData && productData.retail_price
    const mrpPrice = productData && productData.mrp
    const dealCatalog = item.attributes.deal_catalogues && item.attributes.deal_catalogues[0]
    const catalogueOffer =item.attributes?.catalogue_offer
    const isDealExists = item.attributes?.deal_catalogues[0]?.status === "approved" && dealCatalog?.deal_price
    const currentDate = getCurrentDate()
    const offerActive = catalogueOffer && catalogueOffer.sale_schedule_to >= currentDate
    const isOfferExists = catalogueOffer?.status && catalogueOffer?.sale_price && offerActive 
    return(
      <>
      {this.displayDealPrice(dealCatalog?.deal_price, Number(retailPrice), isDealExists as boolean )}
      {this.displayOfferPrice(catalogueOffer?.sale_price as string, Number(retailPrice), isOfferExists as boolean )}
      {this.displayRetailPrice(isDealExists as boolean, isOfferExists as boolean, Number(mrpPrice), Number(retailPrice))}
      {!isDealExists && !isOfferExists && mrpPrice && 
      <>
        <Typography className={classes.discountedPrice}>
        AED <span className={classes.offer}>{retailPrice}</span>
        </Typography>
        <Typography className={classes.ogPrice}>
        <span className={classes.mrp}>
        {mrpPrice}
      </span>{" "}
      </Typography>
      </>
      }
      </>
    )
  }
  render() {
    const { classes, item } = this.props;
    return (
      <>
        <Box data-test-id="item" onClick={() => this.handleRedirectToProduct(item.id)} style={webStyle.link}>
        <Container className={classes.cardContainer}>
          <Card className={classes.mainCard}>
            <Typography style={webStyle.newText}>New</Typography>
            <CardMedia
              className={classes.cardImage}
              component="img"
              image={item.attributes.product_image}
              alt={item.attributes.product_title || ""}
              data-test-id="cardMediaRef"
            />
          </Card>
          <Typography className={classes.mobDetails}>
            {item.attributes?.product_title || 'N/A'}
          </Typography>
          {this.displayPrice()}
          <Box className={classes.last}>
                      <img data-test-id="rapidButtonRef" className={classes.rapidImg} src={rapidLogo} alt="express_img" />
                      <Box style={webStyle.flex}>
                        <Typography className={classes.rateBox}>
                        {Number(
                              item?.attributes
                                ?.product_rating?.average_rating || 0
                            )}{" "}
                          <span className={classes.starImg}>
                            <img src={starIcon} alt="start" />
                          </span>
                        </Typography>
                        <Typography data-test-id="ratingRef" className={classes.ratingText}>
                        {Number(
                              item?.attributes
                                ?.product_rating?.total_reviews || 0
                            )}
                        </Typography>
                      </Box>
                    </Box>
          
        </Container>
        </Box>
      </>
    )
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  cardContainer: {
    width: "100%",
    border: "1px solid rgb(229, 229, 229)",
    padding: "10px",
    borderRadius: "8px",
    '&:hover': {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)"
    }
  },
  mainCard: {
    width: "100%",
    height: "200px",
    borderRadius: "8px",
    border: "1px solid #eee",
    position: "relative" as const,
    '@media (max-width:600px)': {
      padding: 0,
      marginLeft: '0'
    },
  },
  cardImage: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    objectFit:'contain' as const ,
  },
  mobDetails: {
    fontSize: "16px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#33333a",
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    height: 'calc(1.5em * 2)',
    textOverflow: 'ellipsis',
    fontFamily:"Lato, sans-serif",
    fontWeight: 400,
    wordBreak: "break-all" as const,
    '@media (max-width: 600px)': {
      marginLeft: '0'
    }
  },
  discountedPrice: {
    fontSize: "14px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#ff6008",
    fontFamily:"Lato, sans-serif",
    fontWeight: 400,
    '@media (max-width: 600px)': {
      marginLeft: '0'
    }
  },
  ogPrice: {
    fontSize: "10px",
    marginLeft: "10px",
    '@media (max-width: 600px)': {
      marginLeft: '0'
    }
  },
  rapidImg: {
    width:'67px',
    height: '24px',
    marginLeft:'10px',
    '@media (max-width: 600px)': {
      marginLeft: '0'
    }
  },
  rateBox: {
    padding: "2px 6px",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#565656",
    color: "#ffffff",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 700 as const,
  },
  starImg: {
    marginLeft: "5px",
  },
  last: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    '@media (max-width: 600px)': {
      marginRight: '0'
    }
  },
  ratingText: {
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    color: "#ff6008",
    fontWeight: 400 as const,
  },
  offer: {
    color: "#ff6008",
    fontWeight: 700 as const,
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
  },
  mrp: { 
    color: "grey", 
    textDecoration: "line-through", 
    fontSize:'14px', 
    fontWeight: 400, 
    fontFamily: `"Lato",sans-serif`,
  },
  discount: { 
    color: "#33333a", 
    fontSize:'14px', 
    fontWeight: 700, 
    fontFamily: `"Lato",sans-serif`,
  },
  ogPriceTwo: {
    fontSize: "10px",
    marginLeft: "10px",
    marginTop: '6px',
    '@media (max-width: 600px)': {
      marginLeft: '0'
    }
  }
};

const webStyle = {
  link: {
    cursor: 'pointer'
  },
  flex: {
    display: 'flex'
  },
  newText: {
    width: "20%",
    textAlign: "center" as const,
    marginLeft: "78%",
    background: "#fb620f",
    color: "white",
    borderRadius: "3px",
    fontSize: "9px",
    padding: "1.5px",
    position: "absolute" as const ,
    top: "5px",
  }
}

export { ProductList };

export default withStyles(styles)(ProductList);
// Customizable Area End