import React from "react";
import {
    Button,
    Typography,
    InputAdornment,
    // Customizable Area Start
    Paper,
    Grid,
    TextField,
    Modal,
    Box,
    Divider,
    IconButton,
    OutlinedInput,
    Select,
    withStyles,
    Theme,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { CustomBackButton, CustomDeleteButton, CustomMenuItem } from "../../dashboard/src/CustomizedElementStyle";
import { Loader2 } from "../../../components/src/Loader.web";
import { pattern, privacy } from "./assets";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";



class UserProfile extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required').max(25, 'First Name must not exceed 25 characters'),
        lastName: Yup.string().required('Last Name is required').max(25, 'Last Name must not exceed 25 characters'),
        email: Yup.string().email('Invalid email address').required('Email is required').max(256, 'Email must not exceed 256 characters'),
        newPasswordForm: Yup.string().when('currentPassword', {
            is: (currentPassword) => currentPassword && currentPassword.length > 0,
            then: Yup.string()
                .required('New Password is required')
                .notOneOf([Yup.ref('currentPassword')], 'New Password must not match Current Password')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
                    "Password must have at least one lowercase letter, one uppercase letter, one digit, one special character (@$!%*?&), and no spaces. It should be between 8 and 32 characters long."
                ),
            otherwise: Yup.string().matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
                "Password must have at least one lowercase letter, one uppercase letter, one digit, one special character (@$!%*?&), and no spaces. It should be between 8 and 32 characters long."
            ),
        }),
        currentPassword: Yup.string().when('newPasswordForm', {
            is: (newPasswordForm) => newPasswordForm && newPasswordForm.length > 0,
            then: Yup.string()
                .required('Current Password is required'),
            otherwise: Yup.string(),
        })
    }, [['currentPassword', 'newPasswordForm'], ['newPasswordForm', 'currentPassword']])

    accounDelete() {
        const { classes } = this.props;
        return (<Box className={classes.deleteAccountBox}>
            <Box style={{ width: 'max-content' }} >

                <Typography className={classes.subHeaders}>Delete Account</Typography>
                <Divider style={{ ...webStyle.horizontalBar, width: "100%", color: '#efeff4' }} />
            </Box>
            <Button variant="outlined" style={{
                borderRadius: "5px",
                border: "1.75px solid #EE1010",
                marginTop: "20px",
                padding: "10px 22px",
                color: "#EE1010",
                textTransform: "none",
                fontSize: '12px',
                fontWeight: 400,
                fontFamily: "Lato, sans-serif",
                lineHeight: '14.4px',
                width: '100%',
                whiteSpace:'nowrap'
            }} data-testid="accountDelete"
                onClick={() => this.handleOpenVerifyDeleteModal()}

            >
                Delete account
            </Button>
        </Box>)
    }

    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (

            // Customizable Area Start 
            <>
                <ToastContainer />
                <Paper style={webStyle.papperClasses}>
                    {this.state.loading ? <Loader2 loading={this.state.loading} /> : <></>}
                    <Formik
                        initialValues={{
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            email: this.state.email,
                            currentPassword: '',
                            newPasswordForm: ''
                        }}
                        enableReinitialize={true}
                        validationSchema={(values: any) => this.validationSchema}
                        validateOnMount={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={this.updateBuyerProfile}
                        data-test-id="formik"
                    >
                        {({ handleChange,
                            errors,
                            setFieldTouched,
                            touched,
                            handleBlur, values, setErrors
                        }) => (
                            <Form translate={undefined} ref={this.formRef} className={classes.formWrraper} >
                                <Typography variant="h4" className={classes.headerTxt}>{this.state.selectedMenu}</Typography>
                                <Typography style={webStyle.desc}></Typography>
                                <Box className={classes.padding}>
                                    <Box style={{ width: 'max-content' }} >
                                        <Typography className={classes.subHeaders}>Personal Details</Typography>
                                        <Divider style={{ ...webStyle.horizontalBar, width: '100%', color: '#efeff4' }} />
                                    </Box>
                                    <Grid container spacing={3} style={{ marginTop: '8px' }}>

                                        <Grid item xs={12} sm={6} lg={6}>
                                            <label id={"First Name"} className={classes.labelHeader} style={{ color: touched.firstName && Boolean(errors.firstName) ? 'red' : "#33333A" }}>{"First Name"}</label>
                                            <TextField
                                                id={"First Name"}
                                                className={classes.textField}
                                                data-testid="personalDetails1"
                                                placeholder={"Enter First Name"}
                                                margin="normal"
                                                variant="outlined"
                                                type="text"
                                                name='firstName'
                                                error={touched.firstName && Boolean(errors.firstName)}
                                                value={values.firstName}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldTouched("firstName", true, false)
                                                }}
                                                onBlur={handleBlur}
                                                InputProps={{ classes: { root: classes.textField } }}
                                            />
                                            {touched.firstName && errors.firstName ? (
                                                <Typography style={webStyle.errorStyle}>{errors.firstName}</Typography>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <label id={"Last Name"} className={classes.labelHeader} style={{ color: touched.lastName && Boolean(errors.lastName) ? 'red' : "#33333A" }}>{"Last Name"}</label>
                                            <TextField
                                                id={"Last Name"}
                                                className={classes.textField}
                                                data-testid="personalDetails2"
                                                error={touched.lastName && Boolean(errors.lastName)}
                                                placeholder={"Enter " + "Last Name"}
                                                margin="normal"
                                                variant="outlined"
                                                name="lastName"
                                                value={values.lastName}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldTouched("lastName", true, false)
                                                }}
                                                onBlur={handleBlur}

                                                InputProps={{ classes: { root: classes.textField } }}
                                            />
                                            {touched.lastName && errors.lastName ? (
                                                <Typography style={webStyle.errorStyle}>{errors.lastName}</Typography>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label id={"Email Address"} className={classes.labelHeader} style={{ color: touched.email && errors.email ? 'red' : "#33333A" }}>{"Email Address"}</label>
                                            <TextField
                                                id={"Email Address"}
                                                className={classes.textField}
                                                data-testid="personalDetails3"
                                                value={values.email}
                                                placeholder={"Enter " + "Email Address"}
                                                margin="normal"
                                                variant="outlined"
                                                error={touched.email && Boolean(errors.email)}
                                                name="email"
                                                disabled
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldTouched("email", true, false)
                                                }}
                                                onBlur={handleBlur}
                                                InputProps={{ classes: { root: classes.textField } }}
                                            />
                                            {touched.email && errors.email ? (
                                                <Typography style={webStyle.errorStyle}>{errors.email}</Typography>
                                            ) : null}
                                        </Grid>

                                    </Grid>
                                </Box>
                                <Box className={classes.paddingTwo}>
                                    <Box style={{ width: 'max-content' }} >
                                        <Typography className={classes.subHeaders}>Password</Typography>
                                        <Divider style={{ ...webStyle.horizontalBar, width: "100%", color: '#efeff4' }} />
                                    </Box>
                                    <Grid container spacing={3} style={{ marginTop: '10px' }}>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <label htmlFor="outlined-adornment-password" className={classes.labelHeader} style={{ color: touched.currentPassword && Boolean(errors.currentPassword) ? 'red' : "#33333A" }}>Current Password</label>
                                            <OutlinedInput
                                                name="currentPassword"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldTouched("currentPassword", true, false)
                                                }}
                                                onBlur={handleBlur}
                                                id="outlined-adornment-password"
                                                error={touched.currentPassword && Boolean(errors.currentPassword)}
                                                data-testid="currentPassword"
                                                type={
                                                    this.state.showPassword ? "text" : "password"}
                                                placeholder="Enter Current Password"
                                                className={classes.labelHeaderTwo}
                                                classes={{
                                                    input: classes.language
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            data-testid='icon'
                                                            aria-label="toggle password visibility"
                                                            onClick={() => this.handleShowPassword("curPassword")}
                                                            className={classes.pattern}

                                                        >
                                                            {this.state.showPassword ? <img src={privacy} className="rotateIcon" style={{ color: touched.currentPassword && errors.currentPassword ? "red" : "" }} /> : <img src={pattern} />}

                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {touched.currentPassword && errors.currentPassword ? (
                                                <Typography style={webStyle.errorStyle}>{errors.currentPassword}</Typography>
                                            ) : null}

                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={6}>
                                            <label htmlFor="standard-adornment-newpassword" className={classes.labelHeader} style={{ color: touched.newPasswordForm && Boolean(errors.newPasswordForm) ? 'red' : "#33333A" }}>New Password</label>
                                            <OutlinedInput
                                                name="newPasswordForm"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldTouched("newPasswordForm", true, false)
                                                }}
                                                onBlur={handleBlur}

                                                data-testid="newPassword"
                                                id="standard-adornment-newpassword"
                                                placeholder="Enter New Password"
                                                error={touched.newPasswordForm && Boolean(errors.newPasswordForm)}
                                                className={classes.labelHeaderTwo}
                                                type={
                                                    this.state.showNewPassword ? "text" : "password"}
                                                classes={{
                                                    input: classes.language,
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            data-testid='icon2'
                                                            style={{ color: touched.newPasswordForm && errors.newPasswordForm ? "red" : "" }}
                                                            aria-label="toggle password visibility"
                                                            onClick={() => this.handleShowPassword("newPassword")}
                                                        >
                                                            {this.state.showNewPassword ? <img src={privacy} className="rotateIcon" style={{ color: touched.newPasswordForm && errors.newPasswordForm ? "red" : "" }} /> : <img src={pattern} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {touched.newPasswordForm && errors.newPasswordForm ? (
                                                <Typography style={webStyle.errorStyle}>{errors.newPasswordForm}</Typography>
                                            ) : null}


                                        </Grid>
                                    </Grid>
                                </Box >
                                <>{this.accounDelete()}</>

                                <Button type="submit" className={classes.saveBtn} data-testid="saveButton" >
                                    Save and Continue
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Paper>


                <Modal open={this.state.showVerifyDeleteModal}>
                    <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
                        <Box sx={{ width: "300px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
                            <Box sx={{ marginBottom: "20px", textAlign: "center", fontFamily: `"Lato", sans-serif` }}>Are you sure you want to delete your account?</Box>
                            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: '15px' }}>
                                <CustomBackButton data-testid="backRef" onClick={() => this.handleOpenVerifyDeleteModal()}>
                                    Back
                                </CustomBackButton>
                                <CustomDeleteButton style={webStyle.deleteBtn} data-testid="deleteConfirmRef" onClick={this.handleDeleteAccount}>
                                    Delete
                                </CustomDeleteButton>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </>


            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    mainWrapper: {
        background: '#eae9e9',
        padding: "20px",
        fontFamily: " Lato, Arial, san-serif",
    },

    papperClasses: {
        fontFamily: "Lato, sans-serif",
        position: 'relative' as const,
        background: '#fff',
        borderRadius: "15px",
        boxShadow: 'none',
        height: '100%'
    },
    typoText: {
        fontFamily: "Lato, sans-serif",
        color: "#444",
    },

    desc: {
        fontFamily: "Lato, sans-serif",
        color: "rgb(45, 44, 44)",
        marginBlock: "15px",
        fontWeight: 400,
    },
    horizontalBar: {
        fontFamily: "Lato, sans-serif",
        width: "150px",
        height: "1px",
        marginBlock: "10px"
    },



    errorStyle: {
        fontFamily: "Lato, sans-serif",
        color: "red",
        textAlign: "left" as const,
        fontSize: "12px",
        marginTop: "5px"
    },
    deleteBtn: {
        backgroundColor:'#ff6008'
    }

}

const styles = (theme: Theme) => ({
    personName: {
        color: "#33333a",
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Lato, sans-serif",
        lineHeight: '24px'
    },
    personEmail: {
        color: "#666666",
        margin: "20px auto",
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Lato, sans-serif",
        lineHeight: "41px"
    },
    active: {
        color: "#ff6008",
        lineHeight: "21.6px",
        marginBlock: "10px",
        fontWeight: 700,
        textDecoration: "underline",
        cursor: "pointer",
        fontSize: '18px',
        fontFamily: "Lato, sans-serif"
    },
    menuList: {
        color: "#232529",
        lineHeight: "20px",
        marginBlock: "14px",
        fontWeight: 400,
        cursor: "pointer",
        fontFamily: "Lato, sans-serif",
        fontSize: '18px',
        maxWidth: '162px'
    },
    deleteAccountBox: {
        fontFamily: "Lato, sans-serif",
        marginTop: "30px", paddingRight: "15%", paddingTop: "10px",
        width: "20%",
        display: "inline-block",
        marginBottom: '50px',
        [theme.breakpoints.down("xs")]: {
            marginBottom: '30px',
            width: '100%',
            marginTop: "0",
        }
    },
    headerTxt: {
        color: "#232529",
        fontWeight: 700,
        fontSize: "32px",
        fontFamily: "Lato, sans-serif",
        lineHeight: '60px'
    },
    subHeaders: {
        color: "#232529",
        fontWeight: 700,
        fontSize: "16px",
        fontFamily: "Lato, sans-serif",
        lineHeight: '19.2px'
    },
    labelHeader: {
        margin: "6px 5px 10px 0",
        width: "100%",
        color: "#666666",
        fontWeight: 400,
        fontSize: "12px",
        fontFamily: "Lato, sans-serif",
        lineHeight: '14.4px',
        '.MuiInputBase-root': {
            width: "100% !important"
        }
    },
    labelHeaderTwo: {
        margin: "10px 5px 0 0",
        width: "100%",
        color: "#666666",
        fontWeight: 400,
        fontSize: "12px",
        fontFamily: "Lato, sans-serif",
        lineHeight: '14.4px',
        '.MuiInputBase-root': {
            width: "100% !important"
        }
    },
    textField: {
        margin: "5px 5px 0px 0",
        width: "100%",
        color: "#666666 !important",
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "AvenirNext-Regular, sans-serif",
        lineHeight: '17px',
        '.MuiInputBase-root': {
            width: "100% !important",
            fontFamily: "AvenirNext-Regular, sans-serif"
        },
    },
    languageHeader: {
        margin: "22px 5px -4px 10px",
        width: "100%",
        color: "#666666",
        fontWeight: 400,
        fontSize: "12px",
        fontFamily: "Lato, sans-serif",
        lineHeight: '14.4px',
        '.MuiInputBase-root': {
            width: "100% !important"
        }
    },
    language: {
        width: "100%",
        color: "#666666",
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "AvenirNext-Regular, sans-serif",
        lineHeight: '17px'
    },
    root: {
        width: '100%',
        margin: "14px 5px 10px 0px",
        '.MuiInputBase-root': {
            width: "100% !important",
            margin: "10px 5px 10px 0px",
        }
    },
    saveBtn: {
        background: "rgb(253, 97, 12) none repeat scroll 0% 0%",
        padding: "16px 40px",
        fontWeight: 700,
        fontSize: "20px",
        color: "white",
        borderRadius: "8px",
        lineHeight: '20px',
        fontFamily: "Lato, sans-serif",
        textTransform: 'none' as const,
        position: "absolute" as const,
        bottom: "5px" as const,
        right: "55px" as const,
        '&:hover': {
            background: "rgb(253, 97, 12) none repeat scroll 0% 0%",
            color: "white"
        },
        [theme.breakpoints.down("md")]: {
            padding: "14px 32px",
            fontSize: "16px"
        },
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            left: 0,
            right: 0,
            fontSize: "14px",
            position: "unset",
        }
    },
    pattern: {
        '.MuiIconButton-label': {
            'svg': {
                width: '18px', height: '18px', opacity: '0.7'
            }
        }
    },
    padding: {
        marginTop: "34px",
        // paddingRight: "40%",
        [theme.breakpoints.down("sm")]: {
            marginTop: "0",
            // paddingRight: "20%",
        },
    },
    paddingTwo: {
        marginTop: "20px",
        // paddingRight: "40%",
        [theme.breakpoints.down("md")]: {
            // paddingRight: "20%",
        },
        [theme.breakpoints.down("xs")]: {
            // paddingRight: "5%"
        }
    },
    formWrraper: {
        margin: 0,
        width: "65%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    saveBtnBox: {
        fontFamily: "Lato, sans-serif" as const,
        paddingTop: "25px", height: '60px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px" as const,
            paddingTop: "15px", height: '46px',
        },

    },
})

export { UserProfile }
export default withStyles(styles)(UserProfile)
// Customizable Area End