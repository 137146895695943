// Customizable Area Start
import React from "react";
import { Box, Button, Grid, InputAdornment, Theme, Typography, withStyles } from "@material-ui/core";
import StoreHeaderTabController, { Props, configJSON } from "./StoreHeaderTabController.web";
import { CustomizeBrandInput } from "../CustomizedElementStyle";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import { file } from "../assets";
import { errorImage } from "../../../bulkuploading/src/assets";
import { BlackButton, CustomOrangeButton } from "../../../../components/src/CommonButton";
// Customizable Area End

class StoreHeaderTab extends StoreHeaderTabController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Grid container className={classes.marginOne}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomizeBrandInput
              data-test-id="storeName"
              id="outlined-start-adornment"
              label={configJSON.storeNameLabel}
              autoComplete="off"
              disabled={true}
              value={this.state.storeName}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
          </Grid>
        </Grid>
        <Box className={classes.logo}>{configJSON.storeLogoLabel}</Box>
        <input
          data-test-id="input_store_logo"
          type="file"
          accept=".jpg, .png,.jpeg"
          className={classes.input}
          ref={this.logoImgRef}
          id="uploadLogo"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            this.handleUploadFiles(event, "storeLogo");
          }}
        />
        {this.state.logoPreview ? (
          <Box className={classes.previewBox}>
            <img src={this.state.logoPreview} alt="Image Preview" className={classes.logoPreview} />
            <ClickableBox data-test-id="remove_logo" onClick={this.handleRemoveInputFile.bind(this, "storeLogo")} className={classes.removeBox}>
              {configJSON.removeText}
            </ClickableBox>
          </Box>
        ) : (
          <label htmlFor="uploadLogo">
            <Button variant="outlined" fullWidth onClick={this.handleOpenUploadInput.bind(this, "storeLogo")} data-test-id="open_input_logo" className={classes.uploadBtn}>
              <Box>
                <img src={file} />
                <Box className={classes.uploadLogoText}>{configJSON.uploadlogoText}</Box>
                <Box className={classes.fileType}>{configJSON.fileSizeInfoText}</Box>
                <Box className={classes.fileType}>{configJSON.recommendedSize} 96&times;96</Box>
              </Box>
            </Button>
          </label>
        )}
        {this.state.logoErrMsg && (
          <Box className={classes.errorBox}>
            <Box className={classes.rightMargin}>
              <img src={errorImage} />
            </Box>
            <Box>
              <Typography variant="body2" className={classes.errorColor}>
                {this.state.logoErrMsg}
              </Typography>
            </Box>
          </Box>
        )}
        <Box className={classes.logo}>{configJSON.coverImgText}</Box>
        <input
          accept=".png, .jpeg, .jpg"
          ref={this.inputCoverImgRef}
          data-test-id="input_cover_image"
          type="file"
          className={classes.input}
          value=""
          id="upload-button2"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            this.handleUploadFiles(event, "coverImg");
          }}
        />
        {this.state.coverPreview ? (
          <Box className={classes.previewBox}>
            <img src={this.state.coverPreview} alt="Image Preview" className={classes.logoPreview} />
            <ClickableBox data-test-id="remove_cover" onClick={this.handleRemoveInputFile.bind(this, "coverImg")} className={classes.removeBox}>
              {configJSON.removeText}
            </ClickableBox>
          </Box>
        ) : (
          <label htmlFor="upload-button2">
            <Button variant="outlined" fullWidth data-test-id="button_cover_image" onClick={() => this.handleOpenUploadInput("coverImg")} className={classes.uploadBtn}>
              <Box>
                <img src={file} />
                <Box className={classes.uploadLogoText}>{configJSON.uploadCoverImgText}</Box>
                <Box className={classes.fileType}>{configJSON.fileSizeInfoText}</Box>
                <Box className={classes.fileType}>{configJSON.recommendedSize} 1126&times;168</Box>
              </Box>
            </Button>
          </label>
        )}
        <Box className={classes.errorWrap}>
          {this.state.errorMsg && (
            <Box className={classes.errorBox}>
              <Box className={classes.rightMargin}>
                <img src={errorImage} />
              </Box>
              <Box>
                <Typography data-test-id="error" variant="body2" className={classes.errorColor}>
                  {this.state.errorMsg}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box className={classes.btnContainer}>
          <Grid container spacing={3} className={classes.btnGrid}>
            <Grid item>
              <BlackButton data-test-id="back_ref" onClick={this.handleBackBtn}>
                {configJSON.back}
              </BlackButton>
            </Grid>
            <Grid item>
              <CustomOrangeButton data-test-id="next_ref" onClick={this.handleNext}>
                {configJSON.nextBtn}
              </CustomOrangeButton>
            </Grid>
          </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  logo: {
    marginTop: 30,
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 12,
    fontWeight: 600
  },
  removeBox: {
    borderRadius: 5,
    background: "#ff6008",
    padding: "10px 20px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 14,
    marginTop: 10,
    textAlign: "center" as const,
    width: "80px",
    fontWeight: 700,
    color: '#fff'
  },
  marginOne: {
    marginTop: 40
  },
  input: {
    display: "none"
  },
  previewBox: {
    position: "relative" as const,
    width: "fit-content",
    marginTop: 30
  },
  logoPreview: {
    maxWidth: "100%",
    maxHeight: "300px",
    alignSelf: "flex-start"
  },
  uploadBtn: {
    width: "100%",
    height: 210,
    padding: "20px 0px",
    margin: "10px 0px",
    border: "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: "5px",
    textTransform: "none" as const,
    fontFamily: `"Lato", sans-serif`
  },
  uploadLogoText: {
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`
  },
  fileType: {
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 16
  },
  errorBox: {
    flexDirection: "row" as const,
    flexWrap: "nowrap" as const,
    display: "flex",
    paddingTop: "10px",
    alignItems: "center"
  },
  rightMargin: {
    marginRight: "10px"
  },
  errorColor: {
    color: "#D74D4D"
  },
  errorWrap: {
    width: "100%",
    height: 99,
    paddingTop: 10
  },
  successTxt: {
    color: "rgb(61 168 46)",
    fontSize: 14
  },
  btnContainer: {
    justifyContent: "flex-end",
    display: "flex",
    width: "100%"
  },
  btnGrid: {
    justifyContent: "flex-end"
  }
});

export { StoreHeaderTab };
export default withStyles(styles)(StoreHeaderTab);
// Customizable Area End
