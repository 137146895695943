import React, { ChangeEvent } from "react";

// Customizable Area Start
import { Box, Button, Chip, Grid, IconButton, InputAdornment, Link, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography, withStyles } from "@material-ui/core";
import { AddNewProductButton, CustomBackButton, CustomDeleteButton, CustomizeInputSearchBox, CustomSubmitReuploadedButton, FormControlTwo, OrangeButton, SearchIconSVG } from "../../dashboard/src/CustomizedElementStyle";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import MyDeals from "./MyDeals.web";
import moment from "moment";
import { Autocomplete , Pagination } from "@material-ui/lab";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Loader2 } from "../../../components/src/Loader.web";
// Customizable Area End

import ProductDealsController, { Props, configJSON, successIcon } from "./ProductDealsController.web";
import { CustomOrangeButton } from "../../../components/src/CommonButton";

class ProductDeals extends ProductDealsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    validationSchema = Yup.object().shape({
        skuName: Yup.string().required('Please select SKU'),
    })

    returnErrorMessageDeals = (touched: any, errors: any) => {
        if (errors && touched) {
            return <Typography style={webStyle.errorStyle} >{errors}</Typography>;
        } else {
            return null;
        }
    };
    SuccessModal = (modal: boolean) => {
        return (
            <Modal
                open={modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <Box sx={webStyle.modalMainWrapper}>
                    <Box sx={webStyle.blurredBackground}></Box>
                    <Box sx={webStyle.verifiedModalContainerMain} style={{ background: "white", height: "320px", border: "1px solid black" }}>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: 'flex-end', flexDirection: "column" }}>
                            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: 'flex-end', flexDirection: "column" }}>
                                <IconButton
                                    data-test-id="successConfirmRef"
                                    aria-label="toggle password visibility"
                                    onClick={() => this.handleCloseSuccesModal()}
                                    edge="end"
                                    style={{ marginRight: '-4px' }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Box sx={webStyle.verifiedImageWrapperMain} style={{ height: "100%" }}>
                                <Box sx={webStyle.verifiedImageContainer}>
                                    <img src={successIcon} alt="success image" />
                                </Box>
                                <Box sx={{ fontFamily: `"Lato", sans-serif`, marginBottom: "10px", fontWeight: 'bold', fontSize: '20px', color: "#565656" }}>
                                    {"Request is submitted"}
                                </Box>
                                <Box sx={{ fontFamily: `"Lato", sans-serif`, marginBottom: "10px", fontSize: "12px", color: "#565656" }}>
                                    {"Your request is submitted for review"}
                                </Box>
                                <Box sx={{ fontFamily: `"Lato", sans-serif`, marginBottom: "70px", fontSize: "12px", color: "#565656" }}>
                                    {"You are being redirect"}
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    dealsTable() {
        return (
            <>
                <Paper style={{ width: "100%", boxShadow:"0px 2px 1px -1px rgba(255, 249, 249, 0.2),0px 1px 1px 0px rgba(238, 238, 238, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}>
                    <TableContainer >
                        <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={webStyle.tabelHeadeing}>DEAL NAME</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>DEAL CODE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>DISCOUNT VALUE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>DISCOUNT TYPE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>START DATE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>END DATE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>ACTION</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.dealsList.length > 0 ?
                                    this.state.dealsList.map((item: any, index: number) => {
                                        return (
                                            <TableRow data-test-id="list" key={item.id}>
                                                <TableCell style={webStyle.tabelCell}>
                                                    <Box sx={webStyle.boxContainer}>{item.attributes?.deal_name}</Box>
                                                </TableCell>
                                                <TableCell style={webStyle.tabelCell}>
                                                    <Box sx={webStyle.boxContainer}>{item.attributes?.deal_code}</Box>
                                                </TableCell>
                                                <TableCell style={webStyle.tabelCell}>
                                                    <Box sx={webStyle.boxContainer}>{item.attributes?.discount_value}</Box>
                                                </TableCell>
                                                <TableCell style={webStyle.tabelCell}>
                                                    <Box sx={webStyle.boxContainer}>{item.attributes?.discount_type}</Box>
                                                </TableCell>
                                                <TableCell style={webStyle.tabelCell}>{moment(item.attributes?.start_date).format("DD MMM YYYY")}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>{moment(item.attributes?.end_date).format("DD MMM YYYY")}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>
                                                    <Link data-test-id="listYourProductRef" component="button" variant="body2" style={{ color: "#FF6008", fontStyle: "italic", textDecoration: "underline" }} onClick={() => this.handleSelectDealToListProduct(item?.id)}>
                                                        List your product
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : 
                                    <TableRow>
                                        <TableCell style={webStyle.tabelCell} colSpan={7} >
                                            <Typography variant="h6" style={{ textAlign: 'center', fontFamily: `"Lato", sans-serif`, padding: '10px' }}>
                                                No data available
                                            </Typography>
                                        </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {this.state.dealsList?.length > 0 && (
                    <Pagination
                      className={this.props.classes.paginateClass}
                      data-test-id="pagination"
                      page={this.state.page}
                      count={this.state.totalCount}
                      onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                        this.setState({ page: value });
                        this.handleAllDealsListApiCall(value);
                      }}
                      shape="rounded"
                      siblingCount={0}
                      boundaryCount={1}
                    />
                  )}
                </Paper>
                <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <CustomOrangeButton
                        onClick={() => this.handlePanel("myDeals")}
                        data-test-id="nextRef"
                        className={this.props.classes.submitButton}
                        style={{
                            fontFamily: `"Lato", sans-serif`,
                            fontWeight: 700,
                            textTransform: "none",
                            fontSize: 16,
                            color: "white",
                            display: "flex",
                            background: "#FF6008",
                            alignItems: "center",
                            borderRadius: "8px",
                            marginBottom: "25px"
                        }}
                    >
                        Submit
                    </CustomOrangeButton>
                </Box>
            </>
        );
    }

    skuDropdownComponent() {
        const { options, selectedOption, searchTerm } = this.state;
        return (
            <>
                <Formik
                    initialValues={{
                        skuName: this.state.skuName,
                    }}
                    enableReinitialize={true}
                    validationSchema={this.validationSchema}
                    validateOnMount={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={this.createSellerDealsListApiCall}
                    data-test-id="formik"
                >
                    {({
                        errors,
                        setFieldValue,
                        touched,

                    }) => (
                        <Form translate={undefined} style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: '100%',
                            width: '100%',
                            margin: 0,
                        }} >
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlTwo fullWidth={true} >
                                    <Box sx={{ color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontWeight: 600, fontSize: 12, marginBottom: 10 }}>{"Select SKU*"}</Box>
                                    <Autocomplete
                                        multiple
                                        loading={this.state.loadingSKU}
                                        loadingText={'Searching SKU....'}
                                        data-test-id="autocompleteRef"
                                        id="autocomplete"
                                        open={!!searchTerm}
                                        noOptionsText={"No options found"}
                                        options={options?.length > 0 ? options : []}
                                        value={selectedOption}
                                        getOptionLabel={(option) => option?.attributes?.sku ? option.attributes.sku : ""}
                                        renderTags={(selectedOption, getTagProps) => (
                                            <Box display="flex" flexDirection="column">
                                              {selectedOption?.map((option:{attributes:{sku:string}}, index:number) => {
                                                const {sku} = option.attributes
                                                return(
                                                <Chip
                                                  key={index}
                                                  label={String(sku)}
                                                  {...getTagProps({ index })}
                                                  style={{ marginBottom: '8px', display:'flex', justifyContent:'space-between' }} // Spacing between chips
                                                />
                                              )})}
                                            </Box>
                                          )}
                                        renderOption={(option) => (
                                        <Box>
                                            <Box>{option.attributes.sku}</Box>
                                            <Box style={{ fontSize: '14px', color: '#697A8D' }}>
                                                Seller Sku 
                                                <span>{option.attributes.is_variant ? " >> Variant SKu" : "" }</span>
                                            </Box>
                                        </Box>
                                        )}
                                        onChange={(event: ChangeEvent<{}>, newValue: any | null) => {
                                            setFieldValue('skuName', newValue)
                                            this.handleOptionChange(newValue)
                                        }}
                                        inputValue={searchTerm}
                                        onInputChange={this.handleInputChange}
                                        renderInput={(params: any) => (
                                            <CustomizeInputSearchBox
                                                {...params}
                                                placeholder="Select SKU"
                                                data-test-id="autocompletInputeRef"
                                                variant="outlined"
                                                className={this.props.classes?.search}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                          <SearchIconSVG />
                                                        </InputAdornment>
                                                      )
                                                }}
                                            />
                                        )}
                                        classes={{ root: this.props.classes.autoComplete, paper: this.props.classes.autoCompletePopover }}
                                        PaperComponent={Paper}
                                    />
                                    {this.returnErrorMessageDeals(touched['skuName'], errors['skuName'])}
                                </FormControlTwo>
                            </Grid>
                            </Grid>
                            <Box style={{ marginLeft: 'auto' }}>
                                <CustomOrangeButton data-test-id="submitBtn" type="submit" className={this.props.classes.submitButton} style={{
                                    marginLeft: 'auto'
                                }} >Submit</CustomOrangeButton>
                            </Box>
                        </Form>
                    )}
                </Formik >
            </>
        );
    }

    userSchema = () => {
        return Yup.object().shape({
            dealsValue: Yup.number().required("Please enter deals price.").min(0, 'Invalid price').typeError('Invalid price')
                .max(10000000, 'Invalid price'),
        });
    };

    editDealsViewHandler() {
        return (
            <>
                <Formik
                    data-test-id="editDealsViewHandlerFormik"
                    initialValues={{
                        dealsValue: this.state.selectedDealsWithSKUData?.attributes?.deal_price,
                    }}
                    validationSchema={this.userSchema}
                    onSubmit={this.updateSellerDealPriceApiCall}
                    validateOnBlur={true}
                    validateOnChange={true}
                    enableReinitialize={true}
                >
                    {({ errors, setFieldTouched, values, handleChange }) => (
                        <Form translate={undefined} data-test-id="formDealsRef" autoComplete="off" style={{
                            height: "100%", display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            margin: 0
                        }}>
                            <Grid item lg={6} sm={12} xs={12}>
                                <FormControlTwo fullWidth={true} >
                                    <Box sx={{ color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontWeight: 600, fontSize: 12, marginBottom: 10 }}>{"Add Deal Price"}</Box>
                                    <TextField
                                        data-test-id="selectDealsRef"
                                        id="demo-simple-select-outlined"
                                        type="text"
                                        name="dealsValue"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={values.dealsValue}
                                        InputProps={{

                                            inputComponent: 'input',
                                        }}
                                        label=""
                                        variant="outlined"
                                        placeholder="Add deals"
                                        onChange={(event: ChangeEvent<{}>) => {
                                            handleChange(event);
                                            setFieldTouched("dealsValue", true, false);
                                        }}
                                    />
                                    {errors["dealsValue"] && <Typography
                                        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
                                    >
                                        {errors["dealsValue"]}
                                    </Typography>}
                                </FormControlTwo>
                            </Grid>
                            <Box style={{ justifyContent: "flex-end", gap: '15px', width: "100%", display: "flex" }}>
                                <CustomBackButton style={{
                                    textTransform: "none",
                                    fontFamily: `"Lato", sans-serif`,
                                }} data-test-id="backRefEdit" onClick={() => this.handleCloseEditModal()}>
                                    Back
                                </CustomBackButton>
                                <Button
                                    data-test-id="selectedDealsListRef"
                                    type="submit"
                                    style={{
                                        textTransform: "none",
                                        fontFamily: `"Lato", sans-serif`,
                                        fontWeight: 700,
                                        fontSize: 16,
                                        background: "#FF6008",
                                        color: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "8px",
                                        padding: "15px 50px"
                                    }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }

    offerAppliedModal = () => {
        return <Modal open={this.state.offerAppliedModal} data-test-id='deleteModalRef'>
            <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center ", height: "100vh" }} style={{ background: "rgba(0,0,0,0.1)" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "500px", height: "auto", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
                    <Box sx={{ textAlign: "center", fontFamily: `"Lato", sans-serif`, marginBottom: "20px" }}>This product is already enrolled in an Offer, Please remove it first before adding it to a deal.</Box>
                    <Box style={{ justifyContent: "center", gap: '15px', width: "100%", display: "flex" }}>
                        <CustomSubmitReuploadedButton data-test-id="backOfferRef" onClick={this.handleOfferAppliedModal}>
                            Ok
                        </CustomSubmitReuploadedButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    }

    deletDealsModal = () => {
        return <Modal open={this.state.openDealDeleteModal} data-test-id='deleteModalRef'>
            <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center ", height: "100vh" }} style={{ background: "rgba(0,0,0,0.1)" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "300px", height: "auto", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
                    <Box sx={{ textAlign: "center", fontFamily: `"Lato", sans-serif`, marginBottom: "20px" }}>Are you sure you want to delete this deal?</Box>
                    <Box style={{ justifyContent: "space-between", gap: '15px', width: "100%", display: "flex" }}>
                        <CustomBackButton data-test-id="backRef" onClick={() => this.handleCloseDealsDeleteModal()}>
                            Back
                        </CustomBackButton>
                        <CustomDeleteButton data-test-id="deleteConfirmRef" onClick={this.deleteSellerDeals}>
                            Delete
                        </CustomDeleteButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    }
    SelectedDealsComponent() {
        return (
            <>
                <Paper style={{ width: "100%", boxShadow:"0px 2px 1px -1px rgba(255, 249, 249, 0.2),0px 1px 1px 0px rgba(238, 238, 238, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}>
                    <TableContainer >
                        <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={webStyle.tabelHeadeing}>SELLER SKU</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>PRODUCT TITLE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>SKU MIN PRICE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>CURRENT OFFER PRICE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>DEAL PRICE</TableCell>
                                    <TableCell style={webStyle.tabelHeadeing}>ACTION</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.dealsWithSKUList &&
                                    this.state.dealsWithSKUList.map((item: any, index: number) => {
                                        const {sku, is_variant} = item.attributes.catalogue_full_details?.data?.attributes
                                        const variantProductDetails = item.attributes.catalogue_full_details?.data?.attributes?.variant_product_group
                                        return (
                                            <TableRow data-test-id="DealsList" key={item.id}>
                                                {/* @ts-ignore */}
                                                <TableCell style={webStyle.tabelCell}>{is_variant ? variantProductDetails.product_sku : sku}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>{item.attributes.product_title}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>{item.attributes.seller_price || 0}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>{item.attributes.current_offer_price || 0}</TableCell>
                                                <TableCell style={webStyle.tabelCell}>{item?.attributes?.deal_price || 0}</TableCell>

                                                <TableCell>
                                                    <Box style={{ display: 'flex', gap: '7px' }}>
                                                        {item?.attributes?.deal?.data?.attributes?.discount_type === "percentage" ? <></> :
                                                            <Link data-test-id="editSellerRef" component="button" onClick={() => this.handleOpenEditModal(item)} variant="body2" style={{ color: "#147bd1", fontStyle: "italic", textDecoration: "underline" }}>
                                                                Edit
                                                            </Link>}
                                                        <Link data-test-id="deleteSellerRef" component="button" onClick={() => this.handleOpenDealsDeleteModal(item.id)} variant="body2" style={{ color: "#FF6008", fontStyle: "italic", textDecoration: "underline" }}>
                                                            Delete
                                                        </Link>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {this.state.dealsWithSKUList?.length > 0 && (
                    <Pagination
                      className={this.props.classes.paginateClass}
                      data-test-id="pagination"
                      page={this.state.page}
                      count={this.state.totalCount}
                      onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                        this.setState({ page: value });
                        this.handleSellerDealsListApiCall(value);
                      }}
                      shape="rounded"
                      siblingCount={0}
                      boundaryCount={1}
                    />
                  )}
                </Paper>

                <Box style={{
                    display: "flex", width: "100%", alignItems: "center", justifyContent: "center", marginTop: "70px"
                }}>
                    <Box style={{
                        padding: "25px",
                        background: "#eee",
                        border: "3px dashed #cecece"
                    }}>
                        <AddNewProductButton onClick={this.handleAddMoreDeal} data-test-id="addNewProductRef" style={{ fontFamily: `"Lato", sans-serif` }}>
                            <AddIcon style={{ color: "white", marginRight: 10, marginLeft: 0 }} />
                            Add More
                        </AddNewProductButton>
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingBottom: '25px' }}>
                    <CustomOrangeButton
                        data-test-id="submitSelectedDealRef"
                        onClick={() => this.handleFinalSubmit()}
                        className={this.props.classes.submitButton}
                        style={{
                            textTransform: "none",
                            fontFamily: `"Lato", sans-serif`,
                            fontWeight: 700,
                            fontSize: 16,
                            background: "#FF6008",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "8px"
                        }}
                    >
                        Submit
                    </CustomOrangeButton>
                </Box>
            </>
        );
    }
    componentTorender() {
        const { activePanel } = this.state;
        if (activePanel === "allDeals") {
            return <>
                {this.state.activeStep === "dealsTable" && this.dealsTable()}
                {this.state.activeStep === "selectSKU" && this.skuDropdownComponent()}
                {this.state.activeStep === "selectedDealsList" && this.SelectedDealsComponent()}
                {this.state.activeStep === "editDealModal" && this.editDealsViewHandler()}
            </>
        }
        if (activePanel === "myDeals") {
            return <MyDeals navigation={this.props.navigation} id="my-deals" lastUpdatedDealsId={this.state.lastUpdatedDealsId} />;
        }
    }

    // Customizable Area End

    // Customizable Area Start
    render() {
        return (
            <Box sx={webStyle.mainContainer}>
                <ToastContainer />
                <Box style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", width: "100%", background: "linear-gradient(180deg, #F8F8F8 0%, #EEE 100%)" }}>
                    <Box data-test-id="allDealsRef" onClick={() => this.handlePanel("allDeals")} style={this.state.activePanel === "allDeals" ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn}>
                        {configJSON.allDealsTabLabel}
                    </Box>
                    <Box data-test-id="myDealsRef" onClick={() => this.handlePanel("myDeals")} style={this.state.activePanel === "myDeals" ? webStyle.productDetailsColumnSelected : webStyle.productDetailsColumn}>
                        {configJSON.myDealsTabLabel}
                    </Box>
                </Box>
                {this.SuccessModal(
                    this.state.activeStep === "successModal")}
                {this.deletDealsModal()}
                {this.offerAppliedModal()}
                <Box sx={webStyle.mainSection}>
                    {this.state.loading ? <Loader2 loading={this.state.loading} style={{top: "55%"}} /> : <></>}
                    {this.componentTorender()}
                </Box>


            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    mainContainer: {
        left: "0px",
        width: "100%",
        position: "relative",
        // height: "calc(-390px + 100vh)",
    },
    mainSection: {
        padding: "30px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "50vh",
        gap: "25px"
    },
    productDetailsColumn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 62,
        width: "100%",
        fontSize: 14,
        fontWeight: 600,
        fontFamily: `"Lato", sans-serif`,
        color: "#727272",
        background: "linear-gradient(180deg, #F8F8F8 0%, #EEE 100%)",
        cursor: "pointer"
    },
    tabelHeadeing: {
        fontWeight: 600,
        fontSize: "15px",
        backgroundColor: "#f0f0f0",
        fontFamily: `"Lato", sans-serif`,
        color: "#727272",
        whiteSpace: 'nowrap' as const,
    },
    tabelCell: {
        color: "rgb(102, 102, 102)",
        fontFamily: `"Lato", sans-serif`
    },
    productDetailsColumnSelected: {
        height: 62,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#ffa06a",
        color: "#000",
        fontSize: 14,
        fontWeight: 600,
        fontFamily: `"Lato", sans-serif`,
        cursor: "pointer"
    },
    mainWrappers: {
        flexDirection: "column",
        alignItems: "center",
        position: "fixed",
        background: "#fff",
        display: "flex",
        fontFamily: `"Lato", sans-serif`,
        width: "100%",
    },
    modalMainWrapper: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        position: "relative",
        fontFamily: `"Lato", sans-serif`,
    },
    blurredBackground: {
        width: "100%",
        zIndex: -1,
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.1)",
        position: "absolute",
        backdropFilter: "blur(8px)",
        fontFamily: `"Lato", sans-serif`,
    },
    modalContainer: {
        position: "relative",
        width: "450px",
        padding: "50px",
        borderRadius: "10px",
        maxWidth: "450px",
        fontFamily: `"Lato", sans-serif`,
    },
    sendEmailVerifModal: {
        maxWidth: "540px",
        padding: "50px 50px 32px 50px",
        borderRadius: "10px",
        backgroundColor: "white",
        position: "relative",
        width: "540px",
        height: "400px",
        fontFamily: `"Lato", sans-serif`,
    },
    verifiedModalContainerMain: {
        borderRadius: "10px",
        maxWidth: "450px",
        position: "relative",
        width: "350px",
        display: 'flex',
        padding: "20px",
        fontFamily: `"Lato", sans-serif`,
    },
    verifiedImageWrapperMain: {
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        fontFamily: `"Lato", sans-serif`,
    },
    verifiedImageContainer: {
        marginBottom: "24px",
        height: "100px",
        width: "100px",
        fontFamily: `"Lato", sans-serif`,
    },
    errorStyle: {
        color: "red",
        textAlign: "left" as const,
        fontSize: "12px",
        marginTop: "25px",
        fontFamily: `"Lato", sans-serif`,
    },
    boxContainer: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: "300px"
    }

};
const styles = (theme: Theme) => ({
    autoComplete: {
        minWidth: '50%',
        width: 'fit-content',
        maxWidth: '50%',
        height: '40px',
        fontFamily: `"Lato", sans-serif`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: '80%',
        },
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            maxWidth: '100%',
        },
        '& .MuiAutocomplete-inputRoot': {
            fontFamily: `"Lato", sans-serif`,
            '& .MuiAutocomplete-tag': {
                background: "#ffa06a",
                fontSize: "1rem",
                borderRadius: "4px",
                fontFamily: `"Lato", sans-serif`,
            },
        },


    },
    paginateClass: {
        display: "flex",
        margin: "25px",
        justifyContent: "center"
      },
    submitButton: {
        fontWeight: 700,
        fontFamily: `"Lato", sans-serif`,
    },
    autoCompletePopover: {
        background: "#F6F6F6",
        color: "#727272",
        fontSize: "25px",
        top: "10px",
        padding: '0',
        fontFamily: `"Lato", sans-serif`,
        '& .MuiAutocomplete-option': {
            color: "#2e2d2d",
            padding: "15px 14px",
            fontSize: 14,
            fontFamily: `"Lato", sans-serif`,
            "&:hover": {
                backgroundColor: "#eb6b2e",
                color: "white"
            },
            "& .Mui-selected": {
                backgroundColor: "#eb6b2e",
                color: "white"
            },
            "&:selection": {
                backgroundColor: "#eb6b2e",
                color: "white"
            },
            "&.MuiListItem-root.Mui-selected": {
                backgroundColor: "#eb6b2e",
                color: "white",
                fontFamily: `"Lato", sans-serif`,
            }
        },
        '& .MuiAutocomplete-noOptions': {
            padding: "6px 16px",
            fontSize: "20px",
            fontFamily: `"Lato", sans-serif`,
        },
        '& .MuiAutocomplete-loading': {
            padding: "6px 16px",
            fontSize: "20px",
            fontFamily: `"Lato", sans-serif`,
        }
    },
    search: {
        "& .MuiInputBase-root": {
          paddingRight:'20px !important',  
          '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: '0px', // Adjust paddingRight as needed
          },
          border: "1px solid #c8c8c8",
          "&:focus": {
            borderColor: "#c8c8c8",
            outline: "none" // Change the color for the focus state
          },
          "&.Mui-focused": {
            borderColor: "#c8c8c8",
            outline: "none"
          }
        },
        "& .MuiInputLabel-root": {
          color: "#33333a",
          opacity: 0.6,
          "&:focus": {
            borderColor: "#c8c8c8",
            outline: "none"
          },
          "&.Mui-focused": {
            borderColor: "#c8c8c8",
            outline: "none"
          }
        }
      }

})
export { ProductDeals };

export default withStyles(styles)(ProductDeals);
// Customizable Area End
