import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { ISignupData, ISignupErrorMap } from "./Interfaces";
import { SetCookies } from "../../../framework/src/WebUtilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  errorMessages: string[];
  showEmailLoginModal: boolean;
  showPassword: boolean;
  email: string;
  password: string;
  isFetching: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class BuyerEmailLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiSigninUserCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage)];

    this.state = {
      errorMessages: [],
      showEmailLoginModal: false,
      showPassword: false,
      email: "",
      password: "",
      isFetching: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.ReceiveMessage)) {
      const msg = message.getData(getName(MessageEnum.OpenBuyerEmailLoginModal)) || false;
      if (msg) {
        this.setState({ showEmailLoginModal: true, email: "", password: "", showPassword: false, errorMessages: [], isFetching: false });
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ isFetching: false });
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiSigninUserCallId) {
        this.hadleSigninUserResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  hadleSigninUserResponse = (responseJson: unknown) => {
    const successData = responseJson as ISignupData;
    const err = responseJson as ISignupErrorMap;
    if ("meta" in successData) {
      let type = successData.meta.user_type
      if(type === "seller"){
        this.setState({ errorMessages: ["The email is not registered or not activated"] });
      }else{
        let id = successData.meta.id.toString()
        SetCookies("ba", successData.meta.token, 7);
        SetCookies('accountId',id, 7)
        return location.reload();
      }
    }
    if ("errors" in err) {
      const key = Object.keys(err.errors[0])[0];
      return this.setState({ errorMessages: [err.errors[0][key]] });
    }
  };

  handleCloseModal = () => {
    this.setState({ showEmailLoginModal: false });
  };

  handleInputChange = (input: string, value: string) => {
    this.setState({ [input]: value } as unknown as Pick<S, keyof S>);
  };

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleKeyDown = async(e:any)=>{
    if (e.key === "Enter") {
      this.signinUser();
    }
  }
  handleSignIn = () => {
    if (!this.state.email && !this.state.password) {
      return this.setState({ errorMessages: ["Please provide all of the required information!"] });
    }
    this.signinUser();
  };

  handleGoToSignUpPage = () => {
    this.setState({ showEmailLoginModal: false });
    const msg: Message = new Message(getName(MessageEnum.SendMessage));
    msg.addData(getName(MessageEnum.OpenBuyerSignupModal), true);
    this.send(msg);
  };

  handleOpenFP = () => {
    const msg: Message = new Message(getName(MessageEnum.SendMessage));
    msg.addData(getName(MessageEnum.OpenForgoPasswordModal), true);
    this.send(msg);
    this.setState({ showEmailLoginModal: false });
  };

  signinUser = () => {
    const path = window.location.pathname.split("/")[1]
    this.setState({ isFetching: true, errorMessages: [] });
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = { "Content-Type": configJSON.contentType };
    this.apiSigninUserCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSigninUserEndpoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          type: "email_account",
          user_type: path === 'seller' ? "seller": "buyer",
          attributes: {
            email: this.state.email,
            password: this.state.password,
          },
        },
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
