// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";
import { getStorageData } from "framework/src/Utilities";
import { saveAs } from "file-saver";
// Customizable Area End

// Customizable Area Start

export interface ICompareSalesAttributes {
  total_order_item: number, 
  units_ordered: number, 
  ordered_product_sales: number, 
  average_units_or_order_item: number, 
  average_sales_or_order_item: number
}
export interface ICompareSales{
  today_so_far: ICompareSalesAttributes,
  yesterday: ICompareSalesAttributes,
  same_day_last_week : ICompareSalesAttributes,
  same_day_last_year: ICompareSalesAttributes,
  percentage_change_from_yesterday: ICompareSalesAttributes,
  percentage_change_from_same_day_last_week: ICompareSalesAttributes,
  percentage_change_from_same_day_last_year: ICompareSalesAttributes
}

export interface ISales {
  date: string, 
  ordered_product_sales: number, 
  units_ordered: number, 
  total_order_item: number, 
  average_sales_per_order_item: number, 
  average_units_per_order_item:number, 
  average_selling_price: number, 
  order_item_sessions_total: number, 
  order_item_session_percentage: number
}

export interface ISalesRes {
  results: ISales[],
  total_count: number
}

export interface ISalesTrafficRes {
  results: ISalesTraffic[],
  total_count: number
}

export interface IReturnReport {
  date:string, 
  ordered_product_sales: string,
  units_ordered: string, 
  total_order_item: string, 
  units_refunded: string, 
  refund_rate: string, 
  feedback_received: string, 
  negative_feedback_received: string, 
  received_negative_feedback_rate: string,
  a_z_claims_granted: string, 
  claims_amount: string
}

export interface IReturnRes {
  results: IReturnReport[],
  total_count: number
}

export interface ICompareSalesRes {
  results : ICompareSales
}

export interface ISalesTraffic {
  parent_besku: string, 
  child_besku: string, 
  title: string, 
  sku: string, 
  sessions_total: number, 
  sessions_percentage: number, 
  page_views_total:number,
  page_views_percentage_total: number, 
  featured_offer_buy_box_per: number, 
  units_ordered:number, 
  units_ordered_percentage: number, 
  ordered_product_sales: number, 
  total_order_item:number 
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {}
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportType: string,
  fromDate: Date | null,
  toDate: Date | null,
  compareSalesList : ICompareSales,
  salesList: ISales[],
  returnsList: IReturnReport[],
  salesTrafficList: ISalesTraffic[],
  txtInputValue: string
  commonError: string,
  resultsErr: string,
  formattedFromDate: string,
  formattedToDate: string,
  exportCSV: boolean,
  totalCount: number,
  pageNumber: number,
  dateError: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postReportsApiCallId: string = "";
  getCsvReportsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      reportType: 'Sales report',
      fromDate: null,
      toDate: null,
      compareSalesList : {
        today_so_far: {
          total_order_item: 0, 
          units_ordered: 0, 
          ordered_product_sales: 0, 
          average_units_or_order_item: 0, 
          average_sales_or_order_item: 0
        },
        yesterday: {
          total_order_item: 0, 
          units_ordered: 0, 
          ordered_product_sales: 0, 
          average_units_or_order_item: 0, 
          average_sales_or_order_item: 0
        },
        same_day_last_week : {
          total_order_item: 0, 
          units_ordered: 0, 
          ordered_product_sales: 0, 
          average_units_or_order_item: 0, 
          average_sales_or_order_item: 0
        },
        same_day_last_year: {
          total_order_item: 0, 
          units_ordered: 0, 
          ordered_product_sales: 0, 
          average_units_or_order_item: 0, 
          average_sales_or_order_item: 0
        },
        percentage_change_from_yesterday: {
          total_order_item: 0, 
          units_ordered: 0, 
          ordered_product_sales: 0, 
          average_units_or_order_item: 0, 
          average_sales_or_order_item: 0
        },
        percentage_change_from_same_day_last_week: {
          total_order_item: 0, 
          units_ordered: 0, 
          ordered_product_sales: 0, 
          average_units_or_order_item: 0, 
          average_sales_or_order_item: 0
        },
        percentage_change_from_same_day_last_year: {
          total_order_item: 0, 
          units_ordered: 0, 
          ordered_product_sales: 0, 
          average_units_or_order_item: 0, 
          average_sales_or_order_item: 0
        }
      },
      salesList: [],
      returnsList: [],
      salesTrafficList : [],
      txtInputValue:'',
      commonError: '',
      resultsErr: '',
      formattedFromDate: '',
      formattedToDate: '',
      exportCSV: false,
      totalCount: 1,
      pageNumber: 1,
      dateError: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area Start
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.postReportsApiCallId) {
        this.handleReportsResponse(responseJson);
      }
      if (apiRequestCallId === this.getCsvReportsApiCallId) {
        this.handleCSVresponse(responseJson)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }
  // Customizable Area End

  // Customizable Area Start

  handleCSVresponse = (responseJson:unknown) => {
    if(this.state.exportCSV) {
      this.downloadCSVFile(responseJson)
      this.setState({exportCSV:false})
    }
  }

  downloadCSVFile = (responseJson: any) => {
    const data = responseJson;
    const originalHeaders = Object.keys(data.values);
    const values = data.values;
  
    const csvHeaders = this.generateCSVHeaders(originalHeaders);
    let csvContent = csvHeaders.join(",") + "\n";
  
    const maxArrayLength = this.calculateMaxArrayLength(originalHeaders, values);
  
    for (let i = 0; i < maxArrayLength; i++) {
      const row = this.generateCSVRow(originalHeaders, values, i);
      csvContent += row.join(",") + "\n";
    }
  
    this.saveCSV(csvContent);
  };

  generateCSVHeaders = (originalHeaders: string[]) => {
    return originalHeaders.map((header) =>
      header === "Order Item Sessions Total" ? "Sessions Total" : header
    );
  };
  
  calculateMaxArrayLength = (originalHeaders: string[], values: any) => {
    return Math.max(
      ...originalHeaders.map((header) =>
        Array.isArray(values[header]) ? values[header].length : 1
      )
    );
  };
  
  generateCSVRow = (originalHeaders: string[], values: any, index: number) => {
    return originalHeaders.map((header) => {
      const value = values[header];
      return this.formatCellValue(header, value, index);
    });
  };
  
  formatCellValue = (header: string, value: any, index: number) => {
    const headersToCheck = [
      "Average Sales Or Order Item",
      "Ordered Product Sales",
      "Average Sales Per Order Item",
      "Average Selling Price",
      "Claims Amount",
    ];
  
    const percentHeaders = [
      "Order Item Session Percentage",
      "Sessions Percentage",
      "Page Views Percentage Total",
      "Featured Offer Buy Box Percentage",
      "Unit Session Percentage",
      "Refund Rate",
      "Received Negative Feedback Rate",
    ];
  
    if (Array.isArray(value)) {
      let cellValue = value[index] !== undefined ? value[index] : "";
      if (header === "Date" && cellValue) {
        cellValue = `="${this.formatDate(cellValue)}"`;
      }
      if (headersToCheck.includes(header)) {
        cellValue = `AED ${cellValue}`;
      }
      if (percentHeaders.includes(header)) {
        cellValue = `${cellValue}%`;
      }
      return cellValue;
    }
    return index === 0 ? value : "";
  };
  
  saveCSV = (csvContent: string) => {
    const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(csvBlob, "data.csv");
  };

  handleReportsResponse = (responseJson:unknown) => {
    if(this.state.reportType === "Sales report") {
      this.setState({totalCount: (responseJson as ISalesRes).total_count })
      this.setSalesReportRes((responseJson as ISalesRes).results)
    }
    if(this.state.reportType === "Return report") {
      this.setState({totalCount: (responseJson as IReturnRes).total_count })
      this.setReturnReportRes((responseJson as IReturnRes).results)
    }
    if(this.state.reportType === "Compare Sales") {
      this.setCompareSalesReportRes((responseJson as ICompareSalesRes).results)
    }
    if(this.state.reportType === configJSON.salesTrafficReportText) {
      this.setState({totalCount: (responseJson as ISalesTrafficRes).total_count })
      this.setSalesTrafficReportRes((responseJson as ISalesTrafficRes).results)
    }
  }

  setReturnReportRes = (results: IReturnReport[]) => {
    if(results.length > 0) {
      this.setState({returnsList: results, resultsErr:''})
    }
    else this.setState({returnsList: [], resultsErr: configJSON.noDataFoundText})
  }

  setSalesReportRes = (results:ISales[]) => {
    if(results.length > 0) {
      this.setState({salesList: results, resultsErr:''})
    }
    else this.setState({salesList: [], resultsErr: configJSON.noDataFoundText})
  }

  setCompareSalesReportRes = (results: ICompareSales) => {
    this.setState({compareSalesList: results})
  }

  setSalesTrafficReportRes = (results:ISalesTraffic[]) => {
    if(results.length > 0) {
      this.setState({salesTrafficList: results, resultsErr:''})
    }
    else this.setState({salesTrafficList: [], resultsErr: configJSON.noDataFoundText})
  }

  async componentDidMount(): Promise<void> {
    this.setState({commonError: configJSON.commonErrMsg})
  }
    
  handleChangeReportType = (event: React.ChangeEvent<{ name?: string ; value: unknown; }>) => {
    this.setState({ reportType: event.target.value as string, pageNumber:1 }, () => this.setCommonErr())
  }

  fromDate = (date:  Date | null | string) => {
    const { toDate, fromDate } = this.state;
    this.setState({
      fromDate: date as Date,
      pageNumber:1
    }, () => {
      if(toDate && fromDate && new Date(date as Date) > new Date(toDate)) {
        this.setState({dateError: "The 'From Date' cannot be greater than 'To Date'."})
      }
      else this.setState({dateError:''})
      this.setCommonErr()
      this.formattedDate("fromDate", date as string)
    });
  };

  formattedDate = (dateType: string, date: string) => {
    const dateValue = new Date(date);
    const day = String(dateValue.getDate()).padStart(2, '0'); 
    const month = String(dateValue.getMonth() + 1).padStart(2, '0'); 
    const year = dateValue.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    if(dateType === "fromDate") {
      this.setState({formattedFromDate: formattedDate})
    }
    else this.setState({formattedToDate : formattedDate})
  }

  toDate = (date:  Date | null | string) => {
    const { fromDate } = this.state;
    if (fromDate && new Date(date as string) < new Date(fromDate)) {
        this.setState({dateError: "The 'To Date' cannot be earlier than 'From Date'."});
    }
    else {this.setState({dateError:''})}
    this.setState({
      toDate: date as Date,
      pageNumber:1
    }, () => {
      this.setCommonErr()
      this.formattedDate("toDate", date as string)

    });
  };

  setCommonErr = () => {
    if(this.state.fromDate && this.state.toDate) {
      this.setState({commonError: ''})
      this.getReports()
    }
  }

  getReports = async() => {
    const token = await getStorageData('authToken')
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    let reportType;
    if(this.state.reportType === configJSON.salesReportText){
      reportType = configJSON.salesReportTypeText
    }
    if(this.state.reportType === configJSON.compareSalesText){
      reportType = configJSON.compareSalesReportText
    }
    if(this.state.reportType === configJSON.returnReportText){
      reportType = configJSON.returnReportTypeText
    }
    if(this.state.reportType === configJSON.salesTrafficReportText){
      reportType = configJSON.salesTrafficReportType
    }
    const httpBody = {
      "start_date": this.state.formattedFromDate,
      "end_date": this.state.formattedToDate,
      "report_type": reportType,
      "export_csv": this.state.exportCSV
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postReportsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.reportsApiEndpoint + `?per_page=10&page=${this.state.pageNumber}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  formatDate = (date:string) => {
    const [year, month, day] = date.split("-")
    return `${day}/${month}/${year}`
  }

  setCompareSalesHeader = (keyValue:string) => {
    if(keyValue === 'today_so_far') {
      return "Today so far"
    }
    if(keyValue === 'yesterday') {
      return "Yesterday"
    }
    if(keyValue === 'same_day_last_week') {
      return "Same day last week"
    }
    if(keyValue === 'same_day_last_year') {
      return "Same day last year"
    }
    if(keyValue === 'percentage_change_from_yesterday') {
      return "+ % change from yesterday"
    }
    if(keyValue === 'percentage_change_from_same_day_last_week') {
      return "+ % change from same day last week"
    }
    if(keyValue === 'percentage_change_from_same_day_last_year') {
      return "+ % change from same day last year"
    }
  }

  handleCSV = () => {
    this.setState({exportCSV: true}, () =>  this.getCsvData())
  }

  getCsvData = async() => {
    const token = await getStorageData('authToken')
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    let typeOfReport;
    if(this.state.reportType === configJSON.salesReportText){
      typeOfReport = configJSON.salesReportTypeText
    }
    if(this.state.reportType === configJSON.compareSalesText){
      typeOfReport = configJSON.compareSalesReportText
    }
    if(this.state.reportType === configJSON.returnReportText){
      typeOfReport = configJSON.returnReportTypeText
    }
    if(this.state.reportType === configJSON.salesTrafficReportText){
      typeOfReport = configJSON.salesTrafficReportType
    }
    const body = {
      "start_date": this.state.formattedFromDate,
      "report_type": typeOfReport,
      "export_csv": this.state.exportCSV,
      "end_date": this.state.formattedToDate,
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCsvReportsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.reportsApiEndpoint + `?per_page=10&page=${this.state.pageNumber}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReportsRoundedCount = () => {
    const count: number = this.state.totalCount / 10;
    const roundedReportCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedReportCount
  }
  // Customizable Area End
}
