import { ChangeEvent } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("../src/config.js");
// Customizable Area Start
interface DocumentsData {
  id: any,
  type: string,
  attributes: {
    id: any,
    deal_name: string,
    deal_code: string,
    start_date: string
    end_date: string,
    status: string,
    created_at: string,
    updated_at: string
  }

}
interface SuccessResponse {
  data: DocumentsData[];
  total_count:number,
  meta: {
    message: string;
  };
  error:any
}
import { successImg } from "./assets";
import { toast } from "react-toastify";
export const successIcon = successImg;
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    autoCompletePopover: string;
    autoComplete: string;
    submitButton: string;
    paginateClass: string;
    search: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activePanel: any;
  activeStep: string;
  lastUpdatedDealsId: string;
  addMore: boolean,
  openDealDeleteModal: boolean,
  dealsList: Array<Object>;
  dealsWithSKUList: Array<Object>;
  loading: boolean;
  loadingSKU: boolean;
  token: string,
  sellerId: string,
  options: any[];
  selectedOption: any;
  searchTerm: string;
  selectedDealId: string;
  deleteSellerDealsId: string;
  selectedDealsWithSKUData:any,
  skuName: any[];
  page: number,
  tabValue: number;
  filterByValue: string[],
  totalCount: number,
  isOfferApplied: boolean,
  offerAppliedModal: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ProductDealsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllDealsListId: string = "";
  getSellerDealsId: string = "";
  postCreateDealId: string = "";
  updateDealPriceId: string = "";
  getSKUdataId: string = "";
  deleteSellerdealId: string = "";
  getProductInfoApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.ReceiveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)];

    this.state = {
      activePanel: "allDeals",
      activeStep: "dealsTable",
      lastUpdatedDealsId: "",
      addMore: false,
      openDealDeleteModal: false,
      loading: false,
      loadingSKU: false,
      token: "",
      sellerId: "",
      selectedDealId: "",
      skuName: [],
      options: [],
      selectedOption: [],
      deleteSellerDealsId: '',
      selectedDealsWithSKUData: null,
      searchTerm: '',
      dealsList: [],
      dealsWithSKUList: [],
      filterByValue: [],
      totalCount: 0,
      tabValue: 0,
      page: 1,
      offerAppliedModal: false,
      isOfferApplied: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (this.getAllDealsListId === apiRequestCallId) {
        this.handleAllDealslistApiCallResponse(responseJson, errorJson);
      }
      if (this.getSKUdataId === apiRequestCallId) {
        this.handleSKUlistApiCallResponse(responseJson, errorJson);
      }
      if (this.postCreateDealId === apiRequestCallId) {
        this.handleCreateSellerApiCallResponse(responseJson, errorJson);
      }
      if (this.getSellerDealsId === apiRequestCallId) {
        this.handleSellerDealsApiCallResponse(responseJson, errorJson);
      }
      if (this.deleteSellerdealId === apiRequestCallId) {
        this.handleDeleteSellerDealsApiCallResponse(responseJson, errorJson);
      }
      if (this.updateDealPriceId === apiRequestCallId) {
        this.handleDealPriceUpdateApiCallResponse(responseJson, errorJson);
      }
      if (this.getProductInfoApiCallId === apiRequestCallId) {
        this.handleProductInfo(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handlePanel(value: any) {
    this.setState({ activePanel: value ,lastUpdatedDealsId:"" ,page:1});
    this.setState({ activeStep: "dealsTable" });
    this.setState({ skuName: [], selectedOption: [] });
    this.handleAllDealsListApiCall(1);
  }

  handleProductInfo = async (responseJson: any) => {
    if (responseJson.data) {
      const offerId = responseJson.data.attributes?.catalogue_offer?.id;
      const offerStatus = responseJson.data.attributes?.catalogue_offer?.status;
      if(offerId && offerStatus) {
        this.setState({ isOfferApplied: true });
      }
    }
  };

  getRoundedCount = (totalCount:number) => {
    const count: number = totalCount / 10;
    const roundedCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedCount
  }

  handleStep(step: string) {
    this.setState({ activeStep: step ,page:1});
  }

  handleCloseSuccesModal=()=>{
    this.setState({ activePanel: "myDeals" });
    this.setState({ activeStep: "dealsTable" });
  }

  handleFinalSubmit() {
    this.setState({ activeStep: "successModal",lastUpdatedDealsId:this.state.selectedDealId });
    setTimeout(() => {
     this.handleCloseSuccesModal()
    }, 3000);
  }

  handleAddMoreDeal = () => {
    this.setState({ addMore: true });
    this.setState({ activeStep: "selectSKU" ,page:1});
    this.setState({ skuName: [], selectedOption: [] });
  }

  handleCloseDealsDeleteModal = () => {
    this.setState({ openDealDeleteModal: false });
  }

  handleOfferAppliedModal = () => {
    this.setState({ offerAppliedModal: false });
  }

  handleOpenDealsDeleteModal = (id: any) => {
    this.setState({ openDealDeleteModal: true, deleteSellerDealsId: id });
  }

  handleOpenEditModal = (data:any) => {
    this.setState({ activeStep: "editDealModal",selectedDealsWithSKUData: data });
  }

  handleCloseEditModal = () => {
    this.setState({ activeStep: "selectedDealsList",selectedDealsWithSKUData: null });
  }

  handleSelectDealToListProduct = (id: any) => {
    this.setState({ selectedDealId: id });
    this.handleStep("selectSKU")
  }

  async componentDidMount(): Promise<void> {
    await this.getToken();
    const newProductId = await getStorageData("newProductId");
    const catalogId = await getStorageData("catalog_id");
    this.getProductInformation(catalogId, newProductId);
    await this.handleAllDealsListApiCall(1);
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    const sellerId = await getStorageData("seller_id");
    this.setState({ token,sellerId })
  }

  handleAllDealslistApiCallResponse = (responseJson: SuccessResponse, errorJson: any) => {
    this.setState({ loading: false })
    if (responseJson) {
      this.setState({ dealsList: responseJson.data ,totalCount:this.getRoundedCount(responseJson.total_count)});

    } else {
      this.setState({ dealsList: [] })
      this.parseApiCatchErrorResponse(errorJson);
    }
  };

  handleSellerDealsApiCallResponse = (responseJson: SuccessResponse, errorJson: any) => {
    this.setState({ loading: false })
    if (responseJson) {
      this.setState({ dealsWithSKUList: responseJson.data ,totalCount:this.getRoundedCount(responseJson.total_count)});

    } else {
      this.setState({ dealsWithSKUList: [] })
      this.parseApiCatchErrorResponse(errorJson);
    }
  };

  handleDeleteSellerDealsApiCallResponse = (responseJson: SuccessResponse, errorJson: any) => {
    this.setState({ loading: false });
    this.handleCloseDealsDeleteModal()
    if (responseJson) {
      this.handleSellerDealsListApiCall();
      toast.success("Deal deleted successfully!", {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }
    if ("errors" in responseJson || errorJson) {
      toast.error("Failed to delete deal. Please try again later!", {
        autoClose: 3000,
        draggable: true,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  handleDealPriceUpdateApiCallResponse = (responseJson: SuccessResponse, errorJson: any) => {
    this.setState({ loading: false });
    if (responseJson) {
      toast.success("Deals Price updated successfully!", {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.handleSellerDealsListApiCall();
      this.handleCloseEditModal();
    }
    if ("errors" in responseJson || errorJson) {
      toast.error("Failed to update deal price. Please try again later!", {
        autoClose: 3000,
        draggable: true,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  handleSKUlistApiCallResponse = (responseJson: SuccessResponse, errorJson: any) => {
    this.setState({ loadingSKU: false })
    if (responseJson && responseJson?.data && Array.isArray(responseJson?.data)) {
      const filteredOptions = responseJson?.data.filter(
        (option) => !this.state.selectedOption.find((selected: any) => selected.id === option.id)
      );
      filteredOptions.forEach((item:any) => {
        if(item.attributes.is_variant) {
          item.attributes["sku"] = item?.attributes?.sku + '  >  ' + item?.attributes?.variant_product_group?.product_sku
        }
        return item
      })
      this.setState({ options: filteredOptions });
    } else {
      this.setState({ options: [] })
    }
  };

  handleCreateSellerApiCallResponse = (responseJson: SuccessResponse, errorJson: any) => {
    this.setState({ loading: false });
    if ("data" in responseJson) {
      toast.success("Deal added successfully!", {
        progress: undefined,
        autoClose: 3000, // milliseconds
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: true,
      });
      this.setState({ skuName: [], selectedOption: [], activeStep: "selectedDealsList" });
      this.handleSellerDealsListApiCall();
    }  
    if ("error" in responseJson || errorJson)  {
      toast.error("Failed to add deals. Please try again later!", {
        draggable: true,
        progress: undefined,
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        position: "top-right",
      });
      this.parseApiCatchErrorResponse(errorJson);
    }
  };

  handleAllDealsListApiCall = async (page=1) => {
    this.setState({ loading: true })
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllDealsListId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerAllDeals.list + `?per_page=10&page=${page}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSellerDealsListApiCall = async (page=1) => {
    this.setState({ loading: true })
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSellerDealsId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerAllDeals.listSellerDeals(this.state.selectedDealId) + `?per_page=10&page=${page}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createSellerDealsListApiCall = async (values: any) => {
      this.setState({ loading: true })
      const headers = {
        'Content-Type': 'application/json',
        token: this.state.token
      };
      
      const data = {
        "seller_deals": values?.skuName?.map((item:any) => {
          return {
            "catalogue_id": item.id,
            "seller_id": this.state.sellerId
          }
        }),
      }

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.postCreateDealId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerAllDeals.createDeals(this.state.selectedDealId));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateSellerDealPriceApiCall = async (value: any) => {
    this.setState({ loading: true })
    const headers = {
      'Content-Type': 'application/json',
      token: this.state.token
    };
    const data = { 
        "data": {
            "attributes": { 
                "deal_price": value?.dealsValue
            }
        }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateDealPriceId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerAllDeals.updateDeals(this.state.selectedDealId,this.state.selectedDealsWithSKUData?.id));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePatch);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteSellerDeals = async () => {
    this.setState({ loading: true })
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteSellerdealId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerAllDeals.deleteSellerDeal(this.state.selectedDealId, this.state.deleteSellerDealsId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeDelete);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //select sku logic start

  fetchSKUData = (searchTerm: any) => {
    this.setState({ loadingSKU: true })
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSKUdataId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sellerAllDeals.listSKU(searchTerm));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInputChange = (event: ChangeEvent<{}>, newInputValue: string) => {
    this.setState({ searchTerm: newInputValue });
    if (newInputValue) {
      this.fetchSKUData(newInputValue);
    } else {
      this.fetchSKUData("");
    }
  };

  handleOptionChange = (newValue: any | null) => {
    this.setState({ selectedOption: newValue });
  };

  getProductInformation = (catalogId: any, newProductId: any) => {
    if (catalogId) {
      this.getProductInfo(catalogId);
    } else this.getProductInfo(newProductId);
  };

  getProductInfo = (id: string) => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //select sku logic end
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
