import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider, styled } from "@material-ui/core/styles";
import { Box, Container, Grid, Button, Divider } from "@material-ui/core";

// Customizable Area End
//Customizable Area Start
import FeaturesLandingpageController, {Props} from "../../email-account-registration/src/FeaturesLandingpageController.web";
import DOMPurify from "dompurify";
import { objectsImg } from "./EmailAccountRegistrationController";
import SellerLandingNavbar from "./SellerLandingNavbar.web";
import SellerFooter from "./SellerFooter.web";
import { Loader2 } from "../../../components/src/Loader.web";
//Customizable Area End 

class Features extends FeaturesLandingpageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
       <SellerLandingNavbar navigation={this.props.navigation} id={this.props.navigation} />
        <ThemeProvider theme={theme}>
          <Box id="featureBox" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <StyledImgBox>
              <img src={objectsImg} width={"90%"} />
            </StyledImgBox>
            <Container style={{ position: "relative", textAlign: "start", maxWidth: "96vw", paddingLeft: "50px", marginLeft: 0  }}>
            {this.state.loading &&
                <Box sx={{
                  top: " 20%",
                  left: " 50%",
                  width: " 5%",
                  position: "relative",
                  marginBottom: " 100px",
                  height: " 50vh",
                }} id="loaderBox">
                  <Loader2 loading={this.state.loading} />
                </Box>
              }
            {this.state.featuresContent && 
            <>
              <ContentGrid container>
              <Grid item lg={12} sm={12} xs={12}>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.featuresContent.content)}} data-test-id="featuresContent">
              </div>
              </Grid>
              </ContentGrid>
            <Button data-test-id="partner" style={{...webStyle.becomePartner}} 
              onClick={this.handleBecomeAPartner}>
              {"Become a partner/seller"}
            </Button>
            </>
            } 
            </Container>
          </Box>
          <Divider />
          <SellerFooter navigation={this.props.navigation} id={this.props.id} />
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledImgBox = styled(Box)({
  position: "absolute" as const, 
    top: 176, 
    left: 25, 
    zIndex: 0 ,
    '@media (max-width:600px)' : {
      top: 260
    }
})

const ContentGrid = styled(Grid)({ 
    marginLeft: "7px",
    marginTop: 160 ,
    '@media (max-width:780px)' : {
      marginTop: 240
    }
})

export default Features
const webStyle = {
  becomePartner: {
    margin:'10px 0 50px',
    padding: "10px 22px",
    borderRadius: "6px",
    background: "rgba(253, 97, 12, 1)",
    fontSize: 16,
    fontWeight: 600,
    color: "white",
    fontFamily: `"Lato",  sans-serif`,
    height: "max-content",
    width: "max-content", 
    textTransform: "none" as const,
    cursor: "pointer" 
  }
};
// Customizable Area End
