// Customizable Area Start
import DatePicker from "react-datepicker";
import { Box, FormControlLabel, Grid, InputAdornment, Modal, Typography, styled } from "@material-ui/core";
import React, { forwardRef } from "react";
import {FormControlTwo, CustomizeBrandInput, DatePickerButton, IOSSwitch, InputOne, CustomSubmitReuploadedButton } from "../CustomizedElementStyle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { DateTime } from "luxon";
// Customizable Area End

import OffersController, { Props } from "./OffersController";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";

export default class Offers extends OffersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  returnErrorMessage = (touched: any, errors: any) => {
    if (touched && errors) {
      return <Typography style={webStyle.errorStyle}>{errors}</Typography>;
    } else {
      return null;
    }
  };

  validationSchema = Yup.object().shape({
    price_info: Yup.number()
      .nullable()
      .transform((value,original) => original === '' ? null : value)
      .min(0, "Invalid price")
      .typeError("Invalid price")
      .max(10000000, "Invalid price"),
    sale_price: Yup.number()
      .nullable()
      .transform((value,original) => original === '' ? null : value)
      .min(0, "Invalid price")
      .typeError("Invalid price")
      .max(10000000, "Invalid price")
      .test('all-or-none', 'This field is required', function (value) {
        const { sale_schedule_from, sale_schedule_to } = this.parent;
        if (sale_schedule_from || sale_schedule_to) {
          return value ;
        }
        return true;
  }),
    bar_code_info: Yup.string().max(50, "Barcode must not exceed 50 characters"),
    sale_schedule_from: Yup.date()
      .nullable()
      .test('all-or-none', 'This field is required', function (value) {
        const { sale_price, sale_schedule_to } = this.parent;
        if (sale_price || sale_schedule_to) {
          return value;
        }
        return true;
      }),

    sale_schedule_to: Yup.date()
      .nullable()
      .min(Yup.ref('sale_schedule_from'), 'Sale schedule "to" date must be greater than "from" date!')
      .test('all-or-none', 'This field is required', function (value) {
        const { sale_price, sale_schedule_from } = this.parent;
        if (sale_price || sale_schedule_from) {
          return value;
        }
        return true;
      })
  }); 

  dealAppliedModal = () => {
    return <Modal open={this.state.offerErrorModal} data-test-id='deleteModalRef'>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center ", height: "100vh" }} style={{ background: "rgba(0,0,0,0.1)" }}>
            <StyledOfferModalBox>
                <Box sx={{ textAlign: "center", fontFamily: `"Lato", sans-serif`, marginBottom: "20px" }}>{this.state.offerErrorText}</Box>
                <Box style={{ justifyContent: "center", gap: '15px', width: "100%", display: "flex" }}>
                    <CustomSubmitReuploadedButton data-test-id="backOfferRef" onClick={this.handleDealAppliedModal}>
                        Ok
                    </CustomSubmitReuploadedButton>
                </Box>
            </StyledOfferModalBox>
        </Box>
    </Modal>
}


  // Customizable Area End

  // Customizable Area Start
  render() {
    const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref) => (
      <DatePickerButton data-test-id="datepicker" onClick={onClick}>
        <Box>{value ? value : "DD/MM/YYYY"}</Box>
        <ExpandMoreIcon style={{ color: "#6C6C6C" }} />
      </DatePickerButton>
    ));
    return (
      <>
        <ToastContainer />
        {this.dealAppliedModal()}
        <Box style={{ marginTop: 50, padding: "30px" }}>
          <Formik
            initialValues={{
              price_info: this.state.price_info,
              sale_price: this.state.sale_price,
              bar_code_info: this.state.bar_code_info,
              sale_schedule_from: this.state.sale_schedule_from,
              sale_schedule_to: this.state.sale_schedule_to,
              statusSwitch: this.state.product_status
            }}
            enableReinitialize={true}
            validationSchema={this.validationSchema}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={this.createOffer}
            data-test-id="formik"
          >
            {({ handleChange, errors, setFieldTouched, touched, setFieldValue, handleBlur, values, setErrors }) => (
              <Form
                translate={undefined}
                ref={this.formRef}
              >
                <Grid container spacing={4}>
                  <Grid item lg={6} sm={12} xs={12}>
                    <FormControlTwo fullWidth={true}>
                      <CustomizeBrandInput
                        style={{ marginTop: 25 }}
                        type="text"
                        name="price_info"
                        data-test-id="priceInfo"
                        label="Price Info (AED)"
                        placeholder="Enter price info"
                        value={parseFloat(this.state.price_info.toString())}
                        id="outlined-start-adornment"
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start" style={{ marginRight: 15 }}></InputAdornment>
                        }}
                        onChange={e => {
                          handleChange(e);
                          setFieldTouched("price_info", true, false);
                        }}
                        onBlur={handleBlur}
                        disabled={true}
                      />
                      {this.returnErrorMessage(touched.price_info, errors.price_info)}
                    </FormControlTwo>
                  </Grid>
                  <Grid item lg={6} sm={12} xs={12}>
                    <CustomizeBrandInput
                      type="text"
                      style={{ marginTop: 25 }}
                      data-test-id="salePrice"
                      name="sale_price"
                      label="Sale Price (AED)"
                      placeholder="Enter sale price"
                      value={values.sale_price ? parseFloat(values.sale_price.toString()) : ''}
                      id="outlined-start-adornment"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start" style={{ marginRight: 5 }}></InputAdornment>
                      }}
                      onChange={e => {
                        handleChange(e);
                        setFieldTouched("sale_price", true, false);
                      }}
                      onBlur={handleBlur}
                      onKeyDown={(event:React.KeyboardEvent<HTMLDivElement>) => {
                        if (!/^\d$/.test(event.key) && event.key !== 'Backspace') {
                          event.preventDefault();
                        }
                      }}
                    />
                    {this.returnErrorMessage(touched.sale_price, errors.sale_price)}
                  </Grid>
                  <Grid item lg={6} sm={12} xs={12}>
                    <CustomizeBrandInput
                      data-test-id="barcodeInfo"
                      name="bar_code_info"
                      style={{ marginTop: 25 }}
                      label="Barcode Info"
                      value={values.bar_code_info}
                      id="outlined-start-adornment"
                      disabled
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start" style={{ marginRight: 5 }}></InputAdornment>
                      }}
                      onChange={e => {
                        handleChange(e);
                        setFieldTouched("bar_code_info", true, false);
                      }}
                      onBlur={handleBlur}
                    />
                    {this.returnErrorMessage(touched.bar_code_info, errors.bar_code_info)}
                  </Grid>
                  <Grid item lg={3} sm={12} xs={12}>
                    <Box style={{ marginTop: 4, marginBottom: 7, color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontSize: 12, fontWeight: 600 }}>{"Sale Schedule (From)"}</Box>
                    <DatePicker
                      minDate={new Date()}
                      name="sale_schedule_from"
                      selected={values.sale_schedule_from}
                      data-test-id="changeFrom"
                      onChange={date => {
                        this.dateFormat("saleFromDate", DateTime.fromJSDate(date as Date).toISODate())
                        setFieldValue("sale_schedule_from", date);
                        setFieldTouched("sale_schedule_from", true, false);
                      }}
                      onBlur={handleBlur}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                      className="date-input"
                      customInput={<ExampleCustomInput />}
                    />

                    {this.returnErrorMessage(touched.sale_schedule_from, errors.sale_schedule_from)}
                  </Grid>
                  <Grid item lg={3} sm={12} xs={12}>
                    <Box style={{ marginTop: 4, marginBottom: 7, color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontSize: 12, fontWeight: 600 }}>{"Sale Schedule (To)"}</Box>

                    <DatePicker
                      minDate={new Date()}
                      name="sale_schedule_to"
                      data-test-id="changeTo"
                      selected={values.sale_schedule_to}
                      onChange={date => {
                        this.dateFormat("saleToDate", DateTime.fromJSDate(date as Date).toISODate())
                        setFieldValue("sale_schedule_to", date);
                        setFieldTouched("sale_schedule_to", true, false);
                      }}
                      onBlur={handleBlur}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                      className="date-input"
                      customInput={<ExampleCustomInput />}
                    />

                    {this.returnErrorMessage(touched.sale_schedule_to, errors.sale_schedule_to)}
                  </Grid>
                  <Grid item lg={6} sm={12} xs={12}>
                    <StyledStatusBox>
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontWeight: 600, fontSize: 12, marginBottom: 10 }}>{"Status"}</Box>
                        <Box style={{ fontSize: 14, fontFamily: '"Lato", sans-serif', fontWeight: 500, paddingLeft: 0 }}>{"Select Active / Inactive"}</Box>
                      </Box>
                      <FormControlLabel
                        data-test-id="statusSwitch"
                        name="statusSwitch"
                        style={{ marginRight: "-8px" }}
                        control={
                          <IOSSwitch
                            checked={values.statusSwitch}
                            onChange={(e: any) => {
                              setFieldValue("statusSwitch", e.target.checked);
                              setFieldTouched("statusSwitch", true, false);
                            }}
                          />
                        }
                        label={
                          <Box sx={{ position: "relative" }}>
                            <Box sx={values.statusSwitch ? webStyle.active : webStyle.inactive}>{values.statusSwitch ? "Active" : "Inactive"}</Box>
                          </Box>
                        }
                        onBlur={handleBlur}
                      />
                    </StyledStatusBox>
                  </Grid>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <StyledSaveBox sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <CustomOrangeButton data-test-id="submit" type="submit" style={{}}>
                      Save Changes
                    </CustomOrangeButton>
                  </StyledSaveBox>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  active: {
    fontSize: 12,
    fontWeight: 600,
    position: "absolute",
    left: -86,
    bottom: -10,
    color: "white"
  },
  inactive: {
    fontSize: 12,
    fontWeight: 600,
    position: "absolute",
    left: -70,
    bottom: -10,
    color: "white"
  },
  errorStyle: {
    fontFamily: "Lato, sans-serif",
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px"
  }
};

const StyledOfferModalBox = styled(Box)({
  display: "flex", 
  flexDirection: "column", 
  justifyContent: "center",
  width: "350px", 
  height: "auto", 
  borderRadius: "8px", 
  padding: "40px 20px",
  background: "white",
  '@media (max-width: 540px)': {
    width:'300px'
  }
})

const StyledStatusBox = styled(Box)({
  width: "100%", 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  flexDirection: "row", 
  flexWrap: "nowrap",
  '@media (max-width: 600px)': {
    marginBottom: '20px'
  }
})

const StyledSaveBox = styled(Box)({
  display: "flex", 
  justifyContent: "flex-end", 
  width: "100%",
  '@media (max-width: 600px)': {
    justifyContent: 'center'
  }
})
// Customizable Area End
