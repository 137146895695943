// Customizable Area Start
import React from "react";
import HeaderController, { ICategoryList, ITopBrandData, ITopMostPopularData, Props, configJSON } from "./HeaderController.web";
import { Box, Button, ClickAwayListener, Divider, Grid, Link, Typography, styled, withStyles } from "@material-ui/core";
import { fireImage, locationIcon, blueLogo } from "./assets";
import { BoxWithHoverStyle } from "../../dashboard/src/CustomizedElementStyle";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import BuyerEmailLogin from "../../email-account-login/src/BuyerEmailLogin.web";
import BuyerSignup from "../../email-account-registration/src/BuyerSignup.web";
import { ClickableBox } from "../../otp-input-confirmation/src/CustomElementStyling";
import ForgotPassword from "../../forgot-password/src/SellerForgotPassword.web";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Search from "../../search/src/Search.web";
import { userImg } from "../../dashboard/src/assets";
// Customizable Area End

class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  headerAccountPopup=()=>{
    return (
      <>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <ClickableBox style={{ width: 38, height: 38, borderRadius: 100, position: "relative" }}>
            <Box data-test-id="openProfileMenu" className={this.props.classes.signInBox} onClick={this.handleOpenProfileMenu}>
              <Box>
                <Typography className={this.props.classes.welcomeText}>{configJSON.welcomeHeader}</Typography>
              </Box>
              <Box className={this.props.classes.buyerLastName} data-test-id="buyerName">
                {" "}
                {this.state.buyer_lastname
                  .split(" ")
                  .map((name, index) => (index === 0 ? name : name.charAt(0) + "."))
                  .join(" ")}
              </Box>
            </Box>
            {this.state.openProfileMenu && (
              <Box style={{ position: "absolute", width: "228px", height: "124px", bottom: -144, right: 4, boxShadow: "0px 5px 18px rgba(0, 0, 0, 0.174425)", borderRadius: "5px", background: "white", display: "flex", flexDirection: "column", justifyContent: "center", zIndex: 999 }}>
                  <BoxWithHoverStyle onClick={this.handleMyAccount} data-test-id="myAccountRef">My Account</BoxWithHoverStyle>
                <BoxWithHoverStyle data-test-id="signOutRef" onClick={() => this.handleSignOut()}>
                  Sign Out
                </BoxWithHoverStyle>
              </Box>
            )}
          </ClickableBox>
        </ClickAwayListener>
      </>
    );
  }

  renderTopBanner = () => {
    const {classes} = this.props;
    return(
      <Box className={classes.topBanner}>
              <img src={fireImage} className={classes.fireIcon} alt="fire_icon" />
              <Typography className={classes.bannerTitle}>{this.state.topBanner.data && this.state.topBanner.data.attributes.title}</Typography>
              <Link href={this.state.topBanner.data && this.state.topBanner.data.attributes.button_link} target="_self">
                <CustomShopButton>{this.state.topBanner.data && this.state.topBanner.data.attributes.button_text}</CustomShopButton>
              </Link>
          </Box>
    )
  }

  render() {
    // Customizable Area Start
    const { classes, match } = this.props;
    const {topBrand:TopBrands, mostPopular: mostPoppular} = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <BuyerEmailLogin navigation={this.props.navigation} id={"buyer-email-login"} />
        <BuyerSignup navigation={this.props.navigation} id={"buyer-email-signup"} />
        <ForgotPassword navigation={this.props.navigation} id={"forgot-password"} />
        <Box>
          {this.renderTopBanner()}
          <Box className={classes.navigationBar}>
            <Grid container style={{ padding: "0 30px" }}>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <Grid container style={{ display: "flex", alignItems: "center" }}>
                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                    <img data-test-id="byezzyLogo" onClick={this.handleLogo} src={blueLogo} className={classes.logoStyle} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7} lg={10} xl={10} className={classes.searchBox} style={{}}>
                    <Search navigation={this.props.navigation} id={""} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.navRight}>
                <Box className={classes.headerPartText} style={{}}>
                  <Box style={{ padding: "0px 10px" }}>
                    <Box style={{ fontSize: 14, fontWeight: 400, color: "#000", fontFamily: `"Lato", sans-serif` ,lineHeight:1}}>Language</Box>
                    <Box style={{ fontSize: 14, fontWeight: 400, color: "#000", fontFamily: `"Lato", sans-serif` }}>English</Box>
                  </Box>
                  {this.state.token ? (
                    <Box style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", padding: "0px 10px", alignItems: "center" }}>
                      <FavoriteBorderIcon style={{ color: "#000", paddingRight: 10, fontSize: 18 }} />
                      <Box data-test-id="wishlist" onClick={this.handleWishlist} style={{ cursor:'pointer', textDecoration: 'none', fontSize: 14, fontWeight: 400, color: "#000", fontFamily: `"Lato", sans-serif` }}>
                        <Box>Wishlist</Box>
                        <Box>My Items</Box>
                      </Box>
                    </Box>
                  ): ''} 
                  <Box style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", padding: "0px 10px", alignItems: "center" }}>
                    <Box style={{ padding: 1, paddingRight: 10 }}>
                      <img src={userImg} style={{objectFit:"contain" ,width: 24,marginRight: '-6px'}} />
                    </Box>
                    {!this.state.token ? (
                      <ClickableBox data-test-id="openLoginModal" style={{ fontSize: 16, lineHeight:1, fontWeight: 400, color: "#000", fontFamily: `"Lato", sans-serif`, cursor: "pointer" }} onClick={() => this.handleOpenLoginModal()}>
                        Sign In
                      </ClickableBox>
                    ): ''}
                    {this.state.token && this.headerAccountPopup()
}
                  </Box>
                  <Box className={classes.cartWrapper}>
                    <Box>
                    <ShoppingCartOutlinedIcon onClick={this.handleShoppingCart} data-test-id="ShoppingCartOutlinedIconRef" style={{ color: "#000", fontSize: 18, cursor: "pointer" }} />
                    </Box>
                    {this.state.count &&
                    <Box className={classes.badge}>
                      {this.state.count}
                    </Box>
                    }
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={1} data-test-id="header" className={classes.categoriesbar}>
          {this.state.address &&
            <Grid item xs={12} sm={3} md={3} lg={3} className={classes.location}>
              <img src={locationIcon} alt="locationIcon" style={{ marginRight: "8px" ,width:"16px"}} />
              <Typography data-test-id="address" className={classes.address}>{this.state.address}</Typography>
            </Grid>
          }
            <Box className={classes.navMenu}>
              <div data-test-id="mouseLeave" className={classes.categoriesContainer} onMouseLeave={this.handleMouseLeave}>
                <Typography className={classes.navTextAllCategory} data-test-id="categoriesContainerRef"  onMouseEnter={() => this.handleMouseEnter(0)} style={(this.state.isHovered === 0) ? {
                  color: "#f34f26", margin: "auto", whiteSpace: "nowrap",
                  textDecorationColor: "#f34f26",
                } : {
                  color: "#fff", margin: "auto", whiteSpace: "nowrap",
                  textDecorationColor: "#fff",
                }}>
                  {configJSON.allCategoriesText}
                  <span className={classes.dropDown}>{this.state.isHovered === 0 ?  <ArrowDropDownIcon style={{ color: "#f34f26" }} /> : <ArrowDropDownIcon style={{ color: "#fff" }} /> }</span>
                </Typography>
                <Box className={this.state.headerCategories.length ? classes.navDropdownWrraper : classes.navDropdownWrraperItem} style={{ display: this.state.isHovered === 0 ? "flex" : "none" }}>
                  <div style={{ height: '21px', width: '100%', background: "rgba(23,34,54,255)" }}></div>
                  <div className={classes.dropdown} >
                    {this.state.catagoryList &&
                      this.state.catagoryList.map((item: ICategoryList, index: number) => (
                        <div key={index} className={classes.categoriesItem}>
                            <Box style={{ textDecoration: 'none' }}>
                            <Typography onClick={() => this.handleCategory(item.id as string, item.attributes.name)} className={`${classes.navTextList} ${classes.categoryNavText}`} key={`catagory-${index}`} style={this.getColor(item.attributes.name)}>
                              {item.attributes.name}
                            </Typography>
                          </Box>
                        </div>
                      ))}
                  </div>
                </Box>
              </div>
              {this.state.headerCategories && Array.isArray(this.state.headerCategories) && 
                this.state.headerCategories.map((item: any, index: number) => {
                  return (
                    <Box className={classes.staticCategoriesContainer} onMouseLeave={this.handleMouseLeave} key={`${index} + "staticContainer"`}>
                        <Typography onClick={() => this.handleCategory(item.attributes.category_id as string, item.attributes.category.name)} className={`${classes.navText} ${this.getColorMouse(item)}`} key={`catagory-${index}`}  data-test-id="staticCategoriesRef"  onMouseEnter={() => this.handleMouseEnter(item.id)}>
                          {item.attributes.category.name}
                          <span className={classes.dropDown}>{this.isConditionalRending(this.state.isHovered === item.id , <ArrowDropDownIcon /> , <ArrowDropUpIcon style={{ color: "rgba(23,34,54,255)" }} />)}</span>
                        </Typography>
                      <Box className={classes.staticDropdownWrraper} style={{ display: this.state.isHovered === item.id ? "flex" : "none" }}>
                        <div style={{ height: '21px', width: '100%', background: "rgba(23,34,54,255)" }}></div>
                        <Box className={classes.staticDropdown}>
                          <Box className={classes.staticItems}>
                            <Typography style={{ fontWeight: 700, fontSize: '20px', color: "#292929", fontFamily: `"Lato", sans-serif` }}>
                              Most Popular
                            </Typography>
                            {mostPoppular.length > 0 && mostPoppular.map((popularItem: ITopMostPopularData, index: number) => (
                              <Typography className={classes.categoryNavText} data-test-id="mostPoppularRef" style={{ fontSize: '14px',cursor:'pointer', fontFamily: `"Lato", sans-serif` }} key={`popularItem-${index}`} onClick={() => this.handleCategory(popularItem.attributes.category.id , popularItem.attributes.category.name)}>
                                {popularItem.attributes.category.name}
                              </Typography>
                            ))}
                          </Box>
                          <Divider orientation="vertical" flexItem className={classes.verticalMargin} />
                          <Box className={classes.staticItems}>
                            <Typography style={{ fontWeight: 700, fontSize: '20px', color: "#292929", fontFamily: `"Lato", sans-serif` }}>
                              Top Brands
                            </Typography>
                            {TopBrands && TopBrands.length > 0 && TopBrands.map((BrandItem: ITopBrandData, index: number) => (
                              <Typography className={classes.categoryNavText} data-test-id="TopBrandsRef" style={{ fontSize: '14px',cursor:'pointer', fontFamily: `"Lato", sans-serif` }} key={`BrandItem-${index}`} onClick={() => this.handleBrandPageView(BrandItem.attributes.brand_id)}>
                                {BrandItem.attributes?.brand?.brand_name}
                              </Typography>
                            ))}
                          </Box>
                          <Box className={classes.staticItems} style={{ flex: 3, justifyContent: 'center' }}>
                              <img onClick={() => this.handleCategory(item.id as string, item.attributes.name)} data-test-id="logo" src={this.getCategoryImage(item)} className={`${classes.header_image} ${classes.header_image_container}`} style={{ cursor: "pointer", mixBlendMode: "multiply", paddingBottom: 2, display: "flex", alignItems: "flex-start", height: '100%', width: '100%', maxHeight: "200px", minHeight:'200px' }} />
                            </Box>
                        </Box>
                      </Box>

                    </Box>
                  );
                })}
            </Box>
          </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = {
    categoriesbar: {
      width: "100%",
      background: "rgba(23,34,54,255)",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row" as const,
      position: 'relative' as const,
      padding: '17px',
      boxSizing: 'border-box' as const,
      "@media (max-width: 600px)": {
        height: "auto",
        flexDirection: "column" as const,
        padding: '14px'
      }
    },
    location: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "0 0 0 12px",
      "@media (max-width: 600px)": {
        padding: "20px",
        justifyContent: "center",
        flex: 1,
      }
    },
    navMenu: {
      display: "flex",
      flex: 3,
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "7px",
      flexDirection: "row" as const,
      flexWrap: 'wrap' as const,
      "@media (max-width: 460px)": {
        flexDirection: "column" as const,
        flex: 1,
      },
      "@media (max-width: 600px)": {
        flexDirection: "row" as const,
        flex: 1,
        justifyContent: "space-around",
      }
    },
    navText: {
      fontFamily: "Lato, sans-serif",
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      fontWeight: 700 as const,
      whiteSpace: "nowrap" as const,
      margin: "0 8px",
      textTransform: "capitalize" as const,
      cursor: "pointer",
      borderBottom: "1px solid #172236",
      textDecoration: "underline" as const,
      textDecorationColor: "#f34f26",
      textDecorationThickness: "2px",
      textUnderlineOffset: "2px",
      "&:hover": {
        color: "#f34f26",
      }
    },
    navTextList: {
      fontFamily: "Lato, sans-serif",
      alignItems: "center",
      borderBottom: "1px solid #fff",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      fontSize: "14px",
      fontWeight: 700 as const,
      whiteSpace: "normal" as const,
      textTransform: "capitalize" as const,
      cursor: "pointer",
      margin: "0 8px",
      "&:hover": {
        color: "#f34f26",
        textDecoration: "underline" as const,
        textDecorationColor: "#f34f26",
        textDecorationThickness: "2px",
        textUnderlineOffset: "2px",
      }
    },
    navTextAllCategory: {
      fontFamily: "Lato, sans-serif",
      fontWeight: 700 as const,
      alignItems: "center",
      color: "#fff",
      whiteSpace: "normal" as const,
      justifyContent: "center",
      fontSize: "14px",
      textTransform: "capitalize" as const,
      cursor: "pointer",
      margin: "0 8px",
      display: "flex",
      textDecoration: "underline" as const,
      textDecorationColor: " #fff",
      textDecorationThickness: "2px",
      textUnderlineOffset: "2px",
      "&:hover": {
        color: "#f34f26",
        textDecorationColor: "#f34f26"
      }
    },
    dropDown: {
      marginLeft: "0"
    },
    navigationBar: {
      width: "100%",
      minHeight: "70px",
      background: "#faf9f6"
    },
    categoriesContainer: {
      display: "inline-block"
    },
    staticCategoriesContainer: {
      display: "inline-block",
    },
    dropdownWrraper: {
      position: "absolute" as const,
      left: "-3px",
      zIndex: 999,
      background: "#fff",
      top: "20px",
      flexDirection: "row" as const,
      flexWrap: "wrap" as const,
      borderRadius: "2px",
      justifyContent: "space-between",
      width: "50vw",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      "@media (max-width: 600px)": {
        width: "100vw",
      }
    },
    dropdown: {
      background: "#fff",
      padding: "20px",
      gap: "2px",
      justifyContent: "space-between",
      width: '100%',
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      "@media (max-width: 600px)": {
        gridTemplateColumns: "1fr",
        height: '300px',
        overflow: 'scroll' as const,
        flexWrap: "nowrap" as const,
      }
    },
    navDropdownWrraper: {
      position: "absolute" as const,
      right: "10px",
      zIndex: 999,
      background: "#fff",
      top: "38px",
      width: "75%",
      flexWrap: "wrap" as const,
      borderRadius: "2px",
      justifyContent: "space-between",
      height: '100px',
      flexDirection: 'row' as const,
    },
    navDropdownWrraperItem: {
      position: "absolute" as const,
      right: "10px",
      zIndex: 999,
      background: "#fff",
      top: "38px",
      width: "25%",
      flexWrap: "wrap" as const,
      borderRadius: "2px",
      justifyContent: "space-between",
      height: '100px',
      flexDirection: 'row' as const,
      "@media (max-width: 600px)": {
        width: "75%",
        top: "32px"
      }
    },
    staticDropdownWrraper: {
      position: "absolute" as const,
      right: "10px",
      zIndex: 999,
      background: "#fff",
      top: "38px",
      width: "75%",
      flexWrap: "wrap" as const,
      borderRadius: "2px",
      justifyContent: "space-between",
      // height: '200px',
      flexDirection: 'row' as const,
    },
    staticDropdown: {
      display: 'flex',
      zIndex: 1,
      background: "#fff",
      top: "44px",
      width: "100%",
      padding: "18px 20px",
      flexWrap: "wrap" as const,
      borderRadius: "2px",
      gap: "2px",
      justifyContent: "space-between",
      height: '100%',
      flexDirection: 'row' as const,
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      "@media (max-width: 600px)": {
        flexDirection: "column" as const,
        height: '400px',
        overflow: 'scroll' as const,
        flexWrap: "nowrap" as const,
      }
    },
    staticItems: {
      height: '100%',
      flex: 1,
      gap: "10px",
      display: "flex",
      flexDirection: "column" as const,
    },
    header_image: {
      width: '100%',
      height: 'auto',
      display: 'block',
      position: 'absolute' as const,
      objectFit: 'fill' as const,
      top: 0,
      left: 0,
    },
    header_image_container: {
      width: '100%',
      height: '100%',
      position: 'relative' as const,
      '&::before': {
        content: '""',
        display: 'block',
        paddingTop: '33.33%', 
      },
    },
    categoriesItem: {
      padding: "0px",
      boxSizing: "border-box" as const,
      whiteSpace: "nowrap" as const,
      display: 'flex',
      justifyContent: "flex-start",
      "@media (max-width: 600px)": {
        flexDirection: "column" as const,
        width: '100%',
        flex: "0 0 0.5%",
        alignItems: 'flex-start'
      },
      "& a": {
        textDecoration: "none" as const,
        "& p": {
          justifyContent: "left",
          fontWeight: "500",
          color: "#000",
          display: "inline-block"
        }
      },
      "&:hover": {
        "& a": {
          textDecoration: "none" as const,
          "& p": {
          }
        }
      }
    },
    shopBtn: {
      margin: "8px 5px",
      textTransform: "uppercase" as const,
      padding: "5px 8px",
      fontSize: '9px',
      fontFamily: "Lato, sans-serif",
      fontWeight:700,
      borderRadius:5,
    },
    searchBox :{
      display: "flex", 
      flexDirection: "row" as const, 
      flexWrap: "nowrap" as const, 
      alignItems: "center", 
      height: 70, 
      marginLeft: "50px",
      '& .MuiFormControl-root':{
        boxShadow: "0px 1px 5px #EBEBEB",
      },
      "@media (max-width: 600px)": {
        marginLeft: "-20px"
      }
    },
    headerPartText:{
      display: "flex", width: "100%", 
      height: 70, 
      alignItems: "center", 
      justifyContent: "flex-end" ,
      "@media (max-width: 600px)": {
        justifyContent: "flex-start",
        marginLeft: "-5px"
      }
    },
    badge: {
      position:'relative' as const,
      top:'-12px',
      borderRadius:'50%',
      backgroundColor: '#ff6008',
      color: '#fff',
      padding: '3px 6px',
      fontSize: '9px',
      right:'27%',
      fontFamily: "Lato, sans-serif",
    },
    cartWrapper: { 
      padding: "0px 0px 0px 33px", 
      display:'flex'
    },
    verticalMargin: {
      margin: '0 50px 0 20px'
    },
    logoStyle:{ 
      cursor: "pointer",
      mixBlendMode: "multiply" as const, 
      paddingBottom: 2, 
      display: "flex", 
      alignItems: "flex-start", 
      width:'102px', 
      height:'85px' 
    },
    colorStyle : {
      color: "#f34f26", 
      margin: "auto", 
      whiteSpace: "nowrap" as const ,
      textDecorationColor: "#f34f26",
    },
    colorOne: {
      color: "#fff", 
      margin: "auto", 
      whiteSpace: "nowrap" as const,
      textDecorationColor: "rgba(23,34,54,255)",
    },
    navRight: {
      display: 'flex',
      alignItems: 'center'
    },
    categoryNavText : {
      color: '#000',
      fontWeight: 500,
      // marginBottom: "10px",
      '&:hover': {
        color: '#f34f26'
      }
    },
    signInBox: { 
      fontSize: 14, 
      fontWeight: 400, 
      color: "#000", 
      fontFamily: `"Lato", sans-serif`, 
      cursor: "pointer" 
    },
    welcomeText: { 
      color: "#1E1E7D", 
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`, 
      fontSize: "14px", 
      fontWeight: 700 
    },
    buyerLastName: { 
      width: "84px", 
      overflow: "hidden" as const, 
      textOverflow:'ellipsis', 
      whiteSpace:'nowrap' as const
    },
    address: {
      color: "#fff", 
      fontSize: "11px", 
      whiteSpace: 'nowrap' as const, 
      maxWidth:'180px', 
      textOverflow:'ellipsis', 
      overflow:'hidden' as const
    },
    topBanner: {
      height: '34px',
      background: '#1c5ac7', 
      display:'flex', 
      alignItems:'center', 
      justifyContent:'center'
    },
    fireIcon: {
      margin: '0 5px', 
      width:'13px', 
      height:'13px'
    },
    bannerTitle: {
      margin: '0 5px', 
      color : '#fff', 
      fontSize: '10px', 
      fontFamily:'Lato,sans-serif', 
      fontWeight: 700
    }
};

const CustomShopButton = styled(Button)({
  color:'#2a4356',
  fontSize: '10px',
  fontWeight: 700,
  backgroundColor: '#fff',
  borderRadius:'5px',
  textTransform: 'capitalize',
  fontFamily: 'Lato, sans-serif',
  padding: '6px 8px',
  height: '21px',
  margin: '0 5px',
  '&:hover': {
    backgroundColor: '#fff',
    color:'#2a4356'
  }
})
export { Header };
export default withStyles(styles)(Header);
// Customizable Area End
