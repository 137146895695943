import React from "react";

// Customizable Area Start
import { Box, Button, Grid, IconButton, Input, InputAdornment, Select, TextField, Typography, styled } from "@material-ui/core";
import { BoxNoScroll, CustomizeBrandInput, FormControlTwo, GenerateButton, MenuItemTwo, OrangeButton } from "../CustomizedElementStyle";
import { file } from "../assets";
import CloseIcon from "@material-ui/icons/Close";
export const configJSON = require("../config");
// Customizable Area End

import CreateStorePageController, { Props } from "./CreateStorePageController";
import { errorImage } from "../../../bulkuploading/src/assets";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";

class CreateStorePage extends CreateStorePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box sx={webStyle.wrapper}>{configJSON.storeManagementTab}</Box>
        <form id="noway" onSubmit={this.handleOnSubmitAndCreatePage} style={webStyle.form}>
          <BoxNoScroll style={webStyle.boxScroll}>
            <Box style={webStyle.innerBox}>
              <Box style={webStyle.createStoreBox}>
                <Box style={webStyle.innerCreateStoreBox}>{this.state.isEditable ? configJSON.editStoreText : configJSON.createStore}</Box>
              </Box>
              <Box style={webStyle.boxed}></Box>
              <Box style={webStyle.innerBoxed}></Box>
              <Grid container spacing={4}>
                <Grid item lg={6} sm={12} xs={12}>
                  <FormControlTwo fullWidth={true}>
                    <Box sx={webStyle.name}>{configJSON.storeName}</Box>
                    <Select
                      data-test-id="selectStoreDropdown"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      placeholder="Select SKU"
                      value={this.state.dealsDdValues}
                      onChange={(event:React.ChangeEvent<{value: unknown}>) => this.handleChangeMenuItem(event.target.value)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: webStyle.store
                        }
                      }}
                      disabled={this.state.dealsList.length===0}
                    >
                      {this.state.dealsList.length > 0 && this.state.dealsList.map((item: {attributes: {id: string, brand_name: string}}) => <MenuItemTwo value={item.attributes.id}>{item.attributes.brand_name}</MenuItemTwo>)}
                    </Select>
                    {this.state.brandErr ? 
                    <Typography data-test-id="brandErr" style={webStyle.redText}>{this.state.brandErr}</Typography>: ""}
                  </FormControlTwo>
                  
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <Box style={webStyle.headings}>{configJSON.storeYear}</Box>
                  <SecondMainContainer data-test-id="storeYearTestId" id="outlined-start-adornment" inputProps={{ maxLength: 4, style: webStyle.year }} autoComplete="off" value={this.state.store_year} 
                  disabled
                  onChange={this.handleStoreYear} 
                  variant="outlined" className="storeYear" style={webStyle.storeYear} />
                </Grid>
              </Grid>
              <Box style={webStyle.brandBox}>{configJSON.brandTradeCertificate}</Box>
              <Input
                data-test-id="brand_file"
                type="file"
                style={webStyle.uploadBox}
                value=""
                onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleUploadCertFile(event)}
                inputProps={{
                  "aria-label": "upload documents",
                  accept: ".pdf",
                  ref: this.inputCertificateFile
                }}
              />
              {this.state.brand_trade_certificate ? (
                <Box style={webStyle.brandField}>
                  <Box style={webStyle.brandFieldBox}>{this.state.isFileUpload ? this.state.brand_trade_certificate.name : this.state.brand_trade_certificate.split("/").pop()}</Box>
                  <IconButton onClick={this.handleRemoveFile} disabled={this.state.isFetching}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ) : (
                <label htmlFor="upload-button">
                  <Button variant="outlined" fullWidth data-test-id="openFileRef" disabled={this.state.isFetching} onClick={this.handleOpenUploadFile} style={webStyle.uploadButton}>
                    <Box>
                      <img src={file} />
                      <Box style={webStyle.uploads}>{configJSON.upload}</Box>
                      <Box style={webStyle.uploadPDF}>{configJSON.uploadPDF}</Box>
                    </Box>
                  </Button>
                </label>
              )}
              <Grid container style={webStyle.container} spacing={4}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box style={webStyle.storeURL}>
                    <Box style={{width:'100%'}}>
                    <CustomizeBrandInput
                      data-test-id="store_url"
                      label="Create Store URL*"
                      id="outlined-start-adornment"
                      autoComplete="off"
                      value={this.state.store_url}
                      onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleOnInputChange("store_url", event.target.value)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "200px",
                          display: "inline-block"
                        },
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                      }}
                    />
                      {this.state.socialErr ? 
                  <Typography style={webStyle.redText}>{this.state.socialErr}</Typography> : ''}
                    </Box>
                    <Box style={webStyle.generate}></Box>
                    <GenerateButton id="generateurl" onClick={this.handleGenerateWebUrl} style={{height:'42.5px'}}>
                      <Box style={webStyle.generateBox}>{configJSON.generate}</Box>
                    </GenerateButton>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <CustomizeBrandInput
                    data-test-id="website"
                    label="Website/Social Media*"
                    autoComplete="off"
                    id="outlined-start-adornment"
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleOnInputChange("website_social_url", event.target.value)}
                    value={this.state.website_social_url}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "200px",
                        display: "inline-block"
                      },
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                  />
                  {this.state.websiteErr ? 
                  <Typography style={webStyle.redText}>{this.state.websiteErr}</Typography> : ''}
                </Grid>
              </Grid>
              <Box style={webStyle.otherBox}>
                {this.state.errorMessage && (
                  <Box style={webStyle.errorBox}>
                    <Box style={webStyle.innerError}>
                      <img src={errorImage} />
                    </Box>
                    <Box>
                      <Typography style={webStyle.color} variant="body2">
                        {this.state.errorMessage}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {this.state.isError && (
                  <Box style={webStyle.errorBox}>
                    <Box style={webStyle.innerError}>
                      <img src={errorImage} />
                    </Box>
                    <Box>
                      <Typography style={webStyle.color} variant="body2">
                        {this.state.isError}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {this.state.successMessage && (
                  <Box>
                    <Typography style={webStyle.success} variant="body2">
                      {this.state.successMessage}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box style={webStyle.buttonBox}>
                <Grid container spacing={3} style={webStyle.innerBtn}>
                  <Grid item>
                    <BackBtn data-test-id="back_button" onClick={this.handlBackWelcome}>
                      {configJSON.back}
                    </BackBtn>
                  </Grid>
                  <Grid item>
                    <CustomOrangeButton data-test-id="submit_button" type="submit">
                      {this.state.isEditable ? configJSON.updateAndNextBtnText : configJSON.submitAndCreate}
                    </CustomOrangeButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </BoxNoScroll>
        </form>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainContainer = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    height: "40px"
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield"
  }
});

const webStyle = {
  text: { 
    padding: "20.1px 0px 15px 0px", 
    fontSize: "18px", 
    fontWeight: 400, 
    fontFamily: `"Lato", sans-serif` 
  },
  form: {
    overflow: "auto"
  },

  boxScroll: {
    overflow: "auto",
    paddingBottom: 20
  },

  innerBox: {
    borderRadius: "8.1px",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    padding: "30px",
    overflowY: "auto" as "auto",
    overflowX: "hidden" as "hidden",
    position: "relative" as "relative",
    background: "white"
  },

  createStoreBox: {
    width: "100%",
    display: "flex" as "flex",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between" as "space-between",
    alignItems: "center" as "center",
    marginBottom: 10
  },

  innerCreateStoreBox: {
    fontSize: "27px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 700
  },

  boxed: {
    width: "calc(100% + 60px)",
    height: 1,
    background: "#EEEEEE",
    position: "absolute" as "absolute",
    left: 0,
    top: 100
  },

  innerBoxed: {
    width: "100%",
    height: 65
  },

  brandBox: {
    marginTop: 30,
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 12,
    fontWeight: 600
  },

  brandField: {
    margin: "10px 0px",
    height: 50,
    display: "flex" as "flex",
    alignItems: "center" as "center",
    justifyContent: "space-between" as "space-between",
    background: "#f5f5f5",
    borderRadius: 5,
    flexWrap: "nowrap" as "nowrap",
    padding: "0px 0px 0px 20px",
    width: "100%"
  },

  brandFieldBox: {
    fontFamily: `"Lato", sans-serif`
  },

  uploadButton: {
    width: "100%",
    height: 300,
    padding: "20px 0px",
    margin: "10px 0px",
    border: "none" as "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: "5px",
    textTransform: "none" as "none",
    fontFamily: `"Lato", sans-serif`
  },

  uploads: {
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`
  },

  uploadPDF: {
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 16
  },

  headings: {
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600,
    fontSize: 12,
    marginBottom: 10
  },

  container: {
    marginTop: 28
  },

  uploadBox: {
    display: "none"
  },

  storeURL: {
    display: "flex" as "flex",
    flexDirection: "row" as "row",
    flexWrap: "nowrap" as "nowrap"
  },

  generate: {
    width: 30,
    height: 5
  },

  generateBox: {
    color: "#FFF",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 14,
    fontWeight: 500
  },

  otherBox: {
    width: "100%",
    height: 105,
    paddingTop: 10
  },

  errorBox: {
    display: "flex" as "flex",
    paddingTop: "10px" as "10px",
    flexWrap: "nowrap" as "nowrap",
    flexDirection: "row" as "row",
    alignItems: "center" as "center"
  },

  innerError: {
    marginRight: "10.1px"
  },

  color: {
    color: "#D74D4D"
  },

  success: {
    fontSize: 14,
    color: "rgb(61 168 46)"
  },

  buttonBox: {
    display: "flex" as "flex",
    justifyContent: "flex-end" as "flex-end",
    width: "100%"
  },

  innerBtn: {
    justifyContent: "flex-end"
  },

  storeYear: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      height: "40px",
      padding: "10.5px 14px"
    },
    "& .MuiInputBase-root": {
      height: "40px"
    },
    "& .input[type=number]": {
      "-moz-appearance": "textfield"
    }
  },
  year: {
    color: 'rgba(0,0,0,0.8)', 
    fontSize:'14px', 
    fontFamily: "Lato, sans-serif", 
    fontWeight: 500
  },
  name: { 
    color: "#9a9a9a", 
    fontFamily: `"Lato", sans-serif`, 
    fontWeight: 600, 
    fontSize: 12, 
    marginBottom: 10 
  },
  wrapper : { 
    padding: "20.1px 0px 15px 0px", 
    fontSize: "18px", 
    fontWeight: 400, 
    fontFamily: `"Lato", sans-serif` 
  },
  store: {
    background: "white",
    border: "1px solid #b1b3b5",
    boxShadow: "none",
    borderRadius: 5,
    marginTop: "5px",
    padding: 0,
    maxHeight: "400px",
    overflowY: "auto" as const
  },
  redText: {
    fontSize: "12px", 
    fontWeight: 400, 
    fontFamily: `"Lato", sans-serif`,
    color: '#D74D4D',
    marginTop: '5px'
  }
};

const SecondMainContainer = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "40px"
  }
});
const BackBtn = styled(Button)({
  textTransform: "none",
  fontFamily: "'Lato', sans-serif",
  fontWeight: 700,
  fontSize: 16,
  background: "#444",
  color: "white",
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  padding: "8px 30px",
  "&:hover": {
    background: "#555"
  }
});

export default CreateStorePage;
// Customizable Area End
