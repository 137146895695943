import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { emailImage, successImage, errorImage } from "./assets";
import React from "react";
import { InputProps } from "@material-ui/core";
import { setStorageData } from "framework/src/Utilities";
export const emailImg = emailImage;
export const successImg = successImage;
export const errorImg = errorImage;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  showOTPModal: boolean;
  showVerifyEmailModal: boolean;
  showOTPSuccessModal: boolean;
  showEmailSuccessModal: boolean;
  showEmailFailedModal: boolean; 
  emailFailedError: string[];
  inputValues:  string[];
  errorMessages: string[];
  sellerId: string;
  token: string;
  sellerPhoneNumber: string | number;
  otpToken: string;
  emailToken: string;
  minutes: number;
  seconds: number;
  sellerEmail: string;
  sellerPhoneCountryCode: string;
  sellerBasePhoneNumber: string;
  isResendOtp:boolean
  loading: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRefs: React.RefObject<InputProps & { focus: Function }>[];
  apiSendOtpForMobileCallId: string = "";
  apiConfirmMobileOtpCallId: string = "";
  apiConfirmEmailCallId: string = "";
  apiResendEmailCallId: string = "";
  apiSendEmailCallId: string = "";
  timer: NodeJS.Timeout | number;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.OtpInputConfirmationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      showOTPModal: false,
      showOTPSuccessModal: false,
      showVerifyEmailModal: false,
      showEmailSuccessModal: false,
      showEmailFailedModal: false,
      emailFailedError: [""],
      inputValues: ["", "", "", "", ""],
      errorMessages: [],
      sellerId: "",
      sellerEmail: "",
      token: "",
      sellerPhoneNumber: "",
      sellerPhoneCountryCode: "",
      otpToken: "",
      emailToken: "",
      minutes: 1,
      seconds: 30,
      sellerBasePhoneNumber: "",
      isResendOtp:false,
      loading: false
    };
    this.inputRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];

    this.timer = 0;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.OtpInputConfirmationMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.OtpInputConfirmationMessageData)
      );
      const dataRes = data.message.seller
      this.setState({
        sellerId: dataRes.data.id,
        token: data.message.token,
        sellerPhoneNumber: dataRes.data.attributes.full_phone_number,
        sellerPhoneCountryCode: dataRes.data.attributes.country_code,
        inputValues: ["", "", "", "", ""],
        errorMessages: [],
        seconds: 30,
        minutes: 1,
        sellerEmail: dataRes.data.attributes.email,
        sellerBasePhoneNumber: dataRes.data.attributes.phone_number,
      });

      this.setState({ showOTPModal: true });
      this.inputRefs[0].current?.focus();
      this.startTimer();

      this.sendMobileOtp();
      setStorageData("seller_id", dataRes.data.id);
      setStorageData("authToken", data.message.token);
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson =
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) ||
        {};
      if (apiRequestCallId === this.apiSendOtpForMobileCallId) {
        this.handlOtpResponse(responseJson);
      }
      if (apiRequestCallId === this.apiConfirmMobileOtpCallId) {
        this.handleConfirmOtpResponse(responseJson);
      }
      if (apiRequestCallId === this.apiConfirmEmailCallId) {
        this.handleConfimEmailResponse(responseJson);
      }
      if (apiRequestCallId === this.apiResendEmailCallId) {
        this.handleResendEmailResponse(responseJson);
      }
      if (apiRequestCallId === this.apiSendEmailCallId) {
        this.handleEmailSend(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleEmailSend = (responseJson: unknown) => {
    interface SuccessReponse {
      message: string;
    }
    const successData = responseJson as SuccessReponse;
    if ("message" in successData) {
      return;
    }
    this.setState({
      errorMessages: ["Something went wrong! Please try again later!"],
    });
  }

  handleResendEmailResponse = (responseJson: unknown) => {
    interface SuccessReponse {
      message: string;
    }
    const responseData = responseJson as SuccessReponse;
    if ("message" in responseData) {
      if (responseData.message === "Activation email has been resent") {
        this.setState({
          errorMessages: ["Email resent! Please check your inbox!"],
        });
        return;
      }
      this.setState({
        errorMessages: [responseData.message],
      });
      return;
    }
    this.setState({
      errorMessages: ["Something went wrong! Please try again later!"],
    });
  };

  handleConfimEmailResponse = async(responseJson: unknown) => {
    interface SuccessReponse {
      data: {
        id: string
      },
      meta: {
        message: string;
        token: string
      };
    }
    interface FailedResponse {
      errors: string[];
    }
    type ResponseJson = SuccessReponse | FailedResponse;
    const responseData = responseJson as ResponseJson;
    if ("meta" in responseData) {
      if (
        responseData.meta.message ===
        "Your account has been successfully activated. Please continue registration"
      ) {
        await setStorageData("isAccountActivated", true)
        await setStorageData("isAccountCreated", true)
        await setStorageData("seller_id", responseData.data.id)
        await setStorageData("authToken", responseData.meta.token)
        this.setState({ showEmailSuccessModal: true, loading: false });
        setTimeout(() => {
          this.handleGoToSellerLandingPage();
        }, 1500);
        return;
      }
    }
    if ("errors" in responseData) {
      this.setState({ emailFailedError: responseData.errors, showEmailFailedModal: true, loading: false });
      return;
    }
    this.setState({
      errorMessages: ["Something went wrong! Please try again later!"],
    });
  };

  handleConfirmOtpResponse = (responseJson: unknown) => {
    interface SuccessReponse {
      meta: {
        message: string;
      };
    }
    interface FailedResponse {
      errors: {
        pin: string;
      }[];
    }
    type ResponseJson = SuccessReponse | FailedResponse;
    const responseData = responseJson as ResponseJson;
    if ("meta" in responseData) {
      this.setState({ showOTPModal: false, showOTPSuccessModal: true });
      return;
    }
    if ("errors" in responseData) {
      this.setState({ errorMessages: [responseData.errors[0].pin] });
      return;
    }
    this.setState({
      errorMessages: ["Something went wrong! Please try again later!"],
    });
  };

  handlOtpResponse = (responseJson: unknown) => {
    interface SuccessReponse {
      meta: {
        token: string;
      };
    }
    const responseData = responseJson as SuccessReponse;

    if ('meta' in responseData) {
      const tokenValue = responseData.meta.token; 

      this.setState({ 
        otpToken: tokenValue
      });
      return;
    }
    this.setState({
      errorMessages: ["Something went wrong! Please try again later!"],
    });
  };

  handleCloseModal = () => {
    this.setState({
      showOTPModal: false,
      showOTPSuccessModal: false,
      showVerifyEmailModal: false,
      showEmailSuccessModal: false,
      showEmailFailedModal: false,
      loading: false
    });
  };

  handleGoToSellerLandingPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationSellerLandingMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleSubmitOtp = () => {
    if(this.state.minutes <= 0 && this.state.seconds <= 0) {
      this.setState({ errorMessages: ['OTP has been expired. Click on Resend now to continue...'] });
      return;
    }
    this.setState({ errorMessages: [] });
    if ([...this.state.inputValues].includes("") === false) {
      this.confirmMobileOtp();
    }
    if ([...this.state.inputValues].includes("")) {
      this.setState({
        errorMessages: ["Please provide all of the needed information!"],
      });
    }
  };

  handleInputOnChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      const newInputValues = this.state.inputValues;
      newInputValues[index] = value;
      this.setState({ inputValues: newInputValues });

      if (index < this.inputRefs.length - 1 && this.state.inputValues[index]) {
        const reference = this.inputRefs[index + 1];
        if (reference && reference.current) {
          reference.current.focus();
        }
      }
    }
  };

  handleResendOtp = () => {
    this.setState({
      inputValues:["","","","",""]
  })
  this.inputRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];
    if (this.state.seconds === 0 && this.state.minutes === 0) {
      setTimeout(() => {
        this.setState((state, props) => ({
         isResendOtp:true
        }), () => {
          setTimeout(() => {
            this.setState(() => ({
              isResendOtp:false
            }))
          }, 1500);
        })
      }, 100);
      this.setState({ seconds: 30, minutes: 1 });
      this.sendMobileOtp();
      this.startTimer();
      return;
    }

    this.setState({
      errorMessages: ["Please wait for a while to resend otp again!"],
    });
  };

  handleSendVerificationEmail = () => {
    this.setState({
      showOTPModal: false,
      showOTPSuccessModal: false,
      showVerifyEmailModal: true,
      showEmailSuccessModal: false,
      loading: false
    })
    this.sendEmail();
  };

  startTimer = () => {
    clearInterval(this.timer as NodeJS.Timeout);
    this.timer = setInterval(() => {
      const { minutes, seconds } = this.state;
      if (minutes === 0 && seconds === 0) {
        clearInterval(this.timer);
      } else {
        if (seconds > 0) {
          this.setState((prevState) => ({
            seconds: prevState.seconds - 1,
          }));
        } else {
          this.setState((prevState) => ({
            minutes: prevState.minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  };

  sendMobileOtp = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSendOtpForMobileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/send_otps"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            full_phone_number: this.state.sellerPhoneNumber,
          },
        },
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  confirmMobileOtp = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.otpToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiConfirmMobileOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/sms_otp_confirmations"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        pin: this.state.inputValues.join(""),
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  confirmEmail = (token: string) => {
    this.setState({ loading: true });
    const headers = { "Content-Type": "application/json" };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiConfirmEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_activation?token=" + token
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resendEmail = () => {
    const headers = { "Content-Type": "application/json" };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResendEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/resend_email?token=" + this.state.token
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendEmail = () => {
    const headers = { "Content-Type": "application/json" };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSendEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/resend_email?token=" + this.state.token
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSetEmailToken = (verifyParam: string) => {
    this.setState({ emailToken: verifyParam });
    this.confirmEmail(verifyParam);
  };

  hideKeyboard = () => {
    return configJSON.errorTitle;
  };

  handleResendEmail = () => {
    this.resendEmail();
    this.setState({
      errorMessages: ["Resending email, please wait for a while!"],
    });
  };

  handleInputOnPress = (index: number, event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === "Backspace") {
      const newInputValues:string [] = [...this.state.inputValues];
      if (!newInputValues[index] && index > 0) {
        const prevInputRef = this.inputRefs[index - 1];
        if (prevInputRef && prevInputRef.current) {
          prevInputRef.current.focus();
        }
      }
      newInputValues[index] = "";
      this.setState({ inputValues: newInputValues });
    }
    if (event.key === "Enter") {
      this.handleSubmitOtp();
    }
  };

  handleSendEmailVerification = () => {
    const url = window.location.origin;
    window.open(url + "/seller/otp?verify=" + this.state.token);
  };

  async componentDidMount(): Promise<void> {
    try {
      const pageUrl = window.location.href;
      const verifyParam = pageUrl.split("?")[1].split("=")[1];
      if (verifyParam) {
        this.handleSetEmailToken(verifyParam);
      }
    } catch (error) {}
  }

  maskSellerEmail = () => {
    const [localEmailPart, domain] = this.state.sellerEmail.split('@');
    if (localEmailPart.length > 5) {
    const visibleEmailPart = localEmailPart.slice(0, 5);  
    const maskedEmailPart = 'x'.repeat(localEmailPart.length - 5);
    return `${visibleEmailPart}${maskedEmailPart}@${domain}`;
    }
    else return this.state.sellerEmail
  };
  // Customizable Area End
}
