Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductQuickview";
exports.labelBodyText = "ProductQuickview Body";
exports.getProductListAPiEndPoint = "/bx_block_products/products?per=15&page=1";
exports.getProductQuickViewApiEndPoint = "bx_block_catalogue/catalogues/"
exports.getAccountIdEndPoint = "account_block/accounts/logged_user?token=";
exports.categoryApiContentType = "application/json";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.btnExampleTitle = "CLICK ME";
exports.addTocart = `bx_block_shopping_cart/order_items`;
exports.removeWhishlistApiEndpoint = (id)=>`bx_block_favourites/favourites/${id}`;
exports.continueText = "CONTINUE";
exports.checkoutText = "CHECKOUT";
exports.successfullyText = "Successfully";
exports.oneItemAddedToCartText = "added to the cart";
exports.customerReviewText = "Customer reviews";
exports.ratingRangeText = (rating) => `${rating} out of 5`;
exports.peopleFoundUsefulText = " people found this helpful";
exports.seeMoreReviewBtnText = "See more reviews";
exports.soldOutText = "Sold Out";
exports.outOfStockItemText = "This item is currently out of stock";
exports.leftInStockText = (count) => `Only ${count} items are left in stock.`
exports.getReviewsList = (id, accountId) => `bx_block_catalogue/reviews/customer_rating_and_reviews?review_type=product&catalogue_id=${id}&account_id=${accountId}`
exports.productViewEndpoint = "/bx_block_salesreporting/product_views";
exports.addHelpfullEndpoint = (reviewId) => `bx_block_catalogue/reviews/${reviewId}/helpful_review`;
exports.viewMoreText = "View more";
exports.viewLessText = "View less";
// Customizable Area End
