//Customizable Area Start

export const mobileImg = require("../assets/mobile.png");
export const successImg = require("../assets/success.png");
export const wareHouseImg = require("../assets/warehouse.png");
export const questionImg = require("../assets/questionImg.png");
export const logoImg = require("../assets/logo.png");
export const phoneImg = require("../assets/phone.png");
export const heartFilledIcon = require("../assets/heartFilled.png");
export const emptyHeartIcon = require("../assets/emptyHeart.png");
export const expressImg = require("../assets/express.png");
export const starIcon = require("../assets/star.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const editIcon = require("../assets/editIcon.png");
export const greenCheckBox = require("../assets/greenCheckbox.png");
export const checkBox = require("../assets/checkbox.png");
export const paymentMethods = require("../assets/payment.png");
export const location = require("../assets/location.png");
export const etrade = require("../assets/etrade.jpg");
export const envelope = require("../assets/envelope.png");
export const call = require("../assets/call.png")
export const Image4 = require("../assets/image4.jpg");
export const Image5 = require("../assets/Image5.png");
export const Image6 = require("../assets/Image6.png");
export const Image7 = require("../assets/Image7.png");
export const arrowUp = require("../assets/ArrowUp.png"); 
export const arrowDown = require("../assets/ArrowDown.png"); 
export const edit = require("../assets/edit.png"); 
export const spaceIcon = require("../assets/spaceIcon.png"); 
export const calendar = require("../assets/CalendarMonthOutlined.svg"); 
export const rapidLogo = require("../assets/rapidLogo.png"); 
export const editImage = require("../assets/editImage.png"); 
export const returnCheck = require("../assets/returnChecked.png"); 
export const returnUncheck = require("../assets/returnCheckbox.png"); 
export const closeIcon = require("../assets/closeIcon.png"); 
export const shippingIcon = require("../assets/shippingIcon.png"); 
export const reasonIcon = require("../assets/reasonImage.png"); 
export const backIcon = require("../assets/backIcon.png");
export const nextIcon = require("../assets/nextIcon.png");
//Customizable Area End