import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { GetCookies, SetCookies } from "framework/src/WebUtilities";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

// Customizable Area Start
import { createRef } from "react";

export interface ProductContent {
    product_attributes: {
      id: string,
      product_title: string,
      retail_price: string
    }
}

export interface IVariantProduct{
  data: {
    attributes: {
      product_content: ProductContent,
      micro_catgeory: {
        id: string
      },
      variant_product_group: {
        product_sku: string,
        product_besku: string
      }
    }
  }[]
}

export interface IProduct {
    id: string,
    attributes: {
      id: string , 
      product_image: string,
      product_title: string,
      status: boolean,
      sku: string,
      besku: string,
      fulfilled_type: string ,
      content_status: string,
      micro_category: {
        id: string
      },
      product_content: ProductContent
      variant_products: IVariantProduct
      stocks: number | null,
    }
} 
export interface IProductListData {
  data: IProduct[]
}

// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    tableContainer: string;
    checkbox: string;
    headerOne: string;
    headerTwo: string;
    textOne: string;
    textTwo: string;
    addBtn: string;
    image: string;
    addNewBtn: string;
    menuOption: string;
    checkboxOne: string;
    imgWrapper: string;
    accepted: string;
    rejected: string;
    select: string;
    active: string;
    inactive: string;
    tableRowWrap: string;
    tableCell: string;
    img: string;
    cellText: string;
    stockWrapper: string;
    stock: string;
    store: string;
    underReview: string;
    pagination: string;
    filterBtn: string;
    imageTableCell: string;
    imageHeader: string;
    tableTitleCell: string;
    skuCellText: string;
    editIcon: string;
    leftWrap: string;
    editWrapper: string;
    priceCellText: string;
    delivered: string;
    menuCheckbox: string;
    filterText: string;
    activeText: string;
    wrapper: string;
    heading: string;
    filterWrapper: string;
    filterContainer: string;
    noScroll: string;
    mainSection: string;
    marginRight: string;
    titleText: string;
    buttonBox: string;
    arrowImg: string;
    btnText: string;
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  productAnchorEl: null | HTMLElement;
  loading: boolean;
  productList: IProduct[];
  selectedProducts: any[];
  fulfilledType: string | null;
  productStatus: boolean | any;
  productId: string;
  optionsAnchorEl: null | HTMLElement;
  page: number;
  totalProductCount: number;
  search: string;
  filterOptionsAnchorEl: null | HTMLElement;
  sortAnchorEl: null | HTMLElement;
  filterByLiveStatus: boolean[];
  filterByContentStatus: string[];
  filterByFulfillmentType: string[];
  sortByValue: string;
  selectedFilter: boolean;
  openVariant: boolean;
  buttonText: string;
  isAddSingleProductBoolean:boolean;
  addSingleProductTrue:string;
  isVariant:boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerAddSingleController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  getProductListApiCallId: string = "";
  updateProductListApiCallId: string = "";
  topRef: React.RefObject<HTMLFormElement>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      productAnchorEl: null,
      loading: false,
      productList: [],
      selectedProducts: [],
      fulfilledType: "",
      productStatus: true,
      productId: "",
      optionsAnchorEl: null,
      page: 1,
      totalProductCount: 0,
      search: "",
      filterOptionsAnchorEl: null,
      sortAnchorEl: null,
      filterByLiveStatus: [],
      filterByContentStatus: [],
      selectedFilter: false,
      filterByFulfillmentType: [],
      sortByValue: "",
      openVariant: false,
      buttonText: "view all variants",
      isAddSingleProductBoolean:false,
      addSingleProductTrue:"",
      isVariant:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.topRef = createRef()
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const searchValue = message.getData(getName(MessageEnum.SearchData));
      if (searchValue) {
        this.setState({ search: JSON.parse(searchValue), page: 1 });
      }
      this.getSearchValue();
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.getProductListApiCallId) {
        this.handleProductListResponse(responseJson, errorJson);
      }
      if (apiRequestCallId === this.updateProductListApiCallId) {
        if (responseJson) {
          this.getProductList();
        }
        if (responseJson.errors) {
          toast.error(responseJson.errors[0], {
            draggable: true,
            progress: undefined,
            autoClose: 3000, // milliseconds
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: false,
            position: "top-right"
          });
        }
      }
    }
  }

  handleProductListResponse = async(responseJson: any, errorJson: any) => {
    let checkEdit= await getStorageData("checkEdit");
    let isProductEdit = await getStorageData('isProductEdit')
    if(checkEdit){
      toast.success(isProductEdit === "yes" ? 'Product updated successfully' : 'Product created successfully', {
        position: "top-right",
        autoClose: 3000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      removeStorageData("checkEdit")
      removeStorageData("isProductEdit")
    }
    if (responseJson.data) {
      this.setState({ loading: false, productList: responseJson.data, fulfilledType: "Fulfilled by ByEzzy", totalProductCount: responseJson.total_count });
    }
  };

  handleProductStatus = (item: {
    id: string;
    attributes: {
      status: boolean;
      fulfilled_type: string | null;
    };
  }) => {
    if (item.attributes?.status) {
      this.setState({ productStatus: false, productId: item.id });
    } else {
      this.setState({ productStatus: true, productId: item.id });
    }
    this.setFulfillmentType(item.attributes.fulfilled_type);
    this.updateProduct(item.id);
  };

  setFulfillmentType = (type: string | null) => {
    if (type === null || type.length === 0) {
      this.setState({ fulfilledType: configJSON.fulfilledByPartnerText });
    } else if (type === configJSON.byEzzyText) {
      this.setState({ fulfilledType: configJSON.fulfilledByByEzzyText });
    } else {
      this.setState({ fulfilledType: configJSON.fulfilledByPartnerText });
    }
  };

  getContentStatusClass = (status: string) => {
    if (status === configJSON.acceptedText) {
      return this.props.classes.accepted;
    }
    if (status === configJSON.rejectedText) {
      return this.props.classes.rejected;
    } else return this.props.classes.underReview;
  };

  getContentStatus = (status: string) => {
    if (status === configJSON.acceptedText) {
      return configJSON.acceptedBtnText;
    }
    if (status === configJSON.rejectedText) {
      return configJSON.rejectedBtnText;
    } else return configJSON.inReviewText;
  };

  handleFulfillment = (id: string, status: boolean | null, event: React.ChangeEvent<{ name?: string; value: any }>) => {
    if (status === null) {
      this.setState({ productStatus: false });
    } else this.setState({ productStatus: status });
    this.setState({ fulfilledType: event?.target?.value, productId: id });
    this.updateProduct(id);
  };

  handleProductLink = () => {};

  handleViewProduct = async(isVariant: boolean) => {
    this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.addProductPath });
    const message: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    message.addData(getName(MessageEnum.ChangeMenuPanel), configJSON.addProductPath);
    const msg: Message = new Message(getName(MessageEnum.SendMessage));
    msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "product-content", activeScreen: "product-content",isVariant:this.state.isVariant });
    this.send(message);
    this.send(msg);
  };

  handleOptionsMenu = (
    item: any,
    isVariant:boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setStorageData("isProductEdit", "yes");
    if(isVariant) {
      setStorageData("isVariantProduct", true);
      setStorageData("sku", item.attributes.variant_product_group.product_sku);
      setStorageData("variant_sku", item.attributes.variant_product_group.product_sku);
      setStorageData("besku", item.attributes.variant_product_group.product_besku);
    }
    else {
      setStorageData("sku", item.attributes.sku);
      setStorageData("isVariantProduct", false);
      removeStorageData("variant_sku");
      setStorageData("besku", item.attributes.besku);
    }
    SetCookies("catalog_id", item.id, 7);
    SetCookies("micro_category_id", item.attributes.micro_category?.id, 7);
    setStorageData("brand_name", item.attributes.brand.brand_name);
    setStorageData("catalog_id", item.id);
    setStorageData("newProductId", item.id);
    setStorageData("product_content", item.attributes?.product_content?.product_attributes?.id);
    this.setState({ optionsAnchorEl: event?.currentTarget ,isVariant:isVariant});
  };

  handleCloseOptionsMenu = () => {
    this.setState({ optionsAnchorEl: null });
  };

  handleGoToCategorizeProducts = () => {
    SetCookies("catalog_id", "", 7);
    const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    msg.addData(getName(MessageEnum.ChangeMenuPanel), configJSON.singleProductSelCategoryPath);
    msg.addData(getName(MessageEnum.ProductData), JSON.stringify(""));
    this.send(msg);
    this.props.navigation.navigate(configJSON.sellerHomeText, { path: configJSON.singleProductSelCategoryPath });
  };

  async componentDidMount(): Promise<void> {
   
    removeStorageData("sku");
    removeStorageData("isVariantProduct");
    removeStorageData("besku");
    removeStorageData("variant_sku");
    removeStorageData("barcode_id");
    removeStorageData("bar_code_info");
    Cookies.remove("catalog_id");
    Cookies.remove("micro_category_id");
    await removeStorageData("product_content");
    await removeStorageData("newProductId");
    this.setState({ loading: true });
    this.getSearchValue();
  }

  getSearchValue = () => {
    this.SearchDebounceUpdate();
  };

  SearchDebounce = (call: any, delay: number ) => {
    let timer: any;
    return function(...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        call(...args);
      }, delay);
    };
  };

  getProductList = async () => {
    const searchValue = this.state.search || GetCookies("global_search");
    const liveStatusQueryString = this.state.filterByLiveStatus.map((value: boolean) => `&live_status[]=${value}`);
    const statusQuery = liveStatusQueryString.join("");
    const contentStatusQueryString = this.state.filterByContentStatus.map((value: string) => `&content_status[]=${value}`);
    const contentQuery = contentStatusQueryString.join("");
    const fulfillmentQueryString = this.state.filterByFulfillmentType.map((value: string) => `&fulfillment[]=${value}`);
    const fulfillmentQuery = fulfillmentQueryString.join("");
    const headers = {
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues?search=${searchValue}&per_page=10&page=${this.state.page}${statusQuery}${contentQuery}${fulfillmentQuery}&sort_by=${this.state.sortByValue}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  SearchDebounceUpdate = this.SearchDebounce(this.getProductList, 1000);

  updateProduct = async (id: string) => {
    const headers = {
      token: await getStorageData("authToken")
    };
    const formData = new FormData();

    formData.append("fulfilled_type", this.state.fulfilledType === configJSON.fulfilledByByEzzyText ? configJSON.byEzzyText : configJSON.partnerText);
    formData.append("status", this.state.productStatus.toString());
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProductListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  selectedFulfillmentType = (type: string | null) => {
    if (type === null || type.length === 0) {
      return configJSON.fulfilledByPartnerText;
    } else if (type === configJSON.byEzzyText) {
      return configJSON.fulfilledByByEzzyText;
    } else {
      return configJSON.fulfilledByPartnerText;
    }
  };

  getLinkStatusClass = (status: boolean) => {
    const { classes } = this.props;
    return status ? classes.active : classes.inactive;
  };

  setLinkStatus = (status: boolean) => {
    return status ? configJSON.activeText : configJSON.inActiveText;
  };

  handleFilterButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ filterOptionsAnchorEl: event.currentTarget });
  };

  handleCloseFilterOptions = () => {
    this.setState({ filterOptionsAnchorEl: null });
  };

  handleStatusFilters = (value: boolean) => {
    const currentFilterByValue = [...this.state.filterByLiveStatus];
    const optionIndex = currentFilterByValue.indexOf(value);
    if (optionIndex === -1) {
      const newFilterByValue = currentFilterByValue.concat(value);
      this.setState({ selectedFilter: true, filterByLiveStatus: newFilterByValue, page: 1, filterOptionsAnchorEl: null });
    } else {
      currentFilterByValue.splice(optionIndex, 1);
      this.setState({ selectedFilter: true, filterByLiveStatus: currentFilterByValue, page: 1, filterOptionsAnchorEl: null });
    }
    this.SearchDebounceUpdate();
  };

  handleContentStatusFilters = (value: string) => {
    const currentFilterByValue = [...this.state.filterByContentStatus];
    const optionIndex = currentFilterByValue.indexOf(value);
    if (optionIndex === -1) {
      const newFilterByValue = currentFilterByValue.concat(value);
      this.setState({ selectedFilter: true, filterByContentStatus: newFilterByValue, page: 1 });
    } else {
      currentFilterByValue.splice(optionIndex, 1);
      this.setState({ selectedFilter: true, filterByContentStatus: currentFilterByValue, page: 1 });
    }
    this.SearchDebounceUpdate();
  };

  handleFulfillmentFilters = (value: string) => {
    const currentFilterByValue = [...this.state.filterByFulfillmentType];
    const optionIndex = currentFilterByValue.indexOf(value);
    if (optionIndex === -1) {
      const newFilterByValue = currentFilterByValue.concat(value);
      this.setState({ selectedFilter: true, filterByFulfillmentType: newFilterByValue, page: 1 });
    } else {
      currentFilterByValue.splice(optionIndex, 1);
      this.setState({ selectedFilter: true, filterByFulfillmentType: currentFilterByValue, page: 1 });
    }
    this.SearchDebounceUpdate();
  };

  handleSorting = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ sortAnchorEl: event.currentTarget });
  };

  handleCloseSortingMenu = () => {
    this.setState({ sortAnchorEl: null });
  };

  handleSortingOptions = (value: string) => {
    this.setState({ sortByValue: value, sortAnchorEl: null });
    this.SearchDebounceUpdate();
  };

  handleOpenVariant = (itemId: string) => {
    this.setState({ openVariant: true, buttonText: "Hide all", productId: itemId });
  };

  handleCloseVariant = () => {
    this.setState({ openVariant: false, buttonText: "view all variants", productId: "" });
  };

  getCountValue = () => {
    const count: number = this.state.totalProductCount / 10;
    const roundedProdCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedProdCount
  }

  setStockValue = (stockVal: number | null) => {
    return stockVal ? stockVal : 0
  }

  handleEditIcon = (fulfillment: string) => {
    if(fulfillment === "partner") {
      this.props.navigation.navigate(configJSON.sellerHomeText , {path: 'stocklogs'})
    }
    else {
      this.props.navigation.navigate(configJSON.sellerHomeText , {path: 'create-stock-request'})
    }
  }
  // Customizable Area End
}
