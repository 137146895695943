import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

interface myProps {
  loading: boolean;
  style?: React.CSSProperties
}

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  root2: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    cursor:'wait',
    zIndex: 1,
    background:'rgb(234, 233, 233,.3)'
  },
  circularContainer2: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  root3: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    cursor:'wait',
    zIndex: 1,
    background:'rgb(234, 233, 233,.3)'
  },
  circularContainer3: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function Loader(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
    <div className={classes.root}>
      <div className={classes.circularContainer}>
        <CircularProgress />
      </div>
    </div>
  ) : (
    <div />
  );
}

export function Loader2(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
    <div className={classes.root2}>
      <div className={classes.circularContainer2} style={props.style}>
        <CircularProgress />
      </div>
    </div>
  ) : (
    <div />
  );
}

export function Loader3(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
    <div className={classes.root3}>
      <div className={classes.circularContainer3}>
        <CircularProgress />
      </div>
    </div>
  ) : (
    <div />
  );
}
