Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.reportsText = "Reports";
exports.analyticsReportsTabText = "Analytics & Reports";
exports.saleReportHeaders = {
  dateText : "DATE",
  orderedProdText : "ORDERED PRODUCT SALES",
  unitsOrdered : "UNITS ORDERED",
  totalOrderItem : "TOTAL ORDER ITEM",
  averageSalesPerOrderItem : "AVERAGE SALES PER ORDER ITEM",
  averageUnitsPerOrderItem : "AVERAGE UNITS PER ORDER ITEM",
  averageSellingPrice: "AVERAGE SELLING PRICE",
  sessionsTotal: "SESSIONS TOTAL",
  orderItemSessionPer : "ORDER ITEM SESSION PERCENTAGE",
  averageOfferCount: "AVERAGE OFFER COUNT" 
};
exports.returnReportsHeaders = {
  dateText : "DATE",
  orderedProdText : "ORDERED PRODUCT SALES",
  unitsOrdered : "UNITS ORDERED",
  totalOrderItem : "TOTAL ORDER ITEM",
  unitsRefunded: "UNITS REFUNDED",
  refundRate: "REFUND RATE",
  feedbackReceived: "FEEDBACK RECEIVED",
  negativeFeedbackReceived: "NEGATIVE FEEDBACK RECEIVED",
  receivedNegativeFeedbackRate: "RECEIVED NEGATIVE FEEDBACK RATE",
  azClaimsGranted : "A - Z CLAIMS GRANTED",
  claimsAmount : "CLAIMS AMOUNT"
}
exports.saleTrafficHeaders = {
  parentBesku : "(PARENT) BESKU",
  childBesku : "(CHILD) BESKU",
  title : "TITLE",
  skuText : "SKU",
  sessionsTotal: "SESSIONS TOTAL",
  sessionsPer : "SESSIONS PERCENTAGE",
  pageViewsTotal: "PAGE VIEWS TOTAL",
  pageViewsPerTotal: "PAGE VIEWS PERCENTAGE TOTAL",
  featuredOffer : "FEATURED OFFER (BUY BOX) PERCENTAGE",
  unitsOrdered : "UNITS ORDERED",
  unitSessionPer : "UNIT SESSION PERCENTAGE",
  orderedProductSales: "ORDERED PRODUCT SALES",
  totalOrderItems: "TOTAL ORDER ITEMS"
}
exports.reportsApiEndpoint = `bx_block_salesreporting/reports/sales_performance`;
exports.salesReportText = "Sales report";
exports.salesReportTypeText = "sales_report";
exports.compareSalesText = "Compare Sales";
exports.compareSalesReportText = "compare_sales";
exports.returnReportText = "Return report";
exports.returnReportTypeText = "return_report";
exports.salesTrafficReportText = "Sales & traffic report";
exports.salesTrafficReportType = "sales_and_traffic_report";
exports.commonErrMsg = "Please select From date and To date";
exports.noDataFoundText = "No Data Found";
// Customizable Area End