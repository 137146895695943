// Customizable Area Start
import { Box, Button, Grid, withStyles } from "@material-ui/core"
import React from "react"
import { logoImg } from "./EmailAccountRegistrationController";
import {NavLink, withRouter} from "react-router-dom";
import SellerLandingNavbarController, { configJSON, Props } from "./SellerLandingNavbarController.web";

export class SellerLandingNavbar extends SellerLandingNavbarController {
    constructor(props:Props){
        super(props)
    }
    render(){
        const {classes} = this.props;
        return(
            <>
            <Grid container spacing={0} style={webStyle.header}>
            <Grid item lg={8} md={8} className={classes.navContent}>
                <Grid item>
                <img data-test-id="logo" src={logoImg} style={webStyle.logo} />
                </Grid>
                <Grid item lg={6} sm="auto" xs="auto" className={classes.nav}>
                <Grid item xs={3}>
                    <NavLink exact to="/seller/features" style={{ ...webStyle.navigation,textDecoration:'none',cursor: "pointer", borderBottom: this.setActiveTab("features")  ? "4px solid #F3732B" : "none" }} data-test-id="featuresId">
                    {configJSON.featuresText}
                    </NavLink>
                </Grid>
                <Grid item xs={3}>
                <NavLink exact to="/seller/whyus" style={{ ...webStyle.navigation, textDecoration:'none', borderBottom: this.setActiveTab("whyus") ? "4px solid #F3732B" : "none", cursor: "pointer" }} data-test-id="whyusId">
                    {configJSON.whyusText}
                    </NavLink>
                </Grid>
                <Grid item xs={3}>
                <NavLink exact to="/seller/pricing" style={{ ...webStyle.navigation, textDecoration:'none', borderBottom: this.setActiveTab("pricing") ? "4px solid #F3732B" : "none", cursor: "pointer" }} data-test-id="pricingId" >
                    {configJSON.pricingText}
                    </NavLink>
                </Grid>
                </Grid>
            </Grid>
            {(window.location.pathname !== "/") && (window.location.pathname !== "/seller/register") ? (
                <Grid item lg={4} md={4} sm={4} xs={12} className={classes.signupBox}>
                <Box className={classes.signUpButton}>
                    <Button data-test-id="sign" style={webStyle.signUpForFree} 
                    onClick={() => this.handleLogo()}
                    >
                    {configJSON.signupForFreeText}
                    </Button>
                </Box>
                </Grid>
            ) : (
                ""
            )}
            </Grid>

            </>
        )
    }
}

const webStyle = {
  header: {
    top: 30,
    left: 0,
    width: "100%",
    padding: "0rem 50px 0rem 50px",
    color: "#575656",
    fontFamily: `"Lato",  sans-serif`,
    position: "absolute" as const, 
    zIndex: 1 
  },
  navigation: {
    paddingBottom: 3,
    border: "none",
    margin: 20,
    width: "max-content",
    alignItems: "center",
    display: "flex",
    fontSize: '16px',
    fontFamily: 'Lato, sans-serif',
    color:'#575656',
    fontWeight: 400
  },
  signUpForFree: {
    padding: "10px 22px",
    borderRadius: "6px",
    background: "rgba(253, 97, 12, 1)",
    fontSize: 16,
    fontWeight: 600,
    color: "white",
    fontFamily: `"Lato",  sans-serif`,
    height: "max-content",
    width: "max-content", 
    textTransform: "none" as const,
    cursor: "pointer" 
  },
  text: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: 16,
    color: "#A8A8A8"
  },
  container: { 
    border: "none", 
    display: "flex", 
    flexDirection: "row" as const
  },
  logo: {
    cursor: "unset", 
    width:'102px', 
    height:'85px',
    marginTop:'-10px'
  }
};
const styles = {
  navContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "none", 
    flexDirection: "row" as const,
    
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      zIndex: 100,
    }
  },
  signUpButton: {
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      display:'flex',
      justifyContent: 'center'
    } 
  },
  nav: { 
    display: "flex", 
    flexDirection: "row" as const, 
    justifyContent: "space-evenly" 
  },
  signupBox: { 
    display: "flex", 
    justifyContent: "end", 
    border: "none",
    "@media (max-width: 768px)": {
      justifyContent: "center",
      alignItems: 'center',
      display:'contents'
    }
  }
}

const SellerLandingNavbarRouter:any = withRouter(SellerLandingNavbar as any)
export default withStyles(styles)(SellerLandingNavbarRouter);
// Customizable Area End