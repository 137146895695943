// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { createRef } from "react";
import { getStorageData } from "../../../../framework/src/Utilities";
import { IGridData } from "./StoreSectionThreeTabController.web";
export const configJSON = require("../../src/config.js");

interface IBannerResponse {
  data: {
    id: string;
    attributes: {
      id: string;
      banner_name: string;
      banner_image: string;
      banner_url: string;
    };
  }[];
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    topMargin: string;
    bannerImageLabel: string;
    bannerImageInput: string;
    bannerPreviewContainer: string;
    bannerPreviewImage: string;
    removeBanner: string;
    uploadBanner: string;
    btnBox: string;
    btnInfo: string;
    flexEnd: string;
    btnPosition: string;
    err: string;
    errorContainer: string;
    validate: string;
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  bannerName: string;
  bannerPreview: string | null;
  bannerLink: string;
  bannerError: string;
  bannerImage: string;
  bannerLinkError: string;
  bannerNameErr: string;
  bannerCommonErr: string;
  bannerId: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StoreBannerTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  bannerImgRef: React.RefObject<HTMLInputElement>;
  createBannerTabApiCallId: string = "";
  getBannerTabApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      bannerName: "",
      bannerPreview: "",
      bannerLink: "",
      bannerError: "",
      bannerImage: "",
      bannerLinkError: "",
      bannerNameErr: "",
      bannerCommonErr: "",
      bannerId: ""
    };

    this.bannerImgRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.createBannerTabApiCallId) {
        this.handleBannerTabResponse(responseJson);
      }
      if (apiRequestCallId === this.getBannerTabApiCallId) {
        this.setBannerTabResponse(responseJson);
      }
    }
  }

  setBannerTabResponse = (responseJson: IBannerResponse) => {
    if (responseJson.data[0]) {
      const { banner_name, banner_image, banner_url, id } = responseJson.data[0].attributes;
      this.setState({
        bannerName: banner_name,
        bannerPreview: banner_image,
        bannerLink: banner_url,
        bannerId: id
      });
    }
  };

  handleBannerTabResponse = (responseJson: IGridData) => {
    if (responseJson.data) {
      const message: Message = new Message(getName(MessageEnum.SendMessage));
      message.addData(getName(MessageEnum.ChangeActiveTabStore), { activeTab: "sectionOne", activeScreen: "sectionOne" });
      this.send(message);
    }
  };

  handleBannerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    const nameRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9&.,%()| -]{3,45}$/;
    this.setState({ bannerName: event.target.value });
    if (!nameRegex.test(name)) {
      this.setState({ bannerNameErr: "Should be Alphanumeric with some special characters allowed." });
    } else this.setState({ bannerNameErr: "" });
  };

  handleUploadBanner = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const targetElement = event.target as HTMLInputElement;
    const file: File = (targetElement.files as FileList)[0];
    if (!file) return;
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > 5) {
      return this.setState({ bannerError: configJSON.imageSizeErrorText });
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        bannerPreview: reader.result as string,
        bannerError: ""
      });
    };
    reader.readAsDataURL(file);

    this.setState(({ ["bannerImage"]: file } as unknown) as Pick<S, keyof S>);
  };

  handleOpenBanner = () => {
    if (this.bannerImgRef && this.bannerImgRef.current) {
      return this.bannerImgRef.current.click();
    }
  };

  handleRemoveBanner = () => {
    this.setState({ bannerPreview: null, bannerImage: "" });
  };

  handleBannerLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    const bannerLink = event.target.value;
    this.setState({ bannerLink: bannerLink });
    const urlPattern = /^(https:\/\/|www.)/;
    if (bannerLink === '' || !urlPattern.test(bannerLink)) {
      this.setState({ bannerLinkError: configJSON.validUrlErrorText });
    } else {
      this.setState({ bannerLinkError: "" });
    }
  };

  handleNext = () => {
    const { bannerError, bannerLinkError, bannerNameErr, bannerLink, bannerName, bannerPreview } = this.state;
    if (!bannerName || !bannerLink || !bannerPreview) {
      this.setState({ bannerCommonErr: configJSON.fillAllFieldsText });
    } else if (!bannerError && !bannerLinkError && !bannerNameErr) {
      this.setState({ bannerCommonErr: "" });
      this.createBanner();
    }
  };

  createBanner = async () => {
    const storeId = await getStorageData("store_id");
    const endPoint = this.state.bannerId ? `/store_dashboard_sections/${this.state.bannerId}` : "/store_dashboard_sections";
    const method = this.state.bannerId ? configJSON.patchMethod : configJSON.postMethod;
    const headers = { token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createBannerTabApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    const formData = new FormData();
    formData.append("section_name", "banner");
    formData.append("banner_name", this.state.bannerName);
    {
      this.state.bannerImage && formData.append("banner_image", this.state.bannerImage);
    }
    formData.append("banner_url", this.state.bannerLink);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    const storeId = await getStorageData("store_id");
    if (storeId) {
      this.getBannerInfo(storeId);
    }
  }

  getBannerInfo = async (id: string) => {
    const headers = { token: await getStorageData("authToken") };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getBannerTabApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + id + "/store_dashboard_sections");
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(message.id, message);
  };

  //Customizable Area End
}
