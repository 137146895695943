import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider, styled } from "@material-ui/core/styles";
import { Box, Container, Divider, Grid } from "@material-ui/core";
import PricingController, { Props } from "./PricingController.web";
import DOMPurify from "dompurify";
import { objectsImg } from "./EmailAccountRegistrationController";
import SellerLandingNavbar from "./SellerLandingNavbar.web";
import SellerFooter from "./SellerFooter.web";
import { Loader2 } from "../../../components/src/Loader.web";
// Customizable Area End

class Pricing extends PricingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  background = () => {
    return(
        <BackImgBox>
          <img src={objectsImg} width={"90%"} />
        </BackImgBox>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    // Customizable Area Start
    return (
      <>
        <SellerLandingNavbar navigation={this.props.navigation} id={this.props.id} />
        <ThemeProvider theme={theme}>
          <Box style={{  display: "flex", width: "100%",justifyContent: "center" }}>
            {this.background()}
            <Container style={{ position: "relative", textAlign: "start", maxWidth: "96vw", paddingLeft: "50px", marginLeft: 0  }}>
            {this.state.loading &&
                <Box sx={{
                  top: " 20%",
                  left: " 50%",
                  width: " 5%",
                  position: "relative",
                  marginBottom: " 100px",
                  height: " 50vh",
                }} id="loaderBox">
                  <Loader2 loading={this.state.loading} />
                </Box>
              }
                {this.state.pricingContent &&
                <CustomContentGrid container spacing={3}>
                <Grid style={{marginBottom:'60px', paddingLeft: '0px'}} item lg={12} sm={12} xs={12} >
                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.pricingContent.content)}} data-test-id="pricingContent">
                  </div>
                  </Grid>
              </CustomContentGrid>
                }
            </Container>
          </Box>
          <Divider />
          <SellerFooter navigation={this.props.navigation} id={this.props.id} />
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area End
}

// Customizable Area Start

const CustomContentGrid = styled(Grid)({
  marginLeft: "7px",
  marginTop:"160px" ,
  '@media (max-width:780px)' : {
    marginTop:"240px"
  }
})

const BackImgBox = styled(Box)({
  position: "absolute" as const, 
  top: 176, 
  left: 25, 
  zIndex: 0,
  '@media (max-width:600px)' : {
    top:"260px"
  }
})

export default Pricing;
// Customizable Area End
