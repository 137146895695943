//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { InputProps } from "@material-ui/core/Input";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { ISearchBrandResult } from "./Interfaces";
import { successImg } from "../../../catalogue/src/assets";
import { toast } from "react-toastify";
import { createRef } from "react";
interface FormValues {
  pdfFile: File | null;
}
export const initialValues: FormValues = {
  pdfFile: null
};
export const successIcon = successImg;
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    brandBtn: string;
    brandErr: string;
    serch: string;
    autoCompletePopover: string;
    submitButton: string;
    autoComplete: string;
    message: string;
    createBrandButtonContainer?:string;
    brandHeader: string
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  expandBar: boolean;
  restrictedRequestForm: boolean;
  requestAlreadySent: boolean;
  openSearchResultMenu: boolean;
  successRequestModal: boolean;
  loadingBrand: boolean;
  brandOptions: any[];
  keyword: string;
  brandErr: string;
  productId: string;
  selectedBrandId: string;
  selectedOption: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SearchBrandController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiSearchBrandCallId: string = "";
  apiSubmitRequest: string = "";
  apiVerifyBrandCallId: string = "";
  getBrandDetailsApiCallId: string = "";
  inputRef: React.RefObject<InputProps & { click: Function }>;
  formRef: React.RefObject<HTMLFormElement>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      expandBar: false,
      openSearchResultMenu: false,
      restrictedRequestForm: false,
      successRequestModal: false,
      loadingBrand: false,
      requestAlreadySent: false,
      brandOptions: [],
      keyword: "",
      brandErr: "",
      productId: "",
      selectedBrandId: "",
      selectedOption: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.inputRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const newBrandData = message.getData(getName(MessageEnum.BrandData));
      if (newBrandData) {
        this.handleSelectSearchBrandName("");
      }

      if (this.state.productId) {
        this.getBrandDetails();
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      this.handleSuccessResponse(responseJson, apiRequestCallId);
    }
  }
  // Customizable Area End

  // Customizable Area Start

  handleSuccessResponse = (responseJson: any, apiRequestCallId: any) => {
    switch (apiRequestCallId) {
      case this.apiSearchBrandCallId:
        this.handleSearchBrandResult(responseJson);
        break;

      case this.apiSubmitRequest:
        this.handleRequestResponse(responseJson);
        break;

      case this.apiVerifyBrandCallId:
        this.handleVerifyBrandResult(responseJson);
        break;

      case this.getBrandDetailsApiCallId:
        this.handleBrandDetailsResponse(responseJson);
        break;

      default:
        break;
    }
  };

  handleBrandDetailsResponse = (responseJson: unknown) => {
    if (responseJson.data) {
      const brandName = responseJson.data.attributes?.brand?.brand_name;
      this.setState({ keyword: brandName });
      this.searchBrand(brandName);
    }
  };

  handleVerifyBrandResult = (responseJson: unknown) => {
    const successData = responseJson as ISearchBrandResult;
    if (successData) {
      const goToPannel = this.props.id === configJSON.bulkUploadSearchBrandId ? configJSON.bulkUploadFilePath : configJSON.searchProductPath;
      setStorageData("brand_id", this.state.selectedBrandId);
      this.props.navigation.navigate(configJSON.sellerHomeText, {path: goToPannel})
      const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
      msg.addData(getName(MessageEnum.ChangeMenuPanel), goToPannel);
      msg.addData(getName(MessageEnum.ProductData), JSON.stringify(this.state.productId));
      return this.send(msg);
    }
  };

  handleCloseSucces = () => {
    this.setState({ successRequestModal: false });
  };

  handleOpenSucces = () => {
    this.setState({ successRequestModal: true });
  };

  handleRequestResponse = (responseJson: unknown) => {
    const successData = responseJson as ISearchBrandResult;
    if ("data" in successData) {
      this.handleOpenSucces();
      setTimeout(() => {
        this.handleCloseSucces();
        this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.addSingleHomeMenu})
      }, 3000);
    }
    if ("errors" in successData) {
      const key = Object.keys(responseJson.errors[0])[0];
      toast.error(`${JSON.stringify(responseJson.errors?.[0]?.[key])}`, {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  handleSearchBrandResult = (responseJson: unknown) => {
    this.setState({ loadingBrand: false });
    const successData = responseJson as ISearchBrandResult;
    if (Array.isArray(successData)) {
      return this.setState({ brandOptions: successData });
    } else {
      this.setState({ brandOptions: [] });
    }
  };
  handleGoToCreateBrand = () => {
    this.props.navigation.navigate(configJSON.sellerHomeText, {path: configJSON.brandCatalogMenu})
    const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    msg.addData(getName(MessageEnum.ChangeMenuPanel), configJSON.brandCatalogMenu);
    msg.addData(getName(MessageEnum.createBrandFromAddProduct), true);
    this.send(msg);
  };

  handleSearchOnChange = (event: ChangeEvent<{}>, value: string) => {
    this.setState({ keyword: value });
    if (value) {
      this.setState({ openSearchResultMenu: true });
      this.searchBrand(value);
    } else this.setState({ openSearchResultMenu: false });
  };

  handleOptionChange = (data: any) => {
    if (data) {
      this.setState({ selectedOption: data, selectedBrandId: data.id, openSearchResultMenu: false });
      if (!data?.restricted || !!data?.current_owner || (!!data?.restricted_request_exist && !!data?.permission_granted)) {
        setStorageData("brand_name_selected", data.brand_name);
        setStorageData("brand_id", data.id);
        this.setState({ requestAlreadySent: false, restrictedRequestForm: false });
      } else if (!!data?.restricted_request_exist && !data?.permission_granted) {
        this.setState({ requestAlreadySent: true, restrictedRequestForm: false });
      } else {
        this.setState({ restrictedRequestForm: true, requestAlreadySent: false });
      }
    }
  };

  handleSelectSearchBrandName = (keyword: string) => {
    this.setState({ keyword, openSearchResultMenu: false });
  };

  handleOnNext = () => {
    if (!this.state.selectedOption) {
      this.setState({ brandErr: "Please enter brand name" });
      return;
    }
    this.verifyBrand();
  };

  searchBrand = async (searchTerm: string) => {
    this.setState({ loadingBrand: true });
    const headers = { "Content-Type": configJSON.contentType, token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSearchBrandCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSearchBrandEndpoint + encodeURIComponent(searchTerm));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  verifyBrand = async () => {
    const headers = { "Content-Type": configJSON.contentType, token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiVerifyBrandCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiSearchBrandEndpoint + encodeURIComponent(this.state.keyword));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFileOpenInput = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.click();
    }
  };

  handleSubmitRequest = async (values: any) => {
    const formData = new FormData();
    formData.append("reseller_permit_document", values?.pdfFile);
    const headers = { token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSubmitRequest = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.requestBrandRestrict(this.state.selectedBrandId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBrandDetails = async () => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getBrandDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${this.state.productId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
