// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import WarehouseListController, {
  IWarehouseData,
  Props,
  configJSON,
} from "./WarehouseListController.web";
import { wareHouseImg } from "./assets";
import {
  CustomBackButton,
  CustomDeleteButton,
  OrangeButton,
} from "../../dashboard/src/CustomizedElementStyle";
import { ToastContainer } from "react-toastify";
import { Loader2 } from "../../../components/src/Loader.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WarehouseHome from "./WarehouseHome.web";
import { ClassNameMap } from "@material-ui/styles";
import { CustomOrangeButton } from "../../../components/src/CommonButton";

interface EnhancedTableProps {
  numSelected?: number;

  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy?: string;
  rowCount?: number;
  classes: ClassNameMap;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes } = props;

  return (
    <TableHead style={webStyle.backGrndColor}>
      <TableRow>
        <TableCell className={classes.tableHeaderText}>
          {configJSON.warehouseName}
        </TableCell>
        <TableCell className={classes.tableHeaderText} style={webStyle.processingHeader}>
          {configJSON.processingDays}
        </TableCell>
        <TableCell className={classes.tableHeaderText}>
          {configJSON.address}
        </TableCell>
        <TableCell className={classes.tableHeaderText}>
          {configJSON.contact}
        </TableCell>
        <TableCell className={classes.tableHeaderText}>
          {configJSON.actionLabel}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
// Customizable Area End

class WarehouseList extends WarehouseListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  deletModal = () => {
    return (
      <Modal open={this.state.openDeleteModal} data-test-id="deleteModalRef">
        <Box
          className={this.props.classes.modalBox}
        >
          <Box
            className={this.props.classes.boxOne}
          >
            <Box
              className={this.props.classes.boxTwo}
            >
              {configJSON.deleteWarehouseText}
            </Box>
            <Box
              className={this.props.classes.btnContainer}
            >
              <CustomBackButton
                data-test-id="backRef"
                onClick={this.handleCloseDeleteModal}
              >
                {configJSON.ReturnAndWarrenty.cancelButton}
              </CustomBackButton>
              <CustomDeleteButton
                data-test-id="deleteConfirmRef"
                onClick={this.deleteWarehouseHandler}
              >
                {configJSON.deleteBtnLabel}
              </CustomDeleteButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.loading && 
          <Loader2 loading={this.state.loading} />
        }

        {!this.state.loading && this.state.warehouseList.length === 0 ? (
          <WarehouseHome
            navigation={this.props.navigation}
            id={this.props.id}
          />
        ) : (
          <>
            <ToastContainer />
            <Box className={classes.text}>
              {configJSON.fulfilledByPartnerLabel}
            </Box>

            <Box className={classes.container}>
              <Box className={classes.warehouseLabel}>
                <Box className={classes.textLabel}>
                  {configJSON.wareHouseLabel}
                </Box>
              </Box>

              <Box>
                <Box className={classes.paddingTop}>
                  <TableContainer className={classes.tableContainer}>
                    <Table aria-label="simple table">
                      <EnhancedTableHead
                        data-test-id="tableHeader"
                        classes={classes}
                      />
                      <TableBody
                        data-test-id="warehouseList"
                        className={classes.layout}
                      >
                        {this.state.warehouseList &&
                        Array.isArray(this.state.warehouseList) &&
                        this.state.warehouseList.length > 0 ? (
                          this.state.warehouseList.map(
                            (item: IWarehouseData, index: number) => {
                              return (
                                <TableRow
                                  className={classes.border}
                                  key={index + "warehouse-list"}
                                  data-test-id="warehouseListRef"
                                >
                                  <TableCell className={classes.paddingLeft}>
                                    <Typography className={classes.tableData}>
                                      {item.attributes.warehouse_name}
                                    </Typography>
                                  </TableCell>

                                  <TableCell className={classes.paddingLeft}>
                                    <Typography className={classes.tableData}>
                                      {item.attributes.processing_days}
                                    </Typography>
                                  </TableCell>
                                  <TableCell className={classes.addressBox}>
                                    <Typography className={classes.tableData}>
                                      {item.attributes.warehouse_address_1}{" "}
                                      </Typography>
                                      <Typography className={classes.tableData}>
                                      {item.attributes.warehouse_address_2}
                                    </Typography>
                                    <Typography className={classes.tableData}>
                                      {""}
                                    </Typography>
                                  </TableCell>
                                  <TableCell className={classes.paddingLeft}>
                                    <Typography className={classes.tableData}>
                                      {item.attributes.contact_person}
                                    </Typography>
                                    <Typography className={classes.contact}>
                                      {item.attributes.contact_number}
                                    </Typography>
                                  </TableCell>
                                  <TableCell className={classes.paddingLeft}>
                                    <IconButton
                                      aria-label="more"
                                      aria-controls={`long-menu-${index}`}
                                      onClick={this.handleClickAction.bind(
                                        this,
                                        index
                                      )}
                                      data-test-id="addMoreIconRef"
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      id={`long-menu-${index}`}
                                      data-test-id="editRemoveRef"
                                      anchorEl={this.state.anchorEl[index]}
                                      keepMounted
                                      open={Boolean(this.state.anchorEl[index])}
                                      onClose={() =>
                                        this.handleCloseAction(index)
                                      }
                                      PaperProps={{
                                        style: webStyle.menu,
                                      }}
                                    >
                                      <MenuItem
                                        data-test-id="editRef"
                                        style={webStyle.menuItem}
                                        onClick={() =>
                                          this.editWarehouseHandler(
                                            item
                                          )
                                        }
                                      >
                                        {configJSON.editBtnLabel}
                                      </MenuItem>
                                      <MenuItem
                                        data-test-id="RemoveRef"
                                        style={webStyle.menuItem}
                                        onClick={() =>
                                          this.handleOpenDeleteModal(
                                            item.attributes.id
                                          )
                                        }
                                      >
                                        {configJSON.deleteBtnLabel}
                                      </MenuItem>
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )
                        ) : (
                          <TableRow className={classes.border}>
                            <TableCell colSpan={5}>
                              <Typography
                                variant="h6"
                                style={webStyle.nodataText}
                              >
                                {configJSON.noDataAvailableText}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
              <Box className={classes.paddingBottom}>
                <Box className={classes.box}>
                  <Grid container spacing={2} className={classes.flex}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.flex}
                    >
                      <img src={wareHouseImg} alt="warehouse_icon" />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.flex}
                    >
                      <CustomOrangeButton
                        className={classes.addButton}
                        onClick={this.handleAddNewWarehouse}
                      >
                        {configJSON.addNewWarehouseBtn}
                      </CustomOrangeButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.flex}
                    >
                      <Typography className={classes.description}>
                        {configJSON.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            {this.deletModal()}
          </>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = {
    tableContainer: {
      border: "0.5px solid #ccc",
      borderRadius: "8px",
    },
    layout: {
      backgroundColor: "white",
    },
    tableHeaderText: {
      color: "#333333",
      fontSize: "14px",
      paddingLeft: 30,
      fontFamily: `"Lato", sans-serif` as const,
      fontWeight: 500 as const,
      textAlign: "left" as const,
    },
    tableData: {
      color: "#697A8D",
      fontSize: "14px",
      fontWeight: 400 as const,
    },
    contact: {
      color: "#a1acb8",
      fontSize: "14px",
      fontWeight: 400 as const,
      width:'max-content'
    },
    box: {
      padding: "30px 0",
      margin: "80px 0",
      borderRadius: "10px",
      border: "1px dashed #f4aba9",
    },
    text: {
      fontFamily: `"Lato", sans-serif`,
      padding: "20px 0px 15px 0px",
      fontSize: "18px",
      fontWeight: 400,
    },
    container: {
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column" as const,
      padding: "30px",
      overflowY: "auto" as const,
      overflowX: "hidden" as const,
      position: "relative" as const,
      background: "white",
    },
    warehouseLabel: {
      width: "100%",
      flexDirection: "row" as const,
      display: "flex",
      flexWrap: "wrap" as const,
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10,
    },
    textLabel: {
      fontSize: "27.1px",
      fontWeight: 700,
      fontFamily: `"Lato", sans-serif` as const,
    },
    border: {
      borderBottom: "1px solid rgba(224,224,224,1)",
    },
    flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    addButton: {
      fontSize: 16,
      "@media screen and (max-width: 600px)" :{
        padding: "8px 16px",
      },
    },
    description: {
      color: "#999999",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: `"Lato", sans-serif`,
    },
    paddingTop: {
      paddingTop: "20px"
    },
    paddingBottom: {
      paddingBottom: "100px"
    },
    paddingLeft: {
      paddingLeft: 30
    },
    modalBox: {
      width: "100%",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center ",
      background: "rgba(0,0,0,0.1)"
    },
    boxOne: {
      width: "300px",
      height: "auto",
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      borderRadius: "8px",
      padding: "50px",
      background: "white"
    },
    boxTwo: {
      marginBottom: "20px",
      textAlign: "center" as const,
      fontFamily: `"Lato", sans-serif`,
    },
    btnContainer:  {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      gap: "15px",
    },
    addressBox: {
      paddingLeft: 30,
      display:'flex',
      flexDirection:'column' as const,
      borderBottom: 'none' as const
    }
};

  const webStyle = {
    backGrndColor: { 
      backgroundColor: "#f7f7f7" 
    },
    menu: {
      maxHeight: 200,
      width: "100px",
    },
    menuItem: {
      display: "block",
      padding: "7px 24px",
    },
    nodataText: {
      textAlign: "center" as const,
      fontFamily: `"Lato", sans-serif`,
      padding: "10px",
    },
    processingHeader: {
      width: '100px'
    }
  }

export { WarehouseList };
export default withStyles(styles)(WarehouseList);
// Customizable Area End
