import React from "react";

// Customizable Area Start
import { Box, Button, Grid, InputAdornment, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { BoxNoScroll, CustomizeInputSearchBox, FormControlTwo, MenuItemTwo, SearchIconSVG } from "../../dashboard/src/CustomizedElementStyle";
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

import SellerAddBrandProductController, { IBrandData, ISearchProductBrandData, Props, configJSON, configJSON2 } from "./SellerAddBrandProductController";
import { Pagination } from "@material-ui/lab";

export default class SellerAddBrandProduct extends SellerAddBrandProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  productSelectionComponent = () => {
    const {classes} = this.props;
    return (
      <>
        <Box className={classes.headingBox}>
          <Box className={classes.topHeader}>{configJSON.searchBrandProductText}</Box>
        </Box>
        <Box className={classes.line}></Box>

        <Grid item lg={6} sm={12} xs={12}>
          <FormControlTwo fullWidth={true}>
            <Box sx={webStyle.brandName}>{configJSON.selectBrand}</Box>
            <Select
              data-test-id="selectBrandDropdown"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              placeholder="Select Brand"
              value={this.state.brandValue}
              onChange={(event:React.ChangeEvent<{value: unknown}>) => this.handleChangeBrandName(event.target.value as string)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: webStyle.brand
                }
              }}
              displayEmpty
            >
              <MenuItemTwo value="" disabled >{configJSON.selectBrand}</MenuItemTwo>
              {this.state.brandList.length > 0 && this.state.brandList.map((item: IBrandData) => <MenuItemTwo data-test-id="brandName" value={item.attributes.id}>{item.attributes.brand_name}</MenuItemTwo>)}
            </Select>
            {this.state.brandErr ? <Typography className={classes.error} data-test-id="brandNameErr">{this.state.brandErr}</Typography>: ''}
          </FormControlTwo>
        </Grid>

        <Grid>
          <Box className={classes.desc}>{configJSON.searchProductDes}</Box>
        </Grid>
        <Box className={classes.section}>

          <Grid container spacing={0} style={{marginLeft: "10px"}}>
            <Grid item lg={4} md={4} sm={4} xs={6} className={classes.productBtn}>
              <Button className={classes.productSearch}>{configJSON2.productNameText}</Button>
            </Grid>

            <Grid item lg={8} md={8} sm={12} xs={12} className={classes.searchWrap}>
              <Box sx={webStyle.search}>
                <CustomizeInputSearchBox
                  data-test-id="brandNameRef"
                  id="outlined-start-adornment"
                  autoComplete="off"
                  value={this.state.searchKeyword}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.searchProducts(event)
                  }}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start" className={classes.rightMargin}></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end" className={classes.rightPadding}>
                        <SearchIconSVG />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.resultsTextContainer}>
        <Box className={classes.prodResultsText}>{this.state.searchKeyword ? `found ${this.state.productList.length} results for ${this.state.searchKeyword}` : ""}</Box>
        {this.state.searchKeyword && <Box className={classes.description}>{configJSON2.resultDes}</Box> }
        </Box>  
          <TableContainer className={classes.tableWrap}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead className={classes.theader}>
                <TableRow>
                  <TableCell align="center" rowSpan={2} colSpan={1} className={classes.tableHeading}>{configJSON2.imageLabel}</TableCell>
                  <TableCell className={classes.tableHeading}>{configJSON2.titleText}</TableCell>
                  <TableCell className={classes.tableHeading}>{configJSON2.categoryLabel}</TableCell>
                  <TableCell className={classes.tableHeading}>{configJSON2.besku}</TableCell>
                  <TableCell className={classes.tableHeading}>{configJSON2.modelLabel}</TableCell>
                  <TableCell className={classes.tableHeading}>{configJSON2.detailsLabel}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody ref={this.tableRef}>
                {this.state.productList.length > 0 ?
                  this.state.productList.map((item: ISearchProductBrandData, index: number) => {
                    const modelNumber = item.attributes.product_content.product_attributes.size_and_capacity?.prod_model_number
                    const isVariant = item.attributes.is_variant
                    const description = item.attributes?.product_content.product_attributes?.long_description
                    const product_besku = item.attributes.variant_product_group?.product_besku
                    return (
                      <>
                      <TableRow data-test-id="list" key={`${item.id}-${modelNumber}`}>
                        <TableCell align="center">
                          {" "}
                          <img
                            src={item.attributes.product_image}
                            alt="img"
                            className={classes.brandImage}
                          />
                        </TableCell>
                        <TableCell align="center" className={classes.productTitle}>{item.attributes.product_title}</TableCell>
                        <TableCell align="center" className={classes.title}>{item.attributes.category?.name}</TableCell>
                        <TableCell align="center" className={classes.title}>{isVariant ? product_besku : item.attributes?.besku}</TableCell>
                        <TableCell align="center" className={classes.title}>{modelNumber}</TableCell>
                        <TableCell align="center" className={classes.prodDescription}>{description.length > 70 ? `${description.slice(0,70)}...` : description }<span>{description.length > 70 && <Typography data-test-id="viewMore" onClick={() => this.handleViewMoreBtn(description)} style={{cursor:'pointer',fontFamily:'Lato,sans-serif', fontWeight:600, fontSize:'14px', color:'#ff6008'}}>View more</Typography>}</span></TableCell>
                      </TableRow>
                  </>
                    );
                  })
                :
                (
                  <TableCell colSpan={8}>
                    <Typography variant="h6" className={classes.noProdText}>
                      {configJSON.noDataAvailableText}
                    </Typography>
                  </TableCell>
                )
                }
              </TableBody>
            </Table>
          </TableContainer>
          {this.state.productList.length > 0 && 
            <Pagination
              data-test-id="pagination"
              style={webStyle.paginationStyle}
              page={this.state.pageNumber}
              count={this.getBrandProdCount()}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                this.setState({ pageNumber: value }, () => this.getBrandProductList())
              }}
              shape="rounded"
              siblingCount={0}
              boundaryCount={1}
            />
          }
        {this.state.selectProductScreen && (
          <>
            <Box sx={webStyle.addNewProductBtn}>
              <Button 
                startIcon={<AddIcon />}
                data-test-id={"addNewProductButton"}
                variant="text"
                color="primary"
                style={webStyle.addNew}
                onClick={this.handleAddNewProductBtn}
              >
                {configJSON2.addNewProductText}
              </Button>
            </Box>
          </>
        )}

        <Modal open={this.state.isViewMore}>
          <Box style={webStyle.descModal}>
          <Box 
            style={webStyle.detailsBox}>
              <Box style={webStyle.closeSec}>
              <CloseIcon data-test-id="closeDescIcon" onClick={this.handleCloseDes} style={webStyle.closeImg}/>
            </Box>
              <Typography style={webStyle.detailsText}>Details</Typography>
          <Typography style={webStyle.detailsData}>{this.state.descData}</Typography>
        </Box>
        </Box>
      </Modal>
      </>
    );
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    const {classes} = this.props;
    return (
      <>
        <Box className={classes.main}>{configJSON.brandCatalogText}</Box>
        <BoxNoScroll className={classes.noScroll}>
          <Box className={classes.wrapper}>
            {this.state.activeStep === "listProduct" && this.productSelectionComponent()}
            
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  tableHeading : {
    color: '#33333a',
    fontFamily: "Lato-Heavy, sans-serif",
    fontSize: '14px',
    fontWeight: 600,
    background: "linear-gradient(180deg, #f8f8f8 0%, #eeeeee 100%)",
    textAlign:'center' as const
  },
  title: {
    color: '#697a8d',
    fontFamily: "Lato, sans-serif",
    fontSize: '14px',
    fontWeight: 400
  },
  productTitle: {
    color: '#697a8d',
    fontFamily: "Lato, sans-serif",
    fontSize: '14px',
    fontWeight: 400,
    maxWidth:'200px',
    wordBreak: 'break-all' as const
  },
  viewBtn: {
    color: 'rgb(255, 85, 111)',
    fontFamily: "Lato, sans-serif",
    fontSize: '13px',
    fontWeight: 400,
    backgroundColor: 'rgb(255, 229, 231)',
    borderRadius:'4px',
    textTransform: 'capitalize' as const,
    width:'100px'
  },
  brandRadio: {
    color: "#FF6008",
    marginRight: "-20px",
    paddingLeft: "0",
    "&.Mui-checked": {
      color: "#FF6008"
    }
  },
  brandImage: {
    width: "43px",
    height: "43px",
    borderRadius: "5px",
    background: "rgb(231,231,231)",
    padding: "8px",
    '@media (max-width: 460px)': {
      width: "30px",
      height: "30px"
    }
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "flex-end",
    color: "#B1B2B4",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px"
  },
  footerLabel: {
    padding: "30px 0px 14px 20px",
    fontWeight: 600
  },
  menuItem: {
      color: "#2e2d2d",
      padding: "15px 30px",
      fontSize: 14,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500,
      backgroundColor: '#fff',
      "&:hover": {
        backgroundColor: '#fff',
        color: '#ff6008'
      },
      "&:selection": {
        color: "#ff6008"
      },
      "&.MuiListItem-root.Mui-selected": {
        color: "#ff6008",
        backgroundColor: '#fff !important'
      }
  },
  theader: {
    border: '1px solid rgba(151,151,151,0.33)',
    background: 'linear-gradient(180deg, #f8f8f8 0%, #eeeeee 100%)',
    "&.MuiTableHead-root": {
      // border: "1px solid rgba(151,151,151,0.33)"
    }
  },
  tableWrap: {
    overflowX: "auto" as const,
    maxWidth: "100%"
  },
  searchOption: {
    background: "#000",
    color: "#fff",
    border: "none",
    padding: "6px 0 7px !important",
    zIndex: 2
  },
  resultsTextContainer: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row" as const,
    marginTop: 30
  },
  searchResText: {
    color: "#666666",
    fontWeight: 1000,
    fontSize: 12,
    fontFamily: `"Lato", sans-serif`,
    marginRight: 5
  },
  description : {
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 500,
    color: "#666666",
    fontSize: 12
  },
  searchWrap: {
    marginLeft: '-10px',
    '@media (max-width:460px)': {
      marginLeft:'0'
    }
  },
  rightPadding: { paddingRight: 20 },
  rightMargin: { marginRight: 20 },
  wrapper: { 
    borderRadius: "8px", 
    display: "flex", 
    flexDirection: "column" as const, 
    padding: "30px", 
    overflowY: "auto" as const, 
    overflowX: "hidden" as const, 
    position: "relative" as const, 
    background: "white" 
  },
  main: { 
    padding: "20px 0px 15px 0px", 
    fontSize: "18px", 
    fontWeight: 400, 
    fontFamily: `"Lato", sans-serif` 
  },
  noScroll: { 
    paddingBottom: 20, 
    overflow: "auto" as const
  },
  section: { 
    width: "100%", 
    display: "flex", 
    justifyContent: "space-between"
  },
  desc: { 
    color: "#9a9a9a", 
    fontFamily: `"Lato", sans-serif`, 
    fontWeight: 600, 
    fontSize: 12, 
    marginBottom: 10, 
    marginTop: 20 
  },
  headingBox: { 
    width: "100%", 
    display: "flex", 
    flexDirection: "row" as const, 
    flexWrap: "wrap" as const, 
    justifyContent: "space-between", 
    alignItems: "center", 
    marginBottom: 10 
  },
  topHeader: { 
    fontSize: "27px", 
    fontWeight: 700, 
    fontFamily: `"Lato", sans-serif` ,
    '@media (max-width: 460px)': {
      fontSize: '24px'
    }
  },
  line: { 
    width: "calc(100% + 60px)", 
    height: 1, 
    background: "#EEEEEE", 
    position: "absolute" as const, 
    left: 0, 
    top: 75
  } ,
  productSearch: {
    zIndex: 1,
    marginLeft: "-10px",
    textTransform: "none" as const,
    width: "100%",
    cursor: "none",
    height: "46px",
    padding: "11px 0 11px",
    background: "#000",
    color: "#fff",
    border: "none",
    minWidth: "180px",
    "&:hover": {
      color: "#fff",
      background: "#000"
    },
    '@media (max-width: 960px)': {
      fontSize: "12px"
    }
  },
  noProdText: {
    border: "none",
    textAlign: "center" as const,
    fontFamily: `"Lato", sans-serif`,
    padding: "10px"
  },
  prodDescription: {
    color: '#697a8d',
    fontFamily: "Lato, sans-serif",
    fontSize: '14px',
    fontWeight: 400,
    maxWidth:'100px',
    wordWrap: 'break-word' as const,
    // display: '-webkit-box',
    // overflow: 'hidden',
    // '-webkit-line-clamp': 2,
    // '-webkit-box-orient': 'vertical',
    // height: 'calc(1.5em * 2)',
    // textOverflow: 'ellipsis',
  },
  error: {
    fontSize: "12px",
    marginRight: "20px",
    marginTop: "5px",
    fontFamily: `"Lato", sans-serif`,
    color: "red",
    textAlign: "left" as const,
  },
  prodResultsText: {
    color: "#666666",
    fontWeight: 1000,
    fontSize: 12,
    fontFamily: `"Lato", sans-serif`,
    marginRight: 5
  },
  productBtn: {
    '@media (max-width: 600px)': {
      marginBottom:'10px'
    }
  }
}

const webStyle = {
  selectStyle: {
    color: '#ff6008',
    background: "#FFF",
    border: "1px solid rgba(151, 151, 151, 0.147084)",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0468298)",
    marginTop: "8px",
    maxHeight: "400px", 
    overflowY: "auto" as const
  },
  search: { position: "relative" },
  searchProduct: { width: "100%" },
  validateBtn: {
    textTransform: "none" as const,
    fontFamily: "'Montserrat Variable', sans-serif",
    fontWeight: 700,
    fontSize: 16,
    background: "#000",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px"
  },
  filterBtnWrap: { 
    fontSize: "27px", 
    fontWeight: 700, 
    fontFamily: `"Lato", sans-serif` 
  },
  filter: {
    padding: "7px 23px",
    color: "#657A8E",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 16,
    fontWeight: 500 as const,
    border: "1px solid rgba(101, 122, 142, 0.320241)",
    borderRadius: 5,
    textTransform:"none" as const,
    backgroundColor: 'rgb(242,243,244)'
  },
  addNewProductBtn: { 
    width: "100%", 
    display: "flex", 
    justifyContent: "flex-start", 
    marginTop: 30 
  },
  addNew: {
    textTransform: "none" as const,
    fontFamily: "'Lato', sans-serif",
    fontWeight: 600,
    fontSize: 15,
    background: "#0fba9f",
    color: "#fff",
    padding: "8px 18px"
  },
  continueBtnWrap: { 
    width: "100%", 
    display: "flex", 
    justifyContent: "flex-end", 
    marginTop: 30 
  },
  continueBtn: {
    textTransform: "none" as const,
    fontFamily: "'Montserrat Variable', sans-serif",
    fontWeight: 700,
    fontSize: 16,
    background: "#FF6008",
    color: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    padding: "15px 50px"
  },
  brandName: { 
    fontFamily: `"Lato", sans-serif`, 
    fontWeight: 600, 
    color: "#9a9a9a", 
    fontSize: 12, 
    marginBottom: 10 ,
    marginTop: '36px'
  },
  brand: {
    marginTop: "5px",
    padding: 0,
    background: "white",
    border: "1px solid #b1b3b5",
    boxShadow: "none",
    borderRadius: 5,
    maxHeight: "400px",
    overflowY: "auto" as const
  },
  paginationStyle: {
    marginTop: "15px",
    display: "flex",
    alignItems: 'center',
    justifyContent: "center"
  },
  descModal: {
    display: "flex", 
    alignItems: "center", 
    width: "100%", 
    height: "100vh", 
    justifyContent: "center" 
  },
  closeSec: { 
    fontFamily: `"Lato", sans-serif`, 
    textAlign: "right" as const,
    margin:'-20px -20px 0px 0',
  },
  closeImg: {
    padding: '4px',
    cursor:'pointer',
    fontSize: '24px', 
  },
  detailsText: {
    textAlign:'center' as const, 
    fontSize:'18px', 
    fontFamily:'Lato, sans-serif', 
    fontWeight:600, 
    marginBottom:'10px'
  },
  detailsData: {
    textAlign:'center'as const, 
    fontSize:'14px', 
    fontFamily:'Lato, sans-serif', 
    fontWeight:400, 
    wordBreak:'break-all' as const
  },
  detailsBox: {
    padding:'30px', 
    background:'#fff', 
    width:'240px', 
    height:'auto',
    display: "flex", 
    flexDirection: "column" as const, 
    justifyContent: "center", 
    borderRadius: "8px"
  }

}

const styledAddBrand = withStyles(styles)(SellerAddBrandProduct)
export {styledAddBrand}
// Customizable Area End
