// Customizable Area Start
//@ts-nocheck
import { Grid,Box, Typography, Button, Modal, Select, IconButton } from "@material-ui/core";
import React from "react";
import { Divider } from "react-native-elements";
import { ExpressLogo, successImg, deleteIconPng } from "./assets";
import UserWishlistController, { configJSON } from "./UserWishlistController";
import { CustomBackButton, MenuItemTwo, FormControlTwo } from "../../dashboard/src/CustomizedElementStyle";
import { calculateDiscountPercentage } from "../../catalogue/src/BuyerSearchProduct/ProductListController.web";
import Close from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Loader2 } from "../../../components/src/Loader.web";

class UserWishlist extends UserWishlistController {

    DeleteVerifyModal = () => {
        const title = this.state.selectedProductDetails && this.state.selectedProductDetails.attributes.catalogue.data.attributes.product_title
        return (
            <Modal open={this.state.showVerifyDeleteModal}>
                <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ fontFamily: "Lato, sans-serif", background: "rgba(0,0,0,0.1)", }}>
                    <Box sx={{ width: "300px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "30px", }} style={{ fontFamily: "Lato, sans-serif", background: "white", position: 'relative' }}>
                        <Box sx={{
                            position: "absolute",
                            top: "14px",
                            right: "14px",
                        }}>
                            <IconButton data-test-id="undoCrossRef" aria-label="delete" style={{ cursor: 'pointer', padding: '7px', zIndex: 9999 }} onClick={() => this.handleCloseUndoModal()} >
                                <Close style={{ cursor: 'pointer' }} />
                            </IconButton>
                        </Box>
                        <Box style={{ fontFamily: "Lato, sans-serif", position: 'relative', display: 'flex' }} >

                            <img src={deleteIconPng} alt="rounded Circl" style={{ fontFamily: "Lato, sans-serif", width: "110px", margin: "15px auto" }} />

                        </Box>
                        <Typography style={{ fontFamily: "Lato, sans-serif", marginBottom: "6px", textAlign: "center", color: "#565656", fontSize: 18, fontWeight: 600 }}>{"Product Removed"}</Typography>
                        <Typography style={{ fontFamily: "Lato, sans-serif", marginBottom: "40px", textAlign: "center", color: "#666666", fontSize: 12, wordBreak: 'break-all' }}>{title}</Typography>

                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <CustomBackButton data-testid="undoRef" onClick={() => this.handleUndoRemoveCall()} style={{ width: '180px' }}>
                                Undo
                            </CustomBackButton>

                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    CheckoutModal = () => {
        return (
            <Modal open={this.state.checkoutModal}>
                <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ fontFamily: "Lato, sans-serif", background: "rgba(0,0,0,0.1)" }}>
                    <Box sx={{ position: 'relative', width: "300px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "30px 40px" }} style={{ fontFamily: "Lato, sans-serif", background: "white" }}>
                        <Box sx={{
                            position: "absolute",
                            top: "14px",
                            right: "14px",
                        }}>
                            <IconButton aria-label="delete" style={{ cursor: 'pointer', padding: '7px' }} onClick={this.handleCloseCheckout}>
                                <Close style={{ cursor: 'pointer' }} />
                            </IconButton>
                        </Box>
                        <Box style={{ fontFamily: "Lato, sans-serif", display: "flex", justifyContent: "center", flexDirection: "column" }} >
                            <img src={successImg} alt="rounded Circl" style={{ fontFamily: "Lato, sans-serif", margin: 'auto', width: 65 }} />
                        </Box>
                        <Typography style={{ marginTop: "5px", fontWeight: 600, fontSize: "20px", textAlign: "center", fontFamily: `"Lato", sans-serif`, color: "black" }}>Successfully</Typography>
                        <Typography style={{ marginBottom: "40px", marginTop: '5px', textAlign: "center", fontFamily: `"Lato", sans-serif`, color: "black" }}>1 Item added to the cart</Typography>

                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: '15px' }}>
                            <Button
                                data-testid="checkoutRef"
                                onClick={() => this.handleNavigateToCheckout()}
                                style={{
                                    fontFamily: "Lato, sans-serif",
                                    textTransform: "none",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    background: "#454545",
                                    color: "white",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    padding: "8px 20px",
                                    letterSpacing: 1
                                }}>
                                CHECKOUT
                            </Button>
                            <Button
                                data-testid="continueRef"
                                onClick={() => this.handleCloseCheckout()}
                                style={{
                                    fontFamily: "Lato, sans-serif",
                                    textTransform: "none",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    background: "#FF6008",
                                    color: "white",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    padding: "8px 20px",
                                    letterSpacing: 1
                                }}>
                                CONTINUE
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        );
    };

    personalDetails(classes) {
        const wishList = this.state.whishList && this.state.whishList.map((item) => {
            const productDetails = item.attributes.catalogue.data.attributes.product_content && item.attributes.catalogue.data.attributes.product_content.product_attributes;
            const isItemAvailable = item.attributes.catalogue.data.attributes.stocks === 0 || item.attributes.catalogue.data.attributes.stocks === null || item.attributes.catalogue.data.attributes.content_status === "archived" || !item.attributes.catalogue.data.attributes.status
            const {product_image, product_title } = item.attributes.catalogue.data.attributes
            const rating = item.attributes.catalogue.data.attributes.product_rating || null
            const {id} = item.attributes.catalogue.data
            return (
                <Box data-test-id="products" className={classes.mainContainer} >
                    <Grid container className={classes.productMainContainer} >
                        <Grid item xs={12} sm={2} md={3} lg={3} className={classes.imageBox}>
                            <Box className={classes.newBox}>
                                <Typography className={classes.newText}>NEW</Typography>
                            </Box>
                            <Box data-test-id="imageBox" style={{width:'auto', height:'80%', cursor:'pointer'}} onClick={() => this.handleViewProduct(id)} >
                            <img src={product_image} alt="img" className={classes.imageContainer} ></img>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10} md={9} lg={9} style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                            <Box style={{display:'flex', justifyContent:'space-between'}}>
                            <Box className={classes.left}>
                            <Typography data-test-id="prodTitle" className={classes.title} onClick={() => this.handleViewProduct(id)} >{product_title}</Typography>
                            <Box className={classes.svgBox}>
                                <Typography className={classes.rating} data-test-id="ratings">{rating?.average_rating || 0}
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 576 512"><path fill="white" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg>
                                </Typography>
                                <span className={classes.reviews}>{rating?.total_reviews || 0}</span>
                            </Box>
                            </Box>
                            <Box className={classes.priceMainContainer}>
                            <img src={ExpressLogo} alt="express" className={classes.expressLogo} />
                            <Typography className={classes.price}><span style={{ fontFamily: "Lato, sans-serif", fontSize: 13 }}>EAD</span> {productDetails?.retail_price || 0}</Typography>
                            {calculateDiscountPercentage(productDetails?.mrp, productDetails?.retail_price) > 0 && <>
                                <Typography style={{ fontFamily: "Lato, sans-serif", color: "rgb(138 135 135)", display: "inline", textDecoration: "line-through", marginTop: 10, marginRight: 5 }}>{productDetails.mrp || 0}</Typography>
                                <span style={{ fontFamily: "Lato, sans-serif", whiteSpace: 'nowrap' }}>{calculateDiscountPercentage(productDetails.mrp, productDetails.retail_price)}% OFF</span>
                            </>}
                            </Box>
                            </Box>
                            <Box style={{padding:'0 0 0 15px'}}>
                            { isItemAvailable && <Typography 
                                variant="body1" 
                                className={classes.outOfStock}
                            >
                                {configJSON.outOfStockText}
                            </Typography>}
                            <Box className={classes.actionButton}>

                                <Button
                                    data-test-id="saveRef"
                                    onClick={() => this.addToCartApiCall(id)}
                                    disabled={isItemAvailable}
                                    className={classes.cartButton}>
                                    ADD TO CART
                                </Button>
                                <Button
                                    data-test-id="deleteRef"
                                    onClick={() => this.handleOpenVerifyDeleteModal(item)}
                                    className={classes.removeBtn}>
                                    REMOVE
                                </Button>
                            </Box>
                            </Box>
                            <Box className={classes.actionButtonMobile}>
                                <Button
                                    data-test-id="saveRefMobile"
                                    onClick={() => this.addToCartApiCall(id)}
                                    disabled={isItemAvailable}
                                    className={classes.orangeBtn}>
                                    ADD TO CART
                                </Button>
                                <Button
                                    data-test-id="deleteRefMobile"
                                    onClick={() => this.handleOpenVerifyDeleteModal(item)}
                                    className={classes.removeButn}>
                                    REMOVE
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box >

            )
        })

        return wishList
    }
    // Customizable Area End

    // Customizable Area Start
    render() {
        const { classes } = this.props;
        return (

            <>
                {this.DeleteVerifyModal()}
                {this.CheckoutModal()}
                <Grid container className={classes.topContainer} >
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Typography variant="h4" style={webStyle.headerTxt}>{configJSON.wishlistText}</Typography>
                    </Grid> 
                    {!this.noWishlistFound() &&
                    <Grid item xs={12} sm={8} md={8} lg={8} className={classes.headerTxtOne}>
                        <Grid container className={classes.filterGrid}>
                            <Grid item xs={12} sm={3} md={3} lg={3} className={classes.container}>
                            <FormControlTwo className={classes.formWidth}>
                            <Typography className={classes.sortText}>{configJSON.displayText}</Typography>
                                <Select 
                                    className={classes.filterBackgrnd}
                                    data-test-id="selectPerPaage"
                                    defaultValue="50"
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.perPage}
                                    onChange={this.handlePerPageFilter}
                                    inputProps={{
                                        classes: {
                                            select: classes.sortDisplay,
                                            },
                                    }}
                                    IconComponent={CustomWishlistExpandMoreIcon}
                                    MenuProps={{
                                        transformOrigin: {
                                            horizontal: "left",
                                            vertical: "top"
                                        },
                                        anchorOrigin: {
                                            horizontal: "left",
                                            vertical: "bottom"
                                        },
                                        PaperProps: {
                                            style: {
                                                boxShadow: "none",
                                                borderRadius: 5,
                                                border: "1px solid #b1b3b5",
                                                padding: 0,
                                                marginTop: "5px",
                                                background: "white",
                                                overflowY: "auto",
                                                maxHeight: "400px",
                                            }
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    <MenuItemTwo value={50}>50 per page</MenuItemTwo>
                                    <MenuItemTwo value={40}>40 per page</MenuItemTwo>
                                    <MenuItemTwo value={30}>30 per page</MenuItemTwo>
                                </Select>
                            </FormControlTwo>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} className={classes.container}>
                            <FormControlTwo style={webStyle.formWidth}>
                            <Typography className={classes.sortText}>Sort by</Typography>
                                <Select 
                                    data-test-id="sortValue"
                                    className={classes.filterBackgrnd}
                                    defaultValue="recommended"
                                    labelId="demo-simple-select-outlined-label"
                                    value={this.state.selectedValue}
                                    onChange={this.handleSelect}
                                    IconComponent={CustomWishlistExpandMoreIcon}
                                    inputProps={{
                                        classes: {
                                            select: classes.sortDisplay,
                                            },
                                    }}
                                    placeholder="Recommended"
                                    id="demo-simple-select-outlined"
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        PaperProps: {
                                            style: {
                                                overflowY: "auto",
                                                marginTop: "5px",
                                                maxHeight: "400px",
                                                border: "1px solid #b1b3b5",
                                                padding: 0,
                                                boxShadow: "none",
                                                borderRadius: 5,
                                                background: "white",
                                            }
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    <MenuItemTwo value="recommended">
                                        Recommended
                                    </MenuItemTwo>
                                    <MenuItemTwo value="whats_new">
                                        What's New
                                    </MenuItemTwo>
                                    <MenuItemTwo value="popularity">Popularity</MenuItemTwo>
                                    <MenuItemTwo value="customer_rating">Customer Rating</MenuItemTwo>
                                    <MenuItemTwo value="high_to_low">High to Low</MenuItemTwo>
                                    <MenuItemTwo value="low_to_high">Low to High</MenuItemTwo>
                                </Select>
                            </FormControlTwo>
                            </Grid>
                        </Grid>
                    </Grid>
                     }

                </Grid>
                {!this.noWishlistFound() && <Divider className={classes.divider} />}

                {this.personalDetails(classes)}
                {this.noWishlistFound() &&
                    <Box id="noWishList" style={{ height: '300px', textAlign: 'center', display: 'flex' }}>
                        <Typography style={webStyle.emptyWishlist}>{configJSON.noWishlistText}</Typography>
                    </Box>
                }
                {this.state.wishlistLoader &&
                    (<Box sx={webStyle.loaderWrapper} id="loaderBox">
                        <Loader2 loading={this.state.wishlistLoader} />
                    </Box>)
                }

            </>

        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const styles = {
    mainContainer: {
        padding: "10px",
        fontFamily: "Lato, sans-serif" as const,
        justifyContent: "space-between",
        borderRadius: "5px",
        border: "2px  solid #F2F2F2",
        margin: "10px",
        display: "flex",
        height: "unset",
        gap:'50px',
        '@media (max-width:600px)':{
            gap:'20px'
        },
        '@media (max-width:460px)': {
            flexDirection: "column" as const,
            gap:'0'
        }
    },
    productMainContainer: {
        display: "flex",
        width: '100%',
        height:'196px',
        flexWrap:'nowrap' as const,
        '@media (max-width:460px)': {
            flexDirection: "column" as const,
            fontFamily: "Lato, sans-serif"  as const,
            gap:'5px',
            height:'auto'
        }
    },
    imageContainer: {
        fontFamily: "Lato, sans-serif"  as const,
        width: "100%",
        marginTop: "15px",
        height:'100%',
        '@media (max-width:460px)': {
            display: 'flex',
            height:'180px',
            objectFit:'fill',
            margin: '15px auto auto auto'
        }
    },
    actionButton: {
        fontFamily: "Lato, sans-serif"  as const,
        display: 'flex',
        gap: 10,
        '@media (max-width:860px)': {
            marginTop:'0'
        },
        '@media (max-width:460px)': {
            display: 'none',
        }
    },
    actionButtonMobile: {
        fontFamily: "Lato, sans-serif"  as const,
        marginTop: 30,
        gap: 10,
        display: 'none',
        '@media (max-width:460px)': {
            display: 'flex',
            justifyContent:'space-around'
        },
        "& button": {
            "&.Mui-disabled":{
                backgroundColor: "grey !important",
                color: "white"
            }
        }
    },
    topContainer: {
        fontFamily: "Lato, sans-serif" as const,
        display: "flex",
        margin: "10px",
        justifyContent: "space-between",
        '@media (max-width:460px)': {
            flexDirection: "column",
            gap: "10px",
        }
    },
    selectRoot: {
        padding: '8px 12px', 
        paddingRight: '32px', 
      },
      select: {
        width: '120px', 
        '&:focus': {
          backgroundColor: 'transparent', 
        },
      },
      headerTxtOne: { 
        fontFamily: "Lato, sans-serif", 
        display: "flex", 
        gap: "10px" ,
        justifyContent:'space-between'
    },
    container: { 
        fontFamily: "Lato, sans-serif", 
        display: "flex", 
        gap: "5px", 
        flexDirection: "column" ,
        width:'200px',
        maxWidth: '200px'
    },
    sortText: { 
        fontFamily: "Lato, sans-serif", 
        color: "#4a4a4a" ,
        marginBottom:'6px',
        fontWeight:600

    },
    gridContainer: { 
        fontFamily: "Lato, sans-serif", 
        marginInline: "10px", 
        display: "flex", 
        gap: "5px", 
        flexDirection: "column" 
    },
    image: { 
        fontFamily: "Lato, sans-serif", 
        width: "40px" 
    },
    divider: { 
        fontFamily: "Lato, sans-serif", 
        height: "1px", 
        margin: "10px", 
        marginBottom: '20px' 
    },
    imageBox: {
        display:'flex', 
        flexDirection:'column', 
        fontFamily: "Lato, sans-serif", 
        background: "#E7E7E7", 
        borderRadius: "5px", 
        padding: "10px" 
    },
    newBox: { 
        fontFamily: "Lato, sans-serif", 
        display: "flex", 
        justifyContent: "flex-end"
    },
    newText: {
        fontFamily: "Lato, sans-serif", background: "rgb(253, 97, 12)", textAlign: "center", color: "white",
        fontSize: "9px",
        borderRadius: "3px",
        padding: "1px 4px"
    },
    left: { 
        fontFamily: "Lato, sans-serif", 
        padding: "0 15px", 
        display: "flex", 
        flexDirection: "column", 
        margin: "6px 0" 
    },
    title: { 
        color: "black", 
        fontWeight: 700, 
        fontSize: 24, 
        lineHeight: 1.1, 
        fontFamily: "Lato, sans-serif" ,
        display: '-webkit-box',
        overflow: 'hidden',
        cursor:'pointer',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        textOverflow: 'ellipsis',
        wordBreak: 'break-all' as const,
        '@media (max-width:860px)': {
            fontSize: "20px"
        },
        '@media and (max-width:600px)': {
            fontSize: '18px'
        }
    },
    svgBox: {
        fontFamily: "Lato, sans-serif", 
        marginTop: 10 
    },
    rating: { 
        fontFamily: "Lato, sans-serif", 
        display: "inline-flex", 
        alignItems: 'center', 
        marginRight: 5, 
        lineHeight: 'normal', 
        background: "#565656", 
        padding: "2px 8px", 
        borderRadius: "15px", 
        gap: 3, 
        color: "white", 
        fontSize: 14 
    },
    reviews: { 
        fontFamily: "Lato, sans-serif", 
        color: '#FF6008' 
    },
    cartButton: {
        fontFamily: "Lato, sans-serif",
        textTransform: "none",
        fontWeight: 500,
        fontSize: 14,
        background: "#FF6008",
        color: "white",
        "&.Mui-disabled": {
            color: "white",
            backgroundColor: "grey !important",
        },
        alignItems: "center",
        borderRadius: "5px",
        padding: "8px 20px",
        letterSpacing: 1,
        '&:hover': {
            background: "#FF6008",
            color: "white",
        },
        '@media (max-width:860px)': {
            padding: '8px 14px',
            fontSize: 14
        },
        '@media (max-width:600px)': {
            padding: '8px 10px',
            fontSize: 12
        }
    },
    removeBtn: {
        fontFamily: "Lato, sans-serif",
        textTransform: "none",
        fontWeight: 500,
        fontSize: 14,
        background: "#454545",
        color: "white",
        alignItems: "center",
        borderRadius: "5px",
        padding: "8px 20px",
        letterSpacing: 1,
        '&:hover': {
            background: "#454545",
            color: "white"
        },
        '@media (max-width:860px)': {
            padding: '8px 14px',
            fontSize: 12
        },
        '@media (max-width:600px)': {
            padding: '8px 10px',
            fontSize: 12
        }
    },
    priceMainContainer: { 
        fontFamily: "Lato, sans-serif", 
        textAlign: 'end' 
    },
    expressLogo: { 
        fontFamily: "Lato, sans-serif", 
        height: "21px", 
        width: "48px",
        margin: "10px 0" 
    },
    price: { 
        fontFamily: "Lato, sans-serif", 
        color: "#FF6008", 
        marginTop: 40, 
        fontWeight: 700, 
        fontSize: "20px" ,
        whiteSpace:'nowrap',
        '@media (max-width:860px)': {
            fontSize: "16px"
        },
        '@media (max-width:600px)': {
            fontSize: "14px"
        }
    },
    outOfStock : {
        position: "relative",
        fontWeight: 'normal',
        color: 'rgba(102, 102, 102, 1)',
        textAlign: 'left',
        fontFamily: 'Helvetica',
        fontSize: "14px",
        '@media (max-width:860px)': {
            top:'0',
            paddingTop:'0'
        },
        '@media (max-width:600px)': {
            fontSize: "12px",
            top:'0'
        },
        '@media (max-width:460px)': {
            fontSize: "12px",
            top:'18px'
        }
    },
    orangeBtn: {
        fontFamily: "Lato, sans-serif",
        textTransform: "none",
        fontWeight: 500,
        fontSize: 14,
        background: "#FF6008",
        color: "white",
        alignItems: "center",
        borderRadius: "5px",
        padding: "8px 20px",
        letterSpacing: 1,
        '@media (max-width:860px)': {
            fontSize: "12px",
            padding:'8px 14px'
        },
        '@media (max-width:600px)': {
            fontSize: "12px",
            padding:'8px 10px'
        }
    },
    removeButn: {
        fontFamily: "Lato, sans-serif",
        textTransform: "none",
        fontWeight: 500,
        fontSize: 14,
        background: "#454545",
        color: "white",
        alignItems: "center",
        borderRadius: "5px",
        padding: "8px 20px",
        letterSpacing: 1,
        '@media (max-width:860px)': {
            fontSize: "12px",
            padding:'8px 14px'
        },
        '@media (max-width:600px)': {
            fontSize: "12px",
            padding:'8px 10px'
        }
    },
    sortDisplay: {
        fontSize: "16px !important",
        fontFamily: `"Avenir-Medium", sans-serif !important` as const,
        color: "#657A8E !important",
        fontWeight: 400,
    },
    filterGrid: {
        justifyContent:'flex-end',
        gridGap:'10px',
        '@media (max-width: 600px)': {
            justifyContent:'space-between'
        }
    },
    formWidth:{
        width:'100%'
    },
    filterBackgrnd: {
        background:"#eee"
    },
    iconColor: {
        color: '#8a919a'
    }
};

const CustomWishlistExpandMoreIcon = withStyles({
    root: {
      fill: "#8a919a", 
      marginRight: 3
    }
  })(ExpandMoreIcon);

export { UserWishlist };

export default withStyles(styles)(UserWishlist)
// Customizable Area End

const webStyle = {

    saveBtn: {
        background: "rgb(253, 97, 12) none repeat scroll 0% 0%",
        padding: "10px 25px",
        fontWeight: 600,
        color: "white",
        borderRadius: "5px",
        textTransform: "none"
    },
    headerTxt: {
        color: "#232529",
        fontWeight: 600,
        fontSize: "2rem",
        fontFamily: "Lato, sans-serif",
        // marginLeft: '12px'
    },
    emptyWishlist: {
        color: "#565656",
        marginBlock: "15px",
        fontWeight: 700,
        fontFamily: "Lato, sans-serif",
        margin:'auto',
        fontSize: "20px"
    },
    loaderWrapper: {
        position: 'relative',
        width: '50px',
        left: '40%',
        marginTop: "100px"
    },
}
