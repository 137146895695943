import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { GetCookies } from "../../../framework/src/WebUtilities";
import { createRef } from "react";
import { getFinalPrice } from "../../../components/src/CommonFunctions";


const labels: { [indexValue: string]: string } = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};
interface Product {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    price: number;
    image: string;
  }
}

interface IAddToCartRes {
  data: {
    id: string
  },
  errors: {
    quantity?: string[]
  }
}

interface ProductListRes {
  data: Product[]
}

interface IWishlist {
  favouriteable_id : string,
  product_variant_group_id? : string
}

interface IAddToFavRes {
  data: {
    id: string,
  }
}

interface IRemoveFavRes {
  message: string
}

interface IProductContent {
  mrp: string,
  retail_price: string,
  long_description: string,
  whats_in_the_package: string,
  country_of_origin: string,
  product_color: string,
  warranty_days: string,
  warranty_months: string,
}

interface IImageUrl {
  id: string;
  product_images: {
      url: string;
  }[];
  group_attributes: {
      options: string[];
  }[];
}

interface IVariantGroupDetails {
  attribute_name: string,
  options: string[]
}

interface IProductObj {
  id: string,
  attributes: {
  },
  quantity: string
}

export interface ICustomFieldContent {
  id: string | null,
  custom_field_name: string,
  value: string | null,
  custom_field_id: string | null,
  catalogue_id: string | null
}

interface IProductVariantGroup {
  id: string | null
  product_sku: string
  product_besku: string
  product_description: null
  price: number | null
  product_title: number | null
  product_images: string[]
  group_attributes: IVariantGroupDetails[]
}

export interface ICatalogOffer {
    price_info: string,
    sale_price: string,
    sale_schedule_to: string,
    status: boolean,
}

export interface IDealCatalogue {
    deal_price: string,
    status: string,
    seller_sku: string
}

export interface IProductDetails {
  id: string
  type: string
  attributes: {
    discount: number,
    calculated_discount: number,
    category_id: number | null
    brand_id: number | null
    parent_catalogue_id: number | null
    warehouse_id: string
    seller_id: number | null
    sku: string
    besku: string
    product_title: string
    fulfilled_type: string
    product_type: string
    stocks: number | null
    content_status: string
    status: boolean
    product_image: string,
    parent_catalogue: null,
    catalogue_offer: ICatalogOffer,
    deal_catalogues: IDealCatalogue[],
    category: {
      id: number | null,
      name: string
    },
    sub_category: {
      id: number | null,
      name: string
    },
    mini_category: {
      id: number | null,
      name: string
    },
    micro_category: {
      id: number | null,
      name: string
    },
    brand: {
      id: number | null,
      brand_name: string,
      store: {
        approve: boolean | null
      }
    },
    product_rating: {
      average_rating: number | null,
      total_reviews: number | null
    },
    product_content: {
      product_attributes: IProductContent,
      image_urls: string[],
      feature_bullets: {
        field_name: string,
        value: string
      }[],
      size_and_capacity: {
        prod_model_name: string | null,
        number_of_pieces: number | null,
        prod_model_number: string | null,
        hs_code: string | null,
        size: number | null,
        size_unit: string | null,
        capacity: number | null,
        capacity_unit: string | null
      },
      shipping_detail: {
        shipping_length: string | null,
        shipping_length_unit: string | null,
        shipping_height: string | null,
        shipping_height_unit: string | null,
        shipping_width: string | null,
        shipping_width_unit: string | null,
        shipping_weight: string | null,
        shipping_weight_unit: string | null
      }
      custom_fields_contents: ICustomFieldContent[]
    },
    product_variant_groups: IProductVariantGroup[],
    variant_products: {
      data: IProductDetails[]
    }
  },
  quantity? : string
}

export interface ProductQuickViewRes {
  data:  IProductDetails,
  favourite: {
    wishlist: boolean,
    id: string | number | null
  } | null
}
export interface IReviews {
  attributes: {
    created_at: string,
    rating: number | string,
    title: string | null,
    description: string | null,
    review_images: string[] | null,
    helpful_count: number | null,
    helpful: {
      helpful_by_current_user: boolean,
      data: null
    },
    reviewer: {
      first_name: string,
      last_name: string
    }
  }
}

export interface IReviewList {
  customer_ratings: {
    "1": number,
    "2": number,
    "3": number,
    "4": number,
    "5": number
  },
  average_rating: number,
  total_rating: number,
  reviews: {
    data: IReviews[]
  },
  total_reviews: number
}

export interface ICustomerRating {
  label: string,
  value: number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openModal: boolean;
  token: string;
  product_variant_group_id: string,
  account_id: string,
  productList: Array<Product>;
  productviewList: IProductDetails;
  showLoader: boolean;
  showToolTip: boolean;
  sortId: number;
  customModalVisibility: boolean;
  customModalText: string;
  amount: number;
  rating: number;
  catalouge_id: string;
  wishlistStatus: boolean,
  apiInprogress: boolean,
  wishlistAddInprogress: boolean,
  wishlistremoveInprogress: boolean,
  favouriteId: string | number | null,
  reviews: number;
  mainImage: IImageUrl | string;
  mainImageIndex: number;
  currentColor: string;
  visibleImages: number;
  currentImageIndex: number;
  productContent: IProductContent
  selectedVariantGroupDetails: IVariantGroupDetails[]
  cartId: string,
  totalItemCount: number | null;
  customerReview: IReviews[];
  featureBullets: {
    field_name: string,
    value: string
  }[],
  customFieldContent: ICustomFieldContent[] | null,
  modelName: string | null,
  stockCount: number;
  averageRating: number,
  customerRating: ICustomerRating[],
  openReviewImages: boolean,
  setImage: string,
  visibleReviews: number,
  variantProducts: any,
  productVariantGroup: IProductVariantGroup[],
  parentProduct : any,
  selectedVariant: string,
  listenerAdded: boolean,
  isVariantListener: boolean,
  showMore: boolean
  totalReviews: number,
  reviewPageNumber: number,
  isActive: boolean,
  isViewMoreClicked: boolean,
  quantityErr: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductQuickviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: string = ''
  getProductQuickViewCallId: string = ''
  addToCartCallId: string = ''
  postWishlistCallId: string = ''
  apiGetAccountIdCallId: string = ''
  removeWishlistApiId: string = ''
  getReviewListApiCallId: string = ''
  getVariantFavInfoCallId: string = ""
  productViewApiId: string = '';
  addHelpfulApiCallId: string = "";
  deleteHelpfulApiCallId: string = "";
  reviewRef: React.RefObject<HTMLDivElement>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      product_variant_group_id: "",
      account_id: "",
      productList: [],
      catalouge_id: "",
      wishlistStatus: false,
      favouriteId: null,
      showLoader: false,
      showToolTip: false,
      sortId: 10,
      customModalVisibility: false,
      apiInprogress: false,
      wishlistAddInprogress: false,
      wishlistremoveInprogress: false,
      customModalText: '',
      productviewList: {
        id: '',
        type: '',
        attributes: {
          calculated_discount: 0,
          discount: 0,
          category_id: null,
          brand_id: null,
          parent_catalogue_id: null,
          warehouse_id: '',
          seller_id: null,
          sku: '',
          besku: '',
          product_title: '',
          fulfilled_type: '',
          product_type: '',
          stocks: null,
          content_status: '',
          status: false,
          product_image:'',
          catalogue_offer: {
            sale_price: '',
            price_info: '',
            sale_schedule_to: '',
            status: false
          },
          deal_catalogues: [],
          parent_catalogue: null,
          category: {
            id: null,
            name: ''
          },
          sub_category: {
            id: null,
            name: ''
          },
          mini_category: {
            id: null,
            name: ''
          },
          micro_category: {
            id: null,
            name: ''
          },
          brand: {
            id: null,
            brand_name: '',
            store: {
              approve: null
            }
          },
          product_rating: {
            average_rating: null,
            total_reviews: null
          },
          product_content: {
            product_attributes: {
              mrp: '',
              retail_price: '',
              long_description: '',
              whats_in_the_package: '',
              country_of_origin: '',
              product_color: '',
              warranty_days: '',
              warranty_months: ''
            },
            image_urls: [],
            feature_bullets: [],
            size_and_capacity: {
              prod_model_name: null,
              number_of_pieces: null,
              prod_model_number: "" ,
              hs_code: "",
              size: null,
              size_unit: "",
              capacity: null,
              capacity_unit: ""
            },
            shipping_detail: {
              shipping_length: "",
              shipping_length_unit: "",
              shipping_height: "",
              shipping_height_unit: "",
              shipping_width: "",
              shipping_width_unit: "",
              shipping_weight: "",
              shipping_weight_unit: ""
            },
            custom_fields_contents: []
          },
          product_variant_groups: [],
          variant_products: {
            data: []
          }
        },
        quantity : ''
      },
      amount: 1,
      currentColor: "",
      rating: 3.5,
      reviews: 132,
      visibleImages: 4,
      mainImage: {
        id: '',
        product_images: [{
            url: ''
        }],
        group_attributes: [{
            options: []
        }]
      },
      mainImageIndex: 0,
      currentImageIndex: 0,
      productContent: {
        mrp: '',
        retail_price: '',
        long_description: '',
        whats_in_the_package: '',
        country_of_origin: '',
        product_color: '',
        warranty_days: "",
        warranty_months: ""
      },
      selectedVariantGroupDetails:[],
      openModal: false,
      cartId: '',
      totalItemCount: null,
      featureBullets: [],
      customFieldContent: null,
      modelName: '',
      customerReview: [],
      stockCount: 0,
      averageRating: 0,
      customerRating: [
        { label: '5 star', value: 0 },
        { label: '4 star', value: 0 },
        { label: '3 star', value: 0 },
        { label: '2 star', value: 0 },
        { label: '1 star', value: 0 },
      ],
      openReviewImages: false,
      setImage: '',
      visibleReviews: 3,
      variantProducts: [],
      parentProduct: [],
      productVariantGroup:[],
      selectedVariant: '',
      listenerAdded: false,
      isVariantListener: false,
      showMore: false,
      totalReviews: 0,
      reviewPageNumber: 1,
      isActive: false,
      isViewMoreClicked: false,
      quantityErr: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.reviewRef = createRef()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      runEngine.debugLog("API Message Recived", message);
      this.setState({ showLoader: false });

      this.handleResponse(apiRequestCallId,responseJson, errorJson)

      // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getProductList()
  }
  // web events


  // Customizable Area Start

  handleResponse = (apiRequestCallId: string, responseJson: unknown,errorJson:unknown) => {
    switch (apiRequestCallId) {
      case this.getProductQuickViewCallId:
        this.setState({ apiInprogress: false })
        let dataRes = responseJson as ProductQuickViewRes
        let dataArray = (responseJson as ProductQuickViewRes).data;
        const productContent = dataRes.data.attributes.catalogue_offer ? {
          ...dataRes.data.attributes.product_content?.product_attributes,
          mrp: dataRes.data.attributes.catalogue_offer.price_info,
          retail_price: dataRes.data.attributes.catalogue_offer.sale_price
        } : dataRes.data.attributes.product_content && dataRes.data.attributes.product_content.product_attributes;
        this.setState({ wishlistStatus: dataRes.favourite?.wishlist as boolean })
        this.setState({ favouriteId: dataRes.favourite?.id as string })
        this.setState({ productviewList: dataArray, productContent }, () => {
          this.setState({
            stockCount: this.state.productviewList.attributes.stocks || 0,
            isActive: this.state.productviewList.attributes.status
          })
        })
        this.setState({ mainImage: dataArray.attributes?.product_image})
        this.setState({ catalouge_id: dataArray.id }, () => this.handleViewProduct());
        this.setState({ featureBullets : dataRes.data.attributes.product_content?.feature_bullets });
        const customContent = dataRes.data.attributes.product_content?.custom_fields_contents?.filter((item: {value: string | null}) => item.value !== null)
        this.setState({ customFieldContent : customContent, modelName: dataArray?.attributes?.product_content?.size_and_capacity?.prod_model_name });
        if (dataRes.data.attributes?.product_variant_groups && !this.state.product_variant_group_id && this.extractUniqueColorOptions(dataRes?.data?.attributes?.product_variant_groups || [])?.length>0) {
          this.updateMainImgUrl(this.extractUniqueColorOptions(dataRes.data.attributes?.product_variant_groups || [])?.[0])
        }
        const filterVariantProducts = dataRes.data.attributes.variant_products.data.filter((item:any) => {
          if(item.attributes.content_status === "accepted" && item.attributes.status === true){
            return item
          }
        })
        this.setState({variantProducts: filterVariantProducts,selectedVariant: dataArray.id, parentProduct: dataArray, productVariantGroup: dataRes?.data?.attributes
          ?.product_variant_groups})
        this.getReviewsList(dataArray.id)
        
        break;
        case this.addToCartCallId:
          this.handleAddToCartApiResponse(responseJson as IAddToCartRes, errorJson);
          break;
        case this.removeWishlistApiId:
          this.handleRemoveWhishListApiResponse(responseJson as IRemoveFavRes, errorJson);
          break;
        case this.postWishlistCallId:
          this.postWhishListApiResponse(responseJson as IAddToFavRes, errorJson);
          break;
        case this.getProductApiCallId:
          let array = (responseJson as ProductListRes)?.data;
          this.setState({ productList: array });
        case this.productViewApiId : 
          this.setState({ showLoader: false})
          break;
        case this.getReviewListApiCallId : 
          this.handleReviewListRes(responseJson as IReviewList)
        break;
        case this.getVariantFavInfoCallId : 
        const variantRes = responseJson as ProductQuickViewRes
        this.setState({ wishlistStatus: variantRes?.favourite?.wishlist as boolean })
        this.setState({ favouriteId: variantRes?.favourite?.id as string })
        break;  
        case this.addHelpfulApiCallId : 
        this.handleHelpFulResponse(responseJson)
        break;
        case this.deleteHelpfulApiCallId : 
          this.handleDelHelpfulRes(responseJson)
        break;
    }
  };

  handleHelpFulResponse = (responseJson:any) => {
    if(responseJson.message === "Marked as helpful") {
      this.getReviewsList(this.state.selectedVariant)
    }
  }

  handleDelHelpfulRes = (responseJson:any) => {
    if(responseJson.message === "Unmarked as helpful") {
      this.getReviewsList(this.state.selectedVariant)
    }
  }


  handleReviewListRes = (responseJson: IReviewList) => {
    if(responseJson) {
      this.reviewRef.current?.scrollIntoView({behavior: 'smooth', block:'start'})
      const allRating = Object.values(responseJson?.customer_ratings)
      allRating.reverse()
      const ratingValues = allRating
      const setRatings = ratingValues.map((rating: string | unknown, index: number) => {
        return {
        label: `${allRating.length - index} star`,
        value: Number(rating)
        }
      })
      const customerReview = responseJson.reviews?.data
      this.setState({totalReviews: responseJson.total_reviews, averageRating: responseJson.average_rating, customerRating: responseJson.reviews?.data.length === 0 ? this.state.customerRating : setRatings, customerReview: customerReview })
    }
  }

  handleRemoveWhishListApiResponse = async (responseJson: IRemoveFavRes, errorJson: unknown) => {
    if (responseJson) {
      this.setState({ wishlistStatus: false, apiInprogress: false, wishlistremoveInprogress: false });
    } else {
      this.setState({ apiInprogress: false, wishlistremoveInprogress: false });
      this.parseApiCatchErrorResponse(errorJson);
    }
  };

  postWhishListApiResponse = async (responseJson: IAddToFavRes, errorJson: unknown) => {
    if (responseJson) {
      this.setState({ wishlistStatus: true, favouriteId: responseJson?.data?.id, apiInprogress: false, wishlistAddInprogress: false });
    } else {
      this.setState({ wishlistStatus: false, wishlistAddInprogress: false, apiInprogress: false })
      this.parseApiCatchErrorResponse(errorJson);
    }
  };

  removeWhishListApiCall = async (productId: string | number) => {
    this.setState({ apiInprogress: true, wishlistremoveInprogress: true })
    const headers = {
      "Content-Type": configJSON.contenttype,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.removeWishlistApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.removeWhishlistApiEndpoint(productId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getReviewsList = async (productId: string) => {
    const accountId = GetCookies("accountId")
    const headers = {
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getReviewListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getReviewsList(productId,accountId) + `&per_page=10&page=${this.state.reviewPageNumber}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddToCartApiResponse = async (responseJson: IAddToCartRes, errorJson: any) => {
    if (responseJson?.data) {
      this.setState({ openModal: true, quantityErr:'',apiInprogress: false, cartId: responseJson.data.id })
    } 
    else if(responseJson.errors.quantity && responseJson.errors.quantity[0]) {
      this.setState({apiInprogress: false, quantityErr: responseJson.errors && responseJson.errors.quantity[0]})
    }
    else {
      this.parseApiCatchErrorResponse(errorJson);
    }

  };

  handleCheckout = () => {
    this.props.navigation.navigate('ShoppingCart')
  }

  handleCloseModal = () => {
    this.setState({openModal: false, apiInprogress: false, cartId:''})
  }

  getProductList = async () => {
    const token = GetCookies("ba");
    this.setState({ token })
    const header = {};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductListAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    const product = window.location.pathname.split('/')[2]
    this.getProductQuickView(product)
  };

  getProductQuickView = async (productId: string) => {
    this.setState({ apiInprogress: true })
    const headers = {};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductQuickViewCallId = requestMessage.messageId;
    let accountId = GetCookies("accountId");
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductQuickViewApiEndPoint + `${productId}?account_id=${accountId}}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }

  handleAddToWishlist = async() => {
    const token = GetCookies("ba")
    if(token) {
      this.handleWishList()
    }
    else {
      const message: Message = new Message(getName(MessageEnum.SendMessage));
      message.addData(getName(MessageEnum.OpenBuyerEmailLoginModal), true);
      this.send(message);
    }
  }

  handleWishList = async () => {
    if (this.state.wishlistStatus) {
      await this.removeWhishListApiCall(this.state.favouriteId as string);
    } else {
      this.setState({ wishlistAddInprogress: true });
      const header = {
        "Content-Type": "application/json",
        token: this.state.token,
      };
      const wishlistData: IWishlist = {
        favouriteable_id: this.state.catalouge_id,
      };
      const httpBody = {
        ...wishlistData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.postWishlistCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_favourites/favourites"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

    }
  }

  handleViewProduct = async () => {
      let accountIds = GetCookies("accountId");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };
      const httpViewBody = {
        product_view:{
          catalogue_id: this.state.catalouge_id,
          ...(!!accountIds ? {user_id: accountIds} : {})
        }
      };
      const requestMessageView = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.productViewApiId = requestMessageView.messageId;
      requestMessageView.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.productViewEndpoint
      );
      requestMessageView.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageView.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpViewBody)
      );
      requestMessageView.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessageView.id, requestMessageView);
      return true;
  }

  addToCartApiCall = async () => {
    this.setState({ apiInprogress: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const body = {
      "order_items": {
        catalogue_id: this.state.catalouge_id,
        quantity: this.state.amount
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addToCartCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addTocart);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setDecrease = () => {
    this.state.amount > 1
      ? this.setState({ amount: this.state.amount - 1 })
      : this.setState({ amount: 1 });
      this.setState({quantityErr: ''})
  };
  setIncrease = (stock: number) => {
    this.state.amount < stock
      ? this.setState({ amount: this.state.amount + 1 })
      : this.setState({ amount: stock });
  };
  updateMainImgUrl = (item: {id: string | null, color: string, group_attributes: IVariantGroupDetails[]}) => {
    
    this.setState({ product_variant_group_id: item?.id as string});
    const prodId = window.location.pathname.split('/')[2]
    this.getProductQuickView(prodId)
    this.setState({ currentColor: item?.color })
    this.setState({ selectedVariantGroupDetails: item?.group_attributes || [] })
  };

  extractUniqueColorOptions = (productVariantGroups: IProductVariantGroup[]) => {
    const colorOptionsArray: { color: string; id: string | null; product_sku: string; product_besku: string; product_description: null; price: number | null; product_title: number | null; product_images: string[]; group_attributes: IVariantGroupDetails[]; }[] = [];

    // Iterate through each product variant group
    Array.isArray(productVariantGroups) && productVariantGroups?.length > 0 && productVariantGroups?.forEach(group => {
      const colorAttribute = group.group_attributes.find((attr: {attribute_name: string}) => attr?.attribute_name?.trim().toLowerCase() === "color");

      if (colorAttribute && colorAttribute.options.length > 0) {
        colorOptionsArray.push({ ...group, color: colorAttribute?.options[0] });
      }
    });

    return colorOptionsArray;
  }

  updateMainImgUrl1 = (item: IImageUrl, index: number) => {
    this.setState({ 
      mainImage: item,
      mainImageIndex: index,
    });
  }

  handleAddToCartApi = async() => {
    const token = GetCookies("ba")
    if(token) {
      this.addToCartApiCall()
    }
    else this.setCartItems()
  }

  handleHome = () => {
    this.props.navigation.navigate("Home")
  }

  handleGoToCategory = (categoryId: string, categoryName: string) => {
    this.props.navigation.navigate("BuyerExploreProducts", {id: categoryId, type: categoryName})
  }

  handleStore = (storeId: string) => {
    if(this.state.productviewList.attributes.brand.store.approve) {
      this.props.navigation.navigate("BrandStore", {menu: "home", id: storeId})
    }
  }

  formatDate = (dateValue :string) => {
    const date:any = new Date(dateValue)
    const currentDate:any= new Date();
    const oneMonth = 30*24*60*60*1000
    const oneYear = 365*24*60*60*1000
    const difference = currentDate - date;
    if(difference > oneYear) {
      return date.getFullYear()
    }
    if(difference > oneMonth) {
      const monthsAgo = Math.floor(difference / oneMonth);
      return `${monthsAgo} month${monthsAgo > 1 ? 's' : ''} ago`;
    }

    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }

  handleViewReviewImages = (image: string) => {
    this.setState({openReviewImages: true, setImage: image})
  }

  handleCloseIcon = () => {
    this.setState({openReviewImages: false, setImage: ''})
  }

  handleSeeMoreReviewBtn = () => {
    this.setState((prevState) => ({
      showMore: true
  }))
  }

  getVariantProduct = (itemId: string) => {
    window.history.pushState(null, '', `/product/${this.state.parentProduct.id}?variantId=${itemId}`);
    this.setVariantData(itemId)
  }

  setVariantData = (itemId: string) => {
    this.setState({selectedVariant : itemId,variantProducts: this.state.variantProducts, product_variant_group_id: this.state.product_variant_group_id})
    const getVariantData = this.state.variantProducts?.filter((item:any) => {
      if(item.id === itemId) {
        return item
      }
    })
    this.setState({ productviewList: getVariantData[0], productContent: getVariantData[0]?.attributes?.product_content?.product_attributes}, () => {
      this.setState({
        stockCount: this.state.productviewList?.attributes.stocks || 0,
        isActive: this.state.productviewList?.attributes.status
      })
    })
    this.setState({ mainImage: getVariantData[0]?.attributes?.product_image})
    this.setState({ catalouge_id: getVariantData[0]?.id });
    this.setState({ featureBullets : getVariantData[0]?.attributes.product_content?.feature_bullets });
    const customContent = getVariantData[0]?.attributes.product_content?.custom_fields_contents?.filter((item: {value: string | null}) => item.value !== null)
    this.setState({ customFieldContent : customContent, modelName: getVariantData[0]?.attributes?.product_content?.size_and_capacity?.prod_model_name });
    this.getVariantFavInfo(itemId)
    this.getReviewsList(itemId)
  }

  getVariantFavInfo = (prodId:string) => {
    let accountInfo = GetCookies("accountId");
    const headers = {};
    const reqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVariantFavInfoCallId = reqMessage.messageId;
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductQuickViewApiEndPoint + `${prodId}?account_id=${accountInfo}`
    )
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(reqMessage.id, reqMessage);
    return true
  }

  setProductData = (itemId: string) => {
    this.props.navigation.navigate("ProductQuickview", {id: itemId})
    this.getProductQuickView(itemId)
    this.getReviewsList(itemId)
  }

  isVariantProduct = () => {
    const query = new URLSearchParams(window.location.search)
    const isVariantId = query.get("variantId")
    if(!isVariantId && !this.state.listenerAdded) {
      this.setProductData(this.state.parentProduct.id)
      this.setState({listenerAdded: true})
    }
    return isVariantId ? true : false
  }

  setHelpful = (reviewId:string,isSet: boolean) => {
    const token = GetCookies("ba")
    if(token) {
      if(isSet) {
        this.deleteHelpful(reviewId)
      }
      else this.addToHelpful(reviewId)
    }
    else {
      const message: Message = new Message(getName(MessageEnum.SendMessage));
      message.addData(getName(MessageEnum.OpenBuyerEmailLoginModal), true);
      this.send(message);
    }
  }

  addToHelpful = async (reviewId: string) => {
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addHelpfulApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addHelpfullEndpoint(reviewId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteHelpful = async (reviewId: string) => {
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteHelpfulApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addHelpfullEndpoint(reviewId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBorder = (index: number) => {
    return this.state.mainImageIndex === index ? "1px solid #FF6008": "1px solid #ccc"
  }

  getReviewListCount = () => {
    const count: number = this.state.totalReviews / 10;
    const roundedReturnCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedReturnCount
  }

  handleViewMore = () => {
    this.setState({isViewMoreClicked : !this.state.isViewMoreClicked})
  }

  formattedText = (description: string) => {
    return description.split(' ').slice(0, 50).join(" ")
  }

  getDisabledStatus = () => {
    const isQuantityStocksSame = Number(this.state.productviewList.quantity) === this.state.productviewList.attributes.stocks
    return this.state.stockCount <= this.state.amount || isQuantityStocksSame
  }

  getWishlistDisableStatus = () => {
    return this.state.wishlistAddInprogress || this.state.wishlistremoveInprogress
  }

  setCartItems = async() => {
    let existingData = await getStorageData("cart_items", true);
    existingData = existingData ? existingData : [];

    const productViewArray = [this.state.productviewList];
    const retailPrice = this.state.productviewList.attributes.product_content.product_attributes.retail_price
    const finalPrice = getFinalPrice(this.state.productviewList)
    const dataWithQuantities = productViewArray.map((item:IProductDetails) => ({
      ...item,
      quantity: this.state.amount,
      final_price : finalPrice,
      attributes: {
        ...item.attributes,
        discount : Number(retailPrice) - Number(finalPrice),
        calculated_discount:Number(retailPrice) - Number(finalPrice)
      }  
    }));

    
    let product = existingData.find((item:IProductDetails) => item.id === this.state.productviewList.id);
    
      if (product && product.attributes.stocks === product.quantity) {
        this.setState({ openModal: false, quantityErr : `This seller has only ${this.state.productviewList.attributes.stocks} of these available`})
      } else {
          this.setState({ openModal: true, apiInprogress: false })
          if (Array.isArray(dataWithQuantities[0])) {
            existingData = existingData.concat(dataWithQuantities[0]);
          } else {
            existingData.push(dataWithQuantities[0]);
          }

          const combinedData = Object.values(existingData.reduce((accumulator: any, item:any) => {
            if (accumulator[item.id]) {
              accumulator[item.id].quantity += item.quantity;
              accumulator[item.id].calculated_price = accumulator[item.id].quantity * item.final_price;
            } else {
              accumulator[item.id] = { ...item, calculated_price: item.quantity * item.final_price};
            }
            return accumulator;
          }, {}));

          const updatedDataString = JSON.stringify(combinedData);
          await setStorageData("cart_items", updatedDataString);
          const quantityArr = existingData.map((orderItem:IProductObj) => orderItem.quantity);
          const totalQuantity = quantityArr.reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0);
          this.setState({totalItemCount: totalQuantity as number, quantityErr:''})
      }
  }

  // Customizable Area End
}
