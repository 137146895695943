import React from "react";

// Customizable Area Start
import { Box, Grid, ThemeProvider } from "@material-ui/core";
import { theme, CustomizeDrawer } from "./CustomizedElementStyle";
import { byezzyLogo } from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SellerMyProfile from "./SellerDashboardComponents/SellerMyProfile.web";
import SellerMyDocuments from "./SellerDashboardComponents/SellerMyDocumens.web";
import SellerTaxPolicy from "./SellerDashboardComponents/SellerTaxPolicy.web";
import SellerLegalPolicy from "./SellerDashboardComponents/SellerLegalPolicy.web";
import SellerSuggestionFeedback from "./SellerDashboardComponents/SellerSuggestionFeedback.web";
import SellerNavigationBar from "./SellerDashboardComponents/SellerNavigationBar.web";
import { ClickableBox } from "../../otp-input-confirmation/src/CustomElementStyling";
// Customizable Area End

import SellerDashboardController, { Props, configJSON } from "./SellerDashboardController";

export default class SellerDashboard extends SellerDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.mainWrapper} style={{ position: "relative" }}>
          <CustomizeDrawer variant="persistent" anchor="left" open={this.state.showMenu}>
            <Grid container direction="column" style={{ width: "100%", margin: 0, position: "relative" }} spacing={5} alignItems="flex-start">
              <Grid item style={{ position: "relative" }}>
                <ClickableBox data-test-id="hideMenuRef" onClick={this.handleShowMenu} style={{ width: 31, height: 31, borderRadius: "100%", position: "absolute", background: "#FF6008", border: "6px solid #F2F3F4", right: -140, top: 36, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <ArrowBackIosIcon style={{ color: "white", marginLeft: "8px" }} />
                </ClickableBox>
                  <img src={byezzyLogo} style={webStyle.logo} onClick={this.handleNavigateToSellerHomeDashboard}/>
              </Grid>
              <Grid item style={{ width: "100%", padding: "0px 30px 5px 26px" }}>
                <Box sx={{ borderBottom: "1px solid #EFEFF4", width: "100%", paddingBottom: "20px", marginBottom: "10px", position: "relative" }}>
                  <Box sx={{ position: "absolute", width: "4px", height: "35px", top: -2, right: -30, borderRadius: "5px 0px 0px 5px" }} style={{ background: "#FF6008" }}></Box>
                  <Box sx={{ ...webStyle.nameFontStyle, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{this.state.full_name}</Box>
                  <Box sx={{ ...webStyle.emailFontStyle, marginTop: 7, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{this.state.email}</Box>
                </Box>
              </Grid>
              <Grid item style={{ width: "100%", padding: "0px 30px 0px 26px" }}>
                <Box>
                  <ClickableBox data-test-id="homeRef" onClick={this.handleNavigateToSellerHomeDashboard} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, borderBottom: "1px solid #EFEFF4" }}>
                    Home
                  </ClickableBox>
                  <ClickableBox data-test-id="myProfileRef" onClick={() => this.handleChangePanel(configJSON.myProfilePath)} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, borderBottom: "1px solid #EFEFF4", ...(this.state.activePanel === configJSON.myProfilePath && webStyle.activePanel) }}>
                    My Profile
                  </ClickableBox>
                  <ClickableBox data-test-id="myDocumentsRef" onClick={() => this.handleChangePanel("mydocuments")} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, borderBottom: "1px solid #EFEFF4", ...(this.state.activePanel === "mydocuments" && webStyle.activePanel) }}>
                    My Documents
                  </ClickableBox>
                  <ClickableBox data-test-id="taxpolicyRef" onClick={() => this.handleChangePanel("taxpolicy")} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...(this.state.activePanel === "taxpolicy" && webStyle.activePanel) }}>
                    <Box>Tax Policy</Box>
                  </ClickableBox>
                  <ClickableBox data-test-id="legalpolicyRef" onClick={() => this.handleChangePanel("legalpolicy")} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...(this.state.activePanel === "legalpolicy" && webStyle.activePanel) }}>
                    <Box>Legal Policy</Box>
                  </ClickableBox>
                  <ClickableBox data-test-id="suggestionRef" onClick={() => this.handleChangePanel("suggestionfeedback")} sx={{ ...webStyle.listFontStyle, ...webStyle.listButton, ...(this.state.activePanel === "suggestionfeedback" && webStyle.activePanel) }}>
                    <Box>Suggestions &</Box>
                    <Box sx={{ marginTop: "5px" }}>Feedbacks</Box>
                  </ClickableBox>
                </Box>
              </Grid>
            </Grid>
          </CustomizeDrawer>
          <Box style={{ width: "100%", overflow: "auto", padding: "20px 20px 59px 20px", backgroundColor: "#F2F3F4", display: "flex",marginLeft: this.setNavStyle(), flexDirection: "column" }}>
            <Box style={{ width: "100%", backgroundColor: "#F2F3F4", display: "flex", flexDirection: "column" }}>
              <SellerNavigationBar noSearchBar={true} navigation={this.props.navigation} id={"seller-navigation-bar"} profilePicture={this.state.profile_picture} />
            </Box>
            <Box style={{ width: "100%", overflow: "hidden", backgroundColor: "#F2F3F4", display: "flex", flexDirection: "column" }}>
              {this.state.activePanel === configJSON.myProfilePath && <SellerMyProfile navigation={this.props.navigation} id={"seller-my-profile"} />}
              {this.state.activePanel === "mydocuments" && <SellerMyDocuments navigation={this.props.navigation} id={"seller-my-documents"} />}
              {this.state.activePanel === "taxpolicy" && <SellerTaxPolicy navigation={this.props.navigation} id={"seller-tax-policy"} />}
              {this.state.activePanel === "legalpolicy" && <SellerLegalPolicy navigation={this.props.navigation} id={"seller-legal-policy"} />}
              {this.state.activePanel === "suggestionfeedback" && <SellerSuggestionFeedback navigation={this.props.navigation} id={"seller-my-feedback"} />}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    background: "#fff",
    width: "100%",
    height: "100vh",
    overflow: "hidden"
  },
  listButton: {
    width: "100%",
    padding: "12px 0px"
  },
  listFontStyle: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#666666",
    fontFamily: `"Lato", sans-serif`
  },
  nameFontStyle: {
    fontSize: "20px",
    fontWeight: 500,
    color: "#33333A",
    fontFamily: `"Lato", sans-serif`
  },
  emailFontStyle: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#FF6008",
    fontFamily: `"Lato", sans-serif`
  },
  activePanel: {
    color: "#000000",
    fontWeight: 700
  },
  logo: {
    width: '102px',
    height:'85px',
    cursor: 'pointer',
    margin:'-10px 0 0'
  }
};
// Customizable Area End
