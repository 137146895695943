// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {
  getStorageData, setStorageData
} from "../../../../framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
import { GetCookies, SetCookies } from "../../../../framework/src/WebUtilities";
import { toast } from "react-toastify";
import { IAddress } from "./OrderOverviewController.web";
export const configJSON = require("../../src/config");

export interface IPriceData {
  total_fees: string, 
  total_tax: string,
  final_price: string,
  order_items: any,
  shipping_address: {
    data: {
      id: string,

    }
  }
}

interface IloggedUserData {
  data: {
    id: string
  }
}

export interface IAddressData {
  id: string,
  attributes: {
    first_name: string,
    last_name: string,
    address_1: string,
    address_2: string,
    city: string,
    phone_number: string,
    state: string
  }
}

export interface IAddressRes {
  data: IAddress[]
}

interface ICartDetails {
  data: {
    attributes: 
      IPriceData
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    addressPath: string;
    backgroundColor: string;
    span: string;
    addressText: string;
    box: string;
    boxDes: string;
    divider: string;
    addNewAddressBtn: string;
    horizontalSec: string;
    shippingAddLabel: string;
    radio: string;
    tableCell: string;
    addressWrapper: string;
    name: string;
    summaryDesc: string;
    deleteWrapper: string;
    delIcon: string;
    deleteText: string;
    editText: string;
    priceWrapper: string;
    priceHeader: string;
    text: string;
    horizontal: string;
    nextBtn: string;
    bottom: string;
    summary: string;
    link: string;
    errorMsg: string,
    modalWrapper: string,
    modal: string,
    modalText: string,
    modalBtnWrapper: string,
    orderSummaryWrap: string,
    price_header: string,
    line: string,
    align: string,
    priceHeaderOne: string,
    navigate: string,
    wrapper: string,
    zoom: string,
    addressScroll: string,
    editWrapper: string,
    btnWrap: string,
    editContainer: string,
    leftContainer: string,
    descGrid: string,
    newLink: string,
    horizontalLine: string,
    summaryGrid: string,
    gridOne: string,
    rightAlign: string
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addressList: IAddress[];
  token: string;
  accountId: string;
  setOrderId: string;
  // priceData: IPriceData;
  priceData: IPriceData;
  setErrorMsg: boolean,
  isOpen: boolean,
  addressId: string,
  isAddSelected: string,
  disabled: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ShippingAddressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLoggedUserApiCallId: string = "";
  getShippingAddressApiCallId: string = "";
  deleteAddressApiCallId: string = "";
  setAddressApiCallId: string = "";
  getCartDetailsApiCallId: string = "";
  createPaymentApiCallId:string = "";
  getActiveCartDetailsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      addressList: [],
      token: "",
      accountId: "",
      setOrderId: '',
      disabled: false,
      priceData: {
        total_fees: '', 
        total_tax: '',
        final_price: '',
        order_items: {},
        shipping_address: {
          data: {
            id: '',
  
          }
        }
      },
      setErrorMsg: false,
      isOpen: false,
      addressId: '',
      isAddSelected: ''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  handleChangeAddress = (value: {id: string}) => {
    this.setState({ setOrderId: value.id});
    this.setAddress(value.id)
    if(value){
      this.setState({setErrorMsg: false })
    }
  };

  handlePlaceOrderBtn = async() => {
    const address = await getStorageData("addressId")
    this.redirectionToNextScreen(address)
  };

  redirectionToNextScreen = (address: string) => {
    if (address && this.state.setOrderId && this.state.addressList.length > 0) {
      this.setState({ setErrorMsg : false})
      this.setAddress(address)
      this.getActiveCartDetails()
    }
    else this.setState({ setErrorMsg : true})
  };

  handleOpenModal = (id: string) => {
    this.setState({isOpen: !this.state.isOpen, addressId: id})
  };

  handleCloseModal = () => {
    this.setState({isOpen: false})
  }

  handleDeleteAddress = () => {
    this.setState({isOpen: false})
    this.deleteAddress(this.state.addressId);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleApiSuccessResponse(responseJson, apiRequestCallId);
    }
  }

  async componentDidMount() {
    const token = await getStorageData("authToken");
    this.setState({ token: token });
    await this.getLoggedUserDetails();
    this.getCartDetails()
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined){
    window.addEventListener('popstate', () =>  {
      if(window.location.pathname.split('/')[1] === "shipping-address") {
        this.props.navigation.navigate('ShippingAddress')
      }
      else this.props.navigation.navigate('ShoppingCart')
    })
  }

  handleApiSuccessResponse = async(responseJson: unknown, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getLoggedUserApiCallId) {
      this.setState({ accountId: (responseJson as IloggedUserData).data.id });
      SetCookies("accountId", (responseJson as IloggedUserData).data.id, 7);
      setStorageData("accountId", (responseJson as IloggedUserData).data.id)
      this.getShippingAddress((responseJson as IloggedUserData).data.id);
    }
    if (apiRequestCallId === this.getShippingAddressApiCallId) {
      this.setState({ addressList: (responseJson as IAddressRes).data });
    }
    if (apiRequestCallId === this.deleteAddressApiCallId) {
      toast.success("Address deleted successfully.");
      this.getShippingAddress(this.state.accountId);
      this.getCartDetails()
    }
    if ( apiRequestCallId === this.getCartDetailsApiCallId ) {
      let cartData = (responseJson as ICartDetails).data 

      const isAnyItemUnavailable = cartData.attributes.order_items.data.length === 0 || cartData.attributes.order_items.data.map((item: any) => {
        const { stocks, content_status, status } = item.attributes.item.catalogue.data.attributes;
        const isItemAvailable = status && !!content_status && !!stocks && stocks > 0 && content_status !== "archived";
        return !isItemAvailable;
      }).includes(true);

      this.setState({ disabled: isAnyItemUnavailable });
      if(cartData.attributes.shipping_address.data !== null){
        const addressId = cartData.attributes.shipping_address.data.id
        await setStorageData("addressId", cartData.attributes.shipping_address.data.id)
        this.setState({setOrderId : addressId, priceData:  cartData.attributes})
        SetCookies('setAddress',addressId,7)
      } else {
        setStorageData("addressId", null)
        SetCookies('setAddress','',7)
      }
      
      this.setState({priceData:  cartData.attributes})
    }
    if (apiRequestCallId === this.setAddressApiCallId) {
      this.getCartDetails()
      this.getLoggedUserDetails()
    }
    if (apiRequestCallId === this.createPaymentApiCallId) {
      let createdResp = responseJson as { order : {url : string}}
      window.location.href = createdResp.order.url
    } 
    this.handleGetOrderDetailsRes(apiRequestCallId,responseJson as ICartDetails)
  };

  handleGetOrderDetailsRes = (apiRequestCallId:string, responseJson: ICartDetails) => {
    if(apiRequestCallId === this.getActiveCartDetailsApiCallId) {
      let orderData = responseJson.data 
      const hasActiveItem = orderData?.attributes.order_items.data.length > 0 && 
      orderData.attributes.order_items.data.some((item: any) => {
        const { stocks, content_status, status } = item.attributes.item.catalogue.data.attributes;
        return status && stocks > 0 && content_status === "accepted";
      });
      if(hasActiveItem) {
        this.createPaymentSession()
      }
      else this.setState({disabled: true})
    }
  } 

  getLoggedUserDetails = async () => {
    const token = GetCookies("ba");
    const headers = { "Content-Type": configJSON.productApiContentType };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getLoggedUserApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLoggedUserApiEndPoint + `${token}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  };

  getCartDetails = () => {
    const currentOrder = GetCookies("currentOrder")
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartDetailsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${currentOrder}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  getShippingAddress = async (id: string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const addressMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShippingAddressApiCallId = addressMessage.messageId;
    addressMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${id}/user_delivery_addresses`
    );
    addressMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addressMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(addressMessage.id, addressMessage);
  };

  setAddress = async (id: string) => {
    const orderId = GetCookies("currentOrder")
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const httpBody = {
      "orders": {
        "status": "on_going", 
        "address_id": id 
      }
    }
    const setMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setAddressApiCallId = setMessage.messageId;
    setMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${orderId}`
    );
    setMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    setMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    setMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePatch
    );
    runEngine.sendMessage(setMessage.id, setMessage);
  }

  deleteAddress = async (id: string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const deleteMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAddressApiCallId = deleteMessage.messageId;
    deleteMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.accountId}/user_delivery_addresses/${id}`
    );
    deleteMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(deleteMessage.id, deleteMessage);
  };

  createPaymentSession = () => {
    const orderId = GetCookies("currentOrder");
    const finalPrice = this.state.priceData.final_price
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const paymentMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createPaymentApiCallId = paymentMessage.messageId;
    paymentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/telr_payments?order_id=${orderId}&final_price=${finalPrice}`
    );
    paymentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    paymentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(paymentMessage.id, paymentMessage);
  }

  handleHomeClick = () => {
    this.props.navigation.navigate("Home")
  }

  handleCartRedirection = () => {
    this.props.navigation.navigate("ShoppingCart")
  }

  handleAddAddressBtn = () => {
    this.props.navigation.navigate("AddAddress", {id: '00'})
  }

  handleEditAddress = (addId: string) => {
    this.props.navigation.navigate("AddAddress", {id: addId})
  }

  getActiveCartDetails = () => {
    const recentOrder = GetCookies("currentOrder")
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActiveCartDetailsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${recentOrder}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  // Customizable Area End
}
