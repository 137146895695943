import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { BoxNoScroll } from "../CustomizedElementStyle";
import * as DOMPurify from 'dompurify';
// Customizable Area End

import TermsAndConditionController, { Props } from "./TermsAndConditionController";

export default class TermsAndCondition extends TermsAndConditionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontFamily: `"Lato", sans-serif`, fontWeight: 400 }}>Terms & Conditions</Box>
        <BoxNoScroll style={{ overflow: "auto", paddingBottom: 20 }}>
          <Box style={{ flexDirection: "column", minHeight: 753, borderRadius: "8px", padding: "30px", overflowY: "auto", overflowX: "hidden", position: "relative", display: "flex", background: "white" }}>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
              <Box sx={{ fontSize: "27px", fontWeight: 600, fontFamily: `"Lato", sans-serif` }}>Terms & Conditions</Box>
            </Box>
            <Box sx={{ marginTop: 10, width: "100%" }}>
              {this.state.content.map((body, index) => {
                return (
                  <>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(body)}}>
                    </div>
                    <Box style={{ height: 20, width: "100%" }}></Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
