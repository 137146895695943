import { IProductDetails } from "../../blocks/productquickview/src/ProductQuickviewController";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";

export const getNavigationMessage = (route: string, props: any, payload?: any): Message => {

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
  
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
  
    if (payload) {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), payload);
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    }
    return message;
}

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};

export const getFinalPrice = (productData:IProductDetails) => {
  const {catalogue_offer, deal_catalogues} = productData.attributes
  const {mrp, retail_price} = productData.attributes.product_content.product_attributes
  const todaysDate = getCurrentDate()
  const isCatOfferAct = catalogue_offer && catalogue_offer.sale_schedule_to >= todaysDate
  const isOfferActive = catalogue_offer?.status && catalogue_offer?.sale_price && isCatOfferAct
  const isDealActive = deal_catalogues[0]?.status === "approved" && deal_catalogues[0]?.deal_price
  if(isOfferActive && !isDealActive) {
    return catalogue_offer?.sale_price
  }
  if(!isOfferActive && isDealActive) {
    return deal_catalogues[0]?.deal_price
  }
  else return retail_price
}