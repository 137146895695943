import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { removeStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { GetCookies, SetCookies } from "framework/src/WebUtilities";
import Cookies from "js-cookie";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  profilePicture: any;
  noSearchBar?: boolean;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  expandBar: boolean;
  openProfileMenu: boolean;
  searchValue: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerNavigationBarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      expandBar: false,
      openProfileMenu: false,
      searchValue: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const isHidden = message.getData(getName(MessageEnum.HideSellerNavigationBar)) || false;
      if (isHidden) {
        this.setState({ expandBar: isHidden });
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleShowMenuBar = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    msg.addData(getName(MessageEnum.ShowSellerDashboardMenu), true);
    this.send(msg);
    this.setState({ expandBar: !this.state.expandBar });
  };

  handleClickAway = () => {
    this.setState({ openProfileMenu: false });
  };

  handleOpenProfileMenu = () => {
    this.setState({ openProfileMenu: !this.state.openProfileMenu });
  };

  handleNavigateToSellerMyAccount = () => {
    this.props.navigation.navigate(configJSON.dashboardPath, {path: configJSON.myProfilePath})
  };

  handleSignOut = async () => {
    setStorageData("authToken", "null");
    setStorageData("seller_id", "null");
    setStorageData("seller_full_name", "null");
    setStorageData("seller_email", "null");
    setStorageData("full_phone_number", "null");
    setStorageData("profile_picture", "null");
    Cookies.remove("global_search");
    Cookies.remove("sellerToken");
    Cookies.remove("ba");
    Cookies.remove("accountId");
    Cookies.remove("setAddress");
    Cookies.remove("currentOrder");
    await removeStorageData("seller_documents_verified")
    await removeStorageData("isAccountActivated")
    await removeStorageData("isAccountCreated")
    await removeStorageData("isDocumentVerified")
    const msg: Message = new Message(getName(MessageEnum.NavigationHomeScreenMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    this.props.navigation.navigate('FeaturesHeader');
  };

  handleGlobalSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ searchValue: event.target.value });
    if (value) {
      SetCookies("global_search", event.target.value, 7);
    } else SetCookies("global_search", "", 7);
    const msg: Message = new Message(getName(MessageEnum.NavigationSellerDashboardMessage));
    msg.addData(getName(MessageEnum.ChangeMenuPanel), "products");
    msg.addData(getName(MessageEnum.SearchData), JSON.stringify(GetCookies("global_search")));
    this.send(msg);
  };

  async componentDidMount(): Promise<void> {
    Cookies.remove("global_search");
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.searchValue !== prevState.searchValue) {
      SetCookies("global_search", this.state.searchValue, 7);
    }
  }

  setVisibility = () => {
    if(this.props.noSearchBar){
        return 'hidden'
    }
  }
  // Customizable Area End
}
