import React from "react";

// Customizable Area Start
import { Box, Button, Grid, IconButton, Input, InputAdornment, Typography } from "@material-ui/core";
import { CustomizeBrandInput, OrangeButton } from "../CustomizedElementStyle";
import CloseIcon from "@material-ui/icons/Close";
import { file } from "../assets";
import { errorImage } from "../../../bulkuploading/src/assets";
// Customizable Area End

import ProductProfileController, { Props } from "./ProductProfileController";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";

export default class ProductProfile extends ProductProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Box sx={{ marginTop: 50, padding: "30px", display: 'flex', flexWrap: 'wrap' }}>
          <Grid container spacing={5}>
            <Grid item lg={6} sm={12} xs={12}>
              <CustomizeBrandInput
                data-test-id="sellerSku"
                label="Seller SKU"
                value={this.state.sku}
                disabled
                id="outlined-start-adornment"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={{ marginRight: 5 }}></InputAdornment>
                }}
                inputProps={{ sx: webstyle.text }}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <CustomizeBrandInput
                data-test-id="besku"
                label="BESKU"
                value={this.state.besku}
                disabled
                id="outlined-start-adornment"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={{ marginRight: 5 }}></InputAdornment>
                }}
                inputProps={{ sx: webstyle.text }}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <CustomizeBrandInput
                data-test-id="bibc"
                label="BIBC"
                value={this.state.bibcNumber}
                disabled
                id="outlined-start-adornment"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start" style={{ marginRight: 5 }}></InputAdornment>
                }}
                inputProps={{ sx: webstyle.text }}
              />
              {this.state.bibcInfo ?
              <Typography variant="body2" style={{color: "#9a9a9a"}}>{this.state.bibcInfo}</Typography>  
              : null
              }
            </Grid>
            </Grid>
            <Grid container spacing={5} style={{padding:'20px 0'}}>
            <Grid item lg={6} sm={12} xs={12}>
              <Box style={{ marginTop: 0, color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontSize: 12, fontWeight: 600 }}>Image</Box>
              <Input
                type="file"
                style={{
                  display: "none"
                }}
                value=""
                data-test-id="fileInput"
                onChange={event => this.handleUploadFile(event)}
                inputProps={{
                  "aria-label": "upload documents",
                  ref: this.inputRef,
                  accept: "image/*"
                }}
              />
              <label htmlFor="upload-button">
                <Button
                  variant="outlined"
                  onClick={this.handleOpenFileInput}
                  fullWidth
                  style={{
                    width: "100%",
                    height: 167,
                    padding: "20px 0px",
                    margin: "10px 0px",
                    border: "none",
                    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                    borderRadius: "5px",
                    textTransform: "none",
                    fontFamily: `"Lato", sans-serif`
                  }}
                >
                  <Box>
                    <img src={file} />
                    <Box sx={{ fontSize: "27px", fontWeight: 400, fontFamily: `"Lato", sans-serif` }}>Drop product image</Box>
                    <Box sx={{ color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontSize: 16 }}>{"Only JPG, PNG file to be uploaded here (Maximum size- 5mb)"}</Box>
                  </Box>
                </Button>
              </label>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <Grid container spacing={2} direction="column" style={{ width: "100%" }}>
                {this.state.product_image && (
                  <Grid item lg={6} sm={12} xs={12}>
                    <Box style={{ height: 50, display: "flex", alignItems: "center", justifyContent: "space-between", background: "#f5f5f5", borderRadius: 5, flexWrap: "nowrap", padding: "0px 0px 0px 20px" }}>
                      <Box data-test-id="uploadedImg" style={{ fontFamily: `"Lato", sans-serif` }}>
                        {this.state.product_image.name}
                      </Box>
                      <IconButton onClick={this.handleRemoveFile}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} direction="column" style={{ marginLeft: "2px" }}>
                {this.state.prodImage && (
                  <>
                    <Box style={webstyle.prodImageContainer}>
                      <img data-test-id="prodImage" src={this.state.prodImage} alt="prod_img" style={webstyle.imageContainer} />
                      <IconButton data-test-id="crossIcon" style={webstyle.image} onClick={this.handleRemoveFile}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <Box style={{ width: "100%", height: 150 }}>
                {this.state.errorMsg.map((error, index) => {
                  return (
                    <Box sx={{ flexWrap: "no-wrap", display: "flex", paddingTop: "10px", flexDirection: "row", alignItems: "center" }} key={index}>
                      <Box sx={{ marginRight: "10px" }}>
                        <img src={errorImage} />
                      </Box>
                      <Box>
                        <Typography data-test-id="error" variant="body2" style={{ color: "#D74D4D" }}>
                          {error}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", flexWrap: 'wrap' }}>
          <CustomOrangeButton
            onClick={this.handleNext}
            disabled={this.state.isFetching}
            data-test-id="nextRef"
            style={{
              position: "absolute",
              right: 30,
              bottom: 30
            }}
          >
            Next
          </CustomOrangeButton>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webstyle = {
  text: {
    fontFamily: `"Avenir-Medium", sans-serif`,
    color: "#333"
  },
  prodImageContainer: {
    position: "relative" as const,
    width: "100px",
    height: "100px",
    padding: "16px",
    border: "0.5px solid #e5e5e5",
    borderRadius: "10px",
    backgroundColor: "#fff"
  },
  imageContainer: {
    width: "100%",
    height: "100%"
  },
  image: {
    position: "absolute" as const,
    top: "-4px",
    left: "90px"
  }
};
// Customizable Area End
