import React from "react";
import {
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    TextField,
    Box,
    withStyles,
    Modal,
    IconButton,
    Input,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AddProductReviewsController,{
    FeedbackData,
    Props, configJSON,
} from "./AddProductReviewsController";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Rating from "@material-ui/lab/Rating";
import StarIcon from "@material-ui/icons/Star";
import CloseIcon from "@material-ui/icons/Close";
import { successIcon } from "../../catalogue/src/ProductDealsController.web";
import { file } from "../../dashboard/src/assets";
import { closeImage } from "./assets";
// Customizable Area End

export default class AddProductReviews extends AddProductReviewsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    renderErrText = (field:string, item: FeedbackData) => {
        return (
        <ErrorMessage name={field} >
        {(message) => <div className={ field === `${item.name}.rating` ? this.props.classes.errorMessageOne : this.props.classes.errorMessage}>{message}</div>}
        </ErrorMessage>
        )
    }
    
    personalDetails() {
        const {classes} = this.props;
        const feedbackData = [
            { feedbackName:"Leave product feedback",rating: "productFeedbackRating",name: "productFeedback",label1: "Title",label2:"Description",placeholder1:"Enter Title",placeholder2:"Enter Details"},
            { feedbackName:"Leave seller feedback",rating: "sellerFeedbackRating",name: "sellerFeedback",label1: "Title",label2:"Description",placeholder1:"Enter Title",placeholder2:"Enter Details"},
            { feedbackName:"Leave delivery feedback",rating: "deliverFeedbackRating",name: "deliveryFeedback",label1: "Title",label2:"Description",placeholder1:"Enter Title",placeholder2:"Enter Details"},
        ]
        return (
            <Formik
                initialValues={{
                productFeedback: { 
                    title: this.state.productFeedback?.attributes?.title || '',
                    description: this.state.productFeedback?.attributes?.description || '', 
                    rating: this.state.productFeedback?.attributes?.rating || 0 
                },
                sellerFeedback: { 
                    title: this.state.sellerFeedback.attributes.title || '', 
                    description: this.state.sellerFeedback.attributes.description || '',  
                    rating: this.state.sellerFeedback.attributes.rating || 0 
                },
                deliveryFeedback: { 
                    title: this.state.deliveryFeedback.attributes.title || '', 
                    description: this.state.deliveryFeedback.attributes.description || '',  
                    rating: this.state.deliveryFeedback.attributes.rating || 0 
                },
                }}
                enableReinitialize={true}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
            >
            {(formikProps) => {
            const {
              values,
              setFieldValue,
              handleSubmit
            } = formikProps;
            return (
            <Form ref={this.reviewFormRef} onSubmit={handleSubmit} translate={undefined}>
                <Grid className={classes.containerOne}>
                {feedbackData.map((item, index) => (
                    <Box key={index} className={classes.feedbackBox}>
                    <Box>
                        <Typography variant="h5" className={classes.feedbackTitle}>
                        {item.feedbackName}
                        </Typography>
                        <Typography variant="h4" className={classes.feedbackStars}>
                        <Rating
                            id={`${item.name}`}
                            name={`${item.name}.rating`}
                            value={values[item.name as keyof typeof values].rating}
                            disabled={this.state[item.name as keyof typeof values].attributes.is_approved}
                            precision={1}
                            onChange={(event, newValue) => {
                                setFieldValue(`${item.name}.rating`, newValue);
                                this.handleRatingClick(item.name, newValue as number)
                            }}
                            size="large"
                            emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize:'32px' }}  />}
                            />
                        </Typography>
                    </Box>
                    <Box className={classes.feedbackMessage}>
                   {this.renderErrText(`${item.name}.rating`, item)}
                     {values[item.name as keyof typeof values].rating > 0 && (
                        <Typography data-test-id="thanksText" className={classes.thanksText}>{configJSON.thanksForAddingReviewText}</Typography>
                    )}
                    </Box>
                    <Box>
                        <label className={classes.label}>{item.label1}</label>
                        <Field
                        as={TextField}
                        id={`${item.name}.title`}
                        name={`${item.name}.title`}
                        placeholder={item.placeholder1}
                        disabled={this.state[item.name as keyof typeof values].attributes.is_approved}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        className={classes.inputFiled1}
                        inputProps={{ style: styles.inputText }}
                        />
                        {this.renderErrText(`${item.name}.title`, item)}
                    </Box>
                    <Box style={webStyle.gap}>
                        <label className={classes.label}>{item.label2}</label>
                        <Field
                        as={TextField}
                        id={`${item.name}.description`}
                        name={`${item.name}.description`}
                        placeholder={item.placeholder2}
                        disabled={this.state[item.name as keyof typeof values].attributes.is_approved}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        className={classes.inputFiled2}
                        inputProps={{ style: styles.inputText }}
                        rows={3}
                        multiline
                        />
                        {this.renderErrText(`${item.name}.description`, item)}
                    </Box>
                    {item.name === "productFeedback" &&
                    <>
                    <Box>
                    <Typography variant="h5" className={classes.imageTitle}>
                        {"Upload image"}
                        </Typography>
                    </Box>
                    {this.state.imageErrMsg ? 
                        <Typography className={classes.imageErrorMessage}>{this.state.imageErrMsg}</Typography> : ''}
                    <Grid container style={{display:'flex', gap:'16px'}}>
                    {this.state.feedbackproductImage.map((image: {url:string}, index:number) => {
                        return(
                            <Box data-test-id="images" style={{position:'relative',width:'183px', height:'194px', display:'flex', paddingTop:'10px', borderRadius:'16px'}}>
                                <img src={image.url} style={{width:'183px', height:'194px', borderRadius:'16px', border:'1px solid #979797'}}/>
                                <IconButton data-test-id="close" aria-label="toggle password visibility" onClick={() => this.handleRemoveImage(index)} edge="end" style={{position:'absolute', right:12, top:10}}>
                                <img src={closeImage} />
                                </IconButton>
                            </Box>
                        )
                    })}
                    <Grid item xs={12} style={{maxWidth:'183px'}}>
                    <Input
                      type="file"
                      style={{
                        display: "none"
                      }}
                      value=""
                      data-test-id="fileInput"
                      onChange={event => this.handleUploadImageFile(event)}
                      inputProps={{
                        "aria-label": "upload documents",
                        ref: this.imgInputRef,
                        accept: "image/*",
                        multiple: true
                      }}
                    />
                    <label htmlFor="upload-button">
                      <Button
                      data-test-id="uploadBtn"
                        variant="outlined"
                        onClick={this.handleOpenImgFileInput}
                        fullWidth
                        style={{
                          width: "100%",
                          height: 194,
                          padding: "20px 0px",
                          margin: "10px 0px",
                          border: "none",
                          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                          borderRadius: "5px",
                          textTransform: "none",
                          fontFamily: `"Lato", sans-serif`
                        }}
                      >
                        <Box>
                          <img src={file} />
                          <Box sx={{ fontSize: "20px", fontWeight: 700, fontFamily: `"Lato", sans-serif`, marginTop:'16px' }}>Upload image</Box>
                        </Box>
                      </Button>
                    </label>
                    </Grid>
                    </Grid>
                    </>
                    }
                    </Box>
                ))}
                <Grid container style={{width:"100%",display:"flex", justifyContent:"flex-end"}}>
                    <Grid item style={webStyle.saveBtnBox}>
                            <Button type="submit" style={{...webStyle.saveBtn,textTransform: "none"}} data-testid="saveButton">
                                Submit
                            </Button>
                    </Grid>
                </Grid>
                </Grid>
            </Form>
            )}}
            </Formik>
    )}

    addProductReviews() {
        const {classes} = this.props;
            return (
            <>
                <Grid container className={classes.container}>
                <Grid container className={classes.header}>
                    <Grid item xs={12} md={5} sm={4} lg={3} className={classes.orderNumber}>
                        <Typography className={classes.orderText}>ORDER <span className={classes.hash}>#</span>
                        <span className={classes.orderNumberSpan}>{' '}{this.state.orderNumber}{"       "}</span></Typography>
                    </Grid>
                    <Grid item xs={12} md={5} sm={5} lg={7} className={classes.orderPlaced}>
                    <Typography className={classes.orderText}>ORDER PLACED
                        <span className={classes.orderPlacedSpan}>{this.state.orderPlacedAt}</span></Typography>
                    </Grid>
                    {!this.state.itemStatus ?
                    <Grid item xs={12} md={2} sm={3} lg={2} style={webStyle.items}><Typography data-test-id="return" onClick={this.handleReturnItem} className={classes.orderText}>{!this.state.loading && "RETURN ITEM"}</Typography></Grid>
                    : ''}
                </Grid>
                <Grid container style={{ display: "flex", flexDirection: "row", width: '98%' }}>
                    <Grid item xs={12} md={2} sm={3} lg={2} className={classes.imageContainer}>
                        <img src={this.state.productImage} alt="img" className={classes.image}></img>
                    </Grid>
                    <Grid item xs={12} md={9} sm={8} lg={9} className={classes.details}>
                        <Typography className={classes.title}>
                            {this.state.orderTitle}
                        </Typography>
                        <Typography className={classes.shippedTo}>
                            Shipped to - <span className={classes.shippedToSpan}>{this.state.shippingAddress}</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
                </>
            )
    }
   
    // Customizable Area End

    render() { 
        const {classes} = this.props;
        return (

            // Customizable Area Start
            <>
                <Grid style={webStyle.wrap}>
                    <Typography variant="h4" style={webStyle.headerTxt}>Write a Review</Typography>
                    <Typography style={webStyle.desc}></Typography>
                    <div>{this.addProductReviews()}</div>
                    <div>{this.personalDetails()}</div>
                </Grid>
                <Modal open={this.state.isReviewModalOpen} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    <Box className={classes.modalMain}>
                    <Box className={classes.blur}></Box>
                    <Box className={classes.verifiedModal} style={webStyle.modalBoxOne}>
                        <Box style={webStyle.boxTwo}>
                        <Box style={webStyle.boxThree}>
                            <IconButton data-test-id="close" aria-label="toggle password visibility" onClick={() => this.handleCloseReview()} edge="end" style={webStyle.close}>
                            <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box className={classes.verifiedImage} style={{ height: "100%" }}>
                            <Box className={classes.verifiedImgContainer}>
                            <img src={successIcon} alt="success image" />
                            </Box>
                            <Box style={webStyle.mainText}>{configJSON.reviewSubmittedText}</Box>
                            <Box style={webStyle.para}>{configJSON.yourReviewSubmitText}</Box>
                        </Box>
                        </Box>
                    </Box>
                    </Box>
                </Modal>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start



const webStyle = {
    wrap: {
        fontFamily: `"Lato", sans-serif`
    },

    papperClasses: {
        padding: "20px 15px",
    },
    items: {
        color: "#FF6008", 
        paddingBottom: "20px", 
        fontWeight: 600 ,
        textAlign: "right" as const,
        cursor: 'pointer'
    }, 
    active: {
        color: "rgb(249, 94, 18)",
        lineHeight: "20px",
        marginBlock: "10px",
        fontWeight: 800,
        textDecoration: "underline",
        cursor: "pointer",
        fontFamily: `"Lato", sans-serif`
    },

    headerTxt: {
        color: "rgb(36, 35, 35)",
        fontWeight: 700,
        fontSize: "2rem",
        fontFamily: `"Lato", sans-serif`,
        marginBottom: '40px'
    },

    desc: {
        marginBlock: "15px",
        fontWeight: 400,
    },
    horizontalBar: {
        width: "100%",
        height: "1px",
        marginBlock: "10px"
    },

    inputFiled1: {
        margin: "10px 5px 10px 0",
        padding: "0px 0px 25px",
        width: "100%",
        '.MuiInputBase-root': {
            width: "100% !important"
        },
       
    },
    inputFiled2: {
        margin: "10px 5px 10px 0",
        padding: "0px 0px 25px",
        width: "100%",  
        '.MuiInputBase-root': {
            width: "100% !important"
        }
    },
    saveBtnBox: {
        display: "flex",
        justifyContent: "end",
    },
    saveBtn: {
        background: "rgb(253, 97, 12) none repeat scroll 0% 0%",
        padding: "10px 25px",
        fontWeight: 600,
        fontSize: "1rem",
        color: "white",
        borderRadius: "5px",
        fontFamily: `"Lato", sans-serif`

    },
    gap: {
        marginTop:'20px'
    },
    modalBoxOne: { 
        background: "white", 
        height: "320px", 
        border: "1px solid black" 
    },
    boxTwo: { 
        width: "100%", 
        height: "100%", 
        display: "flex",
        justifyContent: "flex-end", 
        flexDirection: "column" as const
    },
    boxThree: { 
        width: "100%", 
        height: "100%", 
        display: "flex", 
        alignItems: "flex-end", 
        flexDirection: "column" as const
    },
    close: { 
        marginRight: "-4px" 
    },
    mainText: { 
        fontFamily: `"Lato", sans-serif`, 
        marginBottom: "10px", 
        fontWeight: "bold" as const, 
        fontSize: "20px", 
        color: "#565656" 
    },
    para: { 
        fontFamily: `"Lato", sans-serif`, 
        marginBottom: "70px", 
        fontSize: "12px", 
        color: "#565656" 
    }
}

export const styles = {
    container: {
        width: "96%",
        border: "1px solid rgb(206 204 204)",
        borderRadius: "5px",
        height: "unset",
        marginBlock: "10px",
        padding: "20px 30px 24px 30px",
        marginTop: "50px",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "12px",
        borderBottom: "1px solid #e5e5e5",
        marginBottom: "30px"
    },
    orderNumber: {
        color: "#FF6008",
        paddingBottom: "20px",
        display:'flex',
        alignItems: 'center'
    },
    orderNumberSpan: {
        color: '#33333A',
        fontWeight: 400,
    },
    orderPlaced: {
        textAlign: "start" as const,
        color: "#FF6008",
        paddingBottom: "20px",
        fontWeight: 600,
    },
    orderPlacedSpan: {
        color: 'black',
        fontWeight: 400,
        paddingLeft: "4px",
    },
    imageContainer: {
        textAlign: "center" as const,
        background: "#E7E7E7",
        borderRadius: "5px",
        padding: "10px",
        marginRight: "20px",
        height: "fit-content",
    },
    image: {
        width: "95%",
        borderRadius: "5px",
        margin: "5px",
    },
    details: {
        width: "75%",
    },
    title: {
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 700,
        wordWrap: "break-word" as const,
        fontSize: "24px",
        color: '#030303'
    },
    shippedTo: {
        fontSize: "14px",
        color: "#585858",
        fontWeight: 700,
        paddingTop: "10px",
        fontFamily: `"Lato", sans-serif`,
    },
    shippedToSpan: {
        color: "#585858",
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 400,
        fontSize: "14px",
    },
    orderText: {
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 700,
        fontSize: "14px",
    },
    hash: {
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 400,
        fontSize: "14px",
    },
    containerOne: {
        width: "100%",
        maxWidth: "95%",
        marginTop: "30px",
        paddingRight: "15%",
    },
    feedbackBox: {
        marginBottom: "30px", 
    },
    feedbackTitle: {
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 700,
        float: "left" as const,
    },
    feedbackStars: {
        paddingBottom: "0px",
        fontWeight: 600,
        float: "right" as const,
    },
    feedbackMessage: {
        display: "block",
        float: "right" as const,
        fontSize: "smaller",
        width: "100%",
        color: "grey",
        padding: "0px",
        margin: "0px 0px 30px",
    },
    label: {
        float: "left" as const,
        color: '#666666',
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 400,
        fontSize:'12px'
    },
    inputText: {
        color: "#585858",
        fontFamily: `"AvenirNext", sans-serif`,
        fontWeight: 400,
        fontSize:'14px'
    },
    inputTextLong: {
        color: "#979797",
        paddingBottom: "65px",
    },
    stars : {
        "@media screen and (max-width:500px)": {
           float:"left" as const,
        },
    },
    thanksText: {
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 400,
        fontSize:'13px',
        textAlign: "right" as const,
        color: '#666666'
    },
    errorMessage: {
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 400,
        fontSize:'12px',
        color: '#f44336'
    },
    errorMessageOne: {
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 400,
        fontSize:'12px',
        color: '#f44336',
        textAlign: 'right' as const
    },
    modalMain: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        position: "relative" as const,
        fontFamily: `"Lato", sans-serif`
    },
    blur: {
        width: "100%",
        zIndex: -1,
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.1)",
        position: "absolute" as const,
        backdropFilter: "blur(8px)",
        fontFamily: `"Lato", sans-serif`
    },
    verifiedModal: {
        borderRadius: "10px",
        maxWidth: "450px",
        position: "relative" as const,
        width: "350px",
        display: "flex",
        padding: "20px",
        fontFamily: `"Lato", sans-serif`
      },
      verifiedImage: {
        flexDirection: "column" as const,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        fontFamily: `"Lato", sans-serif`
      },
      verifiedImgContainer: {
        marginBottom: "24px",
        height: "100px",
        width: "100px",
        fontFamily: `"Lato", sans-serif`
      },
      imageTitle: {
        marginTop:'30px',
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 700,
    },
    imageErrorMessage: {
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 400,
        fontSize:'12px',
        color: '#f44336',
        margin: '10px 0'
    },
}
const styledProductReviews = withStyles(styles)(AddProductReviews)
export {styledProductReviews}
// Customizable Area End