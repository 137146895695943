import React from "react";

// Customizable Area Start
import { Box, Table, TableContainer, TableHead, TableRow, TableCell, Theme, withStyles, Button, Menu, MenuItem, TableBody, Typography, Select, FormControlLabel, IconButton, Checkbox, Grid } from "@material-ui/core";
import { BoxNoScroll, CustomMenuItem, FitContentOrangeButton, IOSSwitch } from "../CustomizedElementStyle";
import { arrowSquareDown, arrowSquareUp, cross, stockEditIcon } from "../assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// Customizable Area End

import SellerAddSingleController, { IProduct, Props, configJSON } from "./SellerAddSingleController";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pagination } from "@material-ui/lab";
import { Loader2 } from "../../../../components/src/Loader.web";
import { ToastContainer } from "react-toastify";

export default class SellerAddSingle extends SellerAddSingleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderStocksValue = (fulfilledType: string, stocks: number | null ) => {
    const { classes } = this.props;

    return (
      <>
      {fulfilledType === configJSON.byEzzyText ? 
        <Typography data-test-id="stocks" className={classes.stock}>{configJSON.inStockText} 0</Typography> :
        <Typography data-test-id="stores" className={classes.store}>{configJSON.inStoreText} {this.setStockValue(stocks)}</Typography>}
      </>
    )
  }

  renderButton = (item: any) => {
    return (
      <>
        {this.state.productId === item.id ? (
          <Box onClick={this.handleCloseVariant} className={this.props.classes.buttonBox} data-test-id="closeVarientID">
            <img src={arrowSquareUp} alt="arrowUp" className={this.props.classes.arrowImg} />
            <Typography className={this.props.classes.btnText}>{"Hide all"}</Typography>
          </Box>
        ) : (
          <Box onClick={() => this.handleOpenVariant(item.id)} className={this.props.classes.buttonBox} data-test-id="handleOpenVariantId">
            <img src={arrowSquareDown} alt="arrowDown" className={this.props.classes.arrowImg} />
            <Typography className={this.props.classes.btnText}>{"View all variants"}</Typography>
          </Box>
        )}
      </>
    );
  };

  renderVariants = (item: any,id:string) => {
    
    const { product_title,product_image,content_status,status,fulfilled_type, variant_product_group, stocks} = item.attributes;
    const {retail_price} = item.attributes.product_content.product_attributes
    const { classes } = this.props;
    return (
      <>
        {this.state.productId === id && (
          <Box>
            <TableRow className={classes.tableRowWrap} style={{ margin: "0 8px", border: "1px solid #e7e7e7", backgroundColor: "rgb(242,243,244)", borderBottomLeftRadius: "16px", borderBottomRightRadius: "16px" }}>
              <TableCell className={classes.imageTableCell} style={{ padding: "16px 0px 30px 8px", borderBottomLeftRadius: "16px" }}>
                <Box className={classes.imgWrapper}>
                  <img src={product_image} alt="prod_image" className={classes.img} />
                </Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography className={classes.titleText}>{product_title || ""}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography className={classes.skuCellText}>{variant_product_group.product_sku}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography className={classes.cellText}>{variant_product_group.product_besku}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography className={classes.priceCellText}>AED {retail_price}</Typography>
              </TableCell>
              <TableCell className={classes.stockWrapper}>
                <Grid className={classes.leftWrap} container spacing={1}>
                  <Grid item sm={10} md={10} lg={10} xs={10}>
                  {this.renderStocksValue(fulfilled_type, stocks)}
                  </Grid>
                  <Grid item xs={2} md={2} lg={2} sm={2} className={classes.editWrapper}>
                    <img src={stockEditIcon} onClick={() => this.handleEditIcon(fulfilled_type)} data-test-id="variantEditIcon" className={classes.editIcon} alt="editIcon" />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Box className={this.getContentStatusClass(content_status)}>{this.getContentStatus(content_status)}</Box>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  data-test-id="selectFulfillment"
                  className={classes.select}
                  id="demo-simple-select-standard"
                  IconComponent={ExpandMoreIcon}
                  onChange={this.handleFulfillment.bind(this, item.id, status)}
                  value={this.selectedFulfillmentType(fulfilled_type)}
                  MenuProps={{
                    getContentAnchorEl: null,
                    transformOrigin: {
                      horizontal: "left",
                      vertical: "top"
                    },
                    PaperProps: {
                      style: webStyles.fulfillmentMenu
                    },
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    }
                  }}
                >
                  {configJSON.fulfillmentOptions?.map((item: any, index: number) => {
                    return (
                      <CustomMenuItem key={item} value={item.label} style={{ padding: "8px 12px" }}>
                        {item.label}
                      </CustomMenuItem>
                    );
                  })}
                </Select>
              </TableCell>
              <TableCell style={{ paddingTop: "6px" }} className={classes.tableCell}>
                <FormControlLabel
                  checked={status}
                  onChange={this.handleProductStatus.bind(this, item)}
                  control={<IOSSwitch data-test-id="switch" name="checkedB" disabled={content_status !== "accepted"} />}
                  data-test-id="statusSwitch"
                  label={
                    <Box sx={{ position: "relative" }}>
                      <Box className={this.getLinkStatusClass(status)}>{this.setLinkStatus(status)}</Box>
                    </Box>
                  }
                />
              </TableCell>
              <TableCell className={classes.tableCell} style={{ paddingTop: "4px", borderBottomRightRadius: "16px" }}>
                <IconButton aria-label="more" aria-controls="long-variant-menu" onClick={this.handleOptionsMenu.bind(this, item,true)} data-test-id="variantOptionIconRef">
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-variant-menu"
                  data-test-id="addVariantLongMenu"
                  anchorEl={this.state.optionsAnchorEl}
                  keepMounted
                  open={Boolean(this.state.optionsAnchorEl)}
                  onClose={this.handleCloseOptionsMenu}
                  PaperProps={{
                    style: webStyles.optionsMenu
                  }}
                >
                  <MenuItem data-test-id="productLink" className={classes.menuOption} style={{cursor:'unset'}} >
                    {configJSON.productLinkText}
                  </MenuItem>
                  <MenuItem data-test-id="viewVariantProduct" className={classes.menuOption} onClick={() => this.handleViewProduct(true)}>
                    {configJSON.viewProductLink}
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          </Box>
        )}
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { classes } = this.props;
    const fulfillmentMenu = configJSON.fulfillmentOptions;
    const rowCount = this.state.productList && this.state.productList.length;
    const count: any = this.state.totalProductCount / 10;
    const filterByLiveStatus = configJSON.filterByLiveStatus;
    const filterByContentStatus = configJSON.filterByContentStatus;
    const sortingOptions = configJSON.sortingOptions;
    return (
      <>
        <ToastContainer />
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} sm={3} md={3} lg={3} className={classes.heading}>
            {configJSON.productCatalogueText}
          </Grid>
          <Grid item xs={12} sm={9} md={9} lg={9} className={classes.filterWrapper}>
            <Button id="filter-menu" data-test-id="filterBtn" className={classes.filterBtn} onClick={this.handleFilterButton}>
              {configJSON.filterLabel}
            </Button>
            <Menu
              id="filter-menu"
              data-test-id="filterMenu"
              anchorEl={this.state.filterOptionsAnchorEl}
              keepMounted
              open={Boolean(this.state.filterOptionsAnchorEl)}
              onClose={this.handleCloseFilterOptions}
              PaperProps={{
                style: { ...webStyles.menuStyle, marginTop: window.innerWidth < 600 ? "200px" : "100px" }
              }}
            >
              <Grid container className={classes.filterContainer}>
                <Grid item xs={12} className={classes.marginRight}>
                  <Typography className={classes.filterText}>{configJSON.liveStatusLabel}</Typography>
                  {filterByLiveStatus.map((item: { label: string; value: boolean }) => {
                    return (
                      <MenuItem className={classes.menuCheckbox}>
                        <Checkbox data-test-id="statusFilter" className={classes.delivered} onChange={this.handleStatusFilters.bind(this, item.value)} />
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Grid>
                <Grid item xs={12} className={classes.marginRight}>
                  <Typography className={classes.filterText}>{configJSON.contentStatusLabel}</Typography>
                  {filterByContentStatus.map((item: { label: string; value: string }) => {
                    return (
                      <MenuItem className={classes.menuCheckbox}>
                        <Checkbox data-test-id="contentStatusMenu" className={classes.delivered} onChange={this.handleContentStatusFilters.bind(this, item.value)} />
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Grid>
                <Grid item xs={12} className={classes.marginRight}>
                  <Typography className={classes.filterText}>{configJSON.fulfillmentTypeLabel}</Typography>
                  {fulfillmentMenu.map((item: { label: string; value: string }) => {
                    return (
                      <MenuItem className={classes.menuCheckbox}>
                        <Checkbox data-test-id="fulfillmentTypeFilter" className={classes.delivered} onChange={this.handleFulfillmentFilters.bind(this, item.value)} />
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Grid>
              </Grid>
            </Menu>
            <Button data-test-id="sortBtn" className={classes.filterBtn} onClick={this.handleSorting}>
              {configJSON.sortingLabel}
            </Button>
            <Menu
              id="menu"
              data-test-id="addSortMenu"
              anchorEl={this.state.sortAnchorEl}
              keepMounted
              open={Boolean(this.state.sortAnchorEl)}
              onClose={this.handleCloseSortingMenu}
              PaperProps={{
                style: webStyles.sortMenu
              }}
            >
              {sortingOptions.map((item: { label: string; value: string }) => {
                return (
                  <MenuItem data-test-id="sortMenu" className={item.value === this.state.sortByValue ? classes.activeText : classes.menuOption} onClick={this.handleSortingOptions.bind(this, item.value)}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Menu>
            {this.state.productList.length > 0 && (
              <>
                <Button data-test-id="addProductOption" id="menu" onClick={this.handleGoToCategorizeProducts} className={classes.addNewBtn}>
                  {configJSON.addNewProductText}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <BoxNoScroll className={classes.noScroll}>
          <Box style={webStyles.paddingBottom}>
            <Box className={classes.mainSection}>
              {this.state.loading ? (
                <Box style={webStyles.loader}>
                  <Loader2 loading={this.state.loading} />
                </Box>
              ) : (
                <>
                <Typography ref={this.topRef}>
                  <TableContainer className={classes.tableContainer}>
                    <Table aria-label="simple table" stickyHeader style={{ borderSpacing: "0 8px" }}>
                      <TableHead>
                        <TableRow style={{ display: "grid", gridTemplateColumns: "repeat(10,1fr)" }}>
                          <TableCell align="left" className={classes.headerOne}>
                            {configJSON.prodImageLabel}
                          </TableCell>
                          <TableCell align="left" className={classes.headerOne}>
                            {configJSON.prodNameLabel}
                          </TableCell>
                          <TableCell align="left" className={classes.headerTwo}>
                            {configJSON.sellerSKULabel}
                          </TableCell>
                          <TableCell align="left" className={classes.headerTwo} >
                            {configJSON.beskuLabel}
                          </TableCell>
                          <TableCell align="left" className={classes.headerTwo}>
                            {configJSON.priceLabel}
                          </TableCell>
                          <TableCell align="left" className={classes.headerTwo} >
                            {configJSON.stocksLabel}
                          </TableCell>
                          <TableCell align="left" className={classes.headerTwo} >
                            {configJSON.contentStatusLabel}
                          </TableCell>
                          <TableCell align="left" className={classes.headerTwo} >
                            {configJSON.fulfillmentLabel}
                          </TableCell>
                          <TableCell align="left" className={classes.headerTwo}>
                            {configJSON.liveStatusLabel}
                          </TableCell>
                          <TableCell align="left" className={classes.headerTwo} >
                            {configJSON.optionsLabel}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.productList.length > 0
                        ? this.state.productList.map((item: IProduct) => {
                            const { sku, besku, product_image, status, fulfilled_type ,variant_products, stocks} = item.attributes;
                            const price = item.attributes?.product_content?.product_attributes?.retail_price;
                            const productTitle = item.attributes?.product_content?.product_attributes?.product_title;
                            return (
                              <TableBody data-test-id="list">
                                <TableRow className={classes.tableRowWrap} style={{ border: "1px solid #e7e7e7" }}>
                                  <TableCell className={classes.imageTableCell}>
                                    <Box className={classes.imgWrapper}>
                                      <img className={classes.img} src={product_image} alt="prod_image" />
                                    </Box>
                                    { variant_products?.data.length>0 &&this.renderButton(item)}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Typography className={classes.titleText} data-test-id="titletxt">
                                      {productTitle || ""}
                                    </Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Typography className={classes.skuCellText} data-test-id="skuID">
                                      {sku}
                                    </Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Typography data-test-id="beskuID" className={classes.cellText}>
                                      {besku}
                                    </Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Typography data-test-id="AEDid" className={classes.priceCellText}>
                                      AED {price || 0}
                                    </Typography>
                                  </TableCell>
                                  <TableCell className={classes.stockWrapper}>
                                    <Grid container spacing={1} className={classes.leftWrap}>
                                      <Grid item xs={10} sm={10} md={10} lg={10}>
                                        {this.renderStocksValue(fulfilled_type, stocks)}
                                      </Grid>
                                      <Grid item xs={2} sm={2} md={2} lg={2} className={classes.editWrapper}>
                                        <img data-test-id="editIcon" onClick={() => this.handleEditIcon(fulfilled_type)} src={stockEditIcon} alt="editIcon" className={classes.editIcon} />
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Box className={this.getContentStatusClass(item?.attributes?.content_status)}>{this.getContentStatus(item?.attributes?.content_status)}</Box>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Select
                                      data-test-id="selectFulfillment"
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      className={classes.select}
                                      IconComponent={ExpandMoreIcon}
                                      value={this.selectedFulfillmentType(fulfilled_type)}
                                      onChange={this.handleFulfillment.bind(this, item.id, item.attributes.status)}
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left"
                                        },
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left"
                                        },
                                        PaperProps: {
                                          style: webStyles.fulfillmentMenu
                                        }
                                      }}
                                    >
                                      {fulfillmentMenu?.map((item: any, index: number) => {
                                        return (
                                          <CustomMenuItem style={{ padding: "8px 12px" }} value={item.label} key={item}>
                                            {item.label}
                                          </CustomMenuItem>
                                        );
                                      })}
                                    </Select>
                                  </TableCell>
                                  <TableCell className={classes.tableCell} style={{ paddingTop: "6px" }}>
                                    <FormControlLabel
                                      data-test-id="statusSwitch"
                                      control={<IOSSwitch data-test-id="switch" checked={status} onChange={this.handleProductStatus.bind(this, item)} name="checkedB" disabled={item?.attributes?.content_status !== "accepted"} />}
                                      label={
                                        <Box sx={{ position: "relative" }}>
                                          <Box className={this.getLinkStatusClass(status)}>{this.setLinkStatus(status)}</Box>
                                        </Box>
                                      }
                                    />
                                  </TableCell>
                                  <TableCell style={{ paddingTop: "4px" }} className={classes.tableCell}>
                                    <IconButton onClick={this.handleOptionsMenu.bind(this, item,false)} data-test-id="optionIconRef" aria-label="more" aria-controls="long-menu">
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      anchorEl={this.state.optionsAnchorEl}
                                      id="long-menu"
                                      data-test-id="addLongMenu"
                                      open={Boolean(this.state.optionsAnchorEl)}
                                      keepMounted
                                      PaperProps={{
                                        style: webStyles.optionsMenu
                                      }}
                                      onClose={this.handleCloseOptionsMenu}
                                    >
                                      <MenuItem className={classes.menuOption} data-test-id="productLink" style={{cursor:'unset'}} >
                                        {configJSON.productLinkText}
                                      </MenuItem>
                                      <MenuItem className={classes.menuOption} data-test-id="viewProduct" onClick={() => this.handleViewProduct(false)}>
                                        {configJSON.viewProductLink}
                                      </MenuItem>
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                                {variant_products?.data && variant_products?.data.map((itemm:any)=>{
                                  return this.renderVariants(itemm,item.id)
                                })
                                }
                              </TableBody>
                            );
                          })
                        : <TableBody style={{marginLeft:'350px', display:'flex', fontSize:'14px', fontFamily:'Lato,sans-serif'}}>No Products Found</TableBody>}
                    </Table>
                  </TableContainer>
                  </Typography>
                  {this.state.productList.length > 0 && (
                    <Pagination
                      className={classes.pagination}
                      data-test-id="pagination"
                      page={this.state.page}
                      count={this.getCountValue()}
                      onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                        this.setState({ page: value });
                        this.getSearchValue();
                        this.topRef.current?.scrollIntoView({ behavior: 'smooth' });
                      }}
                      shape="rounded"
                      siblingCount={0}
                      boundaryCount={1}
                    />
                  )}
                  {this.state.productList.length === 0 && this.state.search.length === 0 && (
                    <Box data-test-id="component" sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px" }}>
                        <img src={cross} className={classes.image} />
                        <Box className={classes.textOne}>{configJSON.welcomeText}</Box>
                        <Box className={classes.textTwo}>{configJSON.startAddingProductsText}</Box>
                        <FitContentOrangeButton className={classes.addBtn} onClick={this.handleGoToCategorizeProducts}>
                          {configJSON.addNewProductLabel}
                        </FitContentOrangeButton>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  footer_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "#B1B2B4",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px"
  },
  footer_item: {
    padding: "50px 0px 10px 20px",
    fontWeight: 600
  },
  menuStyle: {
    padding: "12px 12px 16px",
    borderRadius: "5px",
    marginTop: "100px",
    boxSizing: "border-box" as const,
    maxHeight: "300px",
    width: "auto"
  },
  sortMenu: {
    padding: "10px",
    borderRadius: "5px",
    marginTop: "56px",
    maxHeight: 200,
    width: "20ch"
  },
  btnMenu: {
    padding: "10px",
    borderRadius: "5px",
    marginTop: "56px",
    maxHeight: 200,
    width: "20ch"
  },
  paddingBottom: { paddingBottom: 20 },
  optionsMenu: {
    padding: "10px",
    borderRadius: "5px",
    marginTop: "56px",
    maxHeight: 200,
    width: "15ch"
  },
  fulfillmentMenu: {
    background: "#FFF",
    marginTop: "6px",
    maxHeight: "120px",
    overflowY: "auto" as const
  },
  
  loader: { height: "80vh" }
};
const styles = (theme: Theme) => ({
  tableContainer: {
    maxWidth: "100%",
    position: "relative" as const,
    "&.MuiTableContainer-root": {
      overflowX: "auto",
      overflowY: "hidden",
      zIndex: 99
    }
  },
  activeText: {
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
    fontSize: 14,
    color: "#000"
  },
  checkbox: {
    backgroundColor: "#E3E3E3",
    width: 23,
    height: 23,
    borderRadius: "5px"
  },
  headerOne: {
    width:'142px',
    border: "0",
    padding: "16px 8px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 13,
    fontWeight: 800,
    color: "#33333A"
  },
  headerTwo: {
    width:'142px',
    padding: "16px 8px",
    border: "0",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 13,
    fontWeight: 800,
    color: "#33333A"
  },
  textOne: {
    fontFamily: `"Lato-Medium", sans-serif`,
    marginTop: 10,
    fontWeight: 400,
    fontSize: 16,
    color: "#33333A",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px"
    }
  },
  textTwo: {
    fontFamily: `"Lato-Medium", sans-serif`,
    marginTop: 5,
    fontWeight: 400,
    fontSize: 16,
    color: "#33333A",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px"
    }
  },
  addBtn: {
    marginTop: 20,
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px"
    }
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      width: "60px",
      height: "60px"
    }
  },
  addNewBtn: {
    padding: "8px 12px",
    fontFamily: `"Lato-Medium", sans-serif`,
    fontSize: 14,
    color: "#fff",
    textTransform: "none" as const,
    backgroundColor: "#3F87C2",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#3F87ab",
      color: "#fff"
    }
  },
  menuOption: {
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
    fontSize: 14,
    color: "#666666",
    "&:hover": {
      color: "#000",
      fontWeight: 500 as const,
      backgroundColor: "transparent"
    }
  },
  checkboxOne: {
    width: "16px",
    height: "16px",
    position: "relative" as const,
    "&::before": {
      content: "/xb9",
      fontSize: "16px",
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
  },
  imgWrapper: {
    borderRadius: "5px",
    background: "rgb(231,231,231)",
    padding: "6px",
    width: "44px",
    height: "44px"
  },
  accepted: {
    width: "85px",
    padding: "4px 8px",
    color: "#60c32b",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 12,
    fontWeight: 500 as const,
    border: "0px solid #ec9e31",
    backgroundColor: "#d4f4c3",
    borderRadius: 5,
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "0",
    textAlign: "center" as const,
    justifyContent: "center"
  },
  rejected: {
    width: "85px",
    padding: "4px 8px",
    color: "red",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 12,
    fontWeight: 500 as const,
    border: "0px solid #ec9e31",
    backgroundColor: "#FCE4E4",
    borderRadius: 5,
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "0",
    justifyContent: "center"
  },
  select: {
    fontSize: "12px",
    color: "#697A8D",
    fontFamily: `"Lato", sans-serif` as const,
    input: {
      fontSize: "12px",
      color: "#697A8D",
      "&:hover": {
        borderBottom: "none",
        backgroundColor: "#fff"
      }
    },
    "&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderBottom: "none"
    },
    "& .MuiSelect-select:focus": {
      background: "none",
      borderBottom: "none"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  },
  active: {
    fontSize: 12,
    fontWeight: 600,
    position: "absolute" as const,
    left: -86,
    bottom: -9,
    color: "white"
  },
  inactive: {
    fontSize: 12,
    fontWeight: 600,
    position: "absolute" as const,
    left: -70,
    bottom: -9,
    color: "white"
  },
  tableRowWrap: {
    verticalAlign: "top",
    backgroundColor: "#F9F9F9",
    borderSpacing: "0 4px",
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)",
    "&.MuiTable-root": {
      borderSpacing: "0 4px"
    }
  },
  tableCell: {
    border: "0",
    textAlign: "left" as const,
    width:'142px',
    padding: "16px 8px",
    background: "rgb(242,242,244)"
  },
  img: { width: "100%", height: "100%" },
  cellText: {
    color: "#697A8D",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 12,
    fontWeight: 500 as const
  },
  stockWrapper: {
    paddingLeft: "12px",
    paddingRight: "20px",
    border: "0",
    textAlign: "left" as const,
    backgroundColor: "rgb(242,243,244)"
  },
  stock: {
    marginLeft: "-4px",
    fontSize: "12px",
    fontFamily: `"Lato", sans-serif` as const,
    textAlign: "left" as const,
    color: "#697A8D",
    whiteSpace: "nowrap" as const
  },
  store: {
    marginLeft: "-4px",
    fontSize: "11px",
    fontFamily: `"Lato", sans-serif` as const,
    textAlign: "left" as const,
    color: "#697A8D",
    whiteSpace: "nowrap" as const
  },
  underReview: {
    width: "85px",
    padding: "4px 8px",
    color: "#ec9e31",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 12,
    fontWeight: 500 as const,
    border: "0px solid #ec9e31",
    backgroundColor: "#ffefd3",
    borderRadius: 5,
    borderBottom: "0",
    textAlign: "center" as const
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center"
  },
  filterBtn: {
    padding: "4px 23px",
    color: "#657A8E",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 16,
    fontWeight: 500,
    border: "1px solid rgba(101, 122, 142, 0.320241)",
    borderRadius: 5,
    marginRight: 20,
    textTransform: "capitalize" as const
  },
  imageTableCell: {
    border: "0",
    textAlign: "left" as const,
    padding: "16px 0px 16px 16px",
    background: "rgb(242,242,244)",
    width: "142px"
  },
  imageHeader: {
    border: "0",
    padding: "16px 8px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: 13,
    fontWeight: 800,
    color: "#33333A",
    width: "135px"
  },
  tableTitleCell: {
    color: "#697A8D",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 12,
    fontWeight: 500 as const,
    width: "100px"
  },
  skuCellText: {
    color: "#697A8D",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 12,
    fontWeight: 500 as const
  },
  editIcon: {
    width: "18px",
    height: "18px",
    cursor: "pointer"
  },
  leftWrap: { width: "64px" },
  editWrapper: {
    display: "flex",
    alignItems: "center"
  },
  priceCellText: {
    color: "#697A8D",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 12,
    fontWeight: 500 as const,
    width: "70px",
    textTransform: "capitalize" as const
  },
  delivered: {
    color: "#979797",
    "&$checked": {
      color: "#ff6008"
    },
    "&.Mui-checked": {
      color: "#ff6008"
    }
  },
  menuCheckbox: {
    color: "#999999",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: `"Lato-Heavy", sans-serif` as const,
    textTransform: "capitalize" as const,
    paddingLeft: "0",
    marginBottom: "-20px"
  },
  filterText: {
    color: "#33333a",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: `"Lato-Heavy", sans-serif` as const,
    textTransform: "capitalize" as const,
    marginLeft: "12px"
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  heading: {
    padding: "20px 0px 15px 0px",
    color: "#000",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif`,
    "@media (max-width:700px)": {
      fontSize: "14px"
    }
  },
  filterWrapper: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "flex-end",
    padding: "12px 0"
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "nowrap" as const,
    "@media (max-width:600px)": {
      flexDirection: "column" as const
    }
  },
  noScroll: {
    paddingBottom: 20,
    overflow: "auto"
  },
  mainSection: {
    borderRadius: "8px",
    background: "white",
    display: "flex",
    flexDirection: "column" as const,
    padding: "0px 20px 30px 20px",
    position: "relative" as const
  },
  marginRight: {
    marginRight: "20px",
    "@media (max-width:600px)": {
      marginBottom: "16px"
    }
  },
  titleText: {
    color: "#697A8D",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 12,
    fontWeight: 500 as const,
    width: "100px",
    wordWrap: "break-word" as const
  },
  buttonBox: {
    display: "flex",
    marginTop: "10px",
    cursor: "pointer"
  },
  arrowImg: {
    width: "20px",
    height: "20px",
    marginRight: "6px"
  },
  btnText: {
    fontSize: "12px",
    fontFamily: `"Lato", sans-serif` as const,
    color: "#707070"
  }
});

const styledAddSingle = withStyles(styles)(SellerAddSingle);
export { styledAddSingle };
// Customizable Area End
