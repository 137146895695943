import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link,
  // Customizable Area Start
  Grid,
  Modal,
  Input,
  IconButton,
  FormControlLabel,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "typeface-lato";
import { ThemeProvider, createTheme, styled } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SellerLandingPage from "../../landingpage/src/SellerLandingPage.web";
import { documentImage, errorImage } from "./assets";
import Close from "@material-ui/icons/Close";
const documentImg = documentImage;
import { BorderLinearProgress, UploadLinearProgress, CustomTextField, CustomIBANTextField } from "./CustomElementStyling";
interface CustomFile extends File {
  isUploading?: boolean;
  uploadProgress?: number;
  fileId?: string;
}
interface FilesUploaded {
  [key: string]: CustomFile;
}
interface IBasicModal {
  showModal: boolean;
  header: string;
  subHeader: string;
  modalType: string;
  stepPercentage: number;
  files: FilesUploaded;
}
const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 700,
      fontSize: 20,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
      color: "#3E3E3E",
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
      color: "#3E3E3E",
    },
    body1: {
      fontSize: 12,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460,
    },
  },
});
// Customizable Area End

import BulkUploadingController, {
  Props,
  configJSON,
  baseURL,
} from "./BulkUploadingController";

export default class BulkUploading extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  BaseModal = ({ showModal, header, subHeader, modalType, stepPercentage, files }: IBasicModal) => {
    const checkColor = () => {

      if(this.checkForDisableButtonVatIban()) {
        return "#c1c1c1"
      }

      if (modalType === "vatCertificate") {
        if (this.state.vatReason && !this.state.disableButtonOnUpload) return "#ff6008";
        return Object.keys(files).length === 0 ? "#c1c1c1" : "#ff6008";
      }

      if (modalType === "ibanCertificate") {
        if (this.state.account_no && this.state.bank_address && this.state.bank_name && this.state.swift_code && this.state.iban && this.state.name) {
          return "#ff6008";
        }
        return Object.keys(files).length === 0 ? "#c1c1c1" : "#ff6008";
      }
      return Object.keys(files).length === 0 ? "#c1c1c1" : "#ff6008";
    };
    const submitButtonColor = checkColor();
    const disableSubmitButton = submitButtonColor === "#c1c1c1" ? true : false;
    return (
      <Modal open={showModal} hideBackdrop={true}>
        <Box sx={{ width: "100%", height: "100vh", position: "fixed", overflow: "auto" }}>
          <Box sx={{ width: "100%", height: "auto", minHeight: "100vh", overflow: "auto", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
            <Box sx={webStyle.blurredBackground}></Box>
            <Box sx={{ width: "1100px", borderRadius: "10px", padding: "40px", position: "relative", margin: "20px" }} style={{ background: "white" }}>
              <Box sx={{ marginBottom: "20px" }}>
                <Typography variant="h5">Required documents</Typography>
              </Box>
              <Box sx={{ position: "relative", width: "100%", marginBottom: "40px" }}>
                <BorderLinearProgress variant="determinate" value={stepPercentage} />
                <Box sx={{ position: "absolute", top: -12, left: `${stepPercentage}%` }} style={{ transform: "translateX(-50%)" }}>
                  <Box sx={{ width: 0, height: 0, borderLeft: "8.5px solid transparent", borderRight: "8.5px solid transparent", borderTop: "8.5px solid #ff600a" }}></Box>
                </Box>
              </Box>
              {modalType === "vatCertificate" && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box>
                    <FormControlLabel
                      label=""
                      data-test-id="checkboxHasVatRef"
                      checked={this.state.hasVatCheckbox}
                      onChange={() => this.handleHasVatOnChange()}
                      control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008" }} />} name="checkedH" style={{ border: "1px solid #adadad", width: "8px", height: "8px" }} />}
                      style={{ marginRight: "10px", paddingLeft: "10px" }}
                    />
                  </Box>
                  <Box>
                    <Box sx={{ marginBottom: "10px" }}>
                      <Typography variant="h6">VAT certificate</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">Upload your VAT certificate if your organization has one.</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {modalType !== "ibanCertificate" && modalType !== "vatCertificate" && (
                <>
                  <Box sx={{ marginBottom: "10px" }}>
                    <Typography variant="h6">{header}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1">{subHeader}</Typography>
                  </Box>
                </>
              )}
              {modalType === "ibanCertificate" && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box>
                    <FormControlLabel
                      label=""
                      data-test-id="checkboxHasIbanRef"
                      checked={this.state.hasIbanCheckbox}
                      onChange={() => this.handleHasIbanOnChange()}
                      control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008" }} />} name="checkedH" style={{ border: "1px solid #adadad", width: "8px", height: "8px" }} />}
                      style={{ marginRight: "10px", paddingLeft: "10px" }}
                    />
                  </Box>
                  <Box>
                    <Box sx={{ marginBottom: "10px" }}>
                      <Typography variant="h6">IBAN certificate</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">Upload your IBAN certificate if your organization has one.</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Input
                data-test-id={`uploadBasicDocumentButtonRef${modalType}`}
                type="file"
                style={{
                  display: "none",
                }}
                onChange={(event) => this.handleFileUpload(modalType, event)}
                value=""
                inputProps={{
                  "aria-label": "upload documents",
                  ref: this.inputRef,
                  accept: ".pdf",
                }}
              />
              <label htmlFor="upload-button">
                <Button
                  variant="outlined"
                  disabled={this.state.disableButtonOnUpload}
                  fullWidth
                  style={{
                    width: "100%",
                    padding: "20px 0px",
                    margin: "20px 0px",
                    border: "none",
                    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23797979FF' stroke-width='3' stroke-dasharray='6%2c 6' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e")`,
                    borderRadius: "5px",
                    textTransform: "none",
                    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
                    color: "#646464",
                    backgroundColor: "#f3f3f3",
                  }}
                  onClick={this.handleOpenFileDialog}>
                  Upload Documents
                </Button>
              </label>
              <Grid container spacing={2}>
                {Object.keys(files)
                  .map((key) => files[key])
                  .map((file, index) => {
                    const { isUploading, uploadProgress, fileId } = file;
                    if (isUploading) {
                      return (
                        <Grid item xl={12} lg={12} sm={12} xs={12} key={`${file.name}-${index}`}>
                          <Box>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                              <Box>
                                <StyledFileName variant="h6">{file.name}</StyledFileName>
                              </Box>
                              <Box>
                                <IconButton data-test-id="removeFileButtonRef" style={{ margin: 0, padding: 0, color: "black" }} onClick={() => this.handleRemoveFile(modalType, fileId as unknown as string)}>
                                  <Close />
                                </IconButton>
                              </Box>
                            </Box>
                            <UploadLinearProgress variant="determinate" value={uploadProgress} style={{ margin: "10px 0px" }} />
                            <Box>
                              <Typography variant="body1">{uploadProgress}% done</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }
                    if (!isUploading) {
                      return (
                        <Grid item key={`${file.name}-${index}`} style={{width:'100%'}}>
                          <FileBox>
                            <Box sx={{ display: "flex", flexDirection: "row", width: "fit-content", alignItems: "center" }}>
                              <Box sx={{ width: "30px", height: "40px", marginRight: "10px", alignItems: "center", display: "flex" }}>
                                <img src={documentImg} width={"32px"} />
                              </Box>
                              <StyledFileName variant="h6">{file.name}</StyledFileName>
                            </Box>
                            <Box>
                              <IconButton data-test-id={`removeFileDuringUploadingRef${modalType}`} style={{ margin: 0, padding: 0, color: "black" }} onClick={() => this.handleRemoveFile(modalType, fileId as unknown as string)}>
                                <Close />
                              </IconButton>
                            </Box>
                          </FileBox>
                        </Grid>
                      );
                    }
                  })}
              </Grid>
              <Box sx={{ height: "100px" }}>
                {this.state.errorMessages.map((item, index) => {
                  return (
                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "no-wrap", alignItems: "center", marginTop: "5px", paddingTop: "10px" }} key={index}>
                      <Box sx={{ marginRight: "5px" }}>
                        <img src={errorImage} />
                      </Box>
                      <Box>
                        <Typography data-test-id="commonErr" variant="body1" style={{ color: "#D74D4D" }}>
                          {item}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {modalType === "vatCertificate" && (
                <>
                  <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                    <Box>
                      <FormControlLabel
                        label=""
                        data-test-id="checkboxHasNoVatRef"
                        checked={this.state.hasNoVatCheckbox}
                        onChange={() => this.handleHasNoVatOnChange()}
                        control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008" }} />} name="checkedH" style={{ border: "1px solid #adadad", width: "8px", height: "8px" }} />}
                        style={{ marginRight: "10px", paddingLeft: "10px" }}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: "10px" }}>
                        <Typography variant="h6">Don't have VAT certificate ?</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">Please mention the reason</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <CustomTextField label="Type here" data-test-id="vatTextFieldRef" value={this.state.vatReason} multiline minRows={1} fullWidth onChange={(event) => this.handleInputOnChange("vatReason", event)} />
                  {this.state.VatReasonErrorMsg &&
                    <Box id="vatReasonError" sx={{  flexDirection: "row", alignItems: "center",  display: "flex", flexWrap: "no-wrap",  marginTop: "5px", paddingTop: "10px" }}>
                      <Box id="vatReasonErrorImg" sx={{ marginRight: "5px" }}>
                        <img src={errorImage} alt="errorReason" />
                      </Box>
                      <Box id="vatReasonErrorMsg">
                        <Typography data-test-id="commonErr" id="vatReasonErrorMsgText" variant="body1" style={{ color: "#D74D4D" }}>
                          {this.state.VatReasonErrorMsg}
                        </Typography>
                      </Box>
                    </Box>
                  }
                  <Box sx={{ width: "100%", height: "60px" }}></Box>
                </>
              )}
              {modalType === "ibanCertificate" && (
                <>
                  <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                    <Box>
                      <FormControlLabel
                        label=""
                        data-test-id="checkboxHasNoIbanRef"
                        checked={this.state.hasNoIbanCheckbox}
                        onChange={() => this.handleHasNoIbanOnChange()}
                        control={<Checkbox icon={<FiberManualRecordIcon style={{ color: "white" }} />} checkedIcon={<FiberManualRecordIcon style={{ color: "#ff6008" }} />} name="checkedH" style={{ border: "1px solid #adadad", width: "8px", height: "8px" }} />}
                        style={{ marginRight: "10px", paddingLeft: "10px" }}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: "10px" }}>
                        <Typography variant="h6">Don't have IBAN certificate ?</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <CustomIBANTextField data-test-id="accountNoInputRef" value={this.state.account_no} onChange={(event) => this.handleInputOnChange("account_no", event)} label="Account no*" fullWidth />
                      {this.state.accountErr ? 
                      <Typography data-test-id="accountErr" style={webStyle.errorText}>{this.state.accountErr}</Typography> : ''}
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <CustomIBANTextField label="Name*" data-test-id="nameInputRef" value={this.state.name} onChange={(event) => this.handleInputOnChange("name", event)} fullWidth />
                      {this.state.nameErr ? 
                      <Typography data-test-id="nameErr" style={webStyle.errorText}>{this.state.nameErr}</Typography> : ''}
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <CustomIBANTextField label="IBAN*" data-test-id="ibanInputRef" value={this.state.iban} onChange={(event) => this.handleInputOnChange("iban", event)} fullWidth />
                      {this.state.ibanErr ? 
                      <Typography data-test-id="ibanErr" style={webStyle.errorText}>{this.state.ibanErr}</Typography> : ''}
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <CustomIBANTextField label="Bank Name*" data-test-id="bankNameInputRef" value={this.state.bank_name} onChange={(event) => this.handleInputOnChange("bank_name", event)} fullWidth />
                      {this.state.bankNameErr ? 
                      <Typography data-test-id="bankNameErr" style={webStyle.errorText}>{this.state.bankNameErr}</Typography> : ''}
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <CustomIBANTextField label="Bank Address*" data-test-id="bankAddressInputRef" value={this.state.bank_address} onChange={(event) => this.handleInputOnChange("bank_address", event)} fullWidth />
                      {this.state.bankAdd ? 
                      <Typography data-test-id="bankAdd" style={webStyle.errorText}>{this.state.bankAdd}</Typography> : ''}
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <CustomIBANTextField label="Swift Code*" data-test-id="swiftCodeInputRef" value={this.state.swift_code} onChange={(event) => this.handleInputOnChange("swift_code", event)} fullWidth />
                      {this.state.swiftCodeErr ? 
                      <Typography data-test-id="swiftCodeErr" style={webStyle.errorText}>{this.state.swiftCodeErr}</Typography> : ''}
                    </Grid>
                  </Grid>
                  {this.state.ibanErrorMsg ? 
                  <Box sx={webStyle.errorBoxStyle}>
                  <Box sx={webStyle.imgBox}>
                    <img src={errorImage} />
                  </Box>
                  <Box>
                    <Typography variant="body1" style={webStyle.ibanErr} data-test-id="ibanCommon">
                      {this.state.ibanErrorMsg}
                    </Typography>
                  </Box>
                </Box>: ''}
                  <Box sx={{ width: "100%", minHeight: "70px" }}></Box>
                </>
              )}
              <Grid container spacing={4}>
                <Grid item xl={6} lg={6} sm={6} xs={12}>
                  <Button
                    data-test-id={`backBasicModalRef${modalType}`}
                    fullWidth
                    disabled={this.state.disableButtonOnUpload}
                    style={{
                      width: "100%",
                      padding: "10px 0px",
                      borderRadius: 5,
                      background: "#3e3e3e",
                      textTransform: "none",
                      color: "white",
                      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
                      fontSize: 16,
                      fontWeight: "bolder",
                    }}
                    onClick={() => this.handleOnBackButton(modalType)}>
                    Back
                  </Button>
                </Grid>
                <Grid item xl={6} lg={6} sm={6} xs={12}>
                  <Button
                    data-test-id={`submitModalRef${modalType}`}
                    fullWidth
                    disabled={this.checkForDisableButtonVatIban() || this.state.disableButtonOnUpload || disableSubmitButton}
                    style={{
                      width: "100%",
                      padding: "10px 0px",
                      borderRadius: 5,
                      backgroundColor: submitButtonColor,
                      textTransform: "none",
                      color: "white",
                      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
                      fontSize: 16,
                      fontWeight: "bolder",
                    }}
                    onClick={() => this.handleOnSubmitAction(modalType)}>
                    {this.state.submitting ? "Submitting" : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SellerLandingPage navigation={this.props.navigation} id={"seller-landing-page"} />
        <Box sx={webStyle.mainWrapper}>
          <Container maxWidth={"xl"} style={{ position: "relative" }}>
            {this.BaseModal({
              showModal: this.state.tradingLicenseModal,
              header: "Trade License or Commercial registration",
              subHeader: "Company Registration gives the new structure of business while the Trade License is an authorization to an already running business for starting new activity or business.",
              modalType: "tradingLicense",
              stepPercentage: 20,
              files: this.state.tradingLicenseFiles,
            })}
            {this.BaseModal({
              showModal: this.state.passportModal,
              header: "Passport or Residence ID (both sides) ",
              subHeader: "A passport is an official travel document issued by a government that contains a person's identity.",
              modalType: "passport",
              stepPercentage: 40,
              files: this.state.passportFiles,
            })}
            {this.BaseModal({
              showModal: this.state.residenceVisaModal,
              header: "Residence visa for non-nationals",
              subHeader: "Upload your residence visa/emirates ID.",
              modalType: "residenceVisa",
              stepPercentage: 60,
              files: this.state.residenceVisaFiles,
            })}
            {this.BaseModal({
              showModal: this.state.vatCertificateModal,
              header: "",
              subHeader: "",
              modalType: "vatCertificate",
              stepPercentage: 80,
              files: this.state.vatCertificateFiles,
            })}
            {this.BaseModal({
              showModal: this.state.ibanCertificateModal,
              header: "",
              subHeader: "",
              modalType: "ibanCertificate",
              stepPercentage: 100,
              files: this.state.ibanCertificateFiles,
            })}
          </Container>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "none",
    width: "100%",
    overflowX: "hidden",
  },
  blurredBackground: {
    position: "absolute",
    zIndex: -1,
    backgroundColor: "rgba(0,0,0,0.1)",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },
  ibanCommonErr: {
    color: "red",
    fontSize: "12px",
    marginTop: "20px",
  },
  errorBoxStyle: { 
    display: "flex", 
    flexDirection: "row" as const, 
    flexWrap: "no-wrap" as const, 
    alignItems: "center", 
    marginTop: "5px", 
    paddingTop: "20px" 
  },
  ibanErr: { 
    color: "#D74D4D" 
  },
  imgBox: { 
    marginRight: "5px" 
  }
};

const FileBox = styled(Box)({
  width: "400px", 
  display: "flex", 
  justifyContent: "space-between", 
  alignItems: "center", 
  flexDirection: "row", 
  border: "1.5px solid #c8c8c8", 
  padding: "10px 15px", 
  borderRadius: "10px",
  '@media (max-width:600px)': {
    width: 'auto'
  } 
})

const StyledFileName = styled(Typography)({
  wordBreak: 'break-all' as const,
  '@media (max-width:600px)': {
    wordBreak: 'break-all' as const
  }
})
// Customizable Area End
