import React from "react";

import "typeface-lato"
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
      fontSize: '3.5rem',
      fontWeight: 700,
      textAlign: 'center',
      color: '#3e3e3e'
    },
    h5: {
      fontWeight: 400,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    body1: {
      fontWeight: 600,
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    },
    body2: {
      fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1460
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: 'rgba(0, 0, 0, 0.1)',
        },
        '&:after': {
          borderBottomColor: 'rgba(253, 97, 12, 1)',
        },
        '&:hover:not($disabled):before': {
          borderBottomColor: 'rgba(253, 97, 12, 1)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'rgba(253, 97, 12, 1)',
        },
        fontSize: 14,
        fontWeight: 700
      },
    },
  },
});

import BulkUploadingController, {
  Props,
  logoImg,
  objectsImg,
  pdfImg,
} from "./BulkUploadingController";

export default class SellerFinaUploadRegistrationWeb extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.mainWrapper}>
          <Container maxWidth={"xl"} style={{ position: "relative" }}>
            <Box sx={{ position: 'absolute', left: 30, top: 40 }}>
              <img src={logoImg} style={webStyle.appLogo}/>
            </Box>
            <Box sx={{ position: 'absolute', left: '-11%', top: 40 }}>
              <img src={objectsImg} width={"140%"} />
            </Box>
            <Container style={{ position: 'relative' }} maxWidth={"lg"}>
              <Grid container spacing={8} direction='column' style={{flexWrap:'nowrap'}}>
                <Grid item>
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '140px', marginBottom: '25px' }}>
                    <Typography variant="h1">Almost there..</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', marginBottom: '5px', flexWrap: 'wrap' }}>
                    <Typography variant="h5">You are one step closer to</Typography>
                    <Typography variant="h5" style={{ marginLeft: '5px', color: 'rgba(253, 97, 12, 1)' }}>start selling with ByEzzy.</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                    <Typography variant="h5">We have received your documents for veriication. You will be notified once the process is completed.</Typography>
                  </Box>
                </Grid>
                
                <Grid item>
                  <Grid container spacing={3} direction="column">
                    {this.state.sellerDocuments.map((item, index) => {
                      return (
                        <Grid data-test-id="documents" item key={index}>
                          <Box sx={{ width: '100%', justifyContent: 'space-between', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <StyledIcon>
                                <img src={pdfImg} width={'50px'} />
                              </StyledIcon>
                              <StyledDocumentBox>
                                <Box sx={{ marginBottom: '3px' }}>
                                  <StyledName variant="body1">{item.attributes.document_name}</StyledName>
                                </Box>
                                <Box>
                                  <StyledDate variant="body2" style={{ color: '#878787' }}>{this.handleConvertDateToReadable(item.attributes.created_at)}</StyledDate>
                                </Box>
                              </StyledDocumentBox>
                            </Box>
                            <Box>
                              <StyledUploadBox style={{ background: '#50af1c', }}>
                                <StyledButton variant="body1" style={{ color: 'white' }}>Uploaded</StyledButton>
                              </StyledUploadBox>
                            </Box>
                          </Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid item>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CustomizedButton onClick={this.handleGoToSellerLandingPage}>Go To My Profile</CustomizedButton>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Container >
        </Box >
      </ThemeProvider >
    )
  }
}

const webStyle = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    width: "100%",
    overflowX: "hidden",
  },
  appLogo: {
    width:'102px',
    height:'85px'
  }
}

const StyledName = styled(Typography)({
  '@media (max-width:600px)': {
    fontSize:'14px'
  }
})

const StyledDate = styled(Typography)({
  '@media (max-width:600px)': {
    fontSize:'12px'
  }
})

const StyledUploadBox = styled(Box)({
  padding: '10px 15px', 
  borderRadius: '7px',
  '@media (max-width:600px)': {
    padding: '8px'
  }
})

const StyledIcon = styled(Box)({
  width: '70px', 
  height: '70px', 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center', 
  marginRight: '30px', 
  border: '1px solid #d4d4d4', 
  borderRadius: '7px',
  '@media (max-width:600px)': {
    marginRight: '12px',
    width: '52px', 
    height: '52px'
  }
})

const StyledButton = styled(Typography)({
  '@media (max-width:600px)': {
    fontSize:'12px'
  }
})

const StyledDocumentBox = styled(Box)({
  '@media (max-width:600px)': {
    marginRight:'10px'
  }
})

const CustomizedButton = styled(Button)({
    background: "rgba(253, 97, 12, 1)",
    fontFamily: `"Helvetica", "Arial", sans-serif`,
    textTransform: "none",
    fontSize: 18,
    fontWeight: 700,
    width: "fit-content",
    padding: '15px 150px',
    borderRadius: '8px',
    color: "white",
    '&:hover': {
      backgroundColor: 'rgba(253, 97, 12, 1)',
    },
    '@media (max-width:600px)': {
      padding: '12px 30px'
    }
})