import React from "react";

// Customizable Area Start
import {
  Typography,
  styled,
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
} from "@material-ui/core";
import ListViewProductController, { Props, calculateDiscountPer } from "./ListViewProductController.web";
import { starIcon, rapidLogo } from "./assets";
import { getCurrentDate } from "../../../components/src/CommonFunctions";
// Customizable Area End


class ListViewProduct extends ListViewProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  setRetailPrice = (dealActive:boolean, offerActive: boolean, mrp:number, retailValue: number) => {
    return(
        <>
        {!offerActive && !dealActive && !mrp &&
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <StyledDiscountPrice>
                AED <span style={webStyles.offerPrice}>{retailValue}</span>
            </StyledDiscountPrice>
            </Grid>
        }
        </>
    )
  }

  setOfferPrice = (saleValue:number, retailValue: number, offerActive:boolean ) => {
    return(
        <>
        {offerActive &&
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <StyledDiscountPrice>
                AED <span style={webStyles.offerPrice}>{saleValue}</span>
            </StyledDiscountPrice>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {calculateDiscountPer(retailValue, saleValue) > 0 ? <Typography style={webStyles.ogPrice}>
                    <span style={webStyles.mrpPrice}>
                    {retailValue}
                    </span>{" "}
                    <b style={webStyles.discountPrice} data-test-id="discountPriceRef"> &nbsp; {calculateDiscountPer(retailValue,saleValue)}% OFF</b>
                    </Typography>
                    : 
                    <StyledSpace>&nbsp;</StyledSpace>
                }
            </Grid>
        </>
        }
    </>
    )
  }

  setDealPrice = (dealValue:number, retailValue: number, dealActive:boolean ) => {
    return(
        <>
        {dealActive &&
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <StyledDiscountPrice>
                AED <span style={webStyles.offerPrice}>{dealValue}</span>
            </StyledDiscountPrice>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {calculateDiscountPer(retailValue, dealValue) > 0 ? <Typography style={webStyles.ogPrice}>
                    <span style={webStyles.mrpPrice}>
                    {retailValue}
                    </span>{" "}
                    <b style={webStyles.discountPrice} data-test-id="discountPriceRef"> &nbsp; {calculateDiscountPer(retailValue,dealValue)}% OFF</b>
                    </Typography>
                    : 
                    <StyledSpace>&nbsp;</StyledSpace>
                }
            </Grid>
        </>
        }
    </>
    )
  }

  setPrice = () => {
    const {product} = this.props;
    const dealCatalogData = product.attributes.deal_catalogues && product.attributes.deal_catalogues[0]
    const productInfo = product.attributes.product_content?.product_attributes;
    const dateValue = getCurrentDate()
    const retailValue = productInfo && productInfo.retail_price
    const catalogueOfferData = product.attributes?.catalogue_offer 
    const mrpValue = productInfo && productInfo.mrp
    const isOfferExpired = catalogueOfferData && catalogueOfferData.sale_schedule_to >= dateValue
    const isOfferPresent = catalogueOfferData?.status && catalogueOfferData?.sale_price && isOfferExpired
    const isDealPresent = product.attributes?.deal_catalogues[0]?.status === "approved" && dealCatalogData?.deal_price
    
    return(
        <>
            {this.setDealPrice(Number(dealCatalogData?.deal_price), Number(retailValue), isDealPresent as boolean )}
            {this.setOfferPrice(Number(catalogueOfferData?.sale_price), Number(retailValue), isOfferPresent as boolean )}
            {this.setRetailPrice(isDealPresent as boolean, isOfferPresent as boolean, Number(mrpValue), Number(retailValue))}
            {!isDealPresent && !isOfferPresent && mrpValue && 
                <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <StyledDiscountPrice>
                    AED <span style={webStyles.offerPrice}>{retailValue}</span>
                </StyledDiscountPrice>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography style={webStyles.ogPrice}>
                        <span style={webStyles.mrpPrice}>
                        {mrpValue}
                        </span>{" "}
                    </Typography>
                </Grid>
                </>
            }
        </>
    )
  }

  render() {
    const { product } = this.props;
    const details =product?.attributes?.product_content?.product_attributes;
    return (
      <>
        <Box data-test-id="productBox" 
        onClick={() => this.handleProduct(product.id)} 
        style={webStyles.linkStyle}>
        <StyledCardContainer>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
          <StyledCard>
            <Typography style={webStyles.newTextStyle}>New</Typography>
            <CardMedia
              style={webStyles.cardImageStyle}
              component="img"
              image={product.attributes.product_image}
              alt={product.attributes.product_title || ""}
              data-test-id="cardImgRef"
            />
          </StyledCard>
          </Grid> 
          <Grid item xs={12} sm={12} md={9} lg={9} style={webStyles.right}>
                <Grid container style={webStyles.rightTop}>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <StyledTitle>
                            {product.attributes?.product_title || 'N/A'}
                        </StyledTitle>
                    </Grid>
                </Grid>
                <Grid container style={webStyles.rightBottom}>
                   {this.setPrice()}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={webStyles.last}>
                        <img data-test-id="rapidButton" style={webStyles.rapidImg} src={rapidLogo} alt="express_img" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={webStyles.flex}>
                            <Typography style={webStyles.rateBox}>
                            {Number(
                                product?.attributes
                                    ?.product_rating?.average_rating || 0
                                )}{" "}
                            <span style={webStyles.starImg}>
                                <img src={starIcon} alt="start" />
                            </span>
                            </Typography>
                            <Typography data-test-id="ratingRef" style={webStyles.ratingText}>
                            {Number(
                                product?.attributes
                                    ?.product_rating?.total_reviews || 0
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
          </Grid>
          </Grid>
        </StyledCardContainer>
        </Box>
      </>
    )
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyles = {
    linkStyle: {
        cursor: 'pointer'
    },
    flex: {
        display: 'flex'
    },
    newTextStyle: {
        borderRadius: "3px",
        fontSize: "9px",
        padding: "1.5px",
        position: "absolute" as const ,
        top: "5px",
        width: "20%",
        textAlign: "center" as const,
        marginLeft: "78%",
        background: "#fb620f",
        color: "white",
    },
    cardImageStyle: {
        height: "100%",
        objectFit:'contain' as const ,
        alignItems: "center",
        justifyContent: "center",
    },
    ogPrice: {
        fontSize: "10px",
        marginLeft: "10px",
        '@media (max-width: 600px)': {
        marginLeft: '0'
        }
    },
    rapidImg: {
        width:'67px',
        height: '24px',
        marginLeft:'10px',
        '@media (max-width: 600px)': {
        marginLeft: '0'
        }
    },
    rateBox: {
        padding: "2px 6px",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        backgroundColor: "#565656",
        color: "#ffffff",
        fontFamily: `"Lato",sans-serif`,
        fontSize: "14px",
        fontWeight: 700 as const,
        marginLeft: '10px',
        marginTop: '10px',
        '@media (max-width: 600px)': {
            marginTop:'8px',
            marginLeft:'0'
        }
    },
    starImg: {
        marginLeft: "5px",
    },
    last: {
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        '@media (max-width: 600px)': {
        marginRight: '0'
        }
    },
    ratingText: {
        fontFamily: `"Lato",sans-serif`,
        fontSize: "14px",
        marginLeft: "8px",
        marginTop:'10px',
        display: "flex",
        alignItems: "center",
        color: "#ff6008",
        fontWeight: 400 as const,
    },
    offerPrice: {
        fontFamily: `"Lato",sans-serif`,
        color: "#ff6008",
        fontWeight: 700 as const,
        fontSize: "14px",
    },
    mrpPrice: { 
        color: "grey", 
        textDecoration: "line-through", 
        fontFamily: `"Lato",sans-serif`,
        fontSize:'14px', 
        fontWeight: 400, 
    },
    discountPrice: { 
        fontFamily: `"Lato",sans-serif`,
        fontWeight: 700, 
        color: "#33333a", 
        fontSize:'14px', 
    },
    right: {
        display:'flex', 
        flexDirection:'column' as const, 
        justifyContent:'space-between'
    },
    rightTop: {
        display:'flex', 
        alignItems:'flex-start'
    },
    rightBottom: {
        display:'flex', 
        alignItems:'flex-end'
    }
};

const StyledCardContainer = styled(Container)({
    padding: "10px",
    borderRadius: "8px",
    width: "100%",
    border: "1px solid rgb(229, 229, 229)",
    '&:hover': {
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)"
    }
})

const StyledCard = styled(Card)({
    borderRadius: "8px",
    padding:'20px 0',
    width: "100%",
    height: "200px",
    position: "relative" as const,
    '@media (max-width:600px)': {
        marginLeft: '0',
        padding: 0,
    },
})

const StyledTitle = styled(Typography)({
    fontSize: "16px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#33333a",
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    height: 'calc(1.5em * 2)',
    textOverflow: 'ellipsis',
    fontFamily:"Lato, sans-serif",
    fontWeight: 400,
    wordBreak: "break-all" as const,
    '@media (max-width: 600px)': {
        marginLeft: '0'
    }
})

const StyledDiscountPrice = styled(Typography)({
    fontSize: "14px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#ff6008",
    fontFamily:"Lato, sans-serif",
    fontWeight: 400,
    '@media (max-width: 600px)': {
        marginLeft: '0'
    }
})

const StyledSpace = styled(Typography)({
    fontSize: "10px",
    marginLeft: "10px",
    marginTop: '6px',
    '@media (max-width: 600px)': {
        marginLeft: '0'
    }
})

export { ListViewProduct };

export default ListViewProduct;
// Customizable Area End