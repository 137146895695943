import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBrandList, ICategory, ICategoryRes, ProductListArgs } from "./ProductCatalogueController.web";
import { ChangeEvent } from "react";
import { getStorageData, setStorageData } from "framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("../../src/config.js")


// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match?: {
    params : {
      id: string | number
    }
  }
  classes: {
    listWrapper: string,
    checkBox: string,
    sliderColor: string,
    headerText: string,
    container: string,
    orderTextWrapper: string,
    ordersText: string,
    searchWrapper: string,
    searchOrderText: string,
    searchBoxWrapper: string,
    contentWrapper: string
    checkBoxFormControl: string,
    titleText: string,
    optionText: string,
    customTitle: string  ,
    colorBox: string,
    colorGrid: string,
    colorWrapper: string,
    viewOption: string
  }
  subCategoryList: ICategory[];
  brandList: IBrandList[];
  subCatagoryItemsIds: string[];
  miniCatagoryItemsIds: string[];
  miniCategoryList: ICategoryRes[];
  microCatagoryItemsIds: string[];
  microCategoryList: ICategoryRes[];
  catagoryItemsIds: string[];
  categoryList: ICategoryRes[];
  brandItemsIds: string[];
  min_price: number | null;
  max_price: number | null;
  productList: {
    min_range: number,
    max_range: number
  };
  setFilterValues: (args :ProductListArgs)=>void;
  customFieldsData: {[key: string]: string[]; };
  customFieldValues: string[],
  handleRatingVlaue: (args: any) => any;
  colorFilter: string[];
  onSetColorFilter: (color: string) => void;
  out_of_stocks: boolean;
  onSetOutOfStocks: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUpdateUrl: (type:string, value:any) => void
  handleCustomFilter: (type:string) => void
  updatedRating: boolean[]
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  searchValue: string;
  priceRange: number[];
  ratingValues: boolean[],
  visibleItemsCount: number,
  showAllBrands: boolean,
  showAllSubCategories: boolean,
  showAllCustomFields: any
  selectedCustomValues: any,
  filteredCustomValues:any,
  showAllCategories: boolean,
  showAllMicroCategories: boolean,
  showAllMiniCategories: boolean,
  checkedCustomField: string[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CategoriesSideBarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  getCustomFieldsApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      authToken: "",
      searchValue: '',
      priceRange:[Number(this.props.productList.min_range),Number(this.props.productList.max_range)],
      ratingValues: new Array(5).fill(false),
      visibleItemsCount: 8,
      showAllBrands: false,
      showAllSubCategories: false,
      showAllCustomFields: {},
      selectedCustomValues: {},
      filteredCustomValues: {},
      showAllCategories: false,
      showAllMicroCategories: false,
      showAllMiniCategories: false,
      checkedCustomField: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeCatCheckBox = async(id:string, e: React.ChangeEvent<HTMLInputElement>) =>{
    const { catagoryItemsIds } = this.props;
    const filter = catagoryItemsIds.filter((item: string) => item !== id);
   const tempArray:string[] = e.target?.checked
   ? [...catagoryItemsIds, id]
   : [...filter];
   await setStorageData("catagoryItemsIds", JSON.stringify(tempArray))
   this.props.handleUpdateUrl("catagoryIds", tempArray)
    this.props.setFilterValues({catagoryIds:tempArray})
  };

  handleChangeCheckBox = async(id:string, e: React.ChangeEvent<HTMLInputElement>) =>{
    const { subCatagoryItemsIds } = this.props;
    const filter = subCatagoryItemsIds.filter((item: string) => item !== id);
   const tempArray:string[] = e.target?.checked
   ? [...subCatagoryItemsIds, id]
   : [...filter];
   await setStorageData("subCatagoryIds", JSON.stringify(tempArray))
   this.props.handleUpdateUrl("subCatagoryIds", tempArray)
    this.props.setFilterValues({subCatagoryIds:tempArray})
  };

  handleChangeMiniCheckBox = async(id:string, e: React.ChangeEvent<HTMLInputElement>) =>{
    const { miniCatagoryItemsIds } = this.props;
    const filter = miniCatagoryItemsIds.filter((item: string) => item !== id);
   const tempArray:string[] = e.target?.checked
   ? [...miniCatagoryItemsIds, id]
   : [...filter];
   await setStorageData("miniCatagoryItemsIds", JSON.stringify(tempArray))
   this.props.handleUpdateUrl("miniCatagoryIds", tempArray)
    this.props.setFilterValues({miniCategoryIds:tempArray})
  };

  handleChangeMicroCheckBox = async(id:string, e: React.ChangeEvent<HTMLInputElement>) =>{
    const { microCatagoryItemsIds } = this.props;
    const filter = microCatagoryItemsIds.filter((item: string) => item !== id);
   const tempArray:string[] = e.target?.checked
   ? [...microCatagoryItemsIds, id]
   : [...filter];
   await setStorageData("microCatagoryItemsIds", JSON.stringify(tempArray))
   this.props.handleUpdateUrl("microCatagoryIds", tempArray)
    this.props.setFilterValues({microCategoryIds:tempArray})
  };

  handleChangeBrands = (id: string) => (e: React.ChangeEvent<HTMLInputElement>) =>{
    const { brandItemsIds } = this.props;
    const filter = brandItemsIds.filter((item: string) => item !== id);
    const tempArray:string[] = e.target?.checked
    ? [...brandItemsIds, id]
    : [...filter];
    setStorageData("brandIds", JSON.stringify(tempArray))
    this.props.handleUpdateUrl("brandIds", tempArray)
    this.props.setFilterValues({brandIds:tempArray})
  };

  handlePriceSliderChange = (event: ChangeEvent<{}>, value: number | number[]) => {
    this.setState({ priceRange: value as number[] });
  };

  handleChangeCommitted = (
    event: ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    const value = newValue as number[]; 
    const min_price = Math.floor(value[0]);
    const max_price = Math.ceil(value[1]);
    this.props.setFilterValues({min_price,max_price})
  };

  handleChangeCustomFilter = (value: string, item: any) => {
    const { customFieldValues } = this.props;
    const valueIndex = customFieldValues.indexOf(value);
    this.setState((prevState: any) => {
      const selectedCustomValues = prevState.selectedCustomValues[item] || [];
      const newSelectedCustomValues = selectedCustomValues.includes(valueIndex)
        ? selectedCustomValues.filter((index:any) => index !== valueIndex)
        : [...selectedCustomValues, valueIndex]; 

        return {
        selectedCustomValues: {
          ...prevState.selectedCustomValues,
          [item]: newSelectedCustomValues 
        }
      };
    });

    this.setState((prevState: any) => {
    const filteredCustomValues = prevState.filteredCustomValues[item] || [];
      const newSelectedCustomValues = filteredCustomValues.includes(value)
        ? filteredCustomValues.filter((index:any) => index !== valueIndex)
        : [...filteredCustomValues, value]; 

        return {
        filteredCustomValues: {
          ...prevState.filteredCustomValues,
          [item]: newSelectedCustomValues 
        },
      };
    }, () => this.props.setFilterValues({ customFieldValues:  this.state.filteredCustomValues}))
    this.props.handleCustomFilter(value)

    this.setState({
      checkedCustomField: this.state.checkedCustomField.includes(value) ?
      this.state.checkedCustomField.filter((field) => field !== value)
      : [...this.state.checkedCustomField, value]
    })
  }

  handleChange = (index:number) => (event:React.ChangeEvent<HTMLInputElement>) => {
    const checked  = event.target?.checked;
    this.setState((prevState) => {
        const ratingValues = [...prevState.ratingValues];
        ratingValues[index] = checked; 
        this.props.handleRatingVlaue(ratingValues);
        return { ratingValues };
    });
};

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
      const isRatingSelected = await getStorageData('rating', true)
      if(prevProps.updatedRating !== this.props.updatedRating) {
        this.setState({ratingValues: this.props.updatedRating})
      }
      if(prevProps.subCategoryList !== this.props.subCategoryList) {
      this.setState({ ratingValues: isRatingSelected ? isRatingSelected : new Array(5).fill(false)
        , priceRange: [] 
      });
    }
  }

  async componentDidMount(): Promise<void> {
    const currentParams = new URLSearchParams(window.location.search);    
    const customFiltersFromUrl = currentParams.get("customFilters") || ""
    const arrayValues = customFiltersFromUrl.replace(/_/g, ' ').split(',');

    this.setState({
      checkedCustomField: arrayValues
    })

  }

  handleViewMoreSubCatBtn = () => {
    this.setState({showAllSubCategories: !this.state.showAllSubCategories})
  }

  handleViewMoreMiniCatBtn = () => {
    this.setState({showAllMiniCategories: !this.state.showAllMiniCategories})
  }

  handleViewMoreMicroCatBtn = () => {
    this.setState({showAllMicroCategories: !this.state.showAllMicroCategories})
  }

  handleViewMoreCatBtn = () => {
    this.setState({showAllCategories: !this.state.showAllCategories})
  }

  handleViewMoreCustomFieldsBtn = (item:string) => {
    this.setState(prevState => ({
      showAllCustomFields: {
        ...prevState.showAllCustomFields,
        [item]: !prevState.showAllCustomFields[item], // Toggle the visibility for the specific item
      },
    }))
  }
  
  handleViewMoreBrandsBtn = () => {
    this.setState({showAllBrands: !this.state.showAllBrands})
  }
  // Customizable Area End
}
