// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { Box } from "@material-ui/core";
import { styles } from "./SellerPartnerOrderManagement.web";
import { setStorageData } from 'framework/src/Utilities';

export const configJSON = require("../src/config.js");

export interface IReturnDetails {
    order_details: {
        id: number,
        time_passed_since_order_placed: string,
        order_placed_at: string,
        customer: {
            data: {
                attributes: {
                    full_name: string
                }
            }
        },
        seller: {
            data: {
                attributes: {
                    full_name: string
                }
            }
        },
        return_reason_details: IReturnReasonDetails[],
        return_exchange_requests?: {
          request_reason: string,
          description: string
        }[],
        order_item_details: {
            id: number,
            order_status: {
                data: {
                  id: string,
                    attributes: {
                        name: string
                    }
                }
            },
            catalogue: {
                data: {
                    id: string,
                    attributes: {
                        product_image: string,
                        product_title: string,
                        product_content: {
                            product_attributes: {
                                unique_psku: string
                            }
                        }
                    }
                }
            }
        }
    }
}

export interface IShippingDetails {
    data: []
}

export interface UpdatedItem {
    data: {
        id: string
    }
}

interface IOrderStatus {
    data: {
        id: string,
        attributes: {
            id: string,
            name: string
        }
    }[]
}

export interface IReturnReasonDetails {
    id: number,
    title:string,
    details:string,
    shopping_cart_order_item_id:number,
    reason_type:string,
    created_at:string,
    updated_at: string
}
interface IReturnsRes {
  orders: IReturnDetails[],
  total_count: number
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  returnDetails: IReturnDetails[],
  loading: boolean,
  pageNumber: number,
  searchVal: string,
  returnAnchorEl: null | HTMLElement,
  itemId: string,
  isChecked: boolean,
  checkedIndex: number | null,
  openReturnFilterAnchor: null | HTMLElement,
  openReturnSortAnchorEl: null | HTMLElement,
  returnsFilterByValue: string[],
  returnSortByValue: string,
  openShippingDetailsModal: boolean,
  openReturnReasonModal : boolean,
  shippingDetails: string,
  reasonOfRejection: string,
  orderItemId: string,
  ordersStatusName: string,
  filterValue: string[],
  showOrderDetails: boolean,
  totalReturnOrders: number,
  returnDetailsType: string[],
  setStatus: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FulfilledByPartnerReturnsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getReturnOrdersApiCallId: string = "";
  getOrderStatusIdsApiCallId: string = "";
  updateItemOrderStatusApiCallId: string = "";
  createRejectionReasonApiCallId: string = "";
  addShippingDetailsApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      returnDetails : [],
      loading: false,
      pageNumber: 1,
      searchVal: '',
      returnAnchorEl: null,
      itemId: '',
      isChecked: false,
      checkedIndex: null,
      openReturnFilterAnchor: null ,
      openReturnSortAnchorEl: null ,
      returnsFilterByValue: [],
      returnSortByValue: 'date_latest',
      openShippingDetailsModal: false,
      openReturnReasonModal : false,
      shippingDetails: '',
      reasonOfRejection: '',
      orderItemId: '',
      ordersStatusName: '',
      filterValue: [],
      showOrderDetails: false,
      totalReturnOrders: 0,
      returnDetailsType: [],
      setStatus: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.getReturnOrdersApiCallId) {
        this.handleReturnOrdersResponse(responseJson);
      }
      if (apiRequestCallId === this.getOrderStatusIdsApiCallId) {
        this.handleOrderStatus(responseJson)
      }
      if (apiRequestCallId === this.updateItemOrderStatusApiCallId) {
        this.handleUpdateItemOrderStatusRes(responseJson)
      }
      if (apiRequestCallId === this.createRejectionReasonApiCallId) {
        this.handleRejectionReasonRes(responseJson)
      }
      if (apiRequestCallId === this.addShippingDetailsApiCallId) {
        this.handleShippingDetailsRes(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleShippingDetailsRes = (responseJson: IShippingDetails) => {
    if(responseJson) {
        this.setState({ shippingDetails: '', openShippingDetailsModal: false})
        this.getOrderStatusId()
    }
  }

  handleRejectionReasonRes = (responseJson: IShippingDetails) => {
    if(responseJson) {
        this.setState({ reasonOfRejection: '', openReturnReasonModal: false})
        this.getOrderStatusId()
    }
  }

  handleUpdateItemOrderStatusRes = (responseJson: UpdatedItem) => {
    if(responseJson.data) {
        this.setState({ returnAnchorEl: null , openReturnReasonModal: false, openShippingDetailsModal: false})
        this.getReturnDetails()
    }
  }

  handleOrderStatus = (responseJson: IOrderStatus) => {
    if(responseJson.data) {
        const statusId = responseJson.data.filter((item: {attributes: {id: string, name: string}}) => {
            if(item.attributes.name === this.state.ordersStatusName) {
                return item
            }
        })
        this.updateItemStatus(statusId[0].id)
    }
  }

  handleReturnOrdersResponse = (responseJson:IReturnsRes) => {
    this.setState({loading: false})
    if(responseJson.orders) {
      this.setState({loading: false, returnDetails: responseJson.orders, totalReturnOrders: responseJson.total_count, ordersStatusName: '', setStatus: ''})
    }
  }

  async componentDidMount(): Promise<void> {
    this.getReturnDetails()
  }

  getReturnsDate = (value: string) => {
    const parsedDate = new Date(value);
    const dayValue = parsedDate.getDate().toString().padStart(2, '0');
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = parsedDate.getFullYear();
    return `${dayValue}/${month}/${year}`;
  }

  getReturnsTime = (value: string) => {
    const parsedDate = new Date(value);
    const hour = parsedDate.getHours().toString().padStart(2, '0');
    const minute = parsedDate.getMinutes().toString().padStart(2, '0');
    
    const ampm = hour >= '12' ? 'PM' : 'AM';
    const setHour = (parseInt(hour) % 12) || 12;
    const formattedHour = setHour.toString().length === 1 ? `0${setHour}`: setHour

    return `${formattedHour}:${minute} ${ampm}`;
  }

  handleCloseMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ returnAnchorEl: null });
  }

  getReturnDetails = async() => {
    this.setState({loading: true})
    let endPoint;
    if(this.state?.filterValue?.length > 0) {
        const value = this.state.filterValue.map((value: string) => `&filter_by[]=${value}`)
        const filterQuery = value.join("");
        endPoint = filterQuery
    }
    else endPoint = '&filter_by[]=received&filter_by[]=pickup_initiated&filter_by[]=qc_failed&filter_by[]=qc_passed&filter_by[]=return&filter_by[]=refund_initiated&filter_by[]=qc_processing'
  
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData("authToken"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReturnOrdersApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.partnerReturnsAPIEndpoint + `?&sort_by=${this.state.returnSortByValue}${endPoint}&search_query=${this.state.searchVal}&per_page=10&page=${this.state.pageNumber}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  SearchReturnDebounce = (callValue: (...args: string[]) => void, delay: number | string | unknown) => {
    let timer: string | number;
    return async function (...args: string[]) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        callValue(...args)
      }, delay);
    };
  }

  SearchReturnOrdersDebounceUpdate = this.SearchReturnDebounce(this.getReturnDetails,
    1000)

  handleSearchReturnOrders = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchVal : event.target.value})
    this.SearchReturnOrdersDebounceUpdate()
  }

  handleEditStatus = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, orderStatus: string, orderId:number) => {
    this.setState({ returnAnchorEl: event.currentTarget, orderItemId: orderId.toString(), isChecked: false, checkedIndex: null});
    this.setCheckBox(orderStatus)
  }

  handleCheckBox = (checkedIndex: number, option: string) => {
    this.setState({ isChecked: !this.state.isChecked, checkedIndex: checkedIndex })
    if(option === "Pickup Initiated") {
      this.setState({ openShippingDetailsModal: true , ordersStatusName: option, setStatus: ''})
    }
    else if(option === "QC Failed") {
      this.setState({ openReturnReasonModal: true , ordersStatusName: option, setStatus: ''})
    }
    else if(option === "Refund") {
        this.setState({ ordersStatusName: "Refund Initiated", setStatus: ''})
        this.getOrderStatusId()
    }
    else {
        this.setState({ ordersStatusName: option, setStatus: '' })
        this.getOrderStatusId()
    }
  }

  getReturnOrderCount = () => {
    return this.state.returnDetails?.length > 1 ? 'Orders' : 'Order'
  }

  getReturnOrderNumber = () => {
    return(
      this.state.returnDetails.length > 0 ?
        <Box data-test-id="returnlist"  style={styles.ordersInfo}>{this.state.returnDetails?.length} {this.getReturnOrderCount()}</Box>
      : <Box style={styles.ordersInfo}>{""}</Box>  
    )
  }

  handleCloseReturnFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({ openReturnFilterAnchor: null });
  }

  handleCloseReturnSortMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({ openReturnSortAnchorEl: null });
  }

  handleReturnFilters = (value: string) => {
    const currentFilter = [...this.state.filterValue];
    let selectedFilter;
    if(value === "Refund") {
        selectedFilter = "refund_initiated"
    }
    else selectedFilter = value
    const optionIndex = currentFilter.indexOf(selectedFilter);
    if (optionIndex === -1) {
      const newFilterByValue = currentFilter.concat(selectedFilter);
      this.setState({ filterValue: newFilterByValue, openReturnFilterAnchor: null,  pageNumber: 1 }, () => {
        this.getReturnDetails()
      })
      
    } else {
        currentFilter.splice(optionIndex, 1);
        this.setState({ filterValue: currentFilter, openReturnFilterAnchor: null, pageNumber: 1 },() => this.getReturnDetails());
    }
  }

  handleReturnSorting = (value: string) => {
    this.setState({ returnSortByValue: value , openReturnSortAnchorEl: null}, () => this.getReturnDetails())
  }

  getReturnSortStyle = (value: string) => {
    return value === this.state.returnSortByValue ? styles.activeSortText : styles.sortMenuOption
    }

  setReturnSortValue = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({openReturnSortAnchorEl: event.currentTarget})
  }

  setReturnFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({openReturnFilterAnchor: event.currentTarget})
  }

  handleCloseShippingModal = () => {
    this.setState({ openShippingDetailsModal: false })
    if(!this.state.shippingDetails) {
    this.setState({isChecked: false, checkedIndex: null })
    }
    else this.setState({shippingDetails: '',isChecked: false, checkedIndex: null})
  }

  handleCloseReasonModal = () => {
    this.setState({ openReturnReasonModal: false })
    if(!this.state.reasonOfRejection) {
      this.setState({isChecked: false, checkedIndex: null })
    }
    else this.setState({reasonOfRejection: '',isChecked: false, checkedIndex: null })
  }

  handleShippingInput = (value:string) => {
    this.setState({ shippingDetails: value })
  } 

  handleReturnInput = (value:string) => {
    this.setState({ reasonOfRejection: value })
  } 

  handleSubmitShippingDetails = () => {
    const details = this.state.shippingDetails.trim()
    if(this.state.orderItemId && details) {
        this.addShippingDetails()
    }
  }

  handleSubmitRejectionReason = () => {
    const details = this.state.reasonOfRejection.trim()
    if(this.state.orderItemId && details) {
        this.createRejectionReason()
    }
  }

  addShippingDetails = async () => {
    const httpBody = {
        "return_reason_detail": {
            "shopping_cart_order_item_id": this.state.orderItemId,
            "title": "shipping_details",
            "details": this.state.shippingDetails,
            "reason_type": "pickup_and_delivery"  
        }
    }
    const headers = {
        "Content-Type": configJSON.productApiContentType,
        token: await getStorageData("authToken"),
      };
      const message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addShippingDetailsApiCallId = message.messageId;
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_shopping_cart/return_reason_details'
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
      );
      runEngine.sendMessage(message.id, message);
  }

  createRejectionReason = async () => {
    const httpBody = {
        "return_reason_detail": {
            "shopping_cart_order_item_id": this.state.orderItemId,
            "title": "reason_of_rejection",
            "details": this.state.reasonOfRejection,
            "reason_type": "reason_for_qc_fail"  
        }
    }
    const headers = {
        "Content-Type": configJSON.productApiContentType,
        token: await getStorageData("authToken"),
      };
      const message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createRejectionReasonApiCallId = message.messageId;
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_shopping_cart/return_reason_details'
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
      );
      runEngine.sendMessage(message.id, message);
  }

  getOrderStatusId = async() => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: await getStorageData("authToken"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderStatusIdsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderStatusApiEndpoint 
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  updateItemStatus = async (orderStatusId: string) => {
    const httpBody = {
        "order_status_id": orderStatusId
    }
    const headers = {
        "Content-Type": configJSON.productApiContentType,
        token: await getStorageData("authToken"),
      };
      const message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateItemOrderStatusApiCallId = message.messageId;
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.partnerReturnsAPIEndpoint + `/${this.state.orderItemId}`
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePatch
      );
      runEngine.sendMessage(message.id, message);
  }

  handleViewOrderBtn = async(itemId:number) => {
    await setStorageData("sellerItemId", itemId)
    this.setState({showOrderDetails: true})
    this.props.navigation.navigate("SellerHomeDashboard", {path: "returns-details"})
  }

  getReturnOrderStatus = (status:string) => {
    if(status === "Refund Initiated") {
        return 'Refund'
    }
    else return status
  }

  getSelectedFilter = (value: string) => {
    if(value === "Refund") {
        return this.state.filterValue.includes("refund_initiated") 
    }
    return this.state.filterValue.includes(value) 
  }

  getReturnsRoundedCount = () => {
    const count: number = this.state.totalReturnOrders / 10;
    const roundedReturnCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedReturnCount
  }

  setCheckBox = (orderStatus: string) => {
    this.setState({setStatus: orderStatus})
  }

  isChecked = (indexValue: number, option:string) => {
    return indexValue === this.state.checkedIndex || option === this.state.setStatus
  }

  setMenuStyle = (indexValue: number) => {
    return indexValue === this.state.checkedIndex ?  '#000': '#999'
  }

  getShippingSubmitBtnStyle = () => {
    return this.state.shippingDetails ? this.props.classes.submitActive : this.props.classes.submitInactive
  }

  getRejectionSubmitBtnStyle = () => {
    return this.state.reasonOfRejection ? this.props.classes.submitActive : this.props.classes.submitInactive
  }

  setDisableStatus = (status:string) => {
    let newDetails;
    if (status === "Return") {
      newDetails = ["Received","QC Failed", "QC Passed", "QC Processing", "Refund"];
      this.setState({ returnDetailsType: newDetails})
    } 
    if (status === "Pickup Initiated") {
      newDetails = ["QC Failed", "QC Passed", "QC Processing", "Refund"];
      this.setState({ returnDetailsType: newDetails})
    } 
    if (status === "Received") {
      newDetails = ["Pickup Initiated", "QC Failed", "QC Passed", "Refund"];
      this.setState({ returnDetailsType: newDetails})
    } 
    if (status === "QC Failed") {
      newDetails = ["Pickup Initiated", "Received", "QC Failed", "QC Passed", "QC Processing", "Refund"];
      this.setState({ returnDetailsType: newDetails})
    }
    if (status === "QC Passed") {
      newDetails = ["Pickup Initiated", "Received", "QC Failed", "QC Passed", "QC Processing"];
      this.setState({ returnDetailsType: newDetails})
    }
    if (status === "QC Processing") {
      newDetails = ["Pickup Initiated", "Received","Refund"];
      this.setState({ returnDetailsType: newDetails})
    }
  }
  // Customizable Area End
}
