import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { URLSearchParams } from "url";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { removeStorageData } from "framework/src/Utilities";
type SuggestionItem = {
  attributes: {
    category_id: string;
    product_title: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  searchText: string;
  searchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  open: boolean;
  searchQuery: string;
  suggestions: SuggestionItem[];
  isLoading: boolean;
  resultCount: number;
  productNotFound: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchApiCallId: any;
  suggestionApiCallId: string = "";
  getTotalCountApi: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      searchText: "",
      searchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      open: false,
      searchQuery: "",
      suggestions: [],
      isLoading: false,
      resultCount: 0,
      productNotFound: false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    if(window.location.pathname.includes('search')) {
      this.setState({ searchQuery: await getStorageData('searchQuery') })
      this.getSearchCount()
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      this.handleResponse(responseJson, apiRequestCallId);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputSearchTextProps = {
    onChangeText: (text: string) => {
      this.setState({ searchText: text });
    }
  };

  setSearchText = (text: string) => {
    this.setState({ searchText: text });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  handleResponse = async(responseJson: any, apiRequestCallId: string) => {
    if (responseJson && responseJson.data) {
    if (apiRequestCallId === this.suggestionApiCallId) {
        const suggestions = responseJson.data.length !== 0 ? responseJson.data : [];
        const productNotFound = responseJson.message !== "Successfully Loaded" ? true : false;
        await setStorageData('serachResultCount', responseJson.total_count);
        this.setState({ suggestions: suggestions.slice(0,10), open: true, isLoading: false, resultCount: responseJson.total_count, productNotFound });
      }
      if(apiRequestCallId === this.getTotalCountApi) {
        await setStorageData('serachResultCount', responseJson.total_count);
        this.setState({ resultCount: responseJson.total_count, productNotFound: responseJson.total_count === 0})
      }
    }
  } 

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getSearchList = (token: string) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      query: this.state.searchText
    };

    this.searchApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClickAway = () => {
    this.setState({ open: false });
  };
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if(value) { 
      this.setState({ searchQuery: value }, this.handleGetSuggestion);
    }
    else if(value.length === 0){
      this.setState({ searchQuery: '' },this.handleGetSuggestion)
      removeStorageData("searchQuery")
    }
  };

  handleSuggestionClick = (suggestion: string,categoryId:string) => {
      this.setState({ searchQuery: suggestion, open: false }, this.getSearchCount);
      setStorageData('searchQuery', suggestion);
      setStorageData("sortFilter", "recommended");
      this.props.navigation.navigate("BuyerSearchExploreProduct",  {id: "product", type: encodeURIComponent(suggestion)});      
  };

  handleGetSuggestion = () => {
    if (this.state.searchQuery !== "") {
      this.setState({open:true, isLoading: true})
      const header = {
        "Content-Type": configJSON.searchApiContentType
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const httpBody = {
        "search_keyword": this.state.searchQuery,
        "per_page": 100,
        "page": 1,
        "out_of_stocks": false
      }

      this.suggestionApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSuggestionApiEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({ open: false, isLoading: false })
    }
  }

  getSearchCount = () => {
    if (this.state.searchQuery !== "") {
      const header = {
        "Content-Type": configJSON.searchApiContentType
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const httpBody = {
        "search_keyword": this.state.searchQuery,
        "per_page": 100,
        "page": 1,
        "out_of_stocks": false
      }

      this.getTotalCountApi = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSuggestionApiEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleSearch = (searchQuery: string) => {
    this.setState({ searchQuery })
    setStorageData('searchQuery', searchQuery);
    if(this.state.resultCount === 0 ) {
    this.props.navigation.navigate("BuyerSearchExploreProduct",  {id: 0, type: "noResult"}); 
    }     
  }

  // Customizable Area End
}
