// Customizable Area Start
import React from "react";
import { Box, Button, Grid, InputAdornment, Typography, withStyles } from "@material-ui/core";
import StoreBannerTabController, { Props, configJSON } from "./StoreBannerTabController.web";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import { file } from "../assets";
import { CustomizeBrandInput } from "../CustomizedElementStyle";
import { errorImage } from "../../../mobile-account-login/src/assets";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";
// Customizable Area End

class StoreBannerTab extends StoreBannerTabController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Grid container className={classes.topMargin} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
            <CustomizeBrandInput
              data-test-id="bannerName"
              label={configJSON.bannerNameLabel}
              id="outlined-start-adornment"
              value={this.state.bannerName}
              onChange={this.handleBannerName}
              fullWidth
              inputProps={{
                maxLength: 45
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
            {this.state.bannerNameErr ? (
              <Typography data-test-id="bannerNameErr" className={classes.err}>
                {this.state.bannerNameErr}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Box className={classes.bannerImageLabel}>{configJSON.bannerImage}</Box>
        <input data-test-id="banner_image" type="file" className={classes.bannerImageInput} accept=".jpeg, .jpg, .png" value="" ref={this.bannerImgRef} onChange={this.handleUploadBanner} />
        {this.state.bannerPreview ? (
          <Box className={classes.bannerPreviewContainer}>
            <img src={this.state.bannerPreview} alt="Image Preview" className={classes.bannerPreviewImage} />
            <ClickableBox data-test-id="remove_banner_image" onClick={this.handleRemoveBanner} className={classes.removeBanner}>
              {configJSON.removeText}
            </ClickableBox>
          </Box>
        ) : (
          <label htmlFor="upload-button">
            <Button variant="outlined" fullWidth data-test-id="open_banner_image" onClick={this.handleOpenBanner} className={classes.uploadBanner}>
              <Box>
                <img src={file} />
                <Box className={classes.btnBox}>{configJSON.dropBannerImageText}</Box>
                <Box className={classes.btnInfo}>{configJSON.fileSizeInfoText}</Box>
                <Box className={classes.btnInfo}>{configJSON.recommendedSize} 1126&times;422</Box>
              </Box>
            </Button>
          </label>
        )}
        {this.state.bannerError ? <Typography className={classes.err}>{this.state.bannerError}</Typography> : ""}
        <Grid container className={classes.topMargin} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.topMargin}>
            <CustomizeBrandInput
              data-test-id="bannerlink"
              label={configJSON.bannerLinkLabel}
              id="outlined-start-adornment"
              value={this.state.bannerLink}
              onChange={this.handleBannerLink}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>
              }}
            />
            {this.state.bannerLinkError ? <Typography className={classes.err}>{this.state.bannerLinkError}</Typography> : ""}
          </Grid>
          <Box>
            {this.state.bannerCommonErr && (
              <Box className={classes.errorContainer}>
                <Box>
                  <img src={errorImage} />
                </Box>
                <Box>
                  <Typography data-test-id="bannerCommonErr" variant="body2" className={`${classes.err} ${classes.validate}`}>
                    {this.state.bannerCommonErr}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box className={classes.flexEnd}>
            <Grid container spacing={3} className={classes.btnPosition}>
              <Grid item>
                <CustomOrangeButton data-test-id="nextRef" onClick={this.handleNext}>
                  {configJSON.nextBtn}
                </CustomOrangeButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  topMargin: {
    marginTop: "20px"
  },
  btnPosition: {
    justifyContent: "flex-end"
  },
  bannerImageLabel: {
    marginTop: "30px",
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    fontWeight: 600
  },
  bannerImageInput: {
    display: "none"
  },
  bannerPreviewContainer: {
    width: "fit-content",
    position: "relative" as const,
    marginTop: "30px"
  },
  bannerPreviewImage: {
    maxWidth: "100%",
    maxHeight: "300px",
    alignSelf: "flex-start"
  },
  removeBanner: {
    borderRadius: "5px",
    width: "80px",
    background: "#ff6008",
    padding: "10px 20px",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    marginTop: "10px",
    textAlign:'center' as const,
    color: '#fff'
  },
  uploadBanner: {
    width: "100%",
    height: "210px",
    padding: "20px 0px",
    margin: "10px 0px",
    border: "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: "5px",
    textTransform: "none" as const,
    fontFamily: `"Lato", sans-serif`
  },
  btnBox: {
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`
  },
  btnInfo: {
    color: "#9a9a9a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px"
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "60px",
    padding: "0 16px"
  },
  err: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "12px",
    marginTop: "5px",
    color: "red"
  },
  errorContainer: {
    display: "flex",
    marginLeft: "20px",
    alignItems: "center"
  },
  validate: {
    marginTop: "0",
    marginLeft: "10px"
  }
};

export { StoreBannerTab };
export default withStyles(styles)(StoreBannerTab);

// Customizable Area End
