import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import { ICreateOfferData, IProductProfileError } from "../SellerHomeDashboardComponents/Interfaces";
import { createRef } from "react";

// Customizable Area Start
import moment from "moment";

interface IErrorRes {
  errors: string[]
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  variant:boolean
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  barcode_id: string;
  offerId: string;
  bar_code_info: string;
  product_status: boolean;
  catalog_id: string;
  price_info: number | string;
  sale_price: number | string;
  sale_schedule_from: Date | null;
  sale_schedule_to: Date | null;
  warranty: string;
  errorMsg: string[];
  dealAppliedModal: boolean;
  isDealApplied: boolean;
  offerErrorModal: boolean,
  offerErrorText: string,
  saleFromDate: string,
  saleToDate: string,
  isVariantProduct: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class OffersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCreateOfferCallId: string = "";
  apiUpdateOfferCallId: string = "";
  apiGetExistingOfferId: string = "";
  getProductInfoApiCallId: string = "";
  formRef: React.RefObject<HTMLFormElement>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      barcode_id: "",
      offerId: "",
      bar_code_info: "",
      catalog_id: "",
      product_status: false,
      price_info: "",
      sale_price: "",
      sale_schedule_from: null,
      sale_schedule_to: null,
      warranty: "No Warranty",
      errorMsg: [],
      dealAppliedModal: false,
      isDealApplied: false,
      offerErrorModal: false,
      offerErrorText: '',
      saleFromDate: '',
      saleToDate: '',
      isVariantProduct: false
    };
    this.formRef = createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiCreateOfferCallId) {
        this.handleCreateOfferResponse(responseJson);
      }
      if (apiRequestCallId === this.apiUpdateOfferCallId) {
        this.handleCreateOfferResponse(responseJson);
      }
      if (apiRequestCallId === this.apiGetExistingOfferId) {
        this.handleExistingOffer(responseJson);
      }
      if (apiRequestCallId === this.getProductInfoApiCallId) {
        this.handleProductInfo(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleProductInfo = async (responseJson: any) => {
    if (responseJson) {
      this.checkDealIsAppliedOrNot(responseJson.data.attributes);
      const offerId = responseJson.data.attributes?.catalogue_offer?.id;
      const isVariantProduct = responseJson.data.attributes.is_variant
      this.setState({isVariantProduct: isVariantProduct})
      const barCode = responseJson.data.attributes?.barcode
      this.setState({bar_code_info: barCode?.bar_code ? barCode.bar_code : ''})
      if (offerId) {
        this.setState({
          offerId
        });
        this.getOfferDeatils(offerId);
      } else {
        this.setState({
          offerId: ""
        });
      }
    }
  };

  checkDealIsAppliedOrNot = (responseJson: any) => {
    const dealCatalogue = responseJson.deal_catalogues.length && responseJson.deal_catalogues.map((item: any) => {
      if(item.status === "approved") return item;
    })
    dealCatalogue.length && dealCatalogue.forEach((item: any) => {
      const isApplied = responseJson.deals.length && responseJson.deals.find((a: any) => item?.deal_id === a.id && a.status);
      if(isApplied) {
        this.setState({ isDealApplied: true })
      }
    })
  }

  handleDealAppliedModal = () => {
    this.setState({ offerErrorModal: false });
    const msg: Message = new Message(getName(MessageEnum.SendMessage));
    msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: configJSON.dealsMenu, activeScreen: configJSON.dealsMenu });
    this.send(msg);
  }

  handleCreateOfferResponse = (responseJson: unknown) => {
    const successData = responseJson as ICreateOfferData;
    const errorData = responseJson as IErrorRes;
    if ("data" in successData) {
      const msg: Message = new Message(getName(MessageEnum.SendMessage));
      msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: configJSON.dealsMenu, activeScreen: configJSON.dealsMenu, isVariant : this.state.isVariantProduct});
      this.send(msg);
    }
    if ("errors" in errorData) {
      if(errorData.errors[0]) {
        this.setState({offerErrorModal : true, offerErrorText: "A deal for the product already exists. Please delete the existing deal to create a new offer."})
      }
    }
  };

  handleExistingOffer = (responseJson: unknown) => {
    const successData = responseJson as ICreateOfferData;
    const errorData = responseJson as IProductProfileError;
    if ("data" in successData) {
      const values = successData.data.attributes;
      this.setState({
        price_info: this.state.price_info || values.price_info,
        sale_price: values.sale_price,
        sale_schedule_from: moment(values.sale_schedule_from).isValid() ? moment(values.sale_schedule_from).toDate() : null,
        sale_schedule_to: moment(values.sale_schedule_to).isValid() ? moment(values.sale_schedule_to).toDate() : null,
        warranty: values.warranty,
        product_status: values?.status ? true : false
      });
    }
    if ("errors" in errorData) {
      return this.setState({ errorMsg: errorData.errors });
    }
  };

  getOfferDeatils = async (offerId: any) => {
    const headers = { "Content-Type": configJSON.contentType, token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetExistingOfferId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateOfferEndpoint + this.state.catalog_id + "/catalogue_offers/" + offerId);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createOffer = async (values: any) => {
      const headers = { "Content-Type": configJSON.contentType, token: await getStorageData("authToken") };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      if (this.state.offerId) {
        this.apiUpdateOfferCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateOfferEndpoint + this.state.catalog_id + "/catalogue_offers/" + this.state.offerId);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
      } else {
        this.apiCreateOfferCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateOfferEndpoint + this.state.catalog_id + "/catalogue_offers");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
      }
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            attributes: {
              price_info: values.price_info,
              sale_price: values.sale_price,
              sale_schedule_from: values.sale_schedule_from ? this.dateFormat("saleFromDate", values.sale_schedule_from) : '',
              sale_schedule_to:values.sale_schedule_to ? this.dateFormat("saleToDate", values.sale_schedule_to) : '',
              warranty: values.warranty,
              status: values.statusSwitch
            }
          }
        })
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductInformation = (catalogId: any, newProductId: any) => {
    if (catalogId) {
      this.getProductInfo(catalogId);
    } else this.getProductInfo(newProductId);
  };
  async componentDidMount(): Promise<void> {
    const newProductId = await getStorageData("newProductId");
    const catalogId = await getStorageData("catalog_id");
    const price_info = await getStorageData("retail_price")
    this.getProductInformation(catalogId, newProductId);
    this.setState({ catalog_id: await getStorageData("catalog_id"), price_info });
  }

  getProductInfo = async (id: string) => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  dateFormat = (typeOfDate: string, date: Date | string | any) => {
      const dateValue = new Date(date as string);
      const formatDay = String(dateValue.getDate()).padStart(2, '0'); 
      const formatMonth = String(dateValue.getMonth() + 1).padStart(2, '0'); 
      const formatYear = dateValue.getFullYear();
  
      const formattedDate = `${formatDay}-${formatMonth}-${formatYear}`;
      if(typeOfDate === "saleFromDate") {
        this.setState({saleFromDate: formattedDate})
        return formattedDate
      }
      else {
        this.setState({saleToDate : formattedDate})
        return formattedDate
      }
  }
  // Customizable Area End
}
